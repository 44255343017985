import Posts from "../../../Components/postsCompMain/posts/Posts"

const data = {
  title: "Breaking News",
}
function BreakingNewsPage(){
  return (
    <>
      <Posts data={data} />
    </>
  )
}
export default BreakingNewsPage