
function UpdatePhrases() {
    return (
        <>
        <section className="content">
  <div className="box">
    <div className="box-header with-border">
      <div className="left">
        <h3 className="box-title">Hindi</h3>
      </div>
    </div>{/* /.box-header */}
    <div className="box-body">
      <div className="row">
        {/* include message block */}
        <div className="col-sm-12">
          {/*print error messages*/}
          {/*print custom error message*/}
          {/*print custom success message*/}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive">
            <div className="tab-content">
              <h4>Edit Translations</h4>
              <form action="https://prernamedia.com/admin/search-phrases" method="get" acceptCharset="utf-8">
                <div className="phrases-search">
                  <input type="hidden" name="id" defaultValue={2} />
                  <input type="text" name="q" className="form-control" placeholder="Search" required />
                  <button type="submit" className="btn btn-primary"><i className="fa fa-search" /></button>
                </div>
              </form>{/* form end */}
              <div id="tab1" className="tab-pane fade in active">
                <form action="https://prernamedia.com/language_controller/update_phrases_post" method="post" acceptCharset="utf-8">
                  <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />                                                                 
                  <input type="hidden" name="id" className="form-control" defaultValue={2} />
                  <input type="hidden" id="lang_folder" className="form-control" defaultValue="hindi" />
                  <table className="table table-bordered table-striped dataTable">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Phrase</th>
                        <th>Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>1</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="all" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="All" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>2</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="all_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="All Posts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>3</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_page" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Page" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>4</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_post" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Post" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>5</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_video" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Video" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>6</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_widget" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Widget" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>7</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_poll" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Poll" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>8</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_category" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Category" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>9</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_subcategory" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Subcategory" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>10</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_audio" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Audio" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>11</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_link" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Menu Link" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>12</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_language" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Language" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>13</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_image" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Image" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>14</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_reading_list" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add to Reading List" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>15</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="ad_spaces" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Ad Spaces" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>16</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="admin" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Admin" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>17</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="admin_panel" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Control Panel" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>18</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="about_me" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="About Me" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>19</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="audio" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Audio" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>20</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="audios" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Audios" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>21</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="auto_update" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Auto Update" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>22</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="all_users_can_vote" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="All Users Can Vote" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>23</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="address" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Address" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>24</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="app_name" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Application Name" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>25</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="app_id" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="App ID" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>26</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="app_secret" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="App Secret" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>27</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="avatar" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Avatar" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>28</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_featured" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add to Featured" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>29</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_breaking" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add to Breaking" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>30</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_slider" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add to Slider" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>31</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_recommended" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add to Recommended" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>32</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="active" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Active" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>33</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="additional_images" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Additional Images" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>34</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="approve" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Approve" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>35</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_image_url" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Image Url" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>36</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="back" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Back" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>37</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="breadcrumb_home" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Home" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>38</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="breadcrumb_videos" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Videos" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>39</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="btn_send" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Send" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>40</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="btn_submit" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Submit" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>41</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="btn_reply" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Reply" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>42</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="btn_delete" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Delete" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>43</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="btn_like" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Like" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>44</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="btn_goto_home" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Go Back to the Homepage" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>45</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="breaking_news" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Breaking News" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>46</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="banned" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Banned" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>47</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="ban_user" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Ban User" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>48</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="block_color" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Top Header and Block Heads Color" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>49</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="breaking" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Breaking" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>50</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="category" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Category" /></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-sm-12 m-t-30">
                    <div className="row">
                      <div className="pull-right">
                        <a href="https://prernamedia.com/admin/language-settings" className="btn btn-danger m-r-5">Back</a>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </form>{/* form end */}
              </div>
              <div id="tab2" className="tab-pane fade ">
                <form action="https://prernamedia.com/language_controller/update_phrases_post" method="post" acceptCharset="utf-8">
                  <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />                                                                        
                  <input type="hidden" name="id" className="form-control" defaultValue={2} />
                  <input type="hidden" id="lang_folder" className="form-control" defaultValue="hindi" />
                  <table className="table table-bordered table-striped dataTable">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Phrase</th>
                        <th>Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>51</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="change_avatar" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Change Avatar" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>52</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="change_password" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Change Password" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>53</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="create_account" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Create an Account" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>54</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="comment" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Comment" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>55</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="comments" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Comments" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>56</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="custom" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Custom" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>57</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="close" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Close" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>58</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="contact_messages" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Contact Messages" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>59</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="categories" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Categories" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>60</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="category_name" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Category Name" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>61</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="category_block_style" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Category Block Style" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>62</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="configurations" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Configurations" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>63</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="color" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Color" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>64</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="client_id" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Client ID" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>65</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="client_secret" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Client Secret" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>66</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="contact" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Contact" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>67</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="contact_text" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Contact Text" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>68</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="comment_system" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Comment System" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>69</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="change_favicon" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Change favicon" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>70</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="change_user_role" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Change User Role" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>71</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="change_logo" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Change logo" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>72</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="copyright" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Copyright" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>73</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="contact_settings" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Contact Settings" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>74</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="content" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Content" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>75</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_category" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this category?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>76</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_image" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this image?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>77</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_page" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this page?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>78</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_poll" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this poll?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>79</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_post" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this post?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>80</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_widget" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this widget?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>81</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_comment" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this comment?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>82</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_message" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this message?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>83</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_email" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this email?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>84</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_user" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this user?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>85</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_ban" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to ban this user?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>86</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_remove_ban" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to remove ban for this user?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>87</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_link" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this link?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>88</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_rss" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this feed?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>89</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_language" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this language?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>90</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete selected posts?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>91</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_comments" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete selected comments?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>92</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="cookies_warning" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Cookies Warning" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>93</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="date" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Date" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>94</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="date_added" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Date Added" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>95</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="delete" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Delete" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>96</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="delete_reading_list" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Remove from Reading List" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>97</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="default" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Default" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>98</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="disable" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Disable" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>99</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="days_remaining" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Days Remaining" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>100</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="date_publish" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Date Published" /></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-sm-12 m-t-30">
                    <div className="row">
                      <div className="pull-right">
                        <a href="https://prernamedia.com/admin/language-settings" className="btn btn-danger m-r-5">Back</a>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </form>{/* form end */}
              </div>
              <div id="tab3" className="tab-pane fade ">
                <form action="https://prernamedia.com/language_controller/update_phrases_post" method="post" acceptCharset="utf-8">
                  <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />                                                                                                                             
                  <input type="hidden" name="id" className="form-control" defaultValue={2} />
                  <input type="hidden" id="lang_folder" className="form-control" defaultValue="hindi" />
                  <table className="table table-bordered table-striped dataTable">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Phrase</th>
                        <th>Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>101</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="drafts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Drafts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>102</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="description" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Description" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>103</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="default_language" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Default Language" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>104</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="dont_add_menu" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Don't Add to Menu" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>105</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="email" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Email" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>106</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="edit" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Edit" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>107</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="enable" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Enable" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>108</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="email_settings" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Email Settings" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>109</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="edit_phrases" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Edit Phrases" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>110</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="example" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Example" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>111</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="featured_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Featured Posts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>112</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="facebook_comments" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Facebook Comments" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>113</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="form_username" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Username" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>114</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="form_email" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Email" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>115</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="form_password" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Password" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>116</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="form_old_password" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Old Password" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>117</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="form_confirm_password" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Confirm Password" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>118</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="footer_random_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Random Posts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>119</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="footer_follow" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Social Media" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>120</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="footer_newsletter" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Subscribe here to get interesting stuff and updates!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>121</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="fonts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Fonts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>122</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="feed_name" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Feed Name" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>123</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="feed_url" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Feed URL" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>124</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="filter" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Filter" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>125</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="file_manager" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="File Manager" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>126</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="feed" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Feed" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>127</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="folder_name" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Folder Name" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>128</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="footer_about_section" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Footer About Section" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>129</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="facebook_comments_code" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Facebook Comments Plugin Code" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>130</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="featured" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Featured" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>131</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="featured_order" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Featured Order" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>132</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="favicon" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Favicon" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>133</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="footer" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Footer" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>134</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="gallery" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Gallery" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>135</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="gallery_categories" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Gallery Categories" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>136</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="general_settings" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="General Settings" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>137</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="gmail_smtp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Gmail SMTP" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>138</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="google_analytics" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Google Analytics" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>139</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="google_analytics_code" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Google Analytics Code" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>140</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="google_recaptcha" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Google reCAPTCHA" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>141</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="site_key" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Site Key" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>142</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="secret_key" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Secret Key" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>143</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="get_video_from_url" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Get Video from URL" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>144</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="get_video" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Get Video" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>145</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="generate" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Generate" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>146</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="hide" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Hide" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>147</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="home" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Home" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>148</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="home_title" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Home Title" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>149</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="id" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Id" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>150</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="images" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Images" /></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-sm-12 m-t-30">
                    <div className="row">
                      <div className="pull-right">
                        <a href="https://prernamedia.com/admin/language-settings" className="btn btn-danger m-r-5">Back</a>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </form>{/* form end */}
              </div>
              <div id="tab4" className="tab-pane fade ">
                <form action="https://prernamedia.com/language_controller/update_phrases_post" method="post" acceptCharset="utf-8">
                  <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />
                  <input type="hidden" name="id" className="form-control" defaultValue={2} />
                  <input type="hidden" id="lang_folder" className="form-control" defaultValue="hindi" />
                  <table className="table table-bordered table-striped dataTable">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Phrase</th>
                        <th>Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>151</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="image_description" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Image Description" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>152</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="index" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Index" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>153</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="import_rss_feed" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Import RSS Feed" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>154</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="invalid_url" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Invalid URL!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>155</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="image" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Image" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>156</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="inactive" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Inactive" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>157</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="keywords" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Keywords" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>158</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="last_comments" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Latest Comments" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>159</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="last_contact_messages" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Latest Contact Messages" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>160</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="latest_users" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Latest Users" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>161</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="latest_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Latest Posts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>162</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="label" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Label" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>163</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="label_video" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Video" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>164</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="leave_message" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Send a Message" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>165</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="load_more" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Load More" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>166</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="login" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Login" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>167</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="logout" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Logout" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>168</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="login_error" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Wrong username or password!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>169</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="leave_reply" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Leave a Reply" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>170</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="language" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Language" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>171</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="languages" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Languages" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>172</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="language_settings" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Language Settings" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>173</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="language_name" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Language Name" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>174</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="language_code" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Language Code" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>175</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="logo" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Logo" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>176</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="logo_footer" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Logo Footer" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>177</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="location" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Location" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>178</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="link" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Link" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>179</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="left_to_right" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Left to Right" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>180</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="main_navigation" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="MAIN NAVIGATION" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>181</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="message" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Message" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>182</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="message_email_unique_error" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="The email has already been taken." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>183</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="message_newsletter_success" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Your email address has been successfully added!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>184</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="message_newsletter_error" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Your email address is already registered!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>185</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="message_contact_success" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Your message has been successfully sent!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>186</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="message_contact_error" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="There was a problem sending your message!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>187</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="message_register_error" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="There was a problem during registration. Please try again!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>188</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="message_profile_success" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Your profile has been successfully updated!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>189</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="message_comment_delete" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this comment?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>190</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="message_invalid_email" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Invalid email address!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>191</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="message_page_auth" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="You must be logged in to view this page!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>192</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="message_post_auth" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="You must be logged in to view this post!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>193</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="message_change_password_success" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Your password has been successfully changed!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>194</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="message_change_password_error" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="There was a problem changing your password!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>195</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="message_ban_error" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Your account has been banned!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>196</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_suc_added" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="successfully added!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>197</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_suc_updated" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="successfully updated!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>198</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_suc_deleted" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="successfully deleted!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>199</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_error" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="An error occurred please try again!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>200</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_slug_used" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="The slug you entered is being used by another user!" /></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-sm-12 m-t-30">
                    <div className="row">
                      <div className="pull-right">
                        <a href="https://prernamedia.com/admin/language-settings" className="btn btn-danger m-r-5">Back</a>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </form>{/* form end */}
              </div>
              <div id="tab5" className="tab-pane fade ">
                <form action="https://prernamedia.com/language_controller/update_phrases_post" method="post" acceptCharset="utf-8">
                  <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />
                  <input type="hidden" name="id" className="form-control" defaultValue={2} />
                  <input type="hidden" id="lang_folder" className="form-control" defaultValue="hindi" />
                  <table className="table table-bordered table-striped dataTable">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Phrase</th>
                        <th>Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>201</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_email_sent" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Email successfully sent!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>202</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_role_changed" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="User role successfully changed!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>203</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_user_banned" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="User successfully banned!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>204</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_ban_removed" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="User ban successfully removed!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>205</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_delete_subcategories" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Please delete subcategories belonging to this category first!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>206</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_delete_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Please delete posts belonging to this category first!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>207</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_delete_images" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Please delete images belonging to this category first!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>208</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_add_slider" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post added to slider!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>209</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_remove_slider" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post removed from slider!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>210</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_add_featured" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post added to featured posts!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>211</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_remove_featured" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post removed from featured posts!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>212</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_add_breaking" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post added to breaking news!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>213</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_remove_breaking" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post removed from breaking news!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>214</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_add_recommended" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post added to recommended posts!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>215</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_remove_recommended" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post removed from recommended posts!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>216</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_post_approved" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post approved!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>217</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_page_delete" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Default pages cannot be deleted!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>218</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_language_delete" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Default language cannot be deleted!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>219</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_widget_delete" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Default widgets cannot be deleted!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>220</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_published" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post successfully published!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>221</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_recaptcha" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Please confirm that you are not a robot!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>222</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_cron_sitemap" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="With this URL you can automatically update your sitemap." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>223</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_cron_feed" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="With this URL you can automatically update your feeds." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>224</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="multilingual_system" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Multilingual System" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>225</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="more_info" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="More info" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>226</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="meta_tag" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Meta Tag" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>227</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="mail_protocol" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Mail Protocol" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>228</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="mail" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Mail" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>229</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="mail_title" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Mail Title" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>230</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="mail_host" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Mail Host" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>231</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="mail_port" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Mail Port" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>232</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="mail_username" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Mail Username" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>233</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="mail_password" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Mail Password" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>234</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="menu_limit" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Menu Limit " /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>235</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="main_menu" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Main Menu" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>236</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="name" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Name" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>237</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="nav_home" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Home" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>238</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="nav_login" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Login" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>239</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="nav_register" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Register" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>240</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="nav_reset_password" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Reset Password" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>241</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="next_article" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Next Article" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>242</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="next_video" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Next Video" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>243</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="no" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="No" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>244</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="newsletter" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Newsletter" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>245</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="navigation" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Navigation" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>246</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="number_of_posts_import" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Number of Posts to Import" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>247</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="or" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="or" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>248</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="options" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Options" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>249</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="online" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="online" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>250</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="option_1" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Option 1" /></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-sm-12 m-t-30">
                    <div className="row">
                      <div className="pull-right">
                        <a href="https://prernamedia.com/admin/language-settings" className="btn btn-danger m-r-5">Back</a>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </form>{/* form end */}
              </div>
              <div id="tab6" className="tab-pane fade ">
                <form action="https://prernamedia.com/language_controller/update_phrases_post" method="post" acceptCharset="utf-8">
                  <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />                                                                                                                      
                  <input type="hidden" name="id" className="form-control" defaultValue={2} />
                  <input type="hidden" id="lang_folder" className="form-control" defaultValue="hindi" />
                  <table className="table table-bordered table-striped dataTable">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Phrase</th>
                        <th>Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>251</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="option_2" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Option 2" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>252</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="option_3" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Option 3" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>253</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="option_4" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Option 4" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>254</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="option_5" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Option 5" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>255</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="option_6" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Option 6" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>256</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="option_7" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Option 7" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>257</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="option_8" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Option 8" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>258</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="option_9" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Option 9" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>259</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="option_10" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Option 10" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>260</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="optional" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Optional" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>261</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="order" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Menu Order" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>262</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="order_1" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Order" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>263</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="optional_url" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Optional URL" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>264</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="optional_url_name" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post Optional URL Button Name" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>265</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="only_registered" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Only Registered" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>266</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="page" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Page" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>267</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="pages" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Pages" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>268</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="page_type" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Page Type" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>269</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="page_not_found" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Page not found" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>270</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="page_not_found_sub" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="The page you are looking for doesn't exist." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>271</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="panel" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Panel" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>272</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="previous_article" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Previous Article" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>273</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="previous_video" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Previous Video" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>274</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="placeholder_username" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Username" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>275</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="placeholder_password" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Password" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>276</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="placeholder_search" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Search..." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>277</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="placeholder_email" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Email Address" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>278</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="placeholder_message" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Message" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>279</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="placeholder_comment" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Comment..." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>280</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="placeholder_old_password" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Old Password" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>281</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="placeholder_confirm_password" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Confirm Password" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>282</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="placeholder_about_me" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="About Me" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>283</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="post" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>284</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Posts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>285</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="pending_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Pending Posts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>286</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="post_tags" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Tags:" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>287</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="popular_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Popular Posts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>288</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="polls" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Polls" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>289</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="poll" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Poll" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>290</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="profile" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Profile" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>291</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="preferences" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Preferences" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>292</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="parent_category" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Parent Category" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>293</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="publish" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Publish" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>294</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="post_type" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post Type" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>295</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="parent_link" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Parent Link" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>296</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="post_details" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post Details" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>297</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="play_list_empty" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Playlist is empty." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>298</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="pagination_number_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Number of Posts Per Page (Pagination)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>299</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="phone" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Phone" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>300</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="priority" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Priority" /></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-sm-12 m-t-30">
                    <div className="row">
                      <div className="pull-right">
                        <a href="https://prernamedia.com/admin/language-settings" className="btn btn-danger m-r-5">Back</a>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </form>{/* form end */}
              </div>
              <div id="tab7" className="tab-pane fade ">
                <form action="https://prernamedia.com/language_controller/update_phrases_post" method="post" acceptCharset="utf-8">
                  <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />                   
                  <input type="hidden" name="id" className="form-control" defaultValue={2} />
                  <input type="hidden" id="lang_folder" className="form-control" defaultValue="hindi" />
                  <table className="table table-bordered table-striped dataTable">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Phrase</th>
                        <th>Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>301</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="priority_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="The priority of a particular URL relative to other pages on the same site" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>302</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="priority_none" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Automatically Calculated Priority" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>303</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="post_list_style" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post Item List Style" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>304</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="phrases" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Phrases" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>305</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="question" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Question" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>306</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="remember_me" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Remember Me" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>307</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="register" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Register" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>308</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="reset_password_error" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="We can't find a user with that e-mail address!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>309</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="random_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Random Posts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>310</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="reset_password" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Reset Password" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>311</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="related_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Related Posts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>312</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="related_videos" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Related Videos" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>313</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="reading_list" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Reading List" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>314</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="recommended_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Recommended Posts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>315</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="rss_feeds" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="RSS Feeds" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>316</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="read_more_button_text" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Read More Button Text" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>317</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="registered_users_can_vote" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Only Registered Users Can Vote" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>318</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="role" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Role" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>319</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="remove_ban" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Remove Ban" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>320</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="registered_emails" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Registered Emails" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>321</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="registration_system" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Registration System" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>322</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="rss" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="RSS" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>323</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="remove_featured" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Remove from Featured" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>324</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="remove_breaking" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Remove from Breaking" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>325</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="remove_recommended" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Remove from Recommended" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>326</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="remove_slider" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Remove from Slider" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>327</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="recommended" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Recommended" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>328</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="right_to_left" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Right to Left" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>329</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="save_changes" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Save Changes" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>330</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="social_accounts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Social Accounts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>331</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="search_noresult" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="No results found." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>332</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="share" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Share" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>333</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="search" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Search" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>334</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="slider_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Slider Posts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>335</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="slug" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Slug" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>336</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>337</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="short_form" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Short Form" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>338</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="select" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Select" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>339</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="select_an_option" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Select an option" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>340</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="select_category" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Select a category" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>341</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="seo_tools" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="SEO Tools" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>342</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="seo_options" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Seo options" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>343</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="social_login_configuration" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Social Login Configuration" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>344</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="settings" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Settings" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>345</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_at_homepage" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show on Homepage" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>346</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_cookies_warning" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show Cookies Warning" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>347</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="slug_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="If you leave it blank, it will be generated automatically." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>348</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="subcategory" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Subcategory" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>349</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="subcategories" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Subcategories" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>350</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="select_image" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Select Image" /></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-sm-12 m-t-30">
                    <div className="row">
                      <div className="pull-right">
                        <a href="https://prernamedia.com/admin/language-settings" className="btn btn-danger m-r-5">Back</a>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </form>{/* form end */}
              </div>
              <div id="tab8" className="tab-pane fade ">
                <form action="https://prernamedia.com/language_controller/update_phrases_post" method="post" acceptCharset="utf-8">
                  <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />
                  <input type="hidden" name="id" className="form-control" defaultValue={2} />
                  <input type="hidden" id="lang_folder" className="form-control" defaultValue="hindi" />
                  <table className="table table-bordered table-striped dataTable">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Phrase</th>
                        <th>Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>351</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="select_audio" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Select Audio" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>352</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="select_video" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Select Video" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>353</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_only_registered" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show Only to Registered Users" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>354</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_title" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show Title" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>355</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_breadcrumb" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show Breadcrumb" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>356</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_right_column" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show Right Column" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>357</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="status" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Status" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>358</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="summary" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Summary" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>359</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_read_more_button" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show Read More Button" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>360</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="save_draft" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Save as Draft" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>361</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="smtp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="SMTP" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>362</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="social_media_settings" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Social Media Settings" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>363</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="shared" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Shared" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>364</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_featured_section" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show Featured Section" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>365</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_latest_posts_homepage" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show Latest Posts on Homepage" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>366</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_news_ticker" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show News Ticker" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>367</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_post_author" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show Post Author" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>368</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_post_dates" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show Post Date" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>369</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_post_view_counts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show Post View Count" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>370</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="site_title" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Site Title" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>371</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="site_description" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Site Description" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>372</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="send_email_registered" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Send Email to Registered Emails" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>373</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="subject" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Subject" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>374</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="send_email" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Send Email" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>375</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="save" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Save" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>376</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="site_color" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Site Color" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>377</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="sitemap" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Sitemap" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>378</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="scheduled_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Scheduled Posts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>379</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="scheduled_post" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Scheduled Post" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>380</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="select_file" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Select File" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>381</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_on_menu" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show on Menu" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>382</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="slider_order" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Slider Order" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>383</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="slider" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Slider" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>384</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="settings_language" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Settings Language" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>385</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="tag" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Tag" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>386</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="text_list_empty" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Your reading list is empty." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>387</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="total_vote" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Total Vote:" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>388</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="tags" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Tags" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>389</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="type_tag" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Type tag and hit enter" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>390</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="title" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Title" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>391</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="top_menu" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Top Menu" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>392</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="text_direction" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Text Direction" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>393</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="type" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Type" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>394</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="phrase" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Phrase" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>395</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_profile" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Profile" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>396</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="user" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="User" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>397</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="users" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Users" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>398</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="username" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Username" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>399</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="user_agreement" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="User Agreement" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>400</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_category" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Category" /></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-sm-12 m-t-30">
                    <div className="row">
                      <div className="pull-right">
                        <a href="https://prernamedia.com/admin/language-settings" className="btn btn-danger m-r-5">Back</a>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </form>{/* form end */}
              </div>
              <div id="tab9" className="tab-pane fade ">
                <form action="https://prernamedia.com/language_controller/update_phrases_post" method="post" acceptCharset="utf-8">
                  <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />
                  <input type="hidden" name="id" className="form-control" defaultValue={2} />
                  <input type="hidden" id="lang_folder" className="form-control" defaultValue="hindi" />
                  <table className="table table-bordered table-striped dataTable">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Phrase</th>
                        <th>Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>401</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_subcategory" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Subcategory" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>402</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_image" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Image" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>403</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_link" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Menu Link" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>404</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_rss_feed" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update RSS Feed" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>405</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="upload" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Upload" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>406</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>407</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_widget" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Widget" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>408</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="upload_video" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Upload Video" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>409</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_video" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Video" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>410</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_page" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Page" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>411</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_poll" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Poll" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>412</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_post" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Post" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>413</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_language" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Language" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>414</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_sitemap" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Sitemap" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>415</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="url" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="URL" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>416</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="view_all" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="View All" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>417</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="view_all_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="View All Posts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>418</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="view_options" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="View Options" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>419</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="view_results" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="View Results" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>420</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="view_site" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="View Site" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>421</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="video" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Video" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>422</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="videos" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Videos" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>423</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="vote" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Vote" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>424</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="voted_message" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="You already voted this poll before." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>425</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="visual_settings" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Visual Settings" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>426</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="view_result" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="View Results" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>427</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="visibility" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Visibility" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>428</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="audio_name" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Audio Name" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>429</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="download_button" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Download Button" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>430</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="musician" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Musician" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>431</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="video_embed_code" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Video Embed Code" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>432</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="video_thumbnails" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Video Thumbnail" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>433</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="image_for_video" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Image for video" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>434</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="video_url" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Video URL" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>435</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="video_name" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Video Name" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>436</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="video_file" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Video File" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>437</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="vote_permission" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Vote Permission" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>438</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="widget" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Widget" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>439</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="widgets" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Widgets" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>440</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="wrong_password_error" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Wrong old password!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>441</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="yes" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Yes" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>442</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="facebook" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Facebook" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>443</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="twitter" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Twitter" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>444</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="google" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Google" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>445</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="January" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Jan" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>446</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="February" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Feb" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>447</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="March" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Mar" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>448</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="April" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Apr" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>449</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="May" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="May" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>450</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="June" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Jun" /></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-sm-12 m-t-30">
                    <div className="row">
                      <div className="pull-right">
                        <a href="https://prernamedia.com/admin/language-settings" className="btn btn-danger m-r-5">Back</a>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </form>{/* form end */}
              </div>
              <div id="tab10" className="tab-pane fade ">
                <form action="https://prernamedia.com/language_controller/update_phrases_post" method="post" acceptCharset="utf-8">
                  <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />                                                                      
                  <input type="hidden" name="id" className="form-control" defaultValue={2} />
                  <input type="hidden" id="lang_folder" className="form-control" defaultValue="hindi" />
                  <table className="table table-bordered table-striped dataTable">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Phrase</th>
                        <th>Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>451</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="July" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Jul" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>452</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="August" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Aug" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>453</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="September" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Sep" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>454</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="October" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Oct" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>455</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="November" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Nov" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>456</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="December" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Dec" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>457</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="select_ad_spaces" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Select Ad Space" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>458</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Ad Space" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>459</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="header_top_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Header" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>460</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="index_top_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Index (Top)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>461</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="index_bottom_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Index (Bottom)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>462</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="post_top_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post Details (Top)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>463</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="post_bottom_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post Details (Bottom)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>464</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="posts_top_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Posts (Top)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>465</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="posts_bottom_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Posts (Bottom)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>466</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="category_top_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Category (Top)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>467</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="category_bottom_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Category (Bottom)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>468</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="tag_top_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Tag (Top)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>469</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="tag_bottom_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Tag (Bottom)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>470</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="search_top_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Search (Top)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>471</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="search_bottom_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Search (Bottom)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>472</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="profile_top_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Profile (Top)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>473</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="profile_bottom_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Profile (Bottom)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>474</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="reading_list_top_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Reading List (Top)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>475</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="reading_list_bottom_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Reading List (Bottom)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>476</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="sidebar_top_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Sidebar (Top)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>477</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="sidebar_bottom_ad_space" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Sidebar (Bottom)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>478</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="banner" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Banner" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>479</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="paste_ad_code" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Ad Code" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>480</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="upload_your_banner" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Create Ad Code" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>481</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="paste_ad_url" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Ad URL" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>482</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="primary_font" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Primary Font (Main)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>483</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="secondary_font" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Secondary Font (Titles)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>484</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="tertiary_font" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Tertiary Font (Post & Page Text)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>485</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="download_sitemap" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Download Sitemap" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>486</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="frequency" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Frequency" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>487</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="frequency_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="This value indicates how frequently the content at a particular URL is likely to change" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>488</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="none" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="None" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>489</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="always" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Always" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>490</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="hourly" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Hourly" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>491</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="daily" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Daily" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>492</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="weekly" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Weekly" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>493</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="monthly" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Monthly" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>494</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="yearly" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Yearly" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>495</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="never" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Never" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>496</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="last_modification" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Last Modification" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>497</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="last_modification_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="The time the URL was last modified" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>498</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="server_response" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Server's Response" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>499</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="form_validation_required" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="The {field} field is required." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>500</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="form_validation_min_length" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="The {field} field must be at least {param} characters in length." /></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-sm-12 m-t-30">
                    <div className="row">
                      <div className="pull-right">
                        <a href="https://prernamedia.com/admin/language-settings" className="btn btn-danger m-r-5">Back</a>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </form>{/* form end */}
              </div>
              <div id="tab11" className="tab-pane fade ">
                <form action="https://prernamedia.com/language_controller/update_phrases_post" method="post" acceptCharset="utf-8">
                  <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />
                  <input type="hidden" name="id" className="form-control" defaultValue={2} />
                  <input type="hidden" id="lang_folder" className="form-control" defaultValue="hindi" />
                  <table className="table table-bordered table-striped dataTable">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Phrase</th>
                        <th>Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>501</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="form_validation_max_length" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="The {field} field cannot exceed {param} characters in length." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>502</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="form_validation_matches" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="The {field} field does not match the {param} field." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>503</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="form_validation_is_unique" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="The {field} field must contain a unique value." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>504</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="emoji_reactions" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Emoji Reactions" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>505</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="whats_your_reaction" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="What's Your Reaction?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>506</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="like" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Like" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>507</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="dislike" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Dislike" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>508</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="love" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Love" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>509</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="funny" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Funny" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>510</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="angry" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Angry" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>511</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="sad" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Sad" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>512</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="wow" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Wow" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>513</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="admin_panel_link" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Admin Panel Link" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>514</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="number_of_links_in_menu" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="The number of links that appear in the menu" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>515</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="no_records_found" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="No records found." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>516</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="reset" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Reset" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>517</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="send_email_subscribers" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Send Email to Subscribers" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>518</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="subscribe" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Subscribe" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>519</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="subscribers" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Subscribers" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>520</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="logo_email" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Logo Email" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>521</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="preview" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Preview" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>522</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="hit" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Hit" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>523</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="post_owner" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post Owner" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>524</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="send_contact_to_mail" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Send Contact Messages to Email Address" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>525</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="contact_messages_will_send" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Contact messages will be sent to this email." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>526</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_updated" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Changes successfully saved!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>527</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_user_added" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="User successfully added!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>528</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="contact_message" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Contact Message" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>529</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="dont_want_receive_emails" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Don't want receive these emails?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>530</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="unsubscribe" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Unsubscribe" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>531</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="unsubscribe_successful" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Unsubscribe Successful!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>532</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="send_post_to_subscribes" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Send Post to All Subscribers (Newsletter)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>533</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_unsubscribe" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="You will no longer receive emails from us!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>534</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="invalid_feed_url" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Invalid feed URL!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>535</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="cache_system" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Cache System" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>536</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="cache_refresh_time" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Cache Refresh Time (Minute)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>537</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="cache_refresh_time_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="After this time, your cache files will be refreshed." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>538</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="refresh_cache_database_changes" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Refresh Cache Files When Database Changes" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>539</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="reset_cache" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Reset Cache" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>540</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_reset_cache" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="All cache files have been deleted!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>541</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="choose_post_format" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Choose a Post Format" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>542</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="article" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Article" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>543</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_user" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add User" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>544</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="administrators" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Administrators" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>545</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_username_unique_error" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="The username has already been taken." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>546</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_posts_as_draft" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Posts as Draft" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>547</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="roles_permissions" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Roles & Permissions" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>548</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="permissions" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Permissions" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>549</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="all_permissions" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="All Permissions" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>550</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="manage_all_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Manage All Posts" /></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-sm-12 m-t-30">
                    <div className="row">
                      <div className="pull-right">
                        <a href="https://prernamedia.com/admin/language-settings" className="btn btn-danger m-r-5">Back</a>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </form>{/* form end */}
              </div>
              <div id="tab12" className="tab-pane fade ">
                <form action="https://prernamedia.com/language_controller/update_phrases_post" method="post" acceptCharset="utf-8">
                  <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />
                  <input type="hidden" name="id" className="form-control" defaultValue={2} />
                  <input type="hidden" id="lang_folder" className="form-control" defaultValue="hindi" />
                  <table className="table table-bordered table-striped dataTable">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Phrase</th>
                        <th>Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>551</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="edit_role" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Edit Role" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>552</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="role_name" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Role Name" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>553</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_not_authorized" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="You are not authorized to perform this operation!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>554</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_all_files" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show all Files" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>555</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_only_own_files" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show Only Users Own Files" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>556</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="approve_added_user_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Approve Added User Posts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>557</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="approve_updated_user_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Approve Updated User Posts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>558</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="this_week" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="This Week" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>559</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="this_month" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="This Month" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>560</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="this_year" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="This Year" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>561</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="timezone" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Timezone" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>562</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_send_confirmation_email" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="A confirmation email has been sent to your email address for activation. Please confirm your account." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>563</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_confirmation_email" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Please confirm your email address by clicking the button below." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>564</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_confirmed" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Your email address has been successfully confirmed!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>565</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_confirmed_required" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Please verify your email address!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>566</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirmed" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Confirmed" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>567</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="unconfirmed" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Unconfirmed" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>568</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_your_email" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Confirm Your Email" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>569</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="email_verification" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Email Verification" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>570</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="resend_activation_email" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Resend Activation Email" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>571</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="please_select_option" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Please select an option!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>572</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="just_now" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Just Now" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>573</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="ago" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="ago" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>574</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="minute" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="minute" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>575</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="minutes" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="minutes" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>576</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="hour" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="hour" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>577</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="hours" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="hours" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>578</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="day" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="day" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>579</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="days" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="days" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>580</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="month" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="month" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>581</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="months" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="months" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>582</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="year" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="year" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>583</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="years" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="years" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>584</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="follow" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Follow" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>585</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="unfollow" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Unfollow" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>586</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="following" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Following" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>587</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="followers" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Followers" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>588</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="member_since" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Member since" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>589</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="email_template" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Email Template" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>590</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_user_email" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Confirm User Email" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>591</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="warning" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Warning" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>592</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="gmail_warning" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="To send e-mails with Gmail server, please read Email Settings section in our documentation." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>593</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="album" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Album" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>594</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="albums" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Albums" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>595</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="gallery_albums" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Gallery Albums" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>596</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_album" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Album" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>597</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="album_name" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Album Name" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>598</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_delete_album" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Please delete categories belonging to this album first!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>599</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_album" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this album?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>600</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_album" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Album" /></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-sm-12 m-t-30">
                    <div className="row">
                      <div className="pull-right">
                        <a href="https://prernamedia.com/admin/language-settings" className="btn btn-danger m-r-5">Back</a>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </form>{/* form end */}
              </div>
              <div id="tab13" className="tab-pane fade ">
                <form action="https://prernamedia.com/language_controller/update_phrases_post" method="post" acceptCharset="utf-8">
                  <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />
                  <input type="hidden" name="id" className="form-control" defaultValue={2} />
                  <input type="hidden" id="lang_folder" className="form-control" defaultValue="hindi" />
                  <table className="table table-bordered table-striped dataTable">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Phrase</th>
                        <th>Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>601</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="select_multiple_images" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="You can select multiple images." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>602</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="album_cover" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Album Cover" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>603</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="set_as_album_cover" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Set as Album Cover" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>604</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_email_on_profile" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show Email on Profile Page" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>605</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="mail_library" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Mail Library" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>606</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="terms_conditions" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Terms & Conditions" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>607</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="terms_conditions_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="I have read and agree to the" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>608</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="forgot_password" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Forgot Password" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>609</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="email_reset_password" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Please click on the button below to reset your password." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>610</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="reset_password_success" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="We've sent an email for resetting your password to your email address. Please check your email for next steps." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>611</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="new_password" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="New Password" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>612</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="or_register_with_email" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Or register with email" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>613</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="or_login_with_email" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Or login with email" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>614</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="enter_email_address" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Enter your email address" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>615</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="enter_new_password" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Enter your new password" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>616</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="to" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="To:" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>617</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="send_email_subscriber" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Send Email to Subscriber" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>618</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="post_comment" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post Comment" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>619</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="load_more_comments" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Load More Comments" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>620</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="author" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Author" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>621</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_cron_scheduled" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="If you want to automatically publish scheduled posts, you should create a Cron Job function with this URL. For more information, please read the documentation." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>622</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_messages" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete selected messages?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>623</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="sort_slider_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Sort Slider Posts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>624</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="sort_featured_posts" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Sort Featured Posts" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>625</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="by_date" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="by Date" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>626</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="by_slider_order" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="by Slider Order" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>627</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="by_featured_order" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="by Featured Order" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>628</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="uploading" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Uploading..." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>629</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="vkontakte" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="VKontakte" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>630</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_gallery" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Gallery" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>631</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_gallery" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Gallery" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>632</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="gallery_post_items" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Gallery Post Items" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>633</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_new_item" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add New Item" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>634</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_item" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this item?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>635</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="gallery_post" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Gallery Post" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>636</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="next" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Next" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>637</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="previous" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Previous" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>638</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="gallery_post_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="A collection of images" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>639</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_article" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Article" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>640</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_article" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Article" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>641</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_audio" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Audio" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>642</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="last_seen" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Last seen:" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>643</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="connect_with_facebook" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Connect with Facebook" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>644</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="connect_with_google" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Connect with Google" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>645</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="connect_with_vk" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Connect with VK" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>646</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="ip_address" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Ip Address" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>647</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_item_numbers" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show Item Numbers in Post Details Page" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>648</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="secure_key" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Secure Key" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>649</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="embed_media" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Embed Media" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>650</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_iframe" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Iframe" /></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-sm-12 m-t-30">
                    <div className="row">
                      <div className="pull-right">
                        <a href="https://prernamedia.com/admin/language-settings" className="btn btn-danger m-r-5">Back</a>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </form>{/* form end */}
              </div>
              <div id="tab14" className="tab-pane fade ">
                <form action="https://prernamedia.com/language_controller/update_phrases_post" method="post" acceptCharset="utf-8">
                  <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />                                                                                             
                  <input type="hidden" name="id" className="form-control" defaultValue={2} />
                  <input type="hidden" id="lang_folder" className="form-control" defaultValue="hindi" />
                  <table className="table table-bordered table-striped dataTable">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Phrase</th>
                        <th>Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>651</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="text_editor_language" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Text Editor Language" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>652</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="upload_image" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Upload Image" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>653</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="drag_drop_files_here" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Drag and drop files here or" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>654</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="drag_drop_file_here" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Drag and drop file here or" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>655</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="browse_files" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Browse Files" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>656</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="txt_processing" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Processing..." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>657</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="file_upload" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="File Upload" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>658</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="pageviews" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Pageviews" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>659</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="show_images_from_original_source" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Show Images from Original Source" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>660</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="download_images_my_server" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Download Images to My Server" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>661</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="maintenance_mode" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Maintenance Mode" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>662</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="post_options" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post Options" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>663</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="route_settings" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Route Settings" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>664</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="route_settings_warning" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="You cannot use special characters in routes. If your language contains special characters, please be careful when editing routes. If you enter an invalid route, you will not be able to access the related page." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>665</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="general" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="General" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>666</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="homepage" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Homepage" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>667</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="main_post_image" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Main post image" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>668</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="more_main_images" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="More main images (slider will be active)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>669</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_media" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Media" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>670</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="files" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Files" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>671</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="files_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Downloadable additional files (.pdf, .docx, .zip etc..)" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>672</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="audios_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Select your audios and create your playlist" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>673</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_beforeunload" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="You have unsaved changes! Are you sure you want to leave this page?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>674</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="item_order" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Item Order" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>675</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="trivia_quiz" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Trivia Quiz" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>676</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="trivia_quiz_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Quizzes with right and wrong answers" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>677</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="personality_quiz" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Personality Quiz" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>678</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="personality_quiz_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Quizzes with custom results" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>679</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_quiz" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Quiz" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>680</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_trivia_quiz" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Trivia Quiz" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>681</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_personality_quiz" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Personality Quiz" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>682</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_trivia_quiz" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Trivia Quiz" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>683</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_personality_quiz" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Personality Quiz" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>684</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="questions" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Questions" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>685</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_question" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this question?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>686</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_answer" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this answer?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>687</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="confirm_result" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Are you sure you want to delete this result?" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>688</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_question" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Question" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>689</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="answer_text" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Answer Text" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>690</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_answer" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Answer" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>691</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="answers" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Answers" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>692</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="correct" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Correct" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>693</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="correct_answer" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Correct Answer" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>694</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="wrong_answer" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Wrong Answer" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>695</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="quiz_images" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Quiz Images" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>696</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="answer_format" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Answer Format" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>697</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="result" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Result" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>698</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="results" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Results" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>699</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_result" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Result" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>700</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="min" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Min" /></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-sm-12 m-t-30">
                    <div className="row">
                      <div className="pull-right">
                        <a href="https://prernamedia.com/admin/language-settings" className="btn btn-danger m-r-5">Back</a>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </form>{/* form end */}
              </div>
              <div id="tab15" className="tab-pane fade ">
                <form action="https://prernamedia.com/language_controller/update_phrases_post" method="post" acceptCharset="utf-8">
                  <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />
                  <input type="hidden" name="id" className="form-control" defaultValue={2} />
                  <input type="hidden" id="lang_folder" className="form-control" defaultValue="hindi" />
                  <table className="table table-bordered table-striped dataTable">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Phrase</th>
                        <th>Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>701</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="max" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Max" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>702</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="number_of_correct_answers" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Number of Correct Answers" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>703</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="number_of_correct_answers_range" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="The range of correct answers to show this result" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>704</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="play_again" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Play Again" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>705</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="select_a_result" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Select a result" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>706</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_added" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Item successfully added!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>707</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_deleted" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Item successfully deleted!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>708</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="post_formats" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Post Formats" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>709</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="font_settings" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Font Settings" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>710</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="font_family" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Font Family" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>711</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_font" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Font" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>712</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_font" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Font" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>713</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="site_font" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Site Font" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>714</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="custom_css_codes" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Custom CSS Codes" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>715</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="custom_css_codes_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="These codes will be added to the header of the site." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>716</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="custom_javascript_codes" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Custom JavaScript Codes" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>717</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="custom_javascript_codes_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="These codes will be added to the footer of the site." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>718</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="adsense_activation_code" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="AdSense Activation Code" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>719</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="comment_approval_system" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Comment Approval System" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>720</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_comment_sent_successfully" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Your comment has been sent. It will be published after being reviewed by the site management." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>721</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_comment_approved" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Comment successfully approved!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>722</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="pending_comments" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Pending Comments" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>723</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="approved_comments" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Approved Comments" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>724</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="recently_added_comments" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Recently added comments" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>725</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="recently_added_unapproved_comments" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Recently added unapproved comments" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>726</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="recently_added_contact_messages" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Recently added contact messages" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>727</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="recently_registered_users" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Recently registered users" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>728</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="audio_download_button" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Audio Download Button" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>729</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="updated" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Updated" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>730</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="themes" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Themes" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>731</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="activate" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Activate" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>732</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="activated" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Activated" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>733</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="dark_mode" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Dark Mode" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>734</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="ok" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="OK" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>735</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="cancel" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Cancel" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>736</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="navigation_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="You can manage the navigation by dragging and dropping menu items." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>737</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="nav_drag_warning" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="You cannot drag a category below a page or a page below a category link!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>738</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="article_post_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="An article with images and embed videos" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>739</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="video_post_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Upload or embed videos" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>740</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="audio_post_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Upload audios and create playlist" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>741</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="sorted_list" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Sorted List" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>742</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="add_sorted_list" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Add Sorted List" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>743</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="update_sorted_list" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Update Sorted List" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>744</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="sorted_list_items" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Sorted List Items" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>745</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="sorted_list_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="A list based article" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>746</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="invalid" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Invalid!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>747</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_delete_subpages" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Please delete subpages/sublinks first!" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>748</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="msg_rss_warning" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="If you chose to download the images to your server, adding posts will take more time and will use more resources. If you see any problems, increase 'max_execution_time' and 'memory_limit' values from your server settings." /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>749</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="send_test_email" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Send Test Email" /></td>
                      </tr>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>750</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="send_test_email_exp" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="You can send a test mail to check if your mail server is working." /></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-sm-12 m-t-30">
                    <div className="row">
                      <div className="pull-right">
                        <a href="https://prernamedia.com/admin/language-settings" className="btn btn-danger m-r-5">Back</a>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </form>{/* form end */}
              </div>
              <div id="tab16" className="tab-pane fade ">
                <form action="https://prernamedia.com/language_controller/update_phrases_post" method="post" acceptCharset="utf-8">
                  <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />
                  <input type="hidden" name="id" className="form-control" defaultValue={2} />
                  <input type="hidden" id="lang_folder" className="form-control" defaultValue="hindi" />
                  <table className="table table-bordered table-striped dataTable">
                    <thead>
                      <tr role="row">
                        <th>#</th>
                        <th>Phrase</th>
                        <th>Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-phrase">
                        <td style={{width: 50}}>751</td>
                        <td style={{width: '40%'}}><input type="text" name="phrase[]" className="form-control" defaultValue="edit_translations" readOnly /></td>
                        <td style={{width: '60%'}}><input type="text" name="label[]" className="form-control" defaultValue="Edit Translations" /></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-sm-12 m-t-30">
                    <div className="row">
                      <div className="pull-right">
                        <a href="https://prernamedia.com/admin/language-settings" className="btn btn-danger m-r-5">Back</a>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </form>{/* form end */}
              </div>
            </div>
            <div className="col-sm-12 m-t-30">
              <div className="row">
                <div className="text-center">
                  <ul className="pagination">
                    <li className="active">
                      <a href="https://prernamedia.com/admin/update-phrases/2?page=1">1</a>
                    </li>
                    <li className>
                      <a href="https://prernamedia.com/admin/update-phrases/2?page=2">2</a>
                    </li>
                    <li className>
                      <a href="https://prernamedia.com/admin/update-phrases/2?page=3">3</a>
                    </li>
                    <li className>
                      <a href="https://prernamedia.com/admin/update-phrases/2?page=4">4</a>
                    </li>
                    <li className>
                      <a href="https://prernamedia.com/admin/update-phrases/2?page=5">5</a>
                    </li>
                    <li className>
                      <a href="https://prernamedia.com/admin/update-phrases/2?page=6">6</a>
                    </li>
                    <li className>
                      <a href="https://prernamedia.com/admin/update-phrases/2?page=7">7</a>
                    </li>
                    <li className>
                      <a href="https://prernamedia.com/admin/update-phrases/2?page=8">8</a>
                    </li>
                    <li className>
                      <a href="https://prernamedia.com/admin/update-phrases/2?page=9">9</a>
                    </li>
                    <li className>
                      <a href="https://prernamedia.com/admin/update-phrases/2?page=10">10</a>
                    </li>
                    <li className>
                      <a href="https://prernamedia.com/admin/update-phrases/2?page=11">11</a>
                    </li>
                    <li className>
                      <a href="https://prernamedia.com/admin/update-phrases/2?page=12">12</a>
                    </li>
                    <li className>
                      <a href="https://prernamedia.com/admin/update-phrases/2?page=13">13</a>
                    </li>
                    <li className>
                      <a href="https://prernamedia.com/admin/update-phrases/2?page=14">14</a>
                    </li>
                    <li className>
                      <a href="https://prernamedia.com/admin/update-phrases/2?page=15">15</a>
                    </li>
                    <li className>
                      <a href="https://prernamedia.com/admin/update-phrases/2?page=16">16</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>{/* /.box-body */}
  </div>
</section>

        </>
    )
}
export default UpdatePhrases