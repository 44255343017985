import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from "react-router-dom";
function Posts(props) {
  
  return (
    <>
      <section className="content">
        <div className="box">
          <div className="box-header with-border">
            <div className="left">
              <h3 className="box-title">{props.data.title}</h3>
            </div>
            <div className="right">
              <a href="https://prernamedia.com/admin/post-format" className="btn btn-success btn-add-new pull-right">
                <i className="fa fa-plus" />
                Add Post
              </a>
            </div>
          </div>{/* /.box-header */}
          <div className="box-body">
            <div className="row">
              {/* include message block */}
              <div className="col-sm-12">
                {/*print error messages*/}
                {/*print custom error message*/}
                {/*print custom success message*/}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="table-responsive">
                  <div className="row table-filter-container">
                    <div className="col-sm-12">
                      <form action="https://prernamedia.com/admin/posts" method="GET" acceptCharset="utf-8" />
                      <div className="item-table-filter" style={{ width: 80, minWidth: 80 }}>
                        <label>Show</label>
                        <select name="show" className=" form-select">
                          <option value={15}>15</option>
                          <option value={30}>30</option>
                          <option value={60}>60</option>
                          <option value={100}>100</option>
                        </select>

                      </div>
                      <div className="item-table-filter">
                        <label>Language</label>
                        <select name="lang_id" className="form-select">
                          <option value>All</option>
                          <option value={1}>English</option>
                          <option value={2}>Hindi</option>
                        </select>
                      </div>
                      <div className="item-table-filter">
                        <label>Post Type</label>
                        <select name="post_type" className="form-select">
                          <option value>All</option>
                          <option value="article">Article</option>
                          <option value="gallery">Gallery</option>
                          <option value="sorted_list">Sorted List</option>
                          <option value="trivia_quiz">Trivia Quiz</option>
                          <option value="personality_quiz">Personality Quiz</option>
                          <option value="video">Video</option>
                          <option value="audio">Audio</option>
                        </select>
                      </div>
                      <div className="item-table-filter">
                        <label>User</label>
                        <select name="user" className="form-select">
                          <option value>All</option>
                          <option value={1}>
                            prernamedia_admin
                          </option>
                          <option value={2}>
                            abaris
                          </option>
                        </select>
                      </div>
                      <div className="item-table-filter">
                        <label>Category</label>
                        <select id="categories" name="category" className="form-select" >
                          <option value>All</option>
                          <option value={1}>
                            सिटी
                          </option>
                          <option value={3}>
                            प्रदेश
                          </option>
                          <option value={4}>
                            देश
                          </option>
                          <option value={5}>
                            वर्ल्ड                  </option>
                          <option value={6}>
                            बॉयोस्कोप                  </option>
                          <option value={7}>
                            खेल                  </option>
                          <option value={8}>
                            B&amp;E                  </option>
                          <option value={9}>
                            ज्ञान-विज्ञान                  </option>
                          <option value={10}>
                            लाइफस्टाइल                  </option>
                          <option value={11}>
                            आस्था                  </option>
                          <option value={12}>
                            कैरियर                  </option>
                          <option value={13}>
                            हेल्थ                  </option>
                          <option value={14}>
                            रसोई                  </option>
                          <option value={15}>
                            Business                  </option>
                          <option value={69}>
                            VIDEO                  </option>
                          <option value={70}>
                            Politics                  </option>
                          <option value={71}>
                            Funding                  </option>
                        </select>
                      </div>
                      <div className="item-table-filter">
                        <div className="form-group">
                          <label className="control-label">Subcategory</label>
                          <select id="subcategories" name="subcategory" className="form-select">
                            <option value>All</option>
                          </select>
                        </div>
                      </div>
                      <div className="item-table-filter">
                        <label>Search</label>
                        <input name="q" className="form-control" placeholder="Search" type="search" defaultValue />
                      </div>
                      <div className="item-table-filter md-top-10" style={{ width: 65, minWidth: 65 }}>
                        <label style={{ display: 'block' }}>&nbsp;</label>
                        <button type="submit" className="btn bg-purple">Filter</button>
                      </div>
                    </div>
                  </div><table className="table table-bordered table-striped" role="grid">
                    <thead>
                      <tr role="row">
                        <th width={20}><input type="checkbox" className="checkbox-table" id="checkAll" /></th>
                        <th width={20}>Id</th>
                        <th>Post</th>
                        <th>Language</th>
                        <th>Post Type</th>
                        <th>Category</th>
                        <th>Author</th>
                        <th>Pageviews</th>
                        <th>Date Added</th>
                        <th style={{ minWidth: 180 }}>Options</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={3039} /></td>
                        <td>3039</td>
                        <td>
                          <div className="td-post-item">
                            <a href="https://prernamedia.com/अमर-उजल-वशष-तलबन-न-2020-म-ह-लख-द-थ-अफगनसतन-म-लटन-क-पटकथ" target="_blank">
                              <div className="post-image">
                                <div className="image">
                                  <img src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b4b01f3615.jpg" data-src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b4b01f3615.jpg" alt className="img-responsive lazyloaded" />
                                </div>
                              </div>
                              <div className="post-title">
                                अमर उजाला विशेष : तालिबान ने 2020 में ही लिख दी थी अफगानिस्तान में लौटने की पटकथा                                                <div className="preview">
                                  <label className="label bg-red label-table ">Slider</label>
                                  <label className="label bg-olive label-table">Featured</label>
                                  <label className="label bg-aqua label-table">Recommended</label>
                                  <label className="label bg-teal label-table">Breaking</label>
                                </div>
                              </div>
                            </a>
                          </div>
                        </td>
                        <td>
                          English
                        </td>
                        <td className="td-post-type">
                          Article
                        </td>
                        <td>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            वर्ल्ड
                          </label>
                        </td>
                        <td>
                          <a href="https://prernamedia.com/profile/prernamedia_admin" target="_blank" className="table-user-link">
                            <strong>prernamedia_admin</strong>
                          </a>
                        </td>
                        <td>11</td>
                        <td>2021-08-17 15:07:21</td>
                        <td>

                          <form action="https://prernamedia.com/post_controller/post_options_post" method="post" acceptCharset="utf-8">
                            <input type="hidden" name="varient_csrf_token" defaultValue="2dd301e1ecb73f710fc5bd0d743a703b" />
                            <input type="hidden" name="id" defaultValue={3039} />



                            <div className="dropdown">
                              <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                  Select an option
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Link className="dropdown-item" to="/admin/editpost">
                                    <i className="fa fa-edit option-icon" />Edit
                                  </Link>
                                  <Dropdown.Item href="#/action-2" className="list_btn">
                                    <i className="fa fa-times option-icon" />Remove from Slider
                                  </Dropdown.Item>

                                  <Dropdown.Item href="#/action-3" className="list_btn">
                                    <i className="fa fa-times option-icon" />Remove from Featured
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3" className="list_btn">
                                    <i className="fa fa-times option-icon" />Add to Breaking
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3" className="list_btn">
                                    <i className="fa fa-times option-icon" />Add to Recommended
                                  </Dropdown.Item>
                                 
                                  <Dropdown.Item href="#/action-3">
                                    <i className="fa fa-trash option-icon" />Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>

                              </Dropdown>
                            </div>

                          </form>
                        </td>
                      </tr>
                      <tr>
                        <td><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={3038} /></td>
                        <td>3038</td>
                        <td>
                          <div className="td-post-item">
                            <a href="https://prernamedia.com/अफगनसतन-सकट-120-स-जयद-भरतय-क-लकर-उड-वयसन-क-वमन-कबल-म-बद-हग-भरतय-दतवस" target="_blank">
                              <div className="post-image">
                                <div className="image">
                                  <img src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b4a2149e12.jpg" data-src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b4a2149e12.jpg" alt className="img-responsive lazyloaded" />
                                </div>
                              </div>
                              <div className="post-title">
                                अफगानिस्तान संकट: 120 से ज्यादा भारतीयों को लेकर उड़ा वायुसेना का विमान, काबुल में बंद होगा भारतीय दूतावास                                                <div className="preview">
                                  <label className="label bg-red label-table">Slider</label>
                                  <label className="label bg-olive label-table">Featured</label>
                                  <label className="label bg-aqua label-table">Recommended</label>
                                  <label className="label bg-teal label-table">Breaking</label>
                                </div>
                              </div>
                            </a>
                          </div>
                        </td>
                        <td>
                          English
                        </td>
                        <td className="td-post-type">
                          Article
                        </td>
                        <td>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            वर्ल्ड
                          </label>
                        </td>
                        <td>
                          <a href="https://prernamedia.com/profile/prernamedia_admin" target="_blank" className="table-user-link">
                            <strong>prernamedia_admin</strong>
                          </a>
                        </td>
                        <td>6</td>
                        <td>2021-08-17 15:03:30</td>
                        <td>

                          <form action="https://prernamedia.com/post_controller/post_options_post" method="post" acceptCharset="utf-8">
                            <input type="hidden" name="varient_csrf_token" defaultValue="2dd301e1ecb73f710fc5bd0d743a703b" />
                            <input type="hidden" name="id" defaultValue={3038} />

                            <div className="dropdown">
                              <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                  Select an option
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Link className="dropdown-item" to="/admin/editpost">
                                    <i className="fa fa-edit option-icon" />Edit
                                  </Link>
                                  <Dropdown.Item href="#/action-2" className="list_btn">
                                    <i className="fa fa-times option-icon" />Remove from Slider
                                  </Dropdown.Item>

                                  <Dropdown.Item href="#/action-3" className="list_btn">
                                    <i className="fa fa-times option-icon" />Remove from Featured
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3" className="list_btn">
                                    <i className="fa fa-times option-icon" />Add to Breaking
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3" className="list_btn">
                                    <i className="fa fa-times option-icon" />Add to Recommended
                                  </Dropdown.Item>

                                  <Dropdown.Item href="#/action-3">
                                    <i className="fa fa-trash option-icon" />Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>

                              </Dropdown>

                              

                            </div>
                          </form>
                        </td>
                      </tr>

                      <tr>
                        <td><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={3037} /></td>
                        <td>3037</td>
                        <td>
                          <div className="td-post-item">
                            <a href="https://prernamedia.com/रहत-अफगन-नगरक-क-आपतकलन-वज-दग-भरत-दककत-दर-करन-क-लए-खल-नय-रसत" target="_blank">
                              <div className="post-image">
                                <div className="image">
                                  <img src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b49db6863a.jpg" data-src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b49db6863a.jpg" alt className="img-responsive lazyloaded" />
                                </div>
                              </div>
                              <div className="post-title">
                                राहत: अफगान नागरिकों को आपातकालीन वीजा देगा भारत, दिक्कत दूर करने के लिए खोला नया रास्ता                                                <div className="preview">
                                  <label className="label bg-red label-table">Slider</label>
                                  <label className="label bg-olive label-table">Featured</label>
                                  <label className="label bg-aqua label-table">Recommended</label>
                                  <label className="label bg-teal label-table">Breaking</label>
                                </div>
                              </div>
                            </a>
                          </div>
                        </td>
                        <td>
                          English                              </td>
                        <td className="td-post-type">
                          Article                              </td>
                        <td>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            वर्ल्ड                                              </label>
                        </td>
                        <td>
                          <a href="https://prernamediuseStatea.com/profile/prernamedia_admin" target="_blank" className="table-user-link">
                            <strong>prernamedia_admin</strong>
                          </a>
                        </td>
                        <td>5</td>
                        <td>2021-08-17 15:02:23</td>
                        <td>

                          <form action="https://prernamedia.com/post_controller/post_options_post" method="post" acceptCharset="utf-8">
                            <input type="hidden" name="varient_csrf_token" defaultValue="2dd301e1ecb73f710fc5bd0d743a703b" />
                            <input type="hidden" name="id" defaultValue={3037} />
                            <div className="dropdown">
                              <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                  Select an option
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Link className="dropdown-item" to="/admin/editpost">
                                    <i className="fa fa-edit option-icon" />Edit
                                  </Link>
                                  <Dropdown.Item href="#/action-2" className="list_btn">
                                    <i className="fa fa-times option-icon" />Remove from Slider
                                  </Dropdown.Item>

                                  <Dropdown.Item href="#/action-3" className="list_btn">
                                    <i className="fa fa-times option-icon" />Remove from Featured
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3" className="list_btn">
                                    <i className="fa fa-times option-icon" />Add to Breaking
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3" className="list_btn">
                                    <i className="fa fa-times option-icon" />Add to Recommended
                                  </Dropdown.Item>

                                  <Dropdown.Item href="#/action-3">
                                    <i className="fa fa-trash option-icon" />Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>

                              </Dropdown>


                            </div>
                          </form>{/* form end */}
                        </td>
                      </tr>

                      <tr>
                        <td><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={3036} /></td>
                        <td>3036</td>
                        <td>
                          <div className="td-post-item">
                            <a href="https://prernamedia.com/अफगनसतन-तलबन-क-खफ-क-बय-करत-एक-और-तसवर-य-एक-वमन-ह" target="_blank">
                              <div className="post-image">
                                <div className="image">
                                  <img src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b4987e0250.jpg" data-src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b4987e0250.jpg" alt className="img-responsive lazyloaded" />
                                </div>
                              </div>
                              <div className="post-title">
                                अफगानिस्तान : तालिबान के खौफ को बयां करती एक और तस्वीर, ये एक विमान है!                                                <div className="preview">
                                  <label className="label bg-red label-table">Slider</label>
                                  <label className="label bg-olive label-table">Featured</label>
                                  <label className="label bg-aqua label-table">Recommended</label>
                                  <label className="label bg-teal label-table">Breaking</label>
                                </div>
                              </div>
                            </a>
                          </div>
                        </td>
                        <td>
                          English                              </td>
                        <td className="td-post-type">
                          Article                              </td>
                        <td>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            वर्ल्ड                                              </label>
                        </td>
                        <td>
                          <a href="https://prernamedia.com/profile/prernamedia_admin" target="_blank" className="table-user-link">
                            <strong>prernamedia_admin</strong>
                          </a>
                        </td>
                        <td>6</td>
                        <td>2021-08-17 15:00:58</td>
                        <td>
                          {/* form post options */}
                          <form action="https://prernamedia.com/post_controller/post_options_post" method="post" acceptCharset="utf-8">
                            <input type="hidden" name="varient_csrf_token" defaultValue="2dd301e1ecb73f710fc5bd0d743a703b" />
                            <input type="hidden" name="id" defaultValue={3036} />
                            <div className="dropdown">
                              <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                  Select an option
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Link className="dropdown-item" to="/admin/editpost">
                                    <i className="fa fa-edit option-icon" />Edit
                                  </Link>
                                  <Dropdown.Item href="#/action-2" className="list_btn">
                                    <i className="fa fa-times option-icon" />Remove from Slider
                                  </Dropdown.Item>

                                  <Dropdown.Item href="#/action-3" className="list_btn">
                                    <i className="fa fa-times option-icon" />Remove from Featured
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3" className="list_btn">
                                    <i className="fa fa-times option-icon" />Add to Breaking
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3" className="list_btn">
                                    <i className="fa fa-times option-icon" />Add to Recommended
                                  </Dropdown.Item>

                                  <Dropdown.Item href="#/action-3">
                                    <i className="fa fa-trash option-icon" />Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>

                              </Dropdown>


                            </div>
                          </form>{/* form end */}
                        </td>
                      </tr>

                      <tr>
                        <td><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={3035} /></td>
                        <td>3035</td>
                        <td>
                          <div className="td-post-item">
                            <a href="https://prernamedia.com/bihar-news-बड-फसल-सरकर-करमय-क-मलग-28-डए-छतर-क-खत-म-जलद-कतब-क-पस-भजग-सरकर" target="_blank">
                              <div className="post-image">
                                <div className="image">
                                  <img src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b493ec7954.jpg" data-src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b493ec7954.jpg" alt className="img-responsive lazyloaded" />
                                </div>
                              </div>
                              <div className="post-title">
                                Bihar News: बड़ा फैसला-सरकारी कर्मियों को मिलेगा 28% डीए, छात्रों के खाते में जल्द किताब के पैसे भेजेगी सरकार                                                <div className="preview">
                                  <label className="label bg-red label-table">Slider</label>
                                  <label className="label bg-olive label-table">Featured</label>
                                  <label className="label bg-aqua label-table">Recommended</label>
                                  <label className="label bg-teal label-table">Breaking</label>
                                </div>
                              </div>
                            </a>
                          </div>
                        </td>
                        <td>
                          English                              </td>
                        <td className="td-post-type">
                          Article                              </td>
                        <td>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            देश                                              </label>
                        </td>
                        <td>
                          <a href="https://prernamedia.com/profile/prernamedia_admin" target="_blank" className="table-user-link">
                            <strong>prernamedia_admin</strong>
                          </a>
                        </td>
                        <td>5</td>
                        <td>2021-08-17 14:59:48</td>
                        <td>
                          {/* form post options */}
                          <form action="https://prernamedia.com/post_controller/post_options_post" method="post" acceptCharset="utf-8">
                            <input type="hidden" name="varient_csrf_token" defaultValue="2dd301e1ecb73f710fc5bd0d743a703b" />
                            <input type="hidden" name="id" defaultValue={3035} />
                            <div className="dropdown">
                              <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                  Select an option
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Link className="dropdown-item" to="/admin/editpost">
                                    <i className="fa fa-edit option-icon" />Edit
                                  </Link>
                                  <Dropdown.Item href="#/action-2" className="list_btn">
                                    <i className="fa fa-times option-icon" />Remove from Slider
                                  </Dropdown.Item>

                                  <Dropdown.Item href="#/action-3" className="list_btn">
                                    <i className="fa fa-times option-icon" />Remove from Featured
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3" className="list_btn">
                                    <i className="fa fa-times option-icon" />Add to Breaking
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3" className="list_btn">
                                    <i className="fa fa-times option-icon" />Add to Recommended
                                  </Dropdown.Item>

                                  <Dropdown.Item href="#/action-3">
                                    <i className="fa fa-trash option-icon" />Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>

                              </Dropdown>


                            </div>
                          </form>{/* form end */}
                        </td>
                      </tr>

                      <tr>
                        <td><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={3034} /></td>
                        <td>3034</td>
                        <td>
                          <div className="td-post-item">
                            <a href="https://prernamedia.com/बलय-व-जनपर-म-दषकरम-मनसक-रप-स-बमर-कशर-और-सबज-लकर-लट-रह-नबलग-स-दरदग-थन-क-घरव" target="_blank">
                              <div className="post-image">
                                <div className="image">
                                  <img src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b48db6988c.jpg" data-src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b48db6988c.jpg" alt className="img-responsive lazyloaded" />
                                </div>
                              </div>
                              <div className="post-title">
                                बलिया व जौनपुर में दुष्कर्म: मानसिक रूप से बीमार किशोरी और सब्जी लेकर लौट रही नाबालिग से दरिंदगी, थाने का घेराव                                                <div className="preview">
                                  <label className="label bg-red label-table">Slider</label>
                                  <label className="label bg-olive label-table">Featured</label>
                                  <label className="label bg-aqua label-table">Recommended</label>
                                  <label className="label bg-teal label-table">Breaking</label>
                                </div>
                              </div>
                            </a>
                          </div>
                        </td>
                        <td>
                          English                              </td>
                        <td className="td-post-type">
                          Article                              </td>
                        <td>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            प्रदेश                                              </label>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            उत्तर प्रदेश                                              </label>
                        </td>
                        <td>
                          <a href="https://prernamedia.com/profile/prernamedia_admin" target="_blank" className="table-user-link">
                            <strong>prernamedia_admin</strong>
                          </a>
                        </td>
                        <td>4</td>
                        <td>2021-08-17 14:58:07</td>
                        <td>
                          {/* form post options */}
                          <form action="https://prernamedia.com/post_controller/post_options_post" method="post" acceptCharset="utf-8">
                            <input type="hidden" name="varient_csrf_token" defaultValue="2dd301e1ecb73f710fc5bd0d743a703b" />
                            <input type="hidden" name="id" defaultValue={3034} />
                            <div className="dropdown">
                              <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                  Select an option
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Link className="dropdown-item" to="/admin/editpost">
                                    <i className="fa fa-edit option-icon" />Edit
                                  </Link>
                                  <Dropdown.Item href="#/action-2" className="list_btn">
                                    <i className="fa fa-times option-icon" />Remove from Slider
                                  </Dropdown.Item>

                                  <Dropdown.Item href="#/action-3" className="list_btn">
                                    <i className="fa fa-times option-icon" />Remove from Featured
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3" className="list_btn">
                                    <i className="fa fa-times option-icon" />Add to Breaking
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3" className="list_btn">
                                    <i className="fa fa-times option-icon" />Add to Recommended
                                  </Dropdown.Item>

                                  <Dropdown.Item href="#/action-3">
                                    <i className="fa fa-trash option-icon" />Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>

                              </Dropdown>


                            </div>
                          </form>{/* form end */}
                        </td>
                      </tr>

                      <tr>
                        <td><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={3033} /></td>
                        <td>3033</td>
                        <td>
                          <div className="td-post-item">
                            <a href="https://prernamedia.com/पल-नरमण-न-हन-पर-गरमण-न-भजप-नतओ-क-बनय-बधक-तन-सल-स-कसन-क-फसल-क-ह-रह-भर-नकसन" target="_blank">
                              <div className="post-image">
                                <div className="image">
                                  <img src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b485e38369.jpg" data-src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b485e38369.jpg" alt className="img-responsive lazyloaded" />
                                </div>
                              </div>
                              <div className="post-title">
                                पुल निर्माण न होने पर ग्रामीणों ने भाजपा नेताओं को बनाया बंधक, तीन साल से किसानों की फसलों को हो रहा भारी नुकसान                                                <div className="preview">
                                  <label className="label bg-red label-table">Slider</label>
                                  <label className="label bg-olive label-table">Featured</label>
                                  <label className="label bg-aqua label-table">Recommended</label>
                                  <label className="label bg-teal label-table">Breaking</label>
                                </div>
                              </div>
                            </a>
                          </div>
                        </td>
                        <td>
                          English                              </td>
                        <td className="td-post-type">
                          Article                              </td>
                        <td>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            प्रदेश                                              </label>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            झारखण्ड                                              </label>
                        </td>
                        <td>
                          <a href="https://prernamedia.com/profile/prernamedia_admin" target="_blank" className="table-user-link">
                            <strong>prernamedia_admin</strong>
                          </a>
                        </td>
                        <td>9</td>
                        <td>2021-08-17 14:56:02</td>
                        <td>
                          {/* form post options */}
                          <form action="https://prernamedia.com/post_controller/post_options_post" method="post" acceptCharset="utf-8">
                            <input type="hidden" name="varient_csrf_token" defaultValue="2dd301e1ecb73f710fc5bd0d743a703b" />
                            <input type="hidden" name="id" defaultValue={3033} />
                            <div className="dropdown">
                              <button className="btn bg-purple dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Select an option
                              </button>

                              <div className="dropdown-menu menu_width" aria-labelledby="dropdownMenuButton">
                                <Link className="dropdown-item" to="/admin/editpost">
                                  <i className="fa fa-edit option-icon" />Edit
                                </Link>

                                <button type="submit" name="option" value="add-remove-from-slider" className="list_btn">
                                  <i className="fa fa-times option-icon" />Remove from Slider
                                </button>

                                <button type="submit" name="option" value="add-remove-from-featured" className="list_btn">
                                  <i className="fa fa-times option-icon" />Remove from Featured
                                </button>

                                <button type="submit" name="option" className="list_btn">
                                  <i className="fa fa-times option-icon" />Remove from Breaking
                                </button>

                                <button type="submit" name="option" className="list_btn">
                                  <i className="fa fa-times option-icon" />Remove from Recommended
                                </button>

                                <a className="dropdown-item" href="javascript:void(0)" ><i className="fa fa-trash option-icon" />Delete
                                </a>
                              </div>
                            </div>
                          </form>{/* form end */}
                        </td>
                      </tr>

                      <tr>
                        <td><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={3032} /></td>
                        <td>3032</td>
                        <td>
                          <div className="td-post-item">
                            <a href="https://prernamedia.com/जलन-म-भषण-सडक-हदस-टरकटर-क-टककर-स-बइक-सवर-द-यवक-क-मत-एक-घयल" target="_blank">
                              <div className="post-image">
                                <div className="image">
                                  <img src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b48064c17b.jpg" data-src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b48064c17b.jpg" alt className="img-responsive lazyloaded" />
                                </div>
                              </div>
                              <div className="post-title">
                                जालौन में भीषण सड़क हादसा: ट्रैक्टर की टक्कर से बाइक सवार दो युवकों की मौत, एक घायल                                                <div className="preview">
                                  <label className="label bg-red label-table">Slider</label>
                                  <label className="label bg-olive label-table">Featured</label>
                                  <label className="label bg-aqua label-table">Recommended</label>
                                  <label className="label bg-teal label-table">Breaking</label>
                                </div>
                              </div>
                            </a>
                          </div>
                        </td>
                        <td>
                          English                              </td>
                        <td className="td-post-type">
                          Article                              </td>
                        <td>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            प्रदेश                                              </label>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            बिहार                                              </label>
                        </td>
                        <td>
                          <a href="https://prernamedia.com/profile/prernamedia_admin" target="_blank" className="table-user-link">
                            <strong>prernamedia_admin</strong>
                          </a>
                        </td>
                        <td>5</td>
                        <td>2021-08-17 14:54:46</td>
                        <td>
                          {/* form post options */}
                          <form action="https://prernamedia.com/post_controller/post_options_post" method="post" acceptCharset="utf-8">
                            <input type="hidden" name="varient_csrf_token" defaultValue="2dd301e1ecb73f710fc5bd0d743a703b" />
                            <input type="hidden" name="id" defaultValue={3032} />
                            <div className="dropdown">
                              <button className="btn bg-purple dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Select an option
                              </button>

                              <div className="dropdown-menu menu_width" aria-labelledby="dropdownMenuButton">
                                <Link className="dropdown-item" to="/admin/editpost">
                                  <i className="fa fa-edit option-icon" />Edit
                                </Link>

                                <button type="submit" name="option" value="add-remove-from-slider" className="list_btn">
                                  <i className="fa fa-times option-icon" />Remove from Slider
                                </button>

                                <button type="submit" name="option" value="add-remove-from-featured" className="list_btn">
                                  <i className="fa fa-times option-icon" />Remove from Featured
                                </button>

                                <button type="submit" name="option" className="list_btn">
                                  <i className="fa fa-times option-icon" />Remove from Breaking
                                </button>

                                <button type="submit" name="option" className="list_btn">
                                  <i className="fa fa-times option-icon" />Remove from Recommended
                                </button>

                                <a className="dropdown-item" href="javascript:void(0)" ><i className="fa fa-trash option-icon" />Delete
                                </a>
                              </div>
                            </div>
                          </form>{/* form end */}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={3031} /></td>
                        <td>3031</td>
                        <td>
                          <div className="td-post-item">
                            <a href="https://prernamedia.com/जज-क-मत-क-ममल-ऑट-डरइवर-क-बरन-मपग-क-लए-ल-गई-cbi-करट-स-3-सतमबर-तक-मल-ह-महलत" target="_blank">
                              <div className="post-image">
                                <div className="image">
                                  <img src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b470803751.jpg" data-src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b470803751.jpg" alt className="img-responsive lazyloaded" />
                                </div>
                              </div>
                              <div className="post-title">
                                जज की मौत का मामला: ऑटो ड्राइवर को ब्रेन मैपिंग के लिए ले गई CBI, कोर्ट से 3 सितम्‍बर तक मिली है मोहलत                                                <div className="preview">
                                  <label className="label bg-red label-table">Slider</label>
                                  <label className="label bg-olive label-table">Featured</label>
                                  <label className="label bg-aqua label-table">Recommended</label>
                                  <label className="label bg-teal label-table">Breaking</label>
                                </div>
                              </div>
                            </a>
                          </div>
                        </td>
                        <td>
                          English                              </td>
                        <td className="td-post-type">
                          Article                              </td>
                        <td>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            प्रदेश                                              </label>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            झारखण्ड                                              </label>
                        </td>
                        <td>
                          <a href="https://prernamedia.com/profile/prernamedia_admin" target="_blank" className="table-user-link">
                            <strong>prernamedia_admin</strong>
                          </a>
                        </td>
                        <td>6</td>
                        <td>2021-08-17 14:50:26</td>
                        <td>
                          {/* form post options */}
                          <form action="https://prernamedia.com/post_controller/post_options_post" method="post" acceptCharset="utf-8">
                            <input type="hidden" name="varient_csrf_token" defaultValue="2dd301e1ecb73f710fc5bd0d743a703b" />
                            <input type="hidden" name="id" defaultValue={3031} />
                            <div className="dropdown">
                              <button className="btn bg-purple dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Select an option
                              </button>

                              <div className="dropdown-menu menu_width" aria-labelledby="dropdownMenuButton">
                                <Link className="dropdown-item" to="/admin/editpost">
                                  <i className="fa fa-edit option-icon" />Edit
                                </Link>

                                <button type="submit" name="option" value="add-remove-from-slider" className="list_btn">
                                  <i className="fa fa-times option-icon" />Remove from Slider
                                </button>

                                <button type="submit" name="option" value="add-remove-from-featured" className="list_btn">
                                  <i className="fa fa-times option-icon" />Remove from Featured
                                </button>

                                <button type="submit" name="option" className="list_btn">
                                  <i className="fa fa-times option-icon" />Remove from Breaking
                                </button>

                                <button type="submit" name="option" className="list_btn">
                                  <i className="fa fa-times option-icon" />Remove from Recommended
                                </button>

                                <a class="dropdown-item" href="javascript:void(0)" ><i className="fa fa-trash option-icon" />Delete
                                </a>
                              </div>
                            </div>
                          </form>{/* form end */}
                        </td>
                      </tr>

                      <tr>
                        <td><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={3030} /></td>
                        <td>3030</td>
                        <td>
                          <div className="td-post-item">
                            <a href="https://prernamedia.com/बहर-म-खल-सकल-कतब-खरदन-क-लए-एक-हफत-म-सव-करड़-बचच-क-खत-म-पहचग-रपए" target="_blank">
                              <div className="post-image">
                                <div className="image">
                                  <img src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b46b17c706.jpg" data-src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b46b17c706.jpg" alt className="img-responsive lazyloaded" />
                                </div>
                              </div>
                              <div className="post-title">
                                बिहार में खुले स्‍कूल, किताब खरीदने के लिए एक हफ्ते में सवा करोड़ बच्चों के खाते में पहुंचेंगे रुपए                                                <div className="preview">
                                  <label className="label bg-red label-table">Slider</label>
                                  <label className="label bg-olive label-table">Featured</label>
                                  <label className="label bg-aqua label-table">Recommended</label>
                                  <label className="label bg-teal label-table">Breaking</label>
                                </div>
                              </div>
                            </a>
                          </div>
                        </td>
                        <td>
                          English                              </td>
                        <td className="td-post-type">
                          Article                              </td>
                        <td>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            प्रदेश                                              </label>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            बिहार                                              </label>
                        </td>
                        <td>
                          <a href="https://prernamedia.com/profile/prernamedia_admin" target="_blank" className="table-user-link">
                            <strong>prernamedia_admin</strong>
                          </a>
                        </td>
                        <td>4</td>
                        <td>2021-08-17 14:49:11</td>
                        <td>
                          {/* form post options */}
                          <form action="https://prernamedia.com/post_controller/post_options_post" method="post" acceptCharset="utf-8">
                            <input type="hidden" name="varient_csrf_token" defaultValue="2dd301e1ecb73f710fc5bd0d743a703b" />
                            <input type="hidden" name="id" defaultValue={3030} />
                            <div className="dropdown">
                              <button className="btn bg-purple dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Select an option
                              </button>

                              <div className="dropdown-menu menu_width" aria-labelledby="dropdownMenuButton">
                                <Link className="dropdown-item" to="/admin/editpost">
                                  <i className="fa fa-edit option-icon" />Edit
                                </Link>

                                <button type="submit" name="option" value="add-remove-from-slider" className="list_btn">
                                  <i className="fa fa-times option-icon" />Remove from Slider
                                </button>

                                <button type="submit" name="option" value="add-remove-from-featured" className="list_btn">
                                  <i className="fa fa-times option-icon" />Remove from Featured
                                </button>

                                <button type="submit" name="option" className="list_btn">
                                  <i className="fa fa-times option-icon" />Remove from Breaking
                                </button>

                                <button type="submit" name="option" className="list_btn">
                                  <i className="fa fa-times option-icon" />Remove from Recommended
                                </button>

                                <a className="dropdown-item" href="javascript:void(0)" ><i className="fa fa-trash option-icon" />Delete
                                </a>
                              </div>
                            </div>
                          </form>
                        </td>
                      </tr>

                      <tr>
                        <td><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={3029} /></td>
                        <td>3029</td>
                        <td>
                          <div className="td-post-item">
                            <a href="https://prernamedia.com/दलल-कट-ममल-सन-क-ससटव-कमर-स-हआ-खलस-बचच-स-मलश-करवत-थ-आरप-पडत" target="_blank">
                              <div className="post-image">
                                <div className="image">
                                  <img src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b45c332a3c.jpg" data-src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b45c332a3c.jpg" alt className="img-responsive lazyloaded" />
                                </div>
                              </div>
                              <div className="post-title">
                                दिल्ली कैंट मामला: सेना के सीसीटीवी कैमरों से हुआ खुलासा, बच्ची से मालिश करवाता था आरोपी पंडित                                                <div className="preview">
                                  <label className="label bg-red label-table">Slider</label>
                                  <label className="label bg-olive label-table">Featured</label>
                                  <label className="label bg-aqua label-table">Recommended</label>
                                  <label className="label bg-teal label-table">Breaking</label>
                                </div>
                              </div>
                            </a>
                          </div>
                        </td>
                        <td>
                          English                              </td>
                        <td className="td-post-type">
                          Article                              </td>
                        <td>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            प्रदेश                                              </label>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            दिल्ली                                              </label>
                        </td>
                        <td>
                          <a href="https://prernamedia.com/profile/prernamedia_admin" target="_blank" className="table-user-link">
                            <strong>prernamedia_admin</strong>
                          </a>
                        </td>
                        <td>5</td>
                        <td>2021-08-17 14:45:46</td>
                        <td>
                          {/* form post options */}
                          <form action="https://prernamedia.com/post_controller/post_options_post" method="post" acceptCharset="utf-8">
                            <input type="hidden" name="varient_csrf_token" defaultValue="2dd301e1ecb73f710fc5bd0d743a703b" />
                            <input type="hidden" name="id" defaultValue={3029} />
                            <div className="dropdown">
                              <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                            <span className="caret" />
                              </button>
                              <ul className="dropdown-menu options-dropdown">
                                <li>
                                  <a href="https://prernamedia.com/admin/update-post/3029"><i className="fa fa-edit option-icon" />Edit</a>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-slider" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Slider                                                      </button>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-featured" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Featured                                                      </button>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-breaking" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Breaking                                                      </button>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-recommended" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Recommended                                                      </button>
                                </li>
                                <li>
                                  <a href="javascript:void(0)"><i className="fa fa-trash option-icon" />Delete</a>
                                </li>
                              </ul>
                            </div>
                          </form>{/* form end */}
                        </td>
                      </tr>
                      <tr>
                        <td><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={3028} /></td>
                        <td>3028</td>
                        <td>
                          <div className="td-post-item">
                            <a href="https://prernamedia.com/delhi-weather-सरज-न-कड-कए-तवर-गरम-स-छट-पसन-18-अगसत-स-रहत-मलन-क-सभवन" target="_blank">
                              <div className="post-image">
                                <div className="image">
                                  <img src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b450059bb6.jpg" data-src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b450059bb6.jpg" alt className="img-responsive lazyloaded" />
                                </div>
                              </div>
                              <div className="post-title">
                                Delhi Weather: सूरज ने कड़े किए तेवर, गर्मी से छूटे पसीने, 18 अगस्त से राहत मिलने की संभावना                                                <div className="preview">
                                  <label className="label bg-red label-table">Slider</label>
                                  <label className="label bg-olive label-table">Featured</label>
                                  <label className="label bg-aqua label-table">Recommended</label>
                                  <label className="label bg-teal label-table">Breaking</label>
                                </div>
                              </div>
                            </a>
                          </div>
                        </td>
                        <td>
                          English                              </td>
                        <td className="td-post-type">
                          Article                              </td>
                        <td>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            सिटी                                              </label>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            दिल्ली एनसीआर                                              </label>
                        </td>
                        <td>
                          <a href="https://prernamedia.com/profile/prernamedia_admin" target="_blank" className="table-user-link">
                            <strong>prernamedia_admin</strong>
                          </a>
                        </td>
                        <td>5</td>
                        <td>2021-08-17 14:42:54</td>
                        <td>
                          {/* form post options */}
                          <form action="https://prernamedia.com/post_controller/post_options_post" method="post" acceptCharset="utf-8">
                            <input type="hidden" name="varient_csrf_token" defaultValue="2dd301e1ecb73f710fc5bd0d743a703b" />
                            <input type="hidden" name="id" defaultValue={3028} />
                            <div className="dropdown">
                              <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                            <span className="caret" />
                              </button>
                              <ul className="dropdown-menu options-dropdown">
                                <li>
                                  <a href="https://prernamedia.com/admin/update-post/3028"><i className="fa fa-edit option-icon" />Edit</a>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-slider" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Slider                                                      </button>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-featured" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Featured                                                      </button>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-breaking" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Breaking                                                      </button>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-recommended" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Recommended                                                      </button>
                                </li>
                                <li>
                                  <a href="javascript:void(0)"><i className="fa fa-trash option-icon" />Delete</a>
                                </li>
                              </ul>
                            </div>
                          </form>{/* form end */}
                        </td>
                      </tr>
                      <tr>
                        <td><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={3027} /></td>
                        <td>3027</td>
                        <td>
                          <div className="td-post-item">
                            <a href="https://prernamedia.com/पढ-यप-कबनट-क-महतवपरण-फसल-ससद-वधयक-बनए-जएग-जल-खनज-फउडशन-क-सदसय" target="_blank">
                              <div className="post-image">
                                <div className="image">
                                  <img src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b4472a7b94.jpg" data-src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b4472a7b94.jpg" alt className="img-responsive lazyloaded" />
                                </div>
                              </div>
                              <div className="post-title">
                                पढ़ें यूपी कैबिनेट के महत्वपूर्ण फैसले : सांसद-विधायक बनाए जाएंगे जिला खनिज फाउंडेशन के सदस्य                                                <div className="preview">
                                  <label className="label bg-red label-table">Slider</label>
                                  <label className="label bg-olive label-table">Featured</label>
                                  <label className="label bg-aqua label-table">Recommended</label>
                                  <label className="label bg-teal label-table">Breaking</label>
                                </div>
                              </div>
                            </a>
                          </div>
                        </td>
                        <td>
                          English                              </td>
                        <td className="td-post-type">
                          Article                              </td>
                        <td>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            सिटी                                              </label>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            लखनऊ                                              </label>
                        </td>
                        <td>
                          <a href="https://prernamedia.com/profile/prernamedia_admin" target="_blank" className="table-user-link">
                            <strong>prernamedia_admin</strong>
                          </a>
                        </td>
                        <td>5</td>
                        <td>2021-08-17 14:39:43</td>
                        <td>
                          {/* form post options */}
                          <form action="https://prernamedia.com/post_controller/post_options_post" method="post" acceptCharset="utf-8">
                            <input type="hidden" name="varient_csrf_token" defaultValue="2dd301e1ecb73f710fc5bd0d743a703b" />
                            <input type="hidden" name="id" defaultValue={3027} />
                            <div className="dropdown">
                              <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                            <span className="caret" />
                              </button>
                              <ul className="dropdown-menu options-dropdown">
                                <li>
                                  <a href="https://prernamedia.com/admin/update-post/3027"><i className="fa fa-edit option-icon" />Edit</a>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-slider" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Slider                                                      </button>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-featured" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Featured                                                      </button>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-breaking" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Breaking                                                      </button>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-recommended" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Recommended                                                      </button>
                                </li>
                                <li>
                                  <a href="javascript:void(0)" ><i className="fa fa-trash option-icon" />Delete</a>
                                </li>
                              </ul>
                            </div>
                          </form>{/* form end */}
                        </td>
                      </tr>
                      <tr>
                        <td><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={3026} /></td>
                        <td>3026</td>
                        <td>
                          <div className="td-post-item">
                            <a href="https://prernamedia.com/अतम-ससकर-क-बद-जद-ह-उठ-यवत-अपन-ह-थयर-म-उलझकर-पटन-पलस-पट-रह-मथ" target="_blank">
                              <div className="post-image">
                                <div className="image">
                                  <img src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b438a6eb28.jpg" data-src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b438a6eb28.jpg" alt className="img-responsive lazyloaded" />
                                </div>
                              </div>
                              <div className="post-title">
                                अंतिम संस्‍कार के बाद 'जिंदा हो उठी' युवती, अपनी ही थ्‍योरी में उलझकर पटना पुलिस पीट रही माथा                                                <div className="preview">
                                  <label className="label bg-red label-table">Slider</label>
                                  <label className="label bg-olive label-table">Featured</label>
                                  <label className="label bg-aqua label-table">Recommended</label>
                                  <label className="label bg-teal label-table">Breaking</label>
                                </div>
                              </div>
                            </a>
                          </div>
                        </td>
                        <td>
                          English                              </td>
                        <td className="td-post-type">
                          Article                              </td>
                        <td>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            सिटी                                              </label>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            पटना                                              </label>
                        </td>
                        <td>
                          <a href="https://prernamedia.com/profile/prernamedia_admin" target="_blank" className="table-user-link">
                            <strong>prernamedia_admin</strong>
                          </a>
                        </td>
                        <td>3</td>
                        <td>2021-08-17 14:35:30</td>
                        <td>
                          {/* form post options */}
                          <form action="https://prernamedia.com/post_controller/post_options_post" method="post" acceptCharset="utf-8">
                            <input type="hidden" name="varient_csrf_token" defaultValue="2dd301e1ecb73f710fc5bd0d743a703b" />
                            <input type="hidden" name="id" defaultValue={3026} />
                            <div className="dropdown">
                              <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                            <span className="caret" />
                              </button>
                              <ul className="dropdown-menu options-dropdown">
                                <li>
                                  <a href="https://prernamedia.com/admin/update-post/3026"><i className="fa fa-edit option-icon" />Edit</a>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-slider" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Slider                                                      </button>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-featured" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Featured                                                      </button>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-breaking" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Breaking                                                      </button>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-recommended" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Recommended                                                      </button>
                                </li>
                                <li>
                                  <a href="javascript:void(0)" ><i className="fa fa-trash option-icon" />Delete</a>
                                </li>
                              </ul>
                            </div>
                          </form>{/* form end */}
                        </td>
                      </tr>
                      <tr>
                        <td><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={3025} /></td>
                        <td>3025</td>
                        <td>
                          <div className="td-post-item">
                            <a href="https://prernamedia.com/jharkhand-assembly-झरखड-वधनसभ-क-मनसन-सतर-तन-सतबर-स-6-क-पश-हग-अनपरक-बजट" target="_blank">
                              <div className="post-image">
                                <div className="image">
                                  <img src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b42806d22c.jpg" data-src="https://prernamedia.com/uploads/images/2021/08/image_140x98_611b42806d22c.jpg" alt className="img-responsive lazyloaded" />
                                </div>
                              </div>
                              <div className="post-title">
                                Jharkhand Assembly: झारखंड विधानसभा का मानसून सत्र तीन सितंबर से, 6 को पेश होगा अनुपूरक बजट                                                <div className="preview">
                                  <label className="label bg-red label-table">Slider</label>
                                  <label className="label bg-olive label-table">Featured</label>
                                  <label className="label bg-aqua label-table">Recommended</label>
                                  <label className="label bg-teal label-table">Breaking</label>
                                </div>
                              </div>
                            </a>
                          </div>
                        </td>
                        <td>
                          English                              </td>
                        <td className="td-post-type">
                          Article                              </td>
                        <td>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            सिटी                                              </label>
                          <label className="category-label m-r-5 label-table" style={{ backgroundColor: '#1877F2!important' }}>
                            रांची                                              </label>
                        </td>
                        <td>
                          <a href="https://prernamedia.com/profile/prernamedia_admin" target="_blank" className="table-user-link">
                            <strong>prernamedia_admin</strong>
                          </a>
                        </td>
                        <td>4</td>
                        <td>2021-08-17 14:31:06</td>
                        <td>
                          {/* form post options */}
                          <form action="https://prernamedia.com/post_controller/post_options_post" method="post" acceptCharset="utf-8">
                            <input type="hidden" name="varient_csrf_token" defaultValue="2dd301e1ecb73f710fc5bd0d743a703b" />
                            <input type="hidden" name="id" defaultValue={3025} />
                            <div className="dropdown">
                              <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                            <span className="caret" />
                              </button>
                              <ul className="dropdown-menu options-dropdown">
                                <li>
                                  <a href="https://prernamedia.com/admin/update-post/3025"><i className="fa fa-edit option-icon" />Edit</a>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-slider" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Slider                                                      </button>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-featured" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Featured                                                      </button>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-breaking" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Breaking                                                      </button>
                                </li>
                                <li>
                                  <button type="submit" name="option" value="add-remove-from-recommended" className="btn-list-button">
                                    <i className="fa fa-times option-icon" />Remove from Recommended                                                      </button>
                                </li>
                                <li>
                                  <a href="javascript:void(0)" ><i className="fa fa-trash option-icon" />Delete</a>
                                </li>
                              </ul>
                            </div>
                          </form>{/* form end */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-sm-12 table-ft">
                    <div className="row">
                      <div className="pull-right">
                        <ul className="pagination"><li className="disabled" /><li className="active page-num"><a href="#">1<span className="sr-only" /></a></li><li className="page-num"><a href="https://prernamedia.com/admin/posts?page=2" data-ci-pagination-page={2}>2</a></li><li className="page-num"><a href="https://prernamedia.com/admin/posts?page=3" data-ci-pagination-page={3}>3</a></li><li className="page-num"><a href="https://prernamedia.com/admin/posts?page=4" data-ci-pagination-page={4}>4</a></li><li className="page-num"><a href="https://prernamedia.com/admin/posts?page=5" data-ci-pagination-page={5}>5</a></li><li className="next"><a href="https://prernamedia.com/admin/posts?page=2" data-ci-pagination-page={2} rel="next">›</a></li><li className="page-last"><a href="https://prernamedia.com/admin/posts?page=202" data-ci-pagination-page={202}>»</a></li></ul>                          </div>
                      <div className="pull-left bulk-options">
                        <button className="btn btn-sm btn-danger btn-table-delete" ><i className="fa fa-trash option-icon" />Delete</button>
                        <button className="btn btn-sm btn-default btn-table-delete" ><i className="fa fa-plus option-icon" />Add to Slider</button>
                        <button className="btn btn-sm btn-default btn-table-delete" ><i className="fa fa-plus option-icon" />Add to Featured</button>
                        <button className="btn btn-sm btn-default btn-table-delete"><i className="fa fa-plus option-icon" />Add to Breaking</button>
                        <button className="btn btn-sm btn-default btn-table-delete"><i className="fa fa-plus option-icon" />Add to Recommended</button>
                        <button className="btn btn-sm btn-default btn-table-delete" ><i className="fa fa-minus option-icon" />Remove from Slider</button>
                        <button className="btn btn-sm btn-default btn-table-delete"><i className="fa fa-minus option-icon" />Remove from Featured</button>
                        <button className="btn btn-sm btn-default btn-table-delete"><i className="fa fa-minus option-icon" />Remove from Breaking</button>
                        <button className="btn btn-sm btn-default btn-table-delete"><i className="fa fa-minus option-icon" />Remove from Recommended</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{/* /.box-body */}
        </div>
      </section>

    </>
  )
}
export default Posts;