import ApprovedComment from "../../../Components/comment/approvedComment/ApporovedComment"

function ApprovedCommentPage() {
    return (
        <>
        <ApprovedComment/>
        </>
    )
}
export default ApprovedCommentPage
