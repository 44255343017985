
function SocialLoginVKontakte() {
  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="box box-primary">
            <div className="box-header with-border">
              <h3 className="box-title">VKontakte</h3>
            </div>
            <form action="https://prernamedia.com/admin_controller/social_login_vk_post" method="post" acceptCharset="utf-8">
              <input type="hidden" name="varient_csrf_token" defaultValue="767371c5be6a03be7543c42b1699a2ef" />
              <div className="box-body">

                <div className="form-group">
                  <label className="label-sitemap">App ID</label>
                  <input type="text" className="form-control" name="vk_app_id" placeholder="App ID" defaultValue />
                </div>
                <div className="form-group">
                  <label className="label-sitemap">Secure Key</label>
                  <input type="text" className="form-control" name="vk_secure_key" placeholder="Secure Key" defaultValue />
                </div>

                <div className="box-footer" style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <button type="submit" className="btn btn-primary pull-right">Save Changes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
export default SocialLoginVKontakte