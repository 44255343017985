
function Themes() {
    return (
        <>
        <section className="content">
  <div className="row">
    <div className="col-sm-12">
      <div className="box box-primary" style={{backgroundColor: 'transparent', border: 0, boxShadow: 'none'}}>
        <div className="box-header with-border">
        </div>
        {/* /.box-header */}
        <div className="box-body">
          {/* include message block */}
          {/*print error messages*/}
          {/*print custom error message*/}
          {/*print custom success message*/}
          <form action="https://prernamedia.com/admin_controller/set_theme_post" method="post" acceptCharset="utf-8">
            <input type="hidden" name="varient_csrf_token" defaultValue="3ca7befd24b36ddf11c8b3cb30989e36" />
            <div className="row-layout-items">
              <div className="layout-item active" data-val="layout_1">
                <button type="submit" name="dark_mode" value={0} className="btn btn-block">
                  <div style={{width: 308, height: '360.7px'}}>
                    <img src="https://prernamedia.com/assets/admin/img/theme_light.jpg" alt className="img-responsive" />
                  </div>
                  <p>
                    Activated                          </p>
                </button>
              </div>
              <div className="layout-item " data-val="layout_2">
                <button type="submit" name="dark_mode" value={1} className="btn btn-block">
                  <div style={{width: 308, height: '360.7px'}}>
                    <img src="https://prernamedia.com/assets/admin/img/theme_dark.jpg" alt className="img-responsive" />
                  </div>
                  <p>
                    Activate                          </p>
                </button>
              </div>
            </div>
          </form>          </div>
      </div>
      {/* /.box */}
    </div>
  </div>
</section>

        </>
    )
}
export default Themes