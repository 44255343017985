
function Post() {
    return (
        <>
            <div className="box box-primary">
                <div className="box-header with-border">
                    <h3 className="box-title">Posts</h3>
                </div>
                {/* /.box-header */}
                {/* form start */}
                <form action="https://prernamedia.com/admin_controller/preferences_post" method="post" acceptCharset="utf-8">
                    <input type="hidden" name="varient_csrf_token" defaultValue="b4ab68091002ec2207d835146854e587" />
                    <div className="box-body">
                        {/* include message block */}
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Comment System</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Enable
                                        </label>
                                    </div>

                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="comment_system" defaultValue={1} id="comment_system_1" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="comment_system_1" className="option-label">Enable</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                            Disable
                                        </label>
                                    </div>

                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="comment_system" defaultValue={0} id="comment_system_2" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="comment_system_2" className="option-label">Disable</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Comment Approval System</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Enable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="comment_approval_system" defaultValue={1} id="comment_approval_system_1" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="comment_approval_system_1" className="option-label">Enable</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                            Disable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="comment_approval_system" defaultValue={0} id="comment_approval_system_2" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="comment_approval_system_2" className="option-label">Disable</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Facebook Comments</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Enable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="facebook_comment_active" defaultValue={1} id="facebook_comment_active_1" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="facebook_comment_active_1" className="option-label">Enable</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                            Disable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="facebook_comment_active" defaultValue={0} id="facebook_comment_active_2" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="facebook_comment_active_2" className="option-label">Disable</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Emoji Reactions</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault4" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Enable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" id="emoji_reactions_1" name="emoji_reactions" defaultValue={1} className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="emoji_reactions_1" className="cursor-pointer">Enable</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault4" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                            Disable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" id="emoji_reactions_2" name="emoji_reactions" defaultValue={0} className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="emoji_reactions_2" className="cursor-pointer">Disable</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Show Post Author</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault5" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Yes
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="show_post_author" defaultValue={1} id="show_post_author_1" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="show_post_author_1" className="option-label">Yes</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault5" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        No
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="show_post_author" defaultValue={0} id="show_post_author_2" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="show_post_author_2" className="option-label">No</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Show Post Date</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault6" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Yes
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="show_post_date" defaultValue={1} id="show_post_date_1" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="show_post_date_1" className="option-label">Yes</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault6" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        No
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="show_post_date" defaultValue={0} id="show_post_date_2" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="show_post_date_2" className="option-label">No</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Show Post View Count</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault7" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Yes
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" id="show_hits_1" name="show_hits" defaultValue={1} className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="show_hits_1" className="cursor-pointer">Yes</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault7" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        No
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" id="show_hits_2" name="show_hits" defaultValue={0} className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="show_hits_2" className="cursor-pointer">No</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Approve Added User Posts</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault8" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Yes
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" id="approve_added_user_posts_1" name="approve_added_user_posts" defaultValue={1} className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="approve_added_user_posts_1" className="cursor-pointer">Yes</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault8" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        No
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" id="approve_added_user_posts_2" name="approve_added_user_posts" defaultValue={0} className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="approve_added_user_posts_2" className="cursor-pointer">No</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Approve Updated User Posts</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault9" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Yes
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" id="approve_updated_user_posts_1" name="approve_updated_user_posts" defaultValue={1} className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="approve_updated_user_posts_1" className="cursor-pointer">Yes</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault9" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        No
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" id="approve_updated_user_posts_2" name="approve_updated_user_posts" defaultValue={0} className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="approve_updated_user_posts_2" className="cursor-pointer">No</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label className="control-label">Number of Posts Per Page (Pagination)</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                    <input type="number" className="form-control" name="pagination_per_page" defaultValue={16} min={1} max={3000} required style={{ maxWidth: 450 }} fdprocessedid="1378tn" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /.box-body */}
                    <div className="box-footer">
                        <button type="submit" name="submit" value="posts" className="btn btn-primary pull-right" fdprocessedid="77hj61">Save Changes</button>
                    </div>
                    {/* /.box-footer */}
                    {/* /.box */}
                </form>{/* form end */}
            </div>
        </>
    )
}
export default Post