import { useState } from "react";
import { Link } from "react-router-dom";

function Aside() {
  const [pages, setPages] = useState(true)
  const [rssFeed, setRssFeed] = useState(true)
  const [categories, setCategories] = useState(true)
  const [posts, setPosts] = useState(true)
  const [widget, setWidget] = useState(true)
  const [polls, setpolls] = useState(true)
  const [gallery, setGallery] = useState(true)
  const [comment, setComment] = useState(true)
  const [newsLetter, setNewsLetter] = useState(true)
  const [users, setUsers] = useState(true)
  return (
    <>
      <aside className="main-sidebar main-sidebar-2">
        {/* sidebar: style can be found in sidebar.less */}
        <section className="sidebar">
          {/* Sidebar user panel */}
          <div className="user-panel">
            <div className="pull-left image">
              <img
                src="https://prernamedia.com/assets/img/user.png"
                className="img-circle"
                alt="User Image"
              />
            </div>
            <div className="pull-left info">
              <p>prernamedia_admin</p>
              <a href="#">
                <i className="fa fa-circle text-success" /> online
              </a>
            </div>
          </div>
          {/* sidebar menu: : style can be found in sidebar.less */}
          <ul className="sidebar-menu tree" data-widget="tree">
            <li className="header">MAIN NAVIGATION</li>
            <li>
              <Link to="https://prernamedia.com/admin/">
                <i className="fa fa-home" />
                <span>Home</span>
              </Link>
            </li>
            <li>
              <Link to="navigation">
                <i className="fa fa-th" />
                <span>Navigation</span>
              </Link>
            </li>
            <li>
              <Link to="themes">
                <i className="fa fa-leaf" />
                <span>Themes</span>
              </Link>
            </li>
            <li className="treeview">
              <Link to="#" onClick={() => setPages(!pages)}>
                <i className="fa fa-file-text" /> <span>Pages</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </Link>
              <ul className={`treeview-menu ${pages ? "menu-open" : "treeview"}`}>
                <li>
                  <Link to="add-page">Add Page</Link>
                </li>
                <li>
                  <Link to="pages">Pages</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="post-format">
                <i className="fa fa-file" />
                <span>Add Post</span>
              </Link>
            </li>
            <li className="treeview">
              <Link to="#" className="treeview" onClick={() => { setPosts(!posts); }}>
                <i className="fa fa-bars" />
                <span>Posts</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </Link>
              <ul className={`treeview-menu ${posts ? "menu-open" : "treeview"}`}>
                <li>
                  <Link to="posts">Posts</Link>
                </li>
                <li>
                  <Link to="slider-posts">
                    Slider Posts
                  </Link>
                </li>
                <li>
                  <Link to="featured-posts">
                    Featured Posts
                  </Link>
                </li>
                <li>
                  <Link to="breaking-news">
                    Breaking News
                  </Link>
                </li>
                <li>
                  <Link to="recommended-posts">
                    Recommended Posts
                  </Link>
                </li>
                <li>
                  <Link to="pending-posts">
                    Pending Posts
                  </Link>
                </li>
                <li>
                  <Link to="scheduled-posts">
                    Scheduled Posts
                  </Link>
                </li>
                <li>
                  <Link to="drafts">Drafts</Link>
                </li>
              </ul>
            </li>
            <li className="treeview">
              <Link to="#" onClick={() => setRssFeed(!rssFeed)}>
                <i className="fa fa-rss" /> <span>RSS Feeds</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </Link>
              <ul className={`treeview-menu ${rssFeed ? "menu-open" : "treeview"}`}>
                <li>
                  <Link to="import-feed">
                    Import RSS Feed
                  </Link>
                </li>
                <li>
                  <Link to="feed">RSS Feeds</Link>
                </li>
              </ul>
            </li>
            <li className="treeview">
              <Link to="#" onClick={() => setCategories(!categories)}>
                <i className="fa fa-folder-open" /> <span>Categories</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </Link>
              <ul className={`treeview-menu ${categories ? "menu-open" : "treeview"}`}>
                <li>
                  <Link to="categories">
                    Categories
                  </Link>
                </li>
                <li>
                  <Link to="subcategories">
                    Subcategories
                  </Link>
                </li>
              </ul>
            </li>
            <li className="treeview">
              <Link to="#" onClick={() => setWidget(!widget)}>
                <i className="fa fa-th" /> <span>Widgets</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </Link>
              <ul className={`treeview-menu ${widget ? "menu-open" : "treeview"}`}>
                <li>
                  <Link to="add-widget">
                    Add Widget
                  </Link>
                </li>
                <li>
                  <Link to="/admin/widgets">Widgets</Link>
                </li>
              </ul>
            </li>
            <li className="treeview">
              <Link to="#" onClick={() => setpolls(!polls)}>
                <i className="fa fa-list" /> <span>Polls</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </Link>
              <ul className={`treeview-menu ${polls ? "menu-open" : "treeview"}`}>
                <li>
                  <Link to="add-poll">Add Poll</Link>
                </li>
                <li>
                  <Link to="/admin/polls">Polls</Link>
                </li>
              </ul>
            </li>
            <li className="treeview">
              <Link to="#" onClick={() => setGallery(!gallery)}>
                <i className="fa fa-image" /> <span>Gallery</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </Link>
              <ul className={`treeview-menu ${gallery ? "menu-open" : "treeview"}`}>
                <li>
                  <Link to="gallery-image">Images</Link>
                </li>
                <li>
                  <Link to="gallery-albums">
                    Albums
                  </Link>
                </li>
                <li>
                  <Link to="gallery-categories">
                    Categories
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="contact-messages">
                <i className="fa fa-paper-plane" aria-hidden="true" />
                <span>Contact Messages</span>
              </Link>
            </li>
            <li className="treeview">
              <Link to="#" onClick={() => setComment(!comment)}>
                <i className="fa fa-comments" /> <span>Comments</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </Link>
              <ul className={`treeview-menu ${comment ? "menu-open" : "treeview"}`}>
                <li>
                  <Link to="pending-comments">
                    Pending Comments
                  </Link>
                </li>
                <li>
                  <Link to="Approved-comments">
                    Approved Comments
                  </Link>
                </li>
              </ul>
            </li>
            <li className="treeview">
              <Link to="#" onClick={() => setNewsLetter(!newsLetter)}>
                <i className="fa fa-envelope" /> <span>Newsletter</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </Link>
              <ul className={`treeview-menu ${newsLetter ? "menu-open" : "treeview"}`}>
                <li>
                  <Link to="send-email-subscribers">
                    Send Email to Subscribers
                  </Link>
                </li>
                <li>
                  <Link to="subscribers">
                    Subscribers
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="ad-spaces">
                <i className="fa fa-dollar" aria-hidden="true" />
                <span>Ad Spaces</span>
              </Link>
            </li>
            <li className="treeview">
              <Link to="#" onClick={() => setUsers(!users)}>
                <i className="fa fa-users" />
                <span>Users</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </Link>
              <ul className={`treeview-menu ${users ? "menu-open" : "treeview"}`}>
                <li>
                  <Link to="add-user"> Add User</Link>
                </li>
                <li>
                  <Link to="administrators">
                    {" "}
                    Administrators
                  </Link>
                </li>
                <li>
                  <Link to="users">Users</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="roles-permissions">
                <i className="fa fa-key" aria-hidden="true" />
                <span>Roles &amp; Permissions</span>
              </Link>
            </li>
            <li>
              <Link to="seo-tools">
                <i className="fa fa-wrench" />
                <span>SEO Tools</span>
              </Link>
            </li>
            <li>
              <Link to="social-login-configuration">
                <i className="fa fa-share-alt" />
                <span>Social Login Configuration</span>
              </Link>
            </li>
            <li>
              <Link to="cache-system">
                <i className="fa fa-database" />
                <span>Cache System</span>
              </Link>
            </li>
            <li className="header">Settings</li>
            <li>
              <Link to="preferences">
                <i className="fa fa-check-square-o" />
                <span>Preferences</span>
              </Link>
            </li>
            <li>
              <Link to="route-settings">
                <i className="fa fa-map-signs" />
                <span>Route Settings</span>
              </Link>
            </li>
            <li>
              <Link to="email-settings">
                <i className="fa fa-cog" />
                <span>Email Settings</span>
              </Link>
            </li>
            <li>
              <Link to="visual-settings">
                <i className="fa fa-paint-brush" />
                <span>Visual Settings</span>
              </Link>
            </li>
            <li>
              <Link to="font-settings">
                <i className="fa fa-font" aria-hidden="true" />
                <span>Font Settings</span>
              </Link>
            </li>
            <li>
              <Link to="language-settings">
                <i className="fa fa-language" />
                <span>Language Settings</span>
              </Link>
            </li>
            <li>
              <Link to="settings">
                <i className="fa fa-cogs" />
                <span>General Settings</span>
              </Link>
            </li>
          </ul>
        </section>
        {/* /.sidebar */}
      </aside>
    </>
  );
}
export default Aside;
