
function SocialLoginFacebook() {
  return (
    <>
      <div className="col-lg-6 col-md-12">
        <div className="box box-primary">
          <div className="box-header with-border">
            <h3 className="box-title">Facebook</h3>
          </div>
          <form action="https://prernamedia.com/admin_controller/social_login_facebook_post" method="post" acceptCharset="utf-8">
            <input type="hidden" name="varient_csrf_token" defaultValue="767371c5be6a03be7543c42b1699a2ef" />
            <div className="box-body">
              <div className="form-group">
                <label className="label-sitemap">App ID</label>
                <input type="text" className="form-control" name="facebook_app_id" placeholder="App ID" defaultValue />
              </div>
              <div className="form-group">
                <label className="label-sitemap">App Secret</label>
                <input type="text" className="form-control" name="facebook_app_secret" placeholder="App Secret" defaultValue />
              </div>
              <div className="box-footer" style={{ paddingLeft: 0, paddingRight: 0 }}>
                <button type="submit" className="btn btn-primary pull-right">Save Changes</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export default SocialLoginFacebook