import { Link } from "react-router-dom"

function ImportFeed() {
    return (
        <>
            <section className="content">
                <div className="row">
                    <div className="col-sm-12 col-lg-8">
                        <div className="box box-primary">
                            <div className="box-header with-border">
                                <div className="left">
                                    <h3 className="box-title">Import RSS Feed</h3>
                                </div>
                                <div className="right">
                                    <Link to="/admin/feed" className="btn btn-success btn-add-new">
                                        <i className="fa fa-bars" />
                                        RSS Feeds                  </Link>
                                </div>
                            </div>{/* /.box-header */}
                            {/* form start */}
                            <form action="https://prernamedia.com/rss_controller/import_feed_post" encType="multipart/form-data" method="post" acceptCharset="utf-8">
                                <input type="hidden" name="varient_csrf_token" defaultValue="4f2ab8c9beaae3963b67a29c4e3a1b21" />
                                <div className="box-body">
                                    {/* include message block */}
                                    {/*print error messages*/}
                                    {/*print custom error message*/}
                                    {/*print custom success message*/}
                                    <div className="form-group">
                                        <label>Feed Name</label>
                                        <input type="text" className="form-control" name="feed_name" placeholder="Feed Name" defaultValue maxLength={400} required fdprocessedid="2ph7ng" />
                                    </div>
                                    <div className="form-group">
                                        <label>Feed URL</label>
                                        <input type="text" className="form-control" name="feed_url" placeholder="Feed URL" defaultValue required fdprocessedid="gvayid" />
                                    </div>
                                    <div className="form-group">
                                        <label>Number of Posts to Import</label>
                                        <input type="number" className="form-control max-500" name="post_limit" placeholder="Number of Posts to Import" defaultValue min={1} max={500} required fdprocessedid="97tcmc" />
                                    </div>
                                    <div className="form-group">
                                        <label>Language</label>
                                        <select name="lang_id" className="form-control max-500" onchange="get_parent_categories_by_lang(this.value);" fdprocessedid="jerrik">
                                            <option value={1} selected>English</option>
                                            <option value={2}>Hindi</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Category</label>
                                        <select id="categories" name="category_id" className="form-control max-500" onchange="get_sub_categories(this.value);" required fdprocessedid="e70av4">
                                            <option value>Select a category</option>
                                            <option value={1}>सिटी</option>
                                            <option value={3}>प्रदेश</option>
                                            <option value={4}>देश</option>
                                            <option value={5}>वर्ल्ड</option>
                                            <option value={6}>बॉयोस्कोप</option>
                                            <option value={7}>खेल</option>
                                            <option value={8}>B&amp;E</option>
                                            <option value={9}>ज्ञान-विज्ञान</option>
                                            <option value={10}>लाइफस्टाइल</option>
                                            <option value={11}>आस्था</option>
                                            <option value={12}>कैरियर</option>
                                            <option value={13}>हेल्थ</option>
                                            <option value={14}>रसोई</option>
                                            <option value={15}>Business</option>
                                            <option value={69}>VIDEO</option>
                                            <option value={70}>Politics</option>
                                            <option value={71}>Funding</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Subcategory</label>
                                        <select id="subcategories" name="subcategory_id" className="form-control max-500" fdprocessedid="8bd2tr">
                                            <option value={0}>Select a category</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12 col-xs-12">
                                                <label>Images</label>
                                            </div>
                                            <div className="col-sm-12 col-xs-12 col-option">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    Show Images from Original Source
                                                    </label>
                                                </div>


                                                {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="image_saving_method" defaultValue="url" id="show_images_from_original_source" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                                <label htmlFor="show_images_from_original_source" className="option-label">Show Images from Original Source</label> */}
                                            </div>
                                            <div className="col-sm-12 col-xs-12 col-option">
                                            <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    Show Images from Original Source
                                                    </label>
                                                </div>
                                                {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="image_saving_method" defaultValue="download" id="download_images" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                                <label htmlFor="download_images" className="option-label">Download Images to My Server</label> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-3 col-xs-12">
                                                <label>Auto Update</label>
                                            </div>
                                            <div className="col-sm-12 col-xs-12 col-option">
                                            <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault1" />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    Yes
                                                    </label>
                                                </div>
                                                {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="auto_update" defaultValue={1} id="auto_update_enabled" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                                <label htmlFor="auto_update_enabled" className="option-label">Yes</label> */}
                                            </div>
                                            <div className="col-sm-12 col-xs-12 col-option">
                                            <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault1" />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    No
                                                    </label>
                                                </div>
                                                {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="auto_update" defaultValue={0} id="auto_update_disabled" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                                <label htmlFor="auto_update_disabled" className="option-label">No</label> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-3 col-xs-12">
                                                <label>Show Read More Button</label>
                                            </div>
                                            <div className="col-sm-12 col-xs-12 col-option">
                                            <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault1" />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    Yes
                                                    </label>
                                                </div>
                                                {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="read_more_button" defaultValue={1} id="read_more_button_enabled" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                                <label htmlFor="read_more_button_enabled" className="option-label">Yes</label> */}
                                            </div>
                                            <div className="col-sm-12 col-xs-12 col-option">
                                            <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault1" />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    No
                                                    </label>
                                                </div>
                                                {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="read_more_button" defaultValue={0} id="read_more_button_disabled" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                                <label htmlFor="read_more_button_disabled" className="option-label">No</label> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-3 col-xs-12">
                                                <label>Add Posts as Draft</label>
                                            </div>
                                            <div className="col-sm-12 col-xs-12 col-option">
                                            <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault1" />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    Yes
                                                    </label>
                                                </div>
                                                {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="add_posts_as_draft" defaultValue={1} id="add_posts_as_draft_1" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                                <label htmlFor="add_posts_as_draft_1" className="option-label">Yes</label> */}
                                            </div>
                                            <div className="col-sm-12 col-xs-12 col-option">
                                            <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault1" />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    No
                                                    </label>
                                                </div>
                                                {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="add_posts_as_draft" defaultValue={0} id="add_posts_as_draft_2" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                                <label htmlFor="add_posts_as_draft_2" className="option-label">No</label> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Read More Button Text</label>
                                        <input type="text" className="form-control max-500" name="read_more_button_text" placeholder="Read More Button Text" defaultValue="Read More" fdprocessedid="k55w9m" />
                                    </div>
                                </div>
                                {/* /.box-body */}
                                <div className="box-footer">
                                    <button type="submit" className="btn btn-primary pull-right" fdprocessedid="3ezy1b">Import RSS Feed</button>
                                </div>
                                {/* /.box-footer */}
                            </form>{/* form end */}
                        </div>
                        {/* /.box */}
                    </div>
                </div>
                <style dangerouslySetInnerHTML={{ __html: "\n\t.col-option{\n\t\tmargin-bottom: 5px;\n\t}\n" }} />
            </section>

        </>
    )
}
export default ImportFeed