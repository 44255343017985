
function  UpdateCategories() {
    return (
        <>
        <section className="content">
  <div className="row">
    <div className="col-lg-5 col-md-12">
      <div className="box box-primary">
        <div className="box-header with-border">
          <h3 className="box-title">Update Category</h3>
        </div>
        {/* /.box-header */}
        {/* form start */}
        <form action="https://prernamedia.com/category_controller/update_gallery_category_post" method="post" acceptCharset="utf-8">
          <input type="hidden" name="varient_csrf_token" defaultValue="3d53193b0ce930dea5ad6663457d1f8b" />                                        
          <input type="hidden" name="id" defaultValue={1} />
          <div className="box-body">
            {/* include message block */}
            {/*print error messages*/}
            {/*print custom error message*/}
            {/*print custom success message*/}
            <div className="form-group">
              <label>Language</label>
              <select name="lang_id" className="form-control" onchange="get_albums_by_lang(this.value);" fdprocessedid="ii3iy">
                <option value={1} selected>English</option>
                <option value={2}>Hindi</option>
              </select>
            </div>
            <div className="form-group">
              <label>Album</label>
              <select name="album_id" id="albums" className="form-control" required fdprocessedid="nbzhks">
                <option value>Select</option>
                <option value={1} selected>Media</option>
              </select>
            </div>
            <div className="form-group">
              <label>Category Name</label>
              <input type="text" className="form-control" name="name" placeholder="Category Name" defaultValue="Ad_photos" maxLength={200} required fdprocessedid="s8bjla" />
            </div>
          </div>
          {/* /.box-body */}
          <div className="box-footer">
            <button type="submit" className="btn btn-primary pull-right" fdprocessedid="anbbd">Save Changes</button>
          </div>
          {/* /.box-footer */}
        </form>{/* form end */}
      </div>
      {/* /.box */}
    </div>
  </div>
</section>

        </>
    )
}
export default UpdateCategories