import { Link } from "react-router-dom"

function Pagess() {
    return (
        <>
            <section className="content">
                <div className="box">
                    <div className="box-header with-border">
                        <div className="left">
                            <h3 className="box-title">Pages</h3>
                        </div>
                        <div className="right">
                            <Link to="/admin/add-page" className="btn btn-success btn-add-new">
                                <i className="fa fa-plus" />
                                Add Page          </Link>
                        </div>
                    </div>{/* /.box-header */}
                    <div className="box-body">
                        <div className="row">
                            {/* include message block */}
                            <div className="col-sm-12">
                                {/*print error messages*/}
                                {/*print custom error message*/}
                                {/*print custom success message*/}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    <div id="cs_datatable_lang_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer"><div className="dataTables_length" id="cs_datatable_lang_length"><label>Show <select name="cs_datatable_lang_length" aria-controls="cs_datatable_lang" className="form-control input-sm"><option value={15}>15</option><option value={30}>30</option><option value={60}>60</option><option value={100}>100</option></select></label></div><div id="table_dropdown"><label className="table-label">Language</label><select className="form-control input-sm"><option value>All</option><option value="English">English</option><option value="Hindi">Hindi</option></select></div><div id="cs_datatable_lang_filter" className="dataTables_filter"><label>Search<input type="search" className="form-control input-sm" placeholder aria-controls="cs_datatable_lang" /></label></div><table className="table table-bordered table-striped dataTable no-footer" id="cs_datatable_lang" role="grid" aria-describedby="cs_datatable_lang_info">
                                        <thead>
                                            <tr role="row"><th width={20} className="sorting_desc" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-sort="descending" aria-label="Id: activate to sort column ascending" style={{ width: 20 }}>Id</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Title: activate to sort column ascending" style={{ width: '153.712px' }}>Title</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Language: activate to sort column ascending" style={{ width: '102.838px' }}>Language</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Location: activate to sort column ascending" style={{ width: '93.5375px' }}>Location</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Visibility: activate to sort column ascending" style={{ width: '93.2px' }}>Visibility</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Page Type: activate to sort column ascending" style={{ width: '106.363px' }}>Page Type</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Date Added: activate to sort column ascending" style={{ width: '142.837px' }}>Date Added</th><th className="max-width-120 sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Options: activate to sort column ascending" style={{ width: '141.113px' }}>Options</th></tr>
                                        </thead>
                                        <tbody>
                                            <tr role="row" className="odd">
                                                <td className="sorting_1">6</td>
                                                <td>Terms &amp; Conditions</td>
                                                <td>
                                                    Hindi                                  </td>
                                                <td>
                                                    Footer                                  </td>
                                                <td>
                                                    <label className="label label-success"><i className="fa fa-eye" /></label>
                                                </td>
                                                <td>
                                                    <label className="label label-default">Default</label>
                                                </td>
                                                <td>2021-05-31 / 07:55</td>
                                                <td>
                                                    <div class="dropdown">
                                                        <button class="btn bg-purple dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            Dropdown button
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <Link class="dropdown-item" to="/admin/update-page/6">
                                                                <i className="fa fa-edit option-icon" />Edit</Link>
                                                            <Link class="dropdown-item" href="javascript:void(0)" onclick="delete_item('page_controller/delete_page_post','6','Are you sure you want to delete this page?');"><i className="fa fa-trash option-icon" />Delete</Link>

                                                        </div>
                                                    </div>
                                                    {/* <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                                <span className="caret" />
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="https://prernamedia.com/admin/update-page/6"><i className="fa fa-edit option-icon" />Edit</a>
                                                            </li>
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('page_controller/delete_page_post','6','Are you sure you want to delete this page?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div> */}
                                                </td>
                                            </tr><tr role="row" className="even">
                                                <td className="sorting_1">5</td>
                                                <td>Contact</td>
                                                <td>
                                                    Hindi                                  </td>
                                                <td>
                                                    Top Menu                                  </td>
                                                <td>
                                                    <label className="label label-success"><i className="fa fa-eye" /></label>
                                                </td>
                                                <td>
                                                    <label className="label label-default">Default</label>
                                                </td>
                                                <td>2021-05-31 / 07:55</td>
                                                <td>
                                                    <div class="dropdown">
                                                        <button class="btn bg-purple dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            Dropdown button
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <Link class="dropdown-item" to="/admin/update-page/6">
                                                                <i className="fa fa-edit option-icon" />Edit</Link>
                                                            <a class="dropdown-item" href="javascript:void(0)" onclick="delete_item('page_controller/delete_page_post','6','Are you sure you want to delete this page?');"><i className="fa fa-trash option-icon" />Delete</a>

                                                        </div>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="odd">
                                                <td className="sorting_1">4</td>
                                                <td>Gallery</td>
                                                <td>
                                                    Hindi                                  </td>
                                                <td>
                                                    Main Menu                                  </td>
                                                <td>
                                                    <label className="label label-success"><i className="fa fa-eye" /></label>
                                                </td>
                                                <td>
                                                    <label className="label label-default">Default</label>
                                                </td>
                                                <td>2021-05-31 / 07:55</td>
                                                <td>
                                                    <div class="dropdown">
                                                        <button class="btn bg-purple dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            Dropdown button
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <Link class="dropdown-item" to="/admin/update-page/6">
                                                                <i className="fa fa-edit option-icon" />Edit</Link>
                                                            <a class="dropdown-item" href="javascript:void(0)" onclick="delete_item('page_controller/delete_page_post','6','Are you sure you want to delete this page?');"><i className="fa fa-trash option-icon" />Delete</a>

                                                        </div>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="even">
                                                <td className="sorting_1">3</td>
                                                <td>Terms &amp; Conditions</td>
                                                <td>
                                                    English                                  </td>
                                                <td>
                                                    Footer                                  </td>
                                                <td>
                                                    <label className="label label-success"><i className="fa fa-eye" /></label>
                                                </td>
                                                <td>
                                                    <label className="label label-default">Default</label>
                                                </td>
                                                <td>2020-02-18 / 11:12</td>
                                                <td>
                                                    <div class="dropdown">
                                                        <button class="btn bg-purple dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            Dropdown button
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <Link class="dropdown-item" to="/admin/update-page/6">
                                                                <i className="fa fa-edit option-icon" />Edit</Link>
                                                            <a class="dropdown-item" href="javascript:void(0)" onclick="delete_item('page_controller/delete_page_post','6','Are you sure you want to delete this page?');"><i className="fa fa-trash option-icon" />Delete</a>

                                                        </div>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="odd">
                                                <td className="sorting_1">2</td>
                                                <td>Gallery</td>
                                                <td>
                                                    English                                  </td>
                                                <td>
                                                    -                                  </td>
                                                <td>
                                                    <label className="label label-success"><i className="fa fa-eye" /></label>
                                                </td>
                                                <td>
                                                    <label className="label label-default">Default</label>
                                                </td>
                                                <td>2020-02-18 / 11:11</td>
                                                <td>
                                                    <div class="dropdown">
                                                        <button class="btn bg-purple dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            Dropdown button
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <Link class="dropdown-item" to="/admin/update-page/6">
                                                                <i className="fa fa-edit option-icon" />Edit</Link>
                                                            <a class="dropdown-item" href="javascript:void(0)" onclick="delete_item('page_controller/delete_page_post','6','Are you sure you want to delete this page?');"><i className="fa fa-trash option-icon" />Delete</a>

                                                        </div>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="even">
                                                <td className="sorting_1">1</td>
                                                <td>Contact</td>
                                                <td>
                                                    English                                  </td>
                                                <td>
                                                    Top Menu                                  </td>
                                                <td>
                                                    <label className="label label-success"><i className="fa fa-eye" /></label>
                                                </td>
                                                <td>
                                                    <label className="label label-default">Default</label>
                                                </td>
                                                <td>2020-02-18 / 11:09</td>
                                                <td>
                                                    <div class="dropdown">
                                                        <button class="btn bg-purple dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            Dropdown button
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <Link class="dropdown-item" to="/admin/update-page/6">
                                                                <i className="fa fa-edit option-icon" />Edit</Link>
                                                            <a class="dropdown-item" href="javascript:void(0)" onclick="delete_item('page_controller/delete_page_post','6','Are you sure you want to delete this page?');"><i className="fa fa-trash option-icon" />Delete</a>

                                                        </div>
                                                    </div>
                                                </td>
                                            </tr></tbody>
                                    </table><div className="dataTables_info" id="cs_datatable_lang_info" role="status" aria-live="polite">Showing 1 to 6 of 6 entries</div><div className="dataTables_paginate paging_simple_numbers" id="cs_datatable_lang_paginate"><ul className="pagination"><li className="paginate_button previous disabled" id="cs_datatable_lang_previous"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={0} tabIndex={0}>‹</a></li><li className="paginate_button active"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={1} tabIndex={0}>1</a></li><li className="paginate_button next disabled" id="cs_datatable_lang_next"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={2} tabIndex={0}>›</a></li></ul></div></div>
                                </div>
                            </div>
                        </div>
                    </div>{/* /.box-body */}
                </div>
            </section>

        </>
    )
}
export default Pagess