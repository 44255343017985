import AddWidgets from "../../../Components/widgets/addWidget/AddWidgets"


function AddWidgetPage() {
    return (
        <>
        <AddWidgets/>
        </>
    )
}
export default AddWidgetPage