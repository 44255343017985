import Posts from "../../../Components/postsCompMain/posts/Posts"

const data = {
  title: "Recommended Posts",
}
function RecommendedPostsPage() {
  return (
    <>
      <Posts data={data} />
    </>
  )
}
export default RecommendedPostsPage