import { useState } from "react";
import { SketchPicker } from "react-color";

function UpdateCategory() {
  const [currentColor, setCurrentColor] = useState("#ff6")
  const [show, setShow] = useState(false)
  const handleOnChange = (color) => {
    // console.log(color)
    setCurrentColor(color.hex)
  }
  return (
    <>
      <section className="content">
        <div className="row">
          <div className="col-lg-5 col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Update Category</h3>
              </div>
              {/* /.box-header */}
              {/* form start */}
              <form
                action="https://prernamedia.com/category_controller/update_category_post"
                method="post"
                acceptCharset="utf-8"
              >
                <input
                  type="hidden"
                  name="varient_csrf_token"
                  defaultValue="aadfcd795a80e220ec5908f7a85daf40"
                />
                <input type="hidden" name="id" defaultValue={69} />
                <input type="hidden" name="parent_id" defaultValue={0} />
                <input
                  type="hidden"
                  name="redirect_url"
                  defaultValue="https://prernamedia.com/admin/navigation?lang=1"
                />
                <div className="box-body">
                  {/* include message block */}
                  {/*print error messages*/}
                  {/*print custom error message*/}
                  {/*print custom success message*/}
                  <div className="form-group">
                    <label>Language</label>
                    <select
                      name="lang_id"
                      className="form-control"
                      fdprocessedid="ftc25l"
                    >
                      <option value={1} selected>
                        English
                      </option>
                      <option value={2}>Hindi</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Category Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Category Name"
                      defaultValue="VIDEO"
                      maxLength={200}
                      required
                      fdprocessedid="f3xd25"
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label">
                      Slug{" "}
                      <small>
                        (If you leave it blank, it will be generated
                        automatically.)
                      </small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name_slug"
                      placeholder="Slug"
                      defaultValue="VIDEO"
                      fdprocessedid="iwmixa"
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label">
                      Description (Meta Tag)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="description"
                      placeholder="Description (Meta Tag)"
                      defaultValue
                      fdprocessedid="zjz14g"
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label">Keywords (Meta Tag)</label>
                    <input
                      type="text"
                      className="form-control"
                      name="keywords"
                      placeholder="Keywords (Meta Tag)"
                      defaultValue
                      fdprocessedid="mcwjvp"
                    />
                  </div>
                  {/* Color Picker */}
                  <div className="form-group">
                    <label>Color</label>
                    <div className="input-group my-colorpicker colorpicker-element">
                      <input
                        type="text"
                        className="form-control"
                        name="color"
                        maxLength={200}
                        placeholder={currentColor}
                        required
                        fdprocessedid="n61toh"

                      />
                      <div className="input-group-addon" onClick={() => setShow(!show)}>
                        <i style={{ backgroundColor: `${currentColor}` }} />
                        <SketchPicker
                          className={`${show ? 'showpicker' : 'showpickersdwc'}`}
                          color={currentColor}
                          onChangeComplete={(color) => handleOnChange(color)}
                        />
                      </div>
                    </div>
                    {/* /.input group */}
                  </div>
                  {/* /.form group */}
                  <div className="form-group">
                    <label>Menu Order</label>
                    <input
                      type="number"
                      className="form-control"
                      name="category_order"
                      placeholder="Menu Order"
                      defaultValue={2}
                      min={1}
                      required
                      fdprocessedid="vijiir"
                    />
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-5 col-xs-12">
                        <label>Show on Menu</label>
                      </div>
                      <div className="col-md-3 col-sm-4 col-xs-12 col-option">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                          <label className="form-check-label" htmlFor="flexRadioDefault1">
                            Yes
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-4 col-xs-12 col-option">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked />
                          <label className="form-check-label" htmlFor="flexRadioDefault2">
                            No
                          </label>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-5 col-xs-12">
                        <label>Show on Homepage</label>
                      </div>
                      <div className="col-md-3 col-sm-4 col-xs-12 col-option">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault1" />
                          <label className="form-check-label" htmlFor="flexRadioDefault1">
                            Yes
                          </label>
                        </div>

                      </div>
                      <div className="col-md-3 col-sm-4 col-xs-12 col-option">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault2" defaultChecked />
                          <label className="form-check-label" htmlFor="flexRadioDefault2">
                            No
                          </label>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Category Block Style</label>
                    <div className="row m-b-15 m-t-15">
                      <div className="category-block-box">
                        <div className="col-sm-12 text-center m-b-15">
                          <div
                            className="iradio_square-purple checked"
                            style={{ position: "relative" }}
                          >
                            <input
                              type="radio"
                              name="block_type"
                              defaultValue="block-1"
                              className="square-purple"
                              defaultChecked
                              style={{
                                position: "absolute",
                                top: "-20%",
                                left: "-20%",
                                display: "block",
                                width: "140%",
                                height: "140%",
                                margin: 0,
                                padding: 0,
                                background: "rgb(255, 255, 255)",
                                border: 0,
                                opacity: 0,
                              }}
                            />
                            <ins
                              className="iCheck-helper"
                              style={{
                                position: "absolute",
                                top: "-20%",
                                left: "-20%",
                                display: "block",
                                width: "140%",
                                height: "140%",
                                margin: 0,
                                padding: 0,
                                background: "rgb(255, 255, 255)",
                                border: 0,
                                opacity: 0,
                              }}
                            />
                          </div>
                        </div>
                        <img
                          src="https://prernamedia.com/assets/admin/img/block-1.png"
                          alt
                          className="img-responsive cat-block-img"
                        />
                      </div>
                      <div className="category-block-box">
                        <div className="col-sm-12 text-center m-b-15">
                          <div
                            className="iradio_square-purple"
                            style={{ position: "relative" }}
                          >
                            <input
                              type="radio"
                              name="block_type"
                              defaultValue="block-2"
                              className="square-purple"
                              style={{
                                position: "absolute",
                                top: "-20%",
                                left: "-20%",
                                display: "block",
                                width: "140%",
                                height: "140%",
                                margin: 0,
                                padding: 0,
                                background: "rgb(255, 255, 255)",
                                border: 0,
                                opacity: 0,
                              }}
                            />
                            <ins
                              className="iCheck-helper"
                              style={{
                                position: "absolute",
                                top: "-20%",
                                left: "-20%",
                                display: "block",
                                width: "140%",
                                height: "140%",
                                margin: 0,
                                padding: 0,
                                background: "rgb(255, 255, 255)",
                                border: 0,
                                opacity: 0,
                              }}
                            />
                          </div>
                        </div>
                        <img
                          src="https://prernamedia.com/assets/admin/img/block-2.png"
                          alt
                          className="img-responsive cat-block-img"
                        />
                      </div>
                      <div className="category-block-box">
                        <div className="col-sm-12 text-center m-b-15">
                          <div
                            className="iradio_square-purple"
                            style={{ position: "relative" }}
                          >
                            <input
                              type="radio"
                              name="block_type"
                              defaultValue="block-3"
                              className="square-purple"
                              style={{
                                position: "absolute",
                                top: "-20%",
                                left: "-20%",
                                display: "block",
                                width: "140%",
                                height: "140%",
                                margin: 0,
                                padding: 0,
                                background: "rgb(255, 255, 255)",
                                border: 0,
                                opacity: 0,
                              }}
                            />
                            <ins
                              className="iCheck-helper"
                              style={{
                                position: "absolute",
                                top: "-20%",
                                left: "-20%",
                                display: "block",
                                width: "140%",
                                height: "140%",
                                margin: 0,
                                padding: 0,
                                background: "rgb(255, 255, 255)",
                                border: 0,
                                opacity: 0,
                              }}
                            />
                          </div>
                        </div>
                        <img
                          src="https://prernamedia.com/assets/admin/img/block-3.png"
                          alt
                          className="img-responsive cat-block-img"
                        />
                      </div>
                      <div className="category-block-box">
                        <div className="col-sm-12 text-center m-b-15">
                          <div
                            className="iradio_square-purple"
                            style={{ position: "relative" }}
                          >
                            <input
                              type="radio"
                              name="block_type"
                              defaultValue="block-4"
                              className="square-purple"
                              style={{
                                position: "absolute",
                                top: "-20%",
                                left: "-20%",
                                display: "block",
                                width: "140%",
                                height: "140%",
                                margin: 0,
                                padding: 0,
                                background: "rgb(255, 255, 255)",
                                border: 0,
                                opacity: 0,
                              }}
                            />
                            <ins
                              className="iCheck-helper"
                              style={{
                                position: "absolute",
                                top: "-20%",
                                left: "-20%",
                                display: "block",
                                width: "140%",
                                height: "140%",
                                margin: 0,
                                padding: 0,
                                background: "rgb(255, 255, 255)",
                                border: 0,
                                opacity: 0,
                              }}
                            />
                          </div>
                        </div>
                        <img
                          src="https://prernamedia.com/assets/admin/img/block-4.png"
                          alt
                          className="img-responsive cat-block-img"
                        />
                      </div>
                      <div className="category-block-box">
                        <div className="col-sm-12 text-center m-b-15">
                          <div
                            className="iradio_square-purple"
                            style={{ position: "relative" }}
                          >
                            <input
                              type="radio"
                              name="block_type"
                              defaultValue="block-5"
                              className="square-purple"
                              style={{
                                position: "absolute",
                                top: "-20%",
                                left: "-20%",
                                display: "block",
                                width: "140%",
                                height: "140%",
                                margin: 0,
                                padding: 0,
                                background: "rgb(255, 255, 255)",
                                border: 0,
                                opacity: 0,
                              }}
                            />
                            <ins
                              className="iCheck-helper"
                              style={{
                                position: "absolute",
                                top: "-20%",
                                left: "-20%",
                                display: "block",
                                width: "140%",
                                height: "140%",
                                margin: 0,
                                padding: 0,
                                background: "rgb(255, 255, 255)",
                                border: 0,
                                opacity: 0,
                              }}
                            />
                          </div>
                        </div>
                        <img
                          src="https://prernamedia.com/assets/admin/img/block-5.png"
                          alt
                          className="img-responsive cat-block-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.box-body */}
                <div className="box-footer">
                  <button
                    type="submit"
                    className="btn btn-primary pull-right"
                    fdprocessedid="0czcnj"
                  >
                    Save Changes{" "}
                  </button>
                </div>
                {/* /.box-footer */}
              </form>
              {/* form end */}
            </div>
            {/* /.box */}
          </div>
        </div>
      </section>
    </>
  );
}
export default UpdateCategory;
