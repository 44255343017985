

function SocialLoginGoogle() {
  return (
    <>
      <div className="col-lg-6 col-md-12">
        <div className="box box-primary">
          <div className="box-header with-border">
            <h3 className="box-title">Google</h3>
          </div>
          <form action="https://prernamedia.com/admin_controller/social_login_google_post" method="post" acceptCharset="utf-8">
            <input type="hidden" name="varient_csrf_token" defaultValue="767371c5be6a03be7543c42b1699a2ef" />
            <div className="box-body">
              <div className="form-group">
                <label className="label-sitemap">Client ID</label>
                <input type="text" className="form-control" name="google_client_id" placeholder="Client ID" defaultValue />
              </div>
              <div className="form-group">
                <label className="label-sitemap">Client Secret</label>
                <input type="text" className="form-control" name="google_client_secret" placeholder="Client Secret" defaultValue />
              </div>
              <div className="box-footer" style={{ paddingLeft: 0, paddingRight: 0 }}>
                <button type="submit" className="btn btn-primary pull-right">Save Changes</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export default SocialLoginGoogle