
function RouteSetting() {
    return (
        <>
            <section className="content">
                <div className="row">
                    <div className="col-sm-12 col-lg-8">
                        <div className="box">
                            <div className="box-header with-border">
                                <div className="left">
                                    <h3 className="box-title">Route Settings</h3>
                                </div>
                            </div>{/* /.box-header */}
                            {/* form start */}
                            <form action="https://prernamedia.com/admin_controller/route_settings_post" encType="multipart/form-data" method="post" acceptCharset="utf-8">
                                <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />
                                <div className="box-body">
                                    <div className="row">
                                        {/* include message block */}
                                        <div className="col-sm-12">
                                            {/*print error messages*/}
                                            {/*print custom error message*/}
                                            {/*print custom success message*/}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="admin_readonly" defaultValue="admin" readOnly fdprocessedid="7ftson" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="admin" defaultValue="admin" maxLength={100} required fdprocessedid="lrryra" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="profile_readonly" defaultValue="profile" readOnly fdprocessedid="gr41z" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="profile" defaultValue="profile" maxLength={100} required fdprocessedid="fkqra" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="tag_readonly" defaultValue="tag" readOnly fdprocessedid="7pte1k" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="tag" defaultValue="tag" maxLength={100} required fdprocessedid="taekp" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="reading_list_readonly" defaultValue="reading-list" readOnly fdprocessedid="3z8oag" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="reading_list" defaultValue="reading-list" maxLength={100} required fdprocessedid="dv2t26" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="settings_readonly" defaultValue="settings" readOnly fdprocessedid="7boj4m" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="settings" defaultValue="settings" maxLength={100} required fdprocessedid="19e44b" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="social_accounts_readonly" defaultValue="social-accounts" readOnly fdprocessedid="uxnm2g" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="social_accounts" defaultValue="social-accounts" maxLength={100} required fdprocessedid="vgesik" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="preferences_readonly" defaultValue="preferences" readOnly fdprocessedid="szdjz9" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="preferences" defaultValue="preferences" maxLength={100} required fdprocessedid="d4v6ec" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="visual_settings_readonly" defaultValue="visual_settings" readOnly fdprocessedid="nc83lq" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="visual_settings" defaultValue="visual-settings" maxLength={100} required fdprocessedid="5k4apk" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="change_password_readonly" defaultValue="change-password" readOnly fdprocessedid="xdll4n" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="change_password" defaultValue="change-password" maxLength={100} required fdprocessedid="pu6mwd" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="forgot_password_readonly" defaultValue="forgot-password" readOnly fdprocessedid="v3nmnn" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="forgot_password" defaultValue="forgot-password" maxLength={100} required fdprocessedid="08g1vun" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="reset_password_readonly" defaultValue="reset-password" readOnly fdprocessedid="jfs2t" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="reset_password" defaultValue="reset-password" maxLength={100} required fdprocessedid="gbwmaq" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="register_readonly" defaultValue="register" readOnly fdprocessedid="z05nkn" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="register" defaultValue="register" maxLength={100} required fdprocessedid="ae14zf" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="posts_readonly" defaultValue="posts" readOnly fdprocessedid="6t7wtq" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="posts" defaultValue="posts" maxLength={100} required fdprocessedid="pra1ot" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="search_readonly" defaultValue="search" readOnly fdprocessedid="jcrzh" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="search" defaultValue="search" maxLength={100} required fdprocessedid="2aflrn" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="rss_feeds_readonly" defaultValue="rss-feeds" readOnly fdprocessedid="rhmpa" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="rss_feeds" defaultValue="rss-feeds" maxLength={100} required fdprocessedid="xepkoh" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="gallery_album_readonly" defaultValue="gallery_album" readOnly fdprocessedid="2wh2w3" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="gallery_album" defaultValue="gallery-album" maxLength={100} required fdprocessedid="xe7nl" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="logout_readonly" defaultValue="logout" readOnly fdprocessedid="iim5t" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="logout" defaultValue="logout" maxLength={100} required fdprocessedid="5jih8" />
                                            </div>
                                        </div>
                                    </div>
                                </div>{/* /.box-body */}
                                {/* /.box-body */}
                                <div className="box-footer">
                                    <button type="submit" className="btn btn-primary pull-right" fdprocessedid="bvz75a">Save Changes</button>
                                </div>
                                {/* /.box-footer */}
                            </form>{/* form end */}
                        </div>
                        <div className="alert alert-danger alert-large">
                            <strong>Warning!</strong>&nbsp;&nbsp;You cannot use special characters in routes. If your language contains special characters, please be careful when editing routes. If you enter an invalid route, you will not be able to access the related page.      </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default RouteSetting