import { useState } from "react";
import { Button, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"

function ApprovedComment() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <section className="content">
                <div className="box">
                    <div className="box-header with-border">
                        <div className="left">
                            <h3 className="box-title">Approved Comments</h3>
                        </div>
                        <div className="right">
                            <Link to="/admin/pending-comments" className="btn btn-success btn-add-new">
                                <i className="fa fa-bars" />
                                Pending Comments			</Link>
                        </div>
                    </div>{/* /.box-header */}
                    <div className="box-body">
                        <div className="row">
                            {/* include message block */}
                            <div className="col-sm-12">
                                {/*print error messages*/}
                                {/*print custom error message*/}
                                {/*print custom success message*/}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    <div id="cs_datatable_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer"><div className="row"><div className="col-sm-6"><div className="dataTables_length" id="cs_datatable_length"><label>Show <select name="cs_datatable_length" aria-controls="cs_datatable" className="form-control input-sm"><option value={15}>15</option><option value={30}>30</option><option value={60}>60</option><option value={100}>100</option></select></label></div></div><div className="col-sm-6"><div id="cs_datatable_filter" className="dataTables_filter"><label>Search<input type="search" className="form-control input-sm" placeholder aria-controls="cs_datatable" /></label></div></div></div><div className="row"><div className="col-sm-12"><table className="table table-bordered table-striped dataTable no-footer" id="cs_datatable" role="grid" aria-describedby="cs_datatable_info">
                                        <thead>
                                            <tr role="row"><th width={20} className="table-no-sort sorting_desc" style={{ textAlign: 'center !important', width: 20 }} tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-sort="descending" aria-label=": activate to sort column ascending"><input type="checkbox" className="checkbox-table" id="checkAll" /></th><th width={20} className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Id: activate to sort column ascending" style={{ width: 20 }}>Id</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '105.6px' }}>Name</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Email: activate to sort column ascending" style={{ width: '139.075px' }}>Email</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Comment: activate to sort column ascending" style={{ width: '236.775px' }}>Comment</th><th style={{ minWidth: '20%', width: '180.7px' }} className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Post: activate to sort column ascending">Post</th><th style={{ minWidth: '10%', width: '86.2px' }} className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Date: activate to sort column ascending">Date</th><th className="max-width-120 sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Options: activate to sort column ascending" style={{ width: '85.25px' }}>Options</th></tr>
                                        </thead>
                                        <tbody>
                                            <tr role="row" className="odd">
                                                <td style={{ textAlign: 'center !important' }} className="sorting_1"><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={5} /></td>
                                                <td>5</td>
                                                <td>ABARIS RANZ</td>
                                                <td>seoabaris@gmail.com</td>
                                                <td className="break-word">good to see</td>
                                                <td>
                                                    <a href="https://prernamedia.com/आज-क-रशफल-11012021-204" target="_blank">आज का राशिफल - 11.01.2021</a>
                                                </td>
                                                <td className="nowrap">2023-02-25 / 14:52</td>
                                                <td>
                                                    <form action="https://prernamedia.com/admin_controller/approve_comment_post" method="post" acceptCharset="utf-8">
                                                        <input type="hidden" name="varient_csrf_token" defaultValue="3d53193b0ce930dea5ad6663457d1f8b" />
                                                        <input type="hidden" name="id" defaultValue={5} />
                                                        <div className="dropdown">
                                                            <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option											
                                                            {/* <span className="caret" /> */}
                                                            </button>
                                                            <ul className="dropdown-menu options-dropdown">
                                                                <li>
                                                                    <Link to="#" onClick={handleShow}><i className="fa fa-trash option-icon" />Delete</Link>
                                                                    <Modal show={show} onHide={handleClose}>
                                                                    <Modal.Header closeButton>
                                                                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                                                                    </Modal.Header>
                                                                    <Modal.Body>Are you sure you want to delete this poll?</Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="secondary" onClick={handleClose}>
                                                                            Close
                                                                        </Button>
                                                                        <Button variant="danger" onClick={handleClose}>
                                                                            Ok
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </form>{/* form end */}
                                                </td>
                                            </tr><tr role="row" className="even">
                                                <td style={{ textAlign: 'center !important' }} className="sorting_1"><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={4} /></td>
                                                <td>4</td>
                                                <td>abcc</td>
                                                <td />
                                                <td className="break-word">sdcbdbd</td>
                                                <td>
                                                    <a href="https://prernamedia.com/हई-टक-पलट-क-लए-बकग-रगन-सटकर-सरल-बन" target="_blank">हाई-टेक प्लेटों के लिए बुकिंग, रंगीन स्टिकर सरल बना</a>
                                                </td>
                                                <td className="nowrap">2021-01-03 / 05:00</td>
                                                <td>
                                                    <form action="https://prernamedia.com/admin_controller/approve_comment_post" method="post" acceptCharset="utf-8">
                                                        <input type="hidden" name="varient_csrf_token" defaultValue="3d53193b0ce930dea5ad6663457d1f8b" />
                                                        <input type="hidden" name="id" defaultValue={4} />
                                                        <div className="dropdown">
                                                            <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option										
                                                            	{/* <span className="caret" /> */}
                                                            </button>
                                                            <ul className="dropdown-menu options-dropdown">
                                                                <li>
                                                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_comment_post','4','Are you sure you want to delete this comment?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </form>{/* form end */}
                                                </td>
                                            </tr><tr role="row" className="odd">
                                                <td style={{ textAlign: 'center !important' }} className="sorting_1"><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={3} /></td>
                                                <td>3</td>
                                                <td>abcc</td>
                                                <td />
                                                <td className="break-word">sdcbdbd</td>
                                                <td>
                                                    <a href="https://prernamedia.com/हई-टक-पलट-क-लए-बकग-रगन-सटकर-सरल-बन" target="_blank">हाई-टेक प्लेटों के लिए बुकिंग, रंगीन स्टिकर सरल बना</a>
                                                </td>
                                                <td className="nowrap">2021-01-03 / 05:00</td>
                                                <td>
                                                    <form action="https://prernamedia.com/admin_controller/approve_comment_post" method="post" acceptCharset="utf-8">
                                                        <input type="hidden" name="varient_csrf_token" defaultValue="3d53193b0ce930dea5ad6663457d1f8b" />
                                                        <input type="hidden" name="id" defaultValue={3} />
                                                        <div className="dropdown">
                                                            <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option										
                                                            	{/* <span className="caret" /> */}
                                                            </button>
                                                            <ul className="dropdown-menu options-dropdown">
                                                                <li>
                                                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_comment_post','3','Are you sure you want to delete this comment?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </form>{/* form end */}
                                                </td>
                                            </tr><tr role="row" className="even">
                                                <td style={{ textAlign: 'center !important' }} className="sorting_1"><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={2} /></td>
                                                <td>2</td>
                                                <td>abarissoftech_admin</td>
                                                <td>admin@citynewsindia.in</td>
                                                <td className="break-word">Best, it's t history and a BIG Shame for BJP, t most bloody corrupt party in the world. </td>
                                                <td>
                                                    <a href="https://prernamedia.com/दलल-सघ-बरडर-क-इस-मल-म-कसन-क-28-बनयद-समन-मफत-म-दए-गए" target="_blank">दिल्ली: सिंघू बॉर्डर के इस मॉल में किसानों को 28 बुनियादी सामान मुफ्त में दिए गए</a>
                                                </td>
                                                <td className="nowrap">2021-01-01 / 20:41</td>
                                                <td>
                                                    <form action="https://prernamedia.com/admin_controller/approve_comment_post" method="post" acceptCharset="utf-8">
                                                        <input type="hidden" name="varient_csrf_token" defaultValue="3d53193b0ce930dea5ad6663457d1f8b" />
                                                        <input type="hidden" name="id" defaultValue={2} />
                                                        <div className="dropdown">
                                                            <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option										
                                                            	{/* <span className="caret" /> */}
                                                            </button>
                                                            <ul className="dropdown-menu options-dropdown">
                                                                <li>
                                                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_comment_post','2','Are you sure you want to delete this comment?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </form>{/* form end */}
                                                </td>
                                            </tr><tr role="row" className="odd">
                                                <td style={{ textAlign: 'center !important' }} className="sorting_1"><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={1} /></td>
                                                <td>1</td>
                                                <td>citynewsindia_admin</td>
                                                <td>admin@citynewsindia.com</td>
                                                <td className="break-word">Good work, We must protest against this Cruel Government, Go Ahead Farmers. Best of luck!</td>
                                                <td>
                                                    <a href="https://prernamedia.com/दलल-सघ-बरडर-क-इस-मल-म-कसन-क-28-बनयद-समन-मफत-म-दए-गए" target="_blank">दिल्ली: सिंघू बॉर्डर के इस मॉल में किसानों को 28 बुनियादी सामान मुफ्त में दिए गए</a>
                                                </td>
                                                <td className="nowrap">2021-01-01 / 19:14</td>
                                                <td>
                                                    <form action="https://prernamedia.com/admin_controller/approve_comment_post" method="post" acceptCharset="utf-8">
                                                        <input type="hidden" name="varient_csrf_token" defaultValue="3d53193b0ce930dea5ad6663457d1f8b" />
                                                        <input type="hidden" name="id" defaultValue={1} />
                                                        <div className="dropdown">
                                                            <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option									
                                                            		{/* <span className="caret" /> */}
                                                            </button>
                                                            <ul className="dropdown-menu options-dropdown">
                                                                <li>
                                                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_comment_post','1','Are you sure you want to delete this comment?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </form>{/* form end */}
                                                </td>
                                            </tr></tbody>
                                    </table></div></div><div className="row"><div className="col-sm-5"><div className="dataTables_info" id="cs_datatable_info" role="status" aria-live="polite">Showing 1 to 5 of 5 entries</div></div><div className="col-sm-7"><div className="dataTables_paginate paging_simple_numbers" id="cs_datatable_paginate"><ul className="pagination"><li className="paginate_button previous disabled" id="cs_datatable_previous"><a href="#" aria-controls="cs_datatable" data-dt-idx={0} tabIndex={0}>‹</a></li><li className="paginate_button active"><a href="#" aria-controls="cs_datatable" data-dt-idx={1} tabIndex={0}>1</a></li><li className="paginate_button next disabled" id="cs_datatable_next"><a href="#" aria-controls="cs_datatable" data-dt-idx={2} tabIndex={0}>›</a></li></ul></div></div></div></div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="row">
                                        <div className="pull-left">
                                            <button className="btn btn-sm btn-danger btn-table-delete" onclick="delete_selected_comments('Are you sure you want to delete selected comments?');">Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/* /.box-body */}
                </div>
            </section>

        </>
    )
}
export default ApprovedComment