
function MenuLimit(){
    return (
        <>
        <div className="box box-primary">
  <div className="box-header with-border">
    <h3 className="box-title">Menu Limit </h3>
  </div>
  {/* /.box-header */}
  {/* form start */}
  <form action="https://prernamedia.com/admin_controller/menu_limit_post" method="post" acceptCharset="utf-8">
    <input type="hidden" name="varient_csrf_token" defaultValue="aadfcd795a80e220ec5908f7a85daf40" />
    <div className="box-body">
      {/* include message block */}
      <div className="form-group">
        <label>Menu Limit (The number of links that appear in the menu)</label>
        <input type="number" className="form-control" name="menu_limit" placeholder="Menu Limit " defaultValue={20} min={1} max={100} required fdprocessedid="o6n64" />
      </div>
    </div>
    {/* /.box-body */}
    <div className="box-footer">
      <button type="submit" className="btn btn-primary pull-right" fdprocessedid="aso5m">Save Changes</button>
    </div>
    {/* /.box-footer */}
  </form>{/* form end */}
</div>

        </>
    )
}
export default MenuLimit