

// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../node_modules/react-bootstrap/css/bootstrap.min.css'
import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.css";

import "./assets/css/vr-icons.min.css";
import "./assets/css/AdminLTE.min.css";
import "./assets/css/skins.css";
// import './assets/css/style-1.7.min.css';
import "./assets/css/dataTable.css";

import "./assets/css/jQuerry1.css";
import "./assets/css/purple.css";
import "./assets/css/pace.css";
import "./assets/css/jQuerry2.css";
import "./assets/css/tagsInput.css";
import "./assets/css/colorPicker.css";
import "./assets/css/fileManager.css";
import "./assets/css/dateTimePicker.css";

import "./assets/css/plugins-1.7.css";
import "./assets/css/social-share-kit7c60.css";

import "./assets/css/violet.min.css";
import "./assets/css/custom.css";
// import './assets/css/navbar.css'

import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import AdminPage from "./Pages/admin/Index";
import DashBoardPage from "./Pages/dashboard/Index";
import NavigationPage from "./Pages/Navigation/Index";
import UpdateCategory from "./Components/navigation/navigationMenu/updateCategory/UpdateCategory";
import ThemsPage from "./Pages/thems/Index";
import AddPages from "./Pages/newsPages/addPages/Index";
import Pagess from "./Components/newsPages/addPages/pagess/Pagess";
import UpdatePage from "./Components/newsPages/addPages/pagess/updatePage/UpdatePage";
// import ImportFeedPage from "./Pages/newsPages/addPages/importFeed/Index";
import Feed from "./Components/rssFeed/feed/Feed";
import ImportFeedPage from "./Pages/rssFeed/importFeed/Index";
import CategoriesPage from "./Pages/categories/categorie/Categories";

import PostsPage from './Pages/postPageMain/postsPage'
import PostEdit from './Components/postsCompMain/posts/postsEdit/PostEdit'

import SliderPostPage from "./Pages/postPageMain/sliderPostpage";

import FeaturedPosts from "./Pages/postPageMain/featuredPosts";
import SubcategoriesPage from "./Pages/categories/subCategories/Index";
import SubCategoriesUpdate from "./Components/categories/subCategories/subUpdateCategories/SubCategoriesUpdate";
import AddWidgetPage from "./Pages/widgets/addWidget/Index";
import Widgets from "./Components/widgets/addWidget/widgets/Widgets";
import UpdateWidget from "./Components/widgets/addWidget/updateWidget/UpdateWidget";
import AddPollsPage from "./Pages/polls/addPolls/Index";
import Polls from "./Components/polls/addPolls/poll/Polls";
import UpdatePolls from "./Components/polls/addPolls/poll/updatePolls/UpdatePolls";
import BreakingNewsPage from "./Pages/postPageMain/breakingNewsPage";
import RecommendedPostsPage from "./Pages/postPageMain/RecommendedPostsPage";
import PendingPostsPage from "./Pages/postPageMain/pendingPostsPage";
import ScheduledPostsPage from "./Pages/postPageMain/scheduledPostsPage";
import DraftsPage from "./Pages/postPageMain/draftsPage";
import AddPostsPage from "./Pages/addPostsPage";
import ContactMessagePage from "./Pages/contactMessage/Index";
import ImagesPage from "./Pages/gallery/images/Index";
import UpdateImage from "./Components/gallery/images/updateImage/UpdateImage";
import GalleryAlbumPage from "./Pages/gallery/album/Index";
import UpdateAlbum from "./Components/gallery/album/updateAlbum/UpdateAlbum";
import GalleryCategoriesPage from "./Pages/gallery/galleryCategories/Index";
import UpdateCategories from "./Components/gallery/galleryCategories/updateCategories/UpdateCategoris";
import PendingCommentPage from "./Pages/comment/pendingComment/Index";
import ApprovedCommentPage from "./Pages/comment/approvedComment/Index";
import SendEmailPage from "./Pages/newsLetter/sendEmail/Index";
import SubscribersPage from "./Pages/newsLetter/subscribers/Index";
import AdSpacesPage from "./Pages/adSpace/Index";
import AddUsersPage from "./Pages/users/addUsers/Index";
import AdministratorsPage from "./Pages/users/administrators/Index";

import AddPostArticalInner from "./Components/addPosts/addPostsArticle/addPostArticalInner/AddPostArticalInner";
import AddPostGalleryInner from "./Components/addPosts/addPostsGallery/addpostGalleryInner/AddPostGalleryInner";
import AddPostsSortedListInner from "./Components/addPosts/addPostsSortedList/addPostsSortedListInner/AddPostsSortedListInner";
import AddPostsTriviaQuizInner from "./Components/addPosts/addpostsTriviaQuiz/addpostsTriviaQuizInner/AddPostsTriviaQuizInner";
import UsersInnerPage from "./Pages/users/usersInner/Index";
import RolePermissionPage from "./Pages/rolePermission/Index";
import EditRole from "./Components/rolePermission/editRole/EditRole";
import PreferencesPage from "./Pages/preferences/Index";
import RouteSettingPage from "./Pages/routeSetting/Index";
import LanguageSettingPage from "./Pages/languageSetting/Index";
import UpdatePhrases from "./Components/languageSetting/updatePhrases/UpdatePhrases";
import UpdateLanguage from "./Components/languageSetting/updatePhrases/updateLanguage/UpdateLanguage";
import InnerPersonalityQquiz from "./Components/addPosts/addPostsPersonalityQuiz/innerPersonalityQuiz/InnerPersonalityQuiz";
import AddPostsInnerVideo from "./Components/addPosts/addPostsVideo/addPostsInnerVideo/AddPostsInnerVideo";
import AddPostsInnerAudio from "./Components/addPosts/addPostsAudio/addPostsInnerAudio/AddPostsInnerAudio";
import CacheSystemPage from "./Pages/cacheSystemPage";
import SocialLoginConfigurationPage from "./Pages/socialLoginConfigurationPage";
import SeoToolPage from "./Pages/SEOToolsPage";
import EmailSettingsPage from "./Pages/emailSettingPage";
import VisualSettingsPage from "./Pages/visualSettingsPage";
import EditFont from "./Components/fontSettings/editFont/EditFont";
import GeneralSettingsPage from "./Pages/generalSettingsPage";
// import FontSettingsPage from "./Pages/fontSetting/Index";
import FontSettingsPage from './Pages/fontSetting/index'
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to={"/admin"} />} />
        <Route path="/admin" element={<AdminPage />}>
          <Route path="" element={<DashBoardPage />} />
          <Route path="navigation" element={<NavigationPage />} />
          <Route path="navigation/update-category" element={<UpdateCategory />} />
          <Route path="themes" element={<ThemsPage />} />
          <Route path="add-page" element={<AddPages />} />
          <Route path="pages" element={<Pagess />} />
          <Route path="update-page/6" element={<UpdatePage />} />
          <Route path="import-feed" element={<ImportFeedPage />} />
          <Route path="feed" element={<Feed />} />
          <Route path="categories" element={<CategoriesPage />} />

          <Route path="posts" element={<PostsPage />} />
          <Route path="editpost" element={<PostEdit />} />
          <Route path="slider-posts" element={<SliderPostPage />} />
          <Route path="featured-posts" element={<FeaturedPosts />} />
          <Route path="subcategories" element={<SubcategoriesPage />} />
          <Route path="update-subcategory" element={<SubCategoriesUpdate />} />
          <Route path="add-widget" element={<AddWidgetPage />} />
          <Route path="widgets" element={<Widgets />} />
          <Route path="update-widget" element={<UpdateWidget />} />
          <Route path="add-poll" element={<AddPollsPage />} />
          <Route path="polls" element={<Polls />} />
          <Route path="update-poll" element={<UpdatePolls />} />
          <Route path="breaking-news" element={<BreakingNewsPage />} />
          <Route path="recommended-posts" element={<RecommendedPostsPage />} />
          <Route path="pending-posts" element={<PendingPostsPage />} />
          <Route path="scheduled-posts" element={<ScheduledPostsPage />} />
          <Route path="drafts" element={<DraftsPage />} />

          <Route path="post-format" element={<AddPostsPage />} />
          <Route path="contact-messages" element={<ContactMessagePage />} />
          <Route path="gallery-image" element={<ImagesPage />} />
          <Route path="update-gallery-image" element={<UpdateImage />} />
          <Route path="gallery-albums" element={<GalleryAlbumPage />} />
          <Route path="update-gallery-album" element={<UpdateAlbum />} />
          <Route path="gallery-categories" element={<GalleryCategoriesPage />} />
          <Route path="update-gallery-category" element={<UpdateCategories />} />
          <Route path="pending-comments" element={<PendingCommentPage />} />
          <Route path="Approved-comments" element={<ApprovedCommentPage />} />
          <Route path="send-email-subscribers" element={<SendEmailPage />} />
          <Route path="subscribers" element={<SubscribersPage />} />
          <Route path="ad-spaces" element={<AdSpacesPage />} />
          <Route path="add-user" element={<AddUsersPage />} />
          <Route path="administrators" element={<AdministratorsPage />} />
          <Route path="add-post-article" element={<AddPostArticalInner />} />
          <Route path="add-post-gallery" element={<AddPostGalleryInner />} />
          <Route path="addPost-sorted-list" element={<AddPostsSortedListInner />} />
          <Route path="addPost-trivia-quiz" element={<AddPostsTriviaQuizInner />} />
          <Route path="users" element={<UsersInnerPage/>} />
          <Route path="roles-permissions" element={<RolePermissionPage/>} />
          <Route path="edit-role" element={<EditRole/>} />
          <Route path="preferences" element={<PreferencesPage/>} />          
          
          <Route path="add-post-personality_quiz" element={<InnerPersonalityQquiz />} />
          <Route path="add-post-video" element={<AddPostsInnerVideo />} />
          <Route path="add-post-audio" element={<AddPostsInnerAudio />} />
          <Route path="cache-system" element={<CacheSystemPage />} />
          <Route path="social-login-configuration" element={<SocialLoginConfigurationPage />} />
          <Route path="route-settings" element={<RouteSettingPage/>} />
          <Route path="language-settings" element={<LanguageSettingPage/>} />
          <Route path="update-phrases" element={<UpdatePhrases/>} />
          <Route path="update-language" element={<UpdateLanguage/>} />
          <Route path="seo-tools" element={< SeoToolPage />} />


          <Route path="email-settings" element={< EmailSettingsPage/>} />
          <Route path="visual-settings" element={< VisualSettingsPage/>} />
          <Route path="font-settings" element={< FontSettingsPage/>} />
          <Route path="update-font" element={< EditFont/>} />
          <Route path="settings" element={< GeneralSettingsPage/>} />

        </Route>
      </Routes>
    </>
  );
}

export default App;
