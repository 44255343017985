
function General() {
    return (
        <>
            <div className="box box-primary">
                <div className="box-header with-border">
                    <h3 className="box-title">General</h3>
                </div>
                {/* /.box-header */}
                {/* form start */}
                <form action="https://prernamedia.com/admin_controller/preferences_post" method="post" acceptCharset="utf-8">
                    <input type="hidden" name="varient_csrf_token" defaultValue="b4ab68091002ec2207d835146854e587" />
                    <div className="box-body">
                        {/* include message block */}
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Multilingual System</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Enable
                                        </label>
                                    </div>

                                    {/* <div className="iradio_square-purple checked hover" style={{ position: 'relative' }}><input type="radio" name="multilingual_system" defaultValue={1} id="multilingual_system_1" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="multilingual_system_1" className="option-label hover">Enable</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Disable
                                        </label>
                                    </div>


                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="multilingual_system" defaultValue={0} id="multilingual_system_2" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="multilingual_system_2" className="option-label">Disable</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Registration System</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Enable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="registration_system" defaultValue={1} id="registration_system_1" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="registration_system_1" className="option-label">Enable</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Disable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="registration_system" defaultValue={0} id="registration_system_2" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="registration_system_2" className="option-label">Disable</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>RSS</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Enable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" id="show_rss_1" name="show_rss" defaultValue={1} className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="show_rss_1" className="cursor-pointer">Enable</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Disable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" id="show_rss_2" name="show_rss" defaultValue={0} className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="show_rss_2" className="cursor-pointer">Disable</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Newsletter</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault4" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Enable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" id="newsletter_1" name="newsletter" defaultValue={1} className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="newsletter_1" className="cursor-pointer">Enable</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault4" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Disable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" id="newsletter_2" name="newsletter" defaultValue={0} className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="newsletter_2" className="cursor-pointer">Disable</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>File Manager</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault5" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Show all Files
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" id="file_manager_show_files_1" name="file_manager_show_files" defaultValue={1} className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="file_manager_show_files_1" className="cursor-pointer">Show all Files</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault5" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Show Only Users Own Files
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" id="file_manager_show_files_2" name="file_manager_show_files" defaultValue={0} className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="file_manager_show_files_2" className="cursor-pointer">Show Only Users Own Files</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Audio Download Button</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault6" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Enable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" id="audio_download_button_1" name="audio_download_button" defaultValue={1} className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="audio_download_button_1" className="cursor-pointer">Enable</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault6" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Disable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" id="audio_download_button_2" name="audio_download_button" defaultValue={0} className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="audio_download_button_2" className="cursor-pointer">Disable</label> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /.box-body */}
                    <div className="box-footer">
                        <button type="submit" name="submit" value="general" className="btn btn-primary pull-right" fdprocessedid="xnlrke">Save Changes</button>
                    </div>
                    {/* /.box-footer */}
                    {/* /.box */}
                </form>{/* form end */}
            </div>
        </>
    )
}
export default General