import { TfiListOl } from "react-icons/tfi"
import { Link } from "react-router-dom";
function AddPostsSortedList() {
  return (
    <>
      <div className="col-xs-12 col-sm-4 col-add-post">
        <Link to="/admin/addPost-sorted-list">
          <div className="item">
            <div className="item-icon">
              <TfiListOl className="iconss list" />
            </div>
            <h5 className="title">Sorted List</h5>
            <p className="desc">A list based article</p>
          </div>
        </Link>
      </div>
    </>
  )
}
export default AddPostsSortedList;