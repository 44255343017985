
function PendingPosts({ val }) {
  return (
    <>
      <section className="content">
        <div className="box">
          <div className="box-header with-border">
            <div className="left">
              <h3 className="box-title">{val.title}</h3>
            </div>
            <div className="right">
              <a href="https://prernamedia.com/admin/post-format" className="btn btn-success btn-add-new pull-right">
                <i className="fa fa-plus" />
                Add Post
              </a>
            </div>
          </div>
          <div className="box-body">
            <div className="row">
              <div className="col-sm-12">
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="table-responsive">
                  <div className="row table-filter-container">
                    <div className="col-sm-12">
                      <form action="https://prernamedia.com/admin/scheduled-posts" method="GET" acceptCharset="utf-8" />
                      <div className="item-table-filter" style={{ width: 80, minWidth: 80 }}>
                        <label>Show</label>
                        <select name="show" className="form-select">
                          <option value={15}>15</option>
                          <option value={30}>30</option>
                          <option value={60}>60</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                      <div className="item-table-filter">
                        <label>Language</label>
                        <select name="lang_id" className="form-select" >
                          <option value>All</option>
                          <option value={1}>English</option>
                          <option value={2}>Hindi</option>
                        </select>
                      </div>
                      <div className="item-table-filter">
                        <label>Post Type</label>
                        <select name="post_type" className="form-select">
                          <option value>All</option>
                          <option value="article">Article</option>
                          <option value="gallery">Gallery</option>
                          <option value="sorted_list">Sorted List</option>
                          <option value="trivia_quiz">Trivia Quiz</option>
                          <option value="personality_quiz">Personality Quiz</option>
                          <option value="video">Video</option>
                          <option value="audio">Audio</option>
                        </select>
                      </div>
                      <div className="item-table-filter">
                        <label>User</label>
                        <select name="user" className="form-select">
                          <option value>All</option>
                          <option value={1}>
                            prernamedia_admin                      </option>
                          <option value={2}>
                            abaris                      </option>
                        </select>
                      </div>
                      <div className="item-table-filter">
                        <label>Category</label>
                        <select id="categories" name="category" className="form-select" >
                          <option value>All</option>
                          <option value={1}>
                            सिटी                  </option>
                          <option value={3}>
                            प्रदेश                  </option>
                          <option value={4}>
                            देश                  </option>
                          <option value={5}>
                            वर्ल्ड                  </option>
                          <option value={6}>
                            बॉयोस्कोप                  </option>
                          <option value={7}>
                            खेल                  </option>
                          <option value={8}>
                            B&amp;E                  </option>
                          <option value={9}>
                            ज्ञान-विज्ञान                  </option>
                          <option value={10}>
                            लाइफस्टाइल                  </option>
                          <option value={11}>
                            आस्था                  </option>
                          <option value={12}>
                            कैरियर                  </option>
                          <option value={13}>
                            हेल्थ                  </option>
                          <option value={14}>
                            रसोई                  </option>
                          <option value={15}>
                            Business                  </option>
                          <option value={69}>
                            VIDEO                  </option>
                          <option value={70}>
                            Politics                  </option>
                          <option value={71}>
                            Funding                  </option>
                        </select>
                      </div>
                      <div className="item-table-filter">
                        <div className="form-group">
                          <label className="control-label">Subcategory</label>
                          <select id="subcategories" name="subcategory" className="form-select">
                            <option value>All</option>
                          </select>
                        </div>
                      </div>
                      <div className="item-table-filter">
                        <label>Search</label>
                        <input name="q" className="form-select" placeholder="Search" type="search" defaultValue />
                      </div>
                      <div className="item-table-filter md-top-10" style={{ width: 65, minWidth: 65 }}>
                        <label style={{ display: 'block' }}>&nbsp;</label>
                        <button type="submit" className="btn bg-purple">Filter</button>
                      </div>
                    </div>
                  </div><table className="table table-bordered table-striped" role="grid">
                    <thead>
                      <tr role="row tab_row">
                        <th width={20}><input type="checkbox" className="checkbox-table" id="checkAll" /></th>
                        <th width={20}>Id</th>
                        <th>Post</th>
                        <th>Post Type</th>
                        <th>Category</th>
                        <th>Author</th>
                        <th>Days Remaining</th>
                        <th>Date Added</th>
                        <th style={{ minWidth: 180 }}>Options</th>
                      </tr>
                    </thead>
                    <tbody>
                    </tbody>
                  </table>
                  <p className="text-center">
                    No records found.                      </p>
                  <div className="col-sm-12 table-ft">
                    <div className="row">
                      <div className="pull-right">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{/* /.box-body */}
        </div>
        <div className="callout" style={{ marginTop: 30, backgroundColor: '#fff', borderColor: '#00c0ef', maxWidth: 600 }}>
          <h4>{val.cron}</h4>
          <p><strong>{val.link}</strong></p>
          <small>{val.paragraph}</small>
        </div>
      </section>
    </>
  )
}
export default PendingPosts;