
function CacheSystem() {
  return (
    <>
      <section className="content">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Cache System</h3>
              </div>

              <form action="https://prernamedia.com/admin_controller/cache_system_post" method="post" acceptCharset="utf-8">
                <input type="hidden" name="varient_csrf_token" defaultValue="4d0a43e6668410800510458f5fbd19b2" />
                <div className="box-body">
                  <div className="m-b-15">
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-5 col-xs-12">
                        <label>Cache System</label>
                      </div>
                      <div className="col-md-3 col-sm-4 col-xs-12 col-option">
                        <div className="iradio_square-purple" style={{ position: 'relative' }}>
                          <input type="radio" name="cache_system" defaultValue={1} id="cache_system_1" className="square purple" />
                        </div>
                        <label htmlFor="cache_system_1" className="option-label">Enable</label>
                      </div>

                      <div className="col-md-4 col-sm-4 col-xs-12 col-option">
                        <div className="iradio_square-purple checked" style={{ position: 'relative' }}>
                          <input type="radio" name="cache_system" defaultValue={0} id="cache_system_2" className="square purple" />

                        </div>
                        <label htmlFor="cache_system_2" className="option-label">Disable</label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-5 col-xs-12">
                        <label>Refresh Cache Files When Database Changes</label>
                      </div>
                      <div className="col-md-3 col-sm-4 col-xs-12 col-option">
                        <div className="iradio_square-purple" style={{ position: 'relative' }}>
                          <input type="radio" name="refresh_cache_database_changes" defaultValue={1} id="refresh_cache_database_changes_1" className="square-purple" />
                        </div>
                        <label htmlFor="refresh_cache_database_changes_1" className="option-label">Yes</label>
                      </div>

                      <div className="col-md-4 col-sm-4 col-xs-12 col-option">
                        <div className="iradio_square-purple checked" style={{ position: 'relative' }}>
                          <input type="radio" name="refresh_cache_database_changes" defaultValue={0} id="refresh_cache_database_changes_2" className="square purple" />
                        </div>
                        <label htmlFor="refresh_cache_database_changes_2" className="option-label">No</label>
                      </div>
                    </div>
                    
                  </div>
                  <div className="form-group">
                    <label className="control-label">Cache Refresh Time (Minute)</label>&nbsp;
                    <small>(After this time, your cache files will be refreshed.)</small>
                    <input type="number" className="form-control" name="cache_refresh_time" placeholder="Cache Refresh Time (Minute)" defaultValue={3000} />
                  </div>
                  <div className="box-footer" style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <button type="submit" name="action" value="save" className="btn btn-primary pull-right">Save Changes</button>
                    <button type="submit" name="action" value="reset" className="btn btn-warning pull-right m-r-10">Reset Cache</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
export default CacheSystem;