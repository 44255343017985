import Editor from "../../../../commonComponents/editor/Editor";

function UpdateArticle() {
  let newLine = "\r\n";
  return (
    <>
      <div className="box">
        <div className="box-header with-border">
          <div className="left">
            <h3 className="box-title">Post Details</h3>
          </div>
        </div>
        <div className="box-body">

          <input type="hidden" name="id" defaultValue="3039" />
          <input type="hidden" name="referrer" className="form-control" defaultValue="https://prernamedia.com/admin/posts" />
          <div className="form-group">
            <label className="control-label">Title</label>
            <input type="text" className="form-control" name="title" placeholder="Title" defaultValue="अमर उजाला विशेष : तालिबान ने 2020 में ही लिख दी थी अफगानिस्तान में लौटने की पटकथा" required />
          </div>
          <div className="form-group">
            <label className="control-label">Slug
              <small>(If you leave it blank, it will be generated automatically.)</small>
            </label>
            <input type="text" className="form-control" name="title_slug" placeholder="Slug" defaultValue="अमर-उजल-वशष-तलबन-न-2020-म-ह-लख-द-थ-अफगनसतन-म-लटन-क-पटकथ" />
          </div>
          <div className="form-group">
            <label className="control-label">Summary &amp; Description (Meta Tag)</label>
            <textarea className="form-control text-area" name="summary" placeholder="Summary & Description (Meta Tag)" defaultValue="2020 में अमेरिका ने अफगान सरकार को किनारे करते हुए तालिबान के साथ दोहा में समझौता किया\n                                      इसके तहत तालिबान को शांति और स्थिरता का वादा निभाना था, लेकिन इसे पूरा न करने के बावजूद अमेरिका वापसी में जुटा रहा\n                                    " />
          </div>
          <div className="form-group">
            <label className="control-label">Keywords (Meta Tag)</label>
            <input type="text" className="form-control" name="keywords" placeholder="Keywords (Meta Tag)" defaultValue="अमर उजाला विशेष : तालिबान ने 2020 में ही लिख दी थी अफगानिस्तान में लौटने की पटकथा " />
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-sm-4 col-xs-12 col-lang">
                <label>Visibility</label>
              </div>

              <div className="col-md-2 col-sm-4 col-xs-12 col-lang">
                <div className="iradio_square-purple checked" style={{ position: 'relative' }}>
                  <input type="radio" id="rb_visibility_1" name="radio" ></input>
                </div>
                <label htmlFor="rb_visibility_1" className="cursor-pointer">Show</label>
              </div>
              <div className="col-md-2 col-sm-4 col-xs-12 col-lang">
                <div className="iradio_square-purple" style={{ position: 'relative' }}>
                  <input type="radio" id="rb_visibility_2" name="radio" ></input>
                </div>
                <label htmlFor="rb_visibility_2" className="cursor-pointer">Hide</label>
              </div>

            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-sm-4 col-xs-12">
                <label>Show Right Column</label>
              </div>

              <div className="col-md-2 col-sm-4 col-xs-12 col-option">
                <div className="iradio_square-purple checked" style={{ position: 'relative' }}>
                  <input type="radio" id="right_column_enabled1" name="cloumnradio" ></input>
                </div>
                <label htmlFor="right_column_enabled1" className="option-label">Yes</label>
              </div>

              <div className="col-md-2 col-sm-4 col-xs-12 col-option">
                <div className="iradio_square-purple" style={{ position: 'relative' }}>
                  <input type="radio" id="right_column_disabled" name="cloumnradio" ></input>
                </div>
                <label htmlFor="right_column_disabled" className="option-label">No</label>

              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <label className="control-label">Add to Featured</label>
              </div>
              <div className="col-md-8 col-sm-12">
                <div className="icheckbox_square-purple" style={{ position: 'relative' }}>
                  <input type="checkbox" id="customs" name="sss"></input>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <label className="control-label">Add to Breaking</label>
              </div>
              <div className="col-md-8 col-sm-12">
                <div className="icheckbox_square-purple checked" style={{ position: 'relative' }}>
                  <input type="checkbox" id="customs" name="sss"></input>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <label className="control-label">Add to Slider</label>
              </div>
              <div className="col-md-8 col-sm-12">
                <div className="icheckbox_square-purple" style={{ position: 'relative' }}>
                  <input type="checkbox" id="customs" name="sss">
                  </input></div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <label className="control-label">Add to Recommended</label>
              </div>
              <div className="col-md-8 col-sm-12">
                <div className="icheckbox_square-purple checked" style={{ position: 'relative' }}>
                  <input type="checkbox" id="customs" name="sss"></input>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <label className="control-label">Show Only to Registered Users</label>
              </div>
              <div className="col-md-8 col-sm-12">
                <div className="icheckbox_square-purple" style={{ position: 'relative' }}>
                  <input type="checkbox" id="customs" name="sss"></input>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="control-label">Tags</label>
            <input id="tags_1" type="text" name="tags" className="form-control tags" defaultValue data-tagsinput-init="true" style={{ display: 'none' }} /><div id="tags_1_tagsinput" className="tagsinput" style={{ width: 'auto', minHeight: 80, height: 80 }}><div id="tags_1_addTag"><input id="tags_1_tag" defaultValue data-default="add a tag" style={{ color: 'rgb(102, 102, 102)', width: 68 }} /></div><div className="tags_clear" /></div>
            <small>(Type tag and hit enter)</small>
          </div>
          <div className="form-group row-optional-url">
            <label className="control-label">Optional URL</label>
            <input type="text" className="form-control" name="optional_url" placeholder="Optional URL" defaultValue />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <label className="control-label control-label-content">Content</label>
          <div id="main_editor">
            <div className="row">
              <div className="col-sm-12 editor-buttons">
                <button type="button" className="btn btn-sm btn-success" data-toggle="modal" data-target="#file_manager_image" data-image-type="editor">
                  <i className="fa fa-image" />Add Image
                </button>
              </div>
            </div>
            <Editor/>
            {/* <textarea name="content" id="content" cols="30" rows="10" className="tinyMCE form-control w-100" >
              {`विस्तार अफगानिस्तान पर तालिबान के शिकंजे की पटकथा डेढ़ साल पहले ही लिखी जा चुकी थी, जब फरवरी 2020 में अमेरिका ने अफगान सरकार को किनारे करते हुए तालिबान के साथ दोहा में समझौता किया था। ${newLine} ${newLine} विस्तार अफगानिस्तान पर तालिबान के शिकंजे की पटकथा डेढ़ साल पहले ही लिखी जा चुकी थी, जब फरवरी 2020 में अमेरिका ने अफगान सरकार को किनारे करते हुए तालिबान के साथ दोहा में समझौता किया था।`}
            </textarea> */}
          </div>
        </div>
      </div>
    </>
  )
}
export default UpdateArticle;