import General from "./general/General"
import Homepage from "./homepage/Homepage"
import PostFormats from "./PostFormats/PostFormats"
import Post from "./Posts/Post"


function Preferences() {
    return (
        <>
            <section className="content">
                <div className="row" style={{ marginBottom: 15 }}>
                    <div className="col-sm-12">
                        <h3 style={{ fontSize: 18, fontWeight: 600, marginTop: 10 }}>Preferences</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <General/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <Homepage/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <Post/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <PostFormats/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default Preferences