import {TbMathGreater} from "react-icons/tb"
import { Link } from "react-router-dom"
function FontSettings() {
  return (
    <>
      <section className="content">
        <div className="row">
          <div className="col-lg-5 col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Site Font</h3>
              </div>
              {/* /.box-header */}
              {/* form start */}
              <form action="https://prernamedia.com/admin_controller/set_site_font_post" method="post" acceptCharset="utf-8">
                <input type="hidden" name="varient_csrf_token" defaultValue="e3042456da8e91b1048dced2012726c4" />
                <div className="box-body">
                  {/* include message block */}
                  <div className="form-group">
                    <label>Language</label>
                    <select name="lang_id" className="form-select" onchange="window.location.href = 'https://prernamedia.com/admin/' + 'font-settings?lang='+this.value;">
                      <option value={1} selected>English</option>
                      <option value={2}>Hindi</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="label-sitemap">Primary Font (Main)</label>
                    <select name="primary_font" className="form-select custom-select">
                      <option value={1}>Arial</option>
                      <option value={2}>Arvo</option>
                      <option value={3}>Averia Libre</option>
                      <option value={4}>Bitter</option>
                      <option value={5}>Cabin</option>
                      <option value={6}>Cherry Swash</option>
                      <option value={7}>Encode Sans</option>
                      <option value={8}>Helvetica</option>
                      <option value={9}>Hind</option>
                      <option value={10}>Josefin Sans</option>
                      <option value={11}>Kalam</option>
                      <option value={12}>Khula</option>
                      <option value={13}>Lato</option>
                      <option value={14}>Lora</option>
                      <option value={15}>Merriweather</option>
                      <option value={16}>Montserrat</option>
                      <option value={17}>Mukta</option>
                      <option value={18}>Nunito</option>
                      <option value={19} selected>Open Sans</option>
                      <option value={20}>Oswald</option>
                      <option value={21}>Oxygen</option>
                      <option value={22}>Poppins</option>
                      <option value={23}>PT Sans</option>
                      <option value={24}>Raleway</option>
                      <option value={25}>Roboto</option>
                      <option value={26}>Roboto Condensed</option>
                      <option value={27}>Roboto Slab</option>
                      <option value={28}>Rokkitt</option>
                      <option value={29}>Source Sans Pro</option>
                      <option value={30}>Titillium Web</option>
                      <option value={31}>Ubuntu</option>
                      <option value={32}>Verdana</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="label-sitemap">Secondary Font (Titles)</label>
                    <select name="secondary_font" className="form-select custom-select" style={{ width: '100%' }}>
                      <option value={1}>Arial</option>
                      <option value={2}>Arvo</option>
                      <option value={3}>Averia Libre</option>
                      <option value={4}>Bitter</option>
                      <option value={5}>Cabin</option>
                      <option value={6}>Cherry Swash</option>
                      <option value={7}>Encode Sans</option>
                      <option value={8}>Helvetica</option>
                      <option value={9}>Hind</option>
                      <option value={10}>Josefin Sans</option>
                      <option value={11}>Kalam</option>
                      <option value={12}>Khula</option>
                      <option value={13}>Lato</option>
                      <option value={14}>Lora</option>
                      <option value={15}>Merriweather</option>
                      <option value={16}>Montserrat</option>
                      <option value={17}>Mukta</option>
                      <option value={18}>Nunito</option>
                      <option value={19}>Open Sans</option>
                      <option value={20}>Oswald</option>
                      <option value={21}>Oxygen</option>
                      <option value={22}>Poppins</option>
                      <option value={23}>PT Sans</option>
                      <option value={24}>Raleway</option>
                      <option value={25} selected>Roboto</option>
                      <option value={26}>Roboto Condensed</option>
                      <option value={27}>Roboto Slab</option>
                      <option value={28}>Rokkitt</option>
                      <option value={29}>Source Sans Pro</option>
                      <option value={30}>Titillium Web</option>
                      <option value={31}>Ubuntu</option>
                      <option value={32}>Verdana</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="label-sitemap">Tertiary Font (Post &amp; Page Text)</label>
                    <select name="tertiary_font" className="form-select custom-select" style={{ width: '100%' }}>
                      <option value={1}>Arial</option>
                      <option value={2}>Arvo</option>
                      <option value={3}>Averia Libre</option>
                      <option value={4}>Bitter</option>
                      <option value={5}>Cabin</option>
                      <option value={6}>Cherry Swash</option>
                      <option value={7}>Encode Sans</option>
                      <option value={8}>Helvetica</option>
                      <option value={9}>Hind</option>
                      <option value={10}>Josefin Sans</option>
                      <option value={11}>Kalam</option>
                      <option value={12}>Khula</option>
                      <option value={13}>Lato</option>
                      <option value={14}>Lora</option>
                      <option value={15}>Merriweather</option>
                      <option value={16}>Montserrat</option>
                      <option value={17}>Mukta</option>
                      <option value={18}>Nunito</option>
                      <option value={19}>Open Sans</option>
                      <option value={20}>Oswald</option>
                      <option value={21}>Oxygen</option>
                      <option value={22}>Poppins</option>
                      <option value={23}>PT Sans</option>
                      <option value={24}>Raleway</option>
                      <option value={25}>Roboto</option>
                      <option value={26}>Roboto Condensed</option>
                      <option value={27}>Roboto Slab</option>
                      <option value={28}>Rokkitt</option>
                      <option value={29}>Source Sans Pro</option>
                      <option value={30}>Titillium Web</option>
                      <option value={31}>Ubuntu</option>
                      <option value={32} selected>Verdana</option>
                    </select>
                  </div>
                </div>
                {/* /.box-body */}
                <div className="box-footer">
                  <button type="submit" className="btn btn-primary pull-right">Save Changes</button>
                </div>
                {/* /.box-footer */}
              </form>{/* form end */}
            </div>
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Add Font</h3>
                <a href="https://fonts.google.com/" target="_blank" style={{ float: 'right', fontSize: 16 }}><strong>Google Fonts&nbsp;<TbMathGreater className="icon-arrow-right" /></strong></a>
              </div>
              {/* /.box-header */}
              {/* form start */}
              <form action="https://prernamedia.com/admin_controller/add_font_post" method="post" acceptCharset="utf-8">
                <input type="hidden" name="varient_csrf_token" defaultValue="e3042456da8e91b1048dced2012726c4" />
                <div className="box-body">
                  {/* include message block */}
                  <div className="form-group">
                    <label>Name</label>
                    <input type="text" className="form-control" name="font_name" placeholder="Name" maxLength={200} required />
                    <small>(E.g: Open Sans)</small>
                  </div>
                  <div className="form-group">
                    <label className="control-label">URL </label>
                    <textarea name="font_url" className="form-control" placeholder="URL" required defaultValue={""} />
                    <small>(E.g: &lt;link href="https://fonts.googleapis.com/css?family=Open+Sans&amp;display=swap" rel="stylesheet"&gt;)</small>
                  </div>
                  <div className="form-group">
                    <label className="control-label">Font Family </label>
                    <input type="text" className="form-control" name="font_family" placeholder="Font Family" maxLength={500} required />
                    <small>(E.g: font-family: "Open Sans", Helvetica, sans-serif)</small>
                  </div>
                </div>
                {/* /.box-body */}
                <div className="box-footer">
                  <button type="submit" className="btn btn-primary pull-right">Add Font</button>
                </div>
                {/* /.box-footer */}
              </form>{/* form end */}
            </div>
            {/* /.box */}
          </div>
          <div className="col-lg-7 col-md-12">
            <div className="box">
              <div className="box-header with-border">
                <div className="pull-left">
                  <h3 className="box-title">Fonts</h3>
                </div>
              </div>{/* /.box-header */}
              <div className="box-body">
                <div className="row">
                  {/* include message block */}
                  <div className="col-sm-12">
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="table-responsive">
                      <div id="cs_datatable_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer"><div className="row"><div className="col-sm-6"><div className="dataTables_length" id="cs_datatable_length"><label>Show <select name="cs_datatable_length" aria-controls="cs_datatable" className="form-show input-sm"><option value={15}>15</option><option value={30}>30</option><option value={60}>60</option><option value={100}>100</option></select></label></div></div><div className="col-sm-6"><div id="cs_datatable_filter" className="dataTables_filter"><label>Search<input type="search" className="form-control input-sm" placeholder aria-controls="cs_datatable" /></label></div></div></div><div className="row"><div className="col-sm-12"><table className="table table-bordered table-striped dataTable no-footer" id="cs_datatable" role="grid" aria-describedby="cs_datatable_info">
                        <thead>
                          <tr role="row"><th width={20} className="sorting_desc" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-sort="descending" aria-label="Id: activate to sort column ascending" style={{ width: 20 }}>Id</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '79.0625px' }}>Name</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Font Family: activate to sort column ascending" style={{ width: '211.688px' }}>Font Family</th><th className="max-width-120 sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Options: activate to sort column ascending" style={{ width: '85.6562px' }}>Options</th></tr>
                        </thead>
                        <tbody>
                          <tr role="row" className="odd">
                            <td className="sorting_1">32</td>
                            <td>Verdana</td>
                            <td>font-family: Verdana, Helvetica, sans-serif</td>
                            <td>
                              <div className="dropdown">
                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                                    

                                </button>
                                <ul className="dropdown-menu options-dropdown">
                                  <li>
                                    <Link to="/admin/update-font">
                                      <i className="fa fa-edit option-icon" />Edit</Link>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_font_post','32','Are you sure you want to delete this item?');"><i className="fa fa-trash option-icon" />Delete</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr><tr role="row" className="even">
                            <td className="sorting_1">31</td>
                            <td>Ubuntu</td>
                            <td>font-family: "Ubuntu", Helvetica, sans-serif</td>
                            <td>
                              <div className="dropdown">
                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                                    
                                
                                </button>
                                <ul className="dropdown-menu options-dropdown">
                                  <li>
                                    <a to="/admin/update-font"><i className="fa fa-edit option-icon" />Edit</a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_font_post','31','Are you sure you want to delete this item?');"><i className="fa fa-trash option-icon" />Delete</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr><tr role="row" className="odd">
                            <td className="sorting_1">30</td>
                            <td>Titillium Web</td>
                            <td>font-family: "Titillium Web", Helvetica, sans-serif</td>
                            <td>
                              <div className="dropdown">
                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                                    
                                </button>
                                <ul className="dropdown-menu options-dropdown">
                                  <li>
                                    <Link to="/admin/update-font"><i className="fa fa-edit option-icon" />Edit</Link>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_font_post','30','Are you sure you want to delete this item?');"><i className="fa fa-trash option-icon" />Delete</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr><tr role="row" className="even">
                            <td className="sorting_1">29</td>
                            <td>Source Sans Pro</td>
                            <td>font-family: "Source Sans Pro", Helvetica, sans-serif</td>
                            <td>
                              <div className="dropdown">
                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                                   
                                </button>
                                <ul className="dropdown-menu options-dropdown">
                                  <li>
                                    <Link to="/admin/update-font"><i className="fa fa-edit option-icon" />Edit</Link>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_font_post','29','Are you sure you want to delete this item?');"><i className="fa fa-trash option-icon" />Delete</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr><tr role="row" className="odd">
                            <td className="sorting_1">28</td>
                            <td>Rokkitt</td>
                            <td>font-family: "Rokkitt", Helvetica, sans-serif</td>
                            <td>
                              <div className="dropdown">
                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                                   
                                </button>
                                <ul className="dropdown-menu options-dropdown">
                                  <li>
                                    <a href="https://prernamedia.com/admin/update-font/28"><i className="fa fa-edit option-icon" />Edit</a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_font_post','28','Are you sure you want to delete this item?');"><i className="fa fa-trash option-icon" />Delete</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr><tr role="row" className="even">
                            <td className="sorting_1">27</td>
                            <td>Roboto Slab</td>
                            <td>font-family: "Roboto Slab", Helvetica, sans-serif</td>
                            <td>
                              <div className="dropdown">
                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                                    
                                </button>
                                <ul className="dropdown-menu options-dropdown">
                                  <li>
                                    <a href="https://prernamedia.com/admin/update-font/27"><i className="fa fa-edit option-icon" />Edit</a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_font_post','27','Are you sure you want to delete this item?');"><i className="fa fa-trash option-icon" />Delete</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr><tr role="row" className="odd">
                            <td className="sorting_1">26</td>
                            <td>Roboto Condensed</td>
                            <td>font-family: "Roboto Condensed", Helvetica, sans-serif</td>
                            <td>
                              <div className="dropdown">
                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                                    
                                </button>
                                <ul className="dropdown-menu options-dropdown">
                                  <li>
                                    <a href="https://prernamedia.com/admin/update-font/26"><i className="fa fa-edit option-icon" />Edit</a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_font_post','26','Are you sure you want to delete this item?');"><i className="fa fa-trash option-icon" />Delete</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr><tr role="row" className="even">
                            <td className="sorting_1">25</td>
                            <td>Roboto</td>
                            <td>font-family: "Roboto", Helvetica, sans-serif</td>
                            <td>
                              <div className="dropdown">
                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                                   
                                </button>
                                <ul className="dropdown-menu options-dropdown">
                                  <li>
                                    <a href="https://prernamedia.com/admin/update-font/25"><i className="fa fa-edit option-icon" />Edit</a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_font_post','25','Are you sure you want to delete this item?');"><i className="fa fa-trash option-icon" />Delete</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr><tr role="row" className="odd">
                            <td className="sorting_1">24</td>
                            <td>Raleway</td>
                            <td>font-family: "Raleway", Helvetica, sans-serif</td>
                            <td>
                              <div className="dropdown">
                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                                    
                                </button>
                                <ul className="dropdown-menu options-dropdown">
                                  <li>
                                    <a href="https://prernamedia.com/admin/update-font/24"><i className="fa fa-edit option-icon" />Edit</a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_font_post','24','Are you sure you want to delete this item?');"><i className="fa fa-trash option-icon" />Delete</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr><tr role="row" className="even">
                            <td className="sorting_1">23</td>
                            <td>PT Sans</td>
                            <td>font-family: "PT Sans", Helvetica, sans-serif</td>
                            <td>
                              <div className="dropdown">
                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                                    
                                </button>
                                <ul className="dropdown-menu options-dropdown">
                                  <li>
                                    <a href="https://prernamedia.com/admin/update-font/23"><i className="fa fa-edit option-icon" />Edit</a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_font_post','23','Are you sure you want to delete this item?');"><i className="fa fa-trash option-icon" />Delete</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr><tr role="row" className="odd">
                            <td className="sorting_1">22</td>
                            <td>Poppins</td>
                            <td>font-family: "Poppins", Helvetica, sans-serif</td>
                            <td>
                              <div className="dropdown">
                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                                    
                                </button>
                                <ul className="dropdown-menu options-dropdown">
                                  <li>
                                    <a href="https://prernamedia.com/admin/update-font/22"><i className="fa fa-edit option-icon" />Edit</a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_font_post','22','Are you sure you want to delete this item?');"><i className="fa fa-trash option-icon" />Delete</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr><tr role="row" className="even">
                            <td className="sorting_1">21</td>
                            <td>Oxygen</td>
                            <td>font-family: "Oxygen", Helvetica, sans-serif</td>
                            <td>
                              <div className="dropdown">
                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                                    
                                </button>
                                <ul className="dropdown-menu options-dropdown">
                                  <li>
                                    <a href="https://prernamedia.com/admin/update-font/21"><i className="fa fa-edit option-icon" />Edit</a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_font_post','21','Are you sure you want to delete this item?');"><i className="fa fa-trash option-icon" />Delete</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr><tr role="row" className="odd">
                            <td className="sorting_1">20</td>
                            <td>Oswald</td>
                            <td>font-family: "Oswald", Helvetica, sans-serif</td>
                            <td>
                              <div className="dropdown">
                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                                    
                                </button>
                                <ul className="dropdown-menu options-dropdown">
                                  <li>
                                    <a href="https://prernamedia.com/admin/update-font/20"><i className="fa fa-edit option-icon" />Edit</a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_font_post','20','Are you sure you want to delete this item?');"><i className="fa fa-trash option-icon" />Delete</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr><tr role="row" className="even">
                            <td className="sorting_1">19</td>
                            <td>Open Sans</td>
                            <td>font-family: "Open Sans", Helvetica, sans-serif</td>
                            <td>
                              <div className="dropdown">
                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                                    
                                </button>
                                <ul className="dropdown-menu options-dropdown">
                                  <li>
                                    <a href="https://prernamedia.com/admin/update-font/19"><i className="fa fa-edit option-icon" />Edit</a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_font_post','19','Are you sure you want to delete this item?');"><i className="fa fa-trash option-icon" />Delete</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr><tr role="row" className="odd">
                            <td className="sorting_1">18</td>
                            <td>Nunito</td>
                            <td>font-family: "Nunito", Helvetica, sans-serif</td>
                            <td>
                              <div className="dropdown">
                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                                    
                                </button>
                                <ul className="dropdown-menu options-dropdown">
                                  <li>
                                    <a href="https://prernamedia.com/admin/update-font/18"><i className="fa fa-edit option-icon" />Edit</a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_font_post','18','Are you sure you want to delete this item?');"><i className="fa fa-trash option-icon" />Delete</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr></tbody>
                      </table></div></div><div className="row"><div className="col-sm-5"><div className="dataTables_info" id="cs_datatable_info" role="status" aria-live="polite">Showing 1 to 15 of 32 entries</div></div><div className="col-sm-7"><div className="dataTables_paginate paging_simple_numbers" id="cs_datatable_paginate"><ul className="pagination"><li className="paginate_button previous disabled" id="cs_datatable_previous"><a href="#" aria-controls="cs_datatable" data-dt-idx={0} tabIndex={0}>‹</a></li><li className="paginate_button active"><a href="#" aria-controls="cs_datatable" data-dt-idx={1} tabIndex={0}>1</a></li><li className="paginate_button "><a href="#" aria-controls="cs_datatable" data-dt-idx={2} tabIndex={0}>2</a></li><li className="paginate_button "><a href="#" aria-controls="cs_datatable" data-dt-idx={3} tabIndex={0}>3</a></li><li className="paginate_button next" id="cs_datatable_next"><a href="#" aria-controls="cs_datatable" data-dt-idx={4} tabIndex={0}>›</a></li></ul></div></div></div></div>
                    </div>
                  </div>
                </div>
              </div>{/* /.box-body */}
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
export default FontSettings