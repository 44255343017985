import { Link } from "react-router-dom"

function PendingComment() {
    return (
        <>
        <section className="content">
  <div className="box">
    <div className="box-header with-border">
      <div className="left">
        <h3 className="box-title">Pending Comments</h3>
      </div>
      <div className="right">
        <Link to="/admin/Approved-comments" className="btn btn-success btn-add-new">
          <i className="fa fa-bars" />
          Approved Comments			</Link>
      </div>
    </div>{/* /.box-header */}
    <div className="box-body">
      <div className="row">
        {/* include message block */}
        <div className="col-sm-12">
          {/*print error messages*/}
          {/*print custom error message*/}
          {/*print custom success message*/}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive">
            <div id="cs_datatable_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer"><div className="row"><div className="col-sm-6"><div className="dataTables_length" id="cs_datatable_length"><label>Show <select name="cs_datatable_length" aria-controls="cs_datatable" className="form-control input-sm"><option value={15}>15</option><option value={30}>30</option><option value={60}>60</option><option value={100}>100</option></select></label></div></div><div className="col-sm-6"><div id="cs_datatable_filter" className="dataTables_filter"><label>Search<input type="search" className="form-control input-sm" placeholder aria-controls="cs_datatable" /></label></div></div></div><div className="row"><div className="col-sm-12"><table className="table table-bordered table-striped dataTable no-footer" id="cs_datatable" role="grid" aria-describedby="cs_datatable_info">
                    <thead>
                      <tr role="row"><th width={20} className="table-no-sort sorting_desc" style={{textAlign: 'center !important', width: 20}} tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-sort="descending" aria-label=": activate to sort column ascending"><input type="checkbox" className="checkbox-table" id="checkAll" /></th><th width={20} className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Id: activate to sort column ascending" style={{width: 20}}>Id</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{width: '132.238px'}}>Name</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Email: activate to sort column ascending" style={{width: '129.312px'}}>Email</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Comment: activate to sort column ascending" style={{width: '186.887px'}}>Comment</th><th style={{minWidth: '20%', width: '111.725px'}} className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Post: activate to sort column ascending">Post</th><th style={{minWidth: '10%', width: '114.9px'}} className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Date: activate to sort column ascending">Date</th><th className="max-width-120 sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Options: activate to sort column ascending" style={{width: '158.538px'}}>Options</th></tr>
                    </thead>
                    <tbody>
                      <tr className="odd"><td valign="top" colSpan={8} className="dataTables_empty">No data available in table</td></tr></tbody>
                  </table></div></div><div className="row"><div className="col-sm-5"><div className="dataTables_info" id="cs_datatable_info" role="status" aria-live="polite">Showing 0 to 0 of 0 entries</div></div><div className="col-sm-7"><div className="dataTables_paginate paging_simple_numbers" id="cs_datatable_paginate"><ul className="pagination"><li className="paginate_button previous disabled" id="cs_datatable_previous"><a href="#" aria-controls="cs_datatable" data-dt-idx={0} tabIndex={0}>‹</a></li><li className="paginate_button next disabled" id="cs_datatable_next"><a href="#" aria-controls="cs_datatable" data-dt-idx={1} tabIndex={0}>›</a></li></ul></div></div></div></div>
          </div>
          <div className="col-sm-12">
            <div className="row">
              <div className="pull-left">
                <button className="btn btn-sm btn-danger btn-table-delete" onclick="delete_selected_comments('Are you sure you want to delete selected comments?');">Delete</button>
                <button className="btn btn-sm btn-success btn-table-delete" onclick="approve_selected_comments();">Approve</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>{/* /.box-body */}
  </div>
</section>

        </>
    )
}
export default PendingComment