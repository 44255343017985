import { useState } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import {FiEdit} from "react-icons/fi"
import {AiFillDelete} from "react-icons/ai"

const icon1 = <FiEdit /> 
const icon2 = <AiFillDelete /> 
const accordianData = [
  {id:'1',title:'Video',category:'category',edit:icon1,delete:icon2,answer:''},
  {id:'2',title:'Politics',category:'category',edit:icon1,delete:icon2,answer:''},
  {id:'3',title:'वर्ल्ड',category:'category',edit:icon1,delete:icon2,answer:''},
  {id:'4',title:'देश',category:'category',edit:icon1,delete:icon2,answer:''},
  {id:'5',title:'सिटी',category:'category',edit:icon1,delete:icon2,answer:''},
  {id:'6',title:'प्रदेश',category:'category',edit:icon1,delete:icon2,answer:''},
  {id:'7',title:'बॉयोस्कोप',category:'category',edit:icon1,delete:icon2,answer:''},
  {id:'8',title:'खेल',category:'category',edit:icon1,delete:icon2,answer:''},
  {id:'9',title:'B&E',category:'category',edit:icon1,delete:icon2,answer:''},
  {id:'10',title:'ज्ञान-विज्ञान',category:'category',edit:icon1,delete:icon2,answer:''},
  {id:'11',title:'लाइफस्टाइल',category:'category',edit:icon1,delete:icon2,answer:''},
  {id:'12',title:'आस्था',category:'category',edit:icon1,delete:icon2,answer:''},
  {id:'13',title:'कैरियर',category:'category',edit:icon1,delete:icon2,answer:''},
  {id:'14',title:'हेल्थ',category:'category',edit:icon1,delete:icon2,answer:''},
  {id:'15',title:'प्ररसोई',category:'category',edit:icon1,delete:icon2,answer:''},
  {id:'16',title:'Business',category:'category',edit:icon1,delete:icon2,answer:''},
  {id:'17',title:'Funding',category:'category',edit:icon1,delete:icon2,answer:''},
]

function NavigationMenu() {
const [select,setSellect] = useState(null)
const toggle = (i) =>{
  if (select === i) {
    return setSellect(null)
  }
  setSellect(i)
}    
  return (
    <>
      <div className="box box-primary">
        <div className="box-header with-border">
          <h3 className="box-title">Navigation</h3>
          <br />
          <small>
            You can manage the navigation by dragging and dropping menu items.
          </small>
        </div>
        <form
          action="https://prernamedia.com/category_controller/add_category_post"
          method="post"
          acceptCharset="utf-8"
        >
          <input
            type="hidden"
            name="varient_csrf_token"
            defaultValue="aadfcd795a80e220ec5908f7a85daf40"
          />
          <input type="hidden" name="parent_id" defaultValue={0} />
          <div className="box-body">
            <div
              id="accordion"
              data-parent-id={0}
              data-item-type="none"
              className="panel-group nested-sortable navigation-editable main-nav-item-container"
            >
              <div
                className="panel panel-default nav-item"
                style={{ pointerEvents: "none" }}
              >
                <a
                  href="javascript:void(0)"
                  className="btn btn-sm btn-danger btn-nav-edit btn-show-hide-home"
                >
                  Hide
                </a>
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <span>Home</span>
                  </h4>
                </div>
              </div>

              <div className="accordian">
                {accordianData.map((item,i) => {
                  return <div
                  id="nav_item_category_69"
                  className="panel panel-default nav-item"
                  data-item-id={69}
                  data-item-type="category"
                  data-have-subs-items={0}
                  onClick={() => toggle(i)}
                >
                  <Link
                    to="/admin/navigation/update-category"
                    className="btn btn-sm btn-secondary btn-nav-edit"
                  >
                    {/* <i className="fa fa-edit" /> */}
                    {item.edit}
                  </Link>
                  <a
                    href="javascript:void(0)"
                    className="btn btn-sm btn-danger btn-nav-edit btn-nav-delete"
                  >
                    {/* <i className="fa fa-trash" /> */}
                    {item.delete}
                  </a>
                  <div
                    className="panel-heading collapsed"
                    // className={panel-heading}
                    // id="collapsedd"
                    // data-toggle="collapse"
                  //   href="#collapse_category_69"
                  // onClick={collapse}
                  >
                    <h4 className="panel-title">
                      {/* <i className="fa fa-plus"/> */}
                      <span className="plus">{select === i ? '-' :'+'}</span>
                      <span>
                        {item.title}<em>({item.category})</em>
                      </span>
                    </h4>
                  </div>
                  <div
                    // id="collapse_category_69"
                    // className="panel-collapse"
                    className={select === i ? "panel-body nested-sortable panel-body-sublinks" : "content-1"}
                  >
                    {item.answer}
                    <div
                      // className="panel-body nested-sortable panel-body-sublinks"
                      data-parent-id={69}
                      data-item-type="category"
                    />
                  </div>
                </div>
                })}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
export default NavigationMenu;
