import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom"

function GalleryCategories() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <section className="content">
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <div className="box box-primary">
                            <div className="box-header with-border">
                                <h3 className="box-title">Add Category</h3>
                            </div>
                            {/* /.box-header */}
                            {/* form start */}
                            <form action="https://prernamedia.com/category_controller/add_gallery_category_post" method="post" acceptCharset="utf-8">
                                <input type="hidden" name="varient_csrf_token" defaultValue="3d53193b0ce930dea5ad6663457d1f8b" />
                                <input type="hidden" name="parent_id" defaultValue={0} />
                                <div className="box-body">
                                    {/* include message block */}
                                    {/*print error messages*/}
                                    {/*print custom error message*/}
                                    {/*print custom success message*/}
                                    <div className="form-group">
                                        <label>Language</label>
                                        <select name="lang_id" className="form-control" onchange="get_albums_by_lang(this.value);" fdprocessedid="vvlgc8">
                                            <option value={1} selected>English</option>
                                            <option value={2}>Hindi</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Album</label>
                                        <select name="album_id" id="albums" className="form-control" required fdprocessedid="1gexf">
                                            <option value>Select</option>
                                            <option value={1}>Media</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Category Name</label>
                                        <input type="text" className="form-control" name="name" placeholder="Category Name" defaultValue maxLength={200} required fdprocessedid="kg7oc" />
                                    </div>
                                </div>
                                {/* /.box-body */}
                                <div className="box-footer">
                                    <button type="submit" className="btn btn-primary pull-right" fdprocessedid="5r0bbp">Add Category</button>
                                </div>
                                {/* /.box-footer */}
                            </form>{/* form end */}
                        </div>
                        {/* /.box */}
                    </div>
                    <div className="col-lg-8 col-md-12">
                        <div className="box">
                            <div className="box-header with-border">
                                <div className="pull-left">
                                    <h3 className="box-title">Gallery Categories</h3>
                                </div>
                            </div>{/* /.box-header */}
                            <div className="box-body">
                                <div className="row">
                                    {/* include message block */}
                                    <div className="col-sm-12">
                                        {/*print error messages*/}
                                        {/*print custom error message*/}
                                        {/*print custom success message*/}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="table-responsive">
                                            <div id="cs_datatable_lang_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer"><div className="dataTables_length" id="cs_datatable_lang_length"><label>Show <select name="cs_datatable_lang_length" aria-controls="cs_datatable_lang" className="form-control input-sm" fdprocessedid="bedje6"><option value={15}>15</option><option value={30}>30</option><option value={60}>60</option><option value={100}>100</option></select></label></div><div id="table_dropdown"><label className="table-label">Language</label><select className="form-control input-sm" fdprocessedid="iv5ara"><option value>All</option><option value="English">English</option><option value="Hindi">Hindi</option></select></div><div id="cs_datatable_lang_filter" className="dataTables_filter"><label>Search<input type="search" className="form-control input-sm" placeholder aria-controls="cs_datatable_lang" /></label></div><table className="table table-bordered table-striped dataTable no-footer" id="cs_datatable_lang" role="grid" aria-describedby="cs_datatable_lang_info">
                                                <thead>
                                                    <tr role="row"><th width={20} className="sorting_desc" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-sort="descending" aria-label="Id: activate to sort column ascending" style={{ width: 20 }}>Id</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Category Name: activate to sort column ascending" style={{ width: '172.5px' }}>Category Name</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Album: activate to sort column ascending" style={{ width: '87.4px' }}>Album</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Language: activate to sort column ascending" style={{ width: '118.65px' }}>Language</th><th className="max-width-120 sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Options: activate to sort column ascending" style={{ width: '161.038px' }}>Options</th></tr>
                                                </thead>
                                                <tbody>
                                                    <tr role="row" className="odd">
                                                        <td className="sorting_1">1</td>
                                                        <td>Ad_photos</td>
                                                        <td>
                                                            Media                                      </td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="7eka1p" aria-expanded="false">Select an option                                                  
                                                                  {/* <span className="caret" /> */}
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <Link to="/admin/update-gallery-category"><i className="fa fa-edit option-icon" />Edit</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="#" onClick={handleShow}><i className="fa fa-trash option-icon" />Delete</Link>
                                                                        <Modal show={show} onHide={handleClose}>
                                                                    <Modal.Header closeButton>
                                                                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                                                                    </Modal.Header>
                                                                    <Modal.Body>Are you sure you want to delete this poll?</Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="secondary" onClick={handleClose}>
                                                                            Close
                                                                        </Button>
                                                                        <Button variant="danger" onClick={handleClose}>
                                                                            Ok
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr></tbody>
                                            </table><div className="dataTables_info" id="cs_datatable_lang_info" role="status" aria-live="polite">Showing 1 to 1 of 1 entries</div><div className="dataTables_paginate paging_simple_numbers" id="cs_datatable_lang_paginate"><ul className="pagination"><li className="paginate_button previous disabled" id="cs_datatable_lang_previous"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={0} tabIndex={0}>‹</a></li><li className="paginate_button active"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={1} tabIndex={0}>1</a></li><li className="paginate_button next disabled" id="cs_datatable_lang_next"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={2} tabIndex={0}>›</a></li></ul></div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* /.box-body */}
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default GalleryCategories