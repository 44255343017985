import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function Subscribers() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <section className="content">
                <div className="box">
                    <div className="box-header">
                        <div className="left">
                            <h3 className="box-title">Subscribers</h3>
                        </div>
                    </div>{/* /.box-header */}
                    <div className="box-body">
                        {/*print error messages*/}
                        {/*print custom error message*/}
                        {/*print custom success message*/}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    <div id="cs_datatable_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer"><div className="row"><div className="col-sm-6"><div className="dataTables_length" id="cs_datatable_length"><label>Show <select name="cs_datatable_length" aria-controls="cs_datatable" className="form-control input-sm"><option value={15}>15</option><option value={30}>30</option><option value={60}>60</option><option value={100}>100</option></select></label></div></div><div className="col-sm-6"><div id="cs_datatable_filter" className="dataTables_filter"><label>Search<input type="search" className="form-control input-sm" placeholder aria-controls="cs_datatable" /></label></div></div></div><div className="row"><div className="col-sm-12"><table className="table table-bordered table-striped dataTable no-footer" id="cs_datatable" role="grid" aria-describedby="cs_datatable_info">
                                        <thead>
                                            <tr role="row"><th width={20} className="sorting_desc" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-sort="descending" aria-label="Id: activate to sort column ascending" style={{ width: 20 }}>Id</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Email: activate to sort column ascending" style={{ width: '327.837px' }}>Email</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Send Email: activate to sort column ascending" style={{ width: '198.05px' }}>Send Email</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Date Added: activate to sort column ascending" style={{ width: '216.425px' }}>Date Added</th><th className="max-width-120 sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Options: activate to sort column ascending" style={{ width: '213.688px' }}>Options</th></tr>
                                        </thead>
                                        <tbody>
                                            <tr role="row" className="odd">
                                                <td className="sorting_1">10</td>
                                                <td>abarisproducts@gmail.com</td>
                                                <td>
                                                    <Link to="/admin/send-email-subscribers" className="btn btn-info btn-sm" target="_blank"><i className="fa fa-envelope" />&nbsp;&nbsp;&nbsp;Send Email</Link>
                                                </td>
                                                <td>2023-02-25 / 17:45</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" aria-expanded="false">Select an option                                           
                                                         {/* <span className="caret" /> */}
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <Link to="#" onClick={handleShow}><i className="fa fa-trash option-icon" />Delete</Link>
                                                                <Modal show={show} onHide={handleClose}>
                                                                    <Modal.Header closeButton>
                                                                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                                                                    </Modal.Header>
                                                                    <Modal.Body>Are you sure you want to delete this poll?</Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="secondary" onClick={handleClose}>
                                                                            Close
                                                                        </Button>
                                                                        <Button variant="danger" onClick={handleClose}>
                                                                            Ok
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="even">
                                                <td className="sorting_1">9</td>
                                                <td>abarissolution@gmail.com</td>
                                                <td>
                                                    <a href="https://prernamedia.com/admin/send-email-subscriber/9" className="btn btn-info btn-sm" target="_blank"><i className="fa fa-envelope" />&nbsp;&nbsp;&nbsp;Send Email</a>
                                                </td>
                                                <td>2021-01-06 / 21:13</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                            <span className="caret" />
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_newsletter_post','9','Are you sure you want to delete this email?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="odd">
                                                <td className="sorting_1">8</td>
                                                <td>abc@gmail.com</td>
                                                <td>
                                                    <a href="https://prernamedia.com/admin/send-email-subscriber/8" className="btn btn-info btn-sm" target="_blank"><i className="fa fa-envelope" />&nbsp;&nbsp;&nbsp;Send Email</a>
                                                </td>
                                                <td>2021-01-03 / 21:11</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                            <span className="caret" />
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_newsletter_post','8','Are you sure you want to delete this email?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="even">
                                                <td className="sorting_1">7</td>
                                                <td>abcsss321@gmail.com</td>
                                                <td>
                                                    <a href="https://prernamedia.com/admin/send-email-subscriber/7" className="btn btn-info btn-sm" target="_blank"><i className="fa fa-envelope" />&nbsp;&nbsp;&nbsp;Send Email</a>
                                                </td>
                                                <td>2021-01-03 / 21:11</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                            <span className="caret" />
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_newsletter_post','7','Are you sure you want to delete this email?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="odd">
                                                <td className="sorting_1">6</td>
                                                <td />
                                                <td>
                                                    <a href="https://prernamedia.com/admin/send-email-subscriber/6" className="btn btn-info btn-sm" target="_blank"><i className="fa fa-envelope" />&nbsp;&nbsp;&nbsp;Send Email</a>
                                                </td>
                                                <td>2021-01-03 / 21:09</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                            <span className="caret" />
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_newsletter_post','6','Are you sure you want to delete this email?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="even">
                                                <td className="sorting_1">5</td>
                                                <td>abc321@gmail.com</td>
                                                <td>
                                                    <a href="https://prernamedia.com/admin/send-email-subscriber/5" className="btn btn-info btn-sm" target="_blank"><i className="fa fa-envelope" />&nbsp;&nbsp;&nbsp;Send Email</a>
                                                </td>
                                                <td>2021-01-03 / 21:09</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                            <span className="caret" />
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_newsletter_post','5','Are you sure you want to delete this email?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="odd">
                                                <td className="sorting_1">1</td>
                                                <td>seoabaris@gmail.com</td>
                                                <td>
                                                    <a href="https://prernamedia.com/admin/send-email-subscriber/1" className="btn btn-info btn-sm" target="_blank"><i className="fa fa-envelope" />&nbsp;&nbsp;&nbsp;Send Email</a>
                                                </td>
                                                <td>2021-01-01 / 20:41</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                            <span className="caret" />
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_newsletter_post','1','Are you sure you want to delete this email?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr></tbody>
                                    </table></div></div><div className="row"><div className="col-sm-5"><div className="dataTables_info" id="cs_datatable_info" role="status" aria-live="polite">Showing 1 to 7 of 7 entries</div></div><div className="col-sm-7"><div className="dataTables_paginate paging_simple_numbers" id="cs_datatable_paginate"><ul className="pagination"><li className="paginate_button previous disabled" id="cs_datatable_previous"><a href="#" aria-controls="cs_datatable" data-dt-idx={0} tabIndex={0}>‹</a></li><li className="paginate_button active"><a href="#" aria-controls="cs_datatable" data-dt-idx={1} tabIndex={0}>1</a></li><li className="paginate_button next disabled" id="cs_datatable_next"><a href="#" aria-controls="cs_datatable" data-dt-idx={2} tabIndex={0}>›</a></li></ul></div></div></div></div>
                                </div>
                            </div>
                        </div>
                    </div>{/* /.box-body */}
                </div>
            </section>

        </>
    )
}
export default Subscribers