import { useState } from "react";
import { Button, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"

function Subcategories() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <section className="content">
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <div className="box box-primary">
                            <div className="box-header with-border">
                                <h3 className="box-title">Add Subcategory</h3>
                            </div>
                            {/* /.box-header */}
                            {/* form start */}
                            <form action="https://prernamedia.com/category_controller/add_subcategory_post" method="post" acceptCharset="utf-8">
                                <input type="hidden" name="varient_csrf_token" defaultValue="d544fd03729d9e069714ee8b8943c6a6" />
                                <div className="box-body">
                                    {/* include message block */}
                                    {/*print error messages*/}
                                    {/*print custom error message*/}
                                    {/*print custom success message*/}
                                    <div className="form-group">
                                        <label>Language</label>
                                        <select name="lang_id" className="form-control" onchange="get_parent_categories_by_lang(this.value);" fdprocessedid="5ndgxa">
                                            <option value={1} selected>English</option>
                                            <option value={2}>Hindi</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Category Name</label>
                                        <input type="text" className="form-control" name="name" placeholder="Category Name" defaultValue maxLength={200} required fdprocessedid="ab2gh" />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Slug                        <small>(If you leave it blank, it will be generated automatically.)</small>
                                        </label>
                                        <input type="text" className="form-control" name="name_slug" placeholder="Slug" defaultValue fdprocessedid="maa7gq" />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Description (Meta Tag)</label>
                                        <input type="text" className="form-control" name="description" placeholder="Description (Meta Tag)" defaultValue fdprocessedid="1hq1zl" />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Keywords (Meta Tag)</label>
                                        <input type="text" className="form-control" name="keywords" placeholder="Keywords (Meta Tag)" defaultValue fdprocessedid="umc7rb" />
                                    </div>
                                    <div className="form-group">
                                        <label>Parent Category</label>
                                        <select id="categories" className="form-control" name="parent_id" required fdprocessedid="11cc2i">
                                            <option value>Select</option>
                                            <option value={1}>सिटी</option>
                                            <option value={3}>प्रदेश</option>
                                            <option value={4}>देश</option>
                                            <option value={5}>वर्ल्ड</option>
                                            <option value={6}>बॉयोस्कोप</option>
                                            <option value={7}>खेल</option>
                                            <option value={8}>B&amp;E</option>
                                            <option value={9}>ज्ञान-विज्ञान</option>
                                            <option value={10}>लाइफस्टाइल</option>
                                            <option value={11}>आस्था</option>
                                            <option value={12}>कैरियर</option>
                                            <option value={13}>हेल्थ</option>
                                            <option value={14}>रसोई</option>
                                            <option value={15}>Business</option>
                                            <option value={69}>VIDEO</option>
                                            <option value={70}>Politics</option>
                                            <option value={71}>Funding</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-4 col-xs-12">
                                                <label>Show on Menu</label>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12 col-option">
                                                <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" id="rb_show_on_menu_1" name="show_on_menu" defaultValue={1} className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                                <label htmlFor="rb_show_on_menu_1" className="cursor-pointer">Yes</label>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12 col-option">
                                                <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" id="rb_show_on_menu_2" name="show_on_menu" defaultValue={0} className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                                <label htmlFor="rb_show_on_menu_2" className="cursor-pointer">No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.box-body */}
                                <div className="box-footer">
                                    <button type="submit" className="btn btn-primary pull-right" fdprocessedid="kctm">Add Subcategory</button>
                                </div>
                                {/* /.box-footer */}
                            </form>{/* form end */}
                        </div>
                        {/* /.box */}
                    </div>
                    <div className="col-lg-7 col-md-12">
                        <div className="box">
                            <div className="box-header with-border">
                                <div className="pull-left">
                                    <h3 className="box-title">Subcategories</h3>
                                </div>
                            </div>{/* /.box-header */}
                            <div className="box-body">
                                <div className="row">
                                    {/* include message block */}
                                    <div className="col-sm-12">
                                        {/*print error messages*/}
                                        {/*print custom error message*/}
                                        {/*print custom success message*/}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="table-responsive">
                                            <div id="cs_datatable_lang_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer"><div className="dataTables_length" id="cs_datatable_lang_length"><label>Show <select name="cs_datatable_lang_length" aria-controls="cs_datatable_lang" className="form-control input-sm" fdprocessedid="k64rdh"><option value={15}>15</option><option value={30}>30</option><option value={60}>60</option><option value={100}>100</option></select></label></div><div id="table_dropdown"><label className="table-label">Language</label><select className="form-control input-sm" fdprocessedid="r3xyrl"><option value>All</option><option value="English">English</option><option value="Hindi">Hindi</option></select></div><div id="cs_datatable_lang_filter" className="dataTables_filter"><label>Search<input type="search" className="form-control input-sm" placeholder aria-controls="cs_datatable_lang" /></label></div><table className="table table-bordered table-striped dataTable no-footer" id="cs_datatable_lang" role="grid" aria-describedby="cs_datatable_lang_info">
                                                <thead>
                                                    <tr role="row"><th width={20} className="sorting_desc" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-sort="descending" aria-label="Id: activate to sort column ascending" style={{ width: 20 }}>Id</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Category Name: activate to sort column ascending" style={{ width: '129.225px' }}>Category Name</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Language: activate to sort column ascending" style={{ width: '76.5875px' }}>Language</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Parent Category: activate to sort column ascending" style={{ width: '121.675px' }}>Parent Category</th><th className="max-width-120 sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Options: activate to sort column ascending" style={{ width: '108.012px' }}>Options</th></tr>
                                                </thead>
                                                <tbody>
                                                    <tr role="row" className="odd">
                                                        <td className="sorting_1">80</td>
                                                        <td>Pune</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>
                                                            सिटी                                      </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="krcmk8">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <Link to="/admin/update-subcategory"><i className="fa fa-edit option-icon" />Edit</Link>
                                                                    </li>
                                                                    <li>
                                                                        <button onClick={handleShow}><i className="fa fa-trash option-icon" />Delete</button>

                                                                        <Modal show={show} onHide={handleClose}>
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title>

                                                                                </Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>Are you sure you want to delete this category?</Modal.Body>
                                                                            <Modal.Footer>
                                                                                <Button variant="secondary" onClick={handleClose}>
                                                                                    Cancel
                                                                                </Button>
                                                                                <Button variant="danger" onClick={handleClose}>
                                                                                    ok
                                                                                </Button>
                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="even">
                                                        <td className="sorting_1">79</td>
                                                        <td>Vadodara</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>
                                                            सिटी                                      </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="onhaii">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <a href="https://prernamedia.com/admin/update-subcategory/79"><i className="fa fa-edit option-icon" />Edit</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','79','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="odd">
                                                        <td className="sorting_1">78</td>
                                                        <td>Surat</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>
                                                            सिटी                                      </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="b8igp7">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <a href="https://prernamedia.com/admin/update-subcategory/78"><i className="fa fa-edit option-icon" />Edit</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','78','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="even">
                                                        <td className="sorting_1">77</td>
                                                        <td>Kolkata</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>
                                                            सिटी                                      </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="ws6r2q">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <a href="https://prernamedia.com/admin/update-subcategory/77"><i className="fa fa-edit option-icon" />Edit</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','77','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="odd">
                                                        <td className="sorting_1">76</td>
                                                        <td>Chennai</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>
                                                            सिटी                                      </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="5dae5">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <a href="https://prernamedia.com/admin/update-subcategory/76"><i className="fa fa-edit option-icon" />Edit</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','76','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="even">
                                                        <td className="sorting_1">75</td>
                                                        <td>Ahmedabad</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>
                                                            सिटी                                      </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="d397up">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <a href="https://prernamedia.com/admin/update-subcategory/75"><i className="fa fa-edit option-icon" />Edit</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','75','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="odd">
                                                        <td className="sorting_1">74</td>
                                                        <td>Hyderabad</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>
                                                            सिटी                                      </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="vgsfq1">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <a href="https://prernamedia.com/admin/update-subcategory/74"><i className="fa fa-edit option-icon" />Edit</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','74','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="even">
                                                        <td className="sorting_1">73</td>
                                                        <td>Bangalore</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>
                                                            सिटी                                      </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="db3h4">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <a href="https://prernamedia.com/admin/update-subcategory/73"><i className="fa fa-edit option-icon" />Edit</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','73','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="odd">
                                                        <td className="sorting_1">72</td>
                                                        <td>Mumbai</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>
                                                            सिटी                                      </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="cpfkb">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <a href="https://prernamedia.com/admin/update-subcategory/72"><i className="fa fa-edit option-icon" />Edit</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','72','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="even">
                                                        <td className="sorting_1">68</td>
                                                        <td>फिल्मिस्तान</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>
                                                            बॉयोस्कोप                                      </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="ex9lyn">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <a href="https://prernamedia.com/admin/update-subcategory/68"><i className="fa fa-edit option-icon" />Edit</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','68','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="odd">
                                                        <td className="sorting_1">67</td>
                                                        <td>छोटा पर्दा</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>
                                                            बॉयोस्कोप                                      </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="lbxo1p">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <a href="https://prernamedia.com/admin/update-subcategory/67"><i className="fa fa-edit option-icon" />Edit</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','67','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="even">
                                                        <td className="sorting_1">66</td>
                                                        <td>स्टार टॉक</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>
                                                            बॉयोस्कोप                                      </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="t7r7ga">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <a href="https://prernamedia.com/admin/update-subcategory/66"><i className="fa fa-edit option-icon" />Edit</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','66','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="odd">
                                                        <td className="sorting_1">65</td>
                                                        <td>हॉलीवुड</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>
                                                            बॉयोस्कोप                                      </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="456srr">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <a href="https://prernamedia.com/admin/update-subcategory/65"><i className="fa fa-edit option-icon" />Edit</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','65','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="even">
                                                        <td className="sorting_1">64</td>
                                                        <td>सुर-ताल</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>
                                                            बॉयोस्कोप                                      </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="hv6jl">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <a href="https://prernamedia.com/admin/update-subcategory/64"><i className="fa fa-edit option-icon" />Edit</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','64','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="odd">
                                                        <td className="sorting_1">63</td>
                                                        <td>फिल्म समीक्षा</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>
                                                            बॉयोस्कोप                                      </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="7hspr">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <a href="https://prernamedia.com/admin/update-subcategory/63"><i className="fa fa-edit option-icon" />Edit</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','63','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr></tbody>
                                            </table><div className="dataTables_info" id="cs_datatable_lang_info" role="status" aria-live="polite">Showing 1 to 15 of 61 entries</div><div className="dataTables_paginate paging_simple_numbers" id="cs_datatable_lang_paginate"><ul className="pagination"><li className="paginate_button previous disabled" id="cs_datatable_lang_previous"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={0} tabIndex={0}>‹</a></li><li className="paginate_button active"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={1} tabIndex={0}>1</a></li><li className="paginate_button "><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={2} tabIndex={0}>2</a></li><li className="paginate_button "><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={3} tabIndex={0}>3</a></li><li className="paginate_button "><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={4} tabIndex={0}>4</a></li><li className="paginate_button "><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={5} tabIndex={0}>5</a></li><li className="paginate_button next" id="cs_datatable_lang_next"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={6} tabIndex={0}>›</a></li></ul></div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* /.box-body */}
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default Subcategories