
function SubCategoriesUpdate() {
    return (
        <>
        <section className="content">
  <div className="row">
    <div className="col-lg-5 col-md-12">
      <div className="box box-primary">
        <div className="box-header with-border">
          <h3 className="box-title">Update Subcategory</h3>
        </div>
        {/* /.box-header */}
        {/* form start */}
        <form action="https://prernamedia.com/category_controller/update_subcategory_post" method="post" acceptCharset="utf-8">
          <input type="hidden" name="varient_csrf_token" defaultValue="d544fd03729d9e069714ee8b8943c6a6" />
          <input type="hidden" name="id" defaultValue={80} />
          <div className="box-body">
            {/* include message block */}
            {/*print error messages*/}
            {/*print custom error message*/}
            {/*print custom success message*/}
            <div className="form-group">
              <label>Language</label>
              <select name="lang_id" className="form-control" onchange="get_parent_categories_by_lang(this.value);" fdprocessedid="d0fq4">
                <option value={1} selected>English</option>
                <option value={2}>Hindi</option>
              </select>
            </div>
            <div className="form-group">
              <label>Category Name</label>
              <input type="text" className="form-control" name="name" placeholder="Category Name" defaultValue="Pune" maxLength={200} required fdprocessedid="r6ue8r" />
            </div>
            <div className="form-group">
              <label className="control-label">Slug                        <small>(If you leave it blank, it will be generated automatically.)</small>
              </label>
              <input type="text" className="form-control" name="name_slug" placeholder="Slug" defaultValue="pune" fdprocessedid="exh3h" />
            </div>
            <div className="form-group">
              <label className="control-label">Description (Meta Tag)</label>
              <input type="text" className="form-control" name="description" placeholder="Description (Meta Tag)" defaultValue fdprocessedid="oz9wvj" />
            </div>
            <div className="form-group">
              <label className="control-label">Keywords (Meta Tag)</label>
              <input type="text" className="form-control" name="keywords" placeholder="Keywords (Meta Tag)" defaultValue fdprocessedid="xj0hui" />
            </div>
            <div className="form-group">
              <label>Parent Category</label>
              <select id="categories" className="form-control" name="parent_id" required fdprocessedid="jfsopo">
                <option value>Select</option>
                <option value={1} selected>सिटी</option>
                <option value={3}>प्रदेश</option>
                <option value={4}>देश</option>
                <option value={5}>वर्ल्ड</option>
                <option value={6}>बॉयोस्कोप</option>
                <option value={7}>खेल</option>
                <option value={8}>B&amp;E</option>
                <option value={9}>ज्ञान-विज्ञान</option>
                <option value={10}>लाइफस्टाइल</option>
                <option value={11}>आस्था</option>
                <option value={12}>कैरियर</option>
                <option value={13}>हेल्थ</option>
                <option value={14}>रसोई</option>
                <option value={15}>Business</option>
                <option value={69}>VIDEO</option>
                <option value={70}>Politics</option>
                <option value={71}>Funding</option>
              </select>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-sm-4 col-xs-12">
                  <label>Show on Menu</label>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12 col-option">
                  <div className="iradio_square-purple checked" style={{position: 'relative'}}><input type="radio" id="rb_show_on_menu_1" name="show_on_menu" defaultValue={1} className="square-purple" defaultChecked style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /><ins className="iCheck-helper" style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /></div>
                  <label htmlFor="rb_show_on_menu_1" className="cursor-pointer">Yes</label>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12 col-option">
                  <div className="iradio_square-purple" style={{position: 'relative'}}><input type="radio" id="rb_show_on_menu_2" name="show_on_menu" defaultValue={0} className="square-purple" style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /><ins className="iCheck-helper" style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /></div>
                  <label htmlFor="rb_show_on_menu_2" className="cursor-pointer">No</label>
                </div>
              </div>
            </div>
          </div>
          {/* /.box-body */}
          <div className="box-footer">
            <button type="submit" className="btn btn-primary pull-right" fdprocessedid="8u2pqc">Save Changes</button>
          </div>
          {/* /.box-footer */}
        </form>{/* form end */}
      </div>
      {/* /.box */}
    </div>
  </div>
</section>

        </>
    )
}
export default SubCategoriesUpdate