import AdSpaces from "../../Components/adSpaces/AdSpaces"


function AdSpacesPage() {
    return (
        <>
        <AdSpaces/>
        </>
    )
}
export default AdSpacesPage