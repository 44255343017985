import { FiCheck } from "react-icons/fi"

function VisualSetting() {
  return (
    <>
      <section className="content">
        <div className="row">
          <div className="col-sm-12 col-xs-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <div className="left">
                  <h3 className="box-title">Visual Settings</h3>
                </div>
              </div>
              <form action="https://prernamedia.com/admin_controller/visual_settings_post" encType="multipart/form-data" method="post" acceptCharset="utf-8">
                <input type="hidden" name="varient_csrf_token" defaultValue="e3042456da8e91b1048dced2012726c4" />
                <div className="box-body">

                  <div className="form-group">
                    <label>Site Color</label>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="visual-color-box" data-color="default" style={{ backgroundColor: '#1abc9c' }} />
                        <div className="visual-color-box" data-color="violet" style={{ backgroundColor: '#6770B7' }}>
                          <FiCheck className="icon-check" />
                        </div>
                        <div className="visual-color-box" data-color="blue" style={{ backgroundColor: '#1da7da' }} />
                        <div className="visual-color-box" data-color="bondi" style={{ backgroundColor: '#0494b1' }} />
                        <div className="visual-color-box" data-color="amaranth" style={{ backgroundColor: '#e91e63' }} />
                        <div className="visual-color-box" data-color="red" style={{ backgroundColor: '#db2017' }} />
                        <div className="visual-color-box" data-color="orange" style={{ backgroundColor: '#f86923' }} />
                        <div className="visual-color-box" data-color="yellow" style={{ backgroundColor: '#f2d438' }} />
                        <div className="visual-color-box" data-color="bluewood" style={{ backgroundColor: '#34495e' }} />
                        <div className="visual-color-box" data-color="cascade" style={{ backgroundColor: '#95a5a6' }} />
                        <input type="hidden" name="site_color" id="input_user_site_color" defaultValue="violet" />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Top Header and Block Heads Color</label>
                    <div className="input-group my-colorpicker colorpicker-element" style={{ maxWidth: 500 }}>
                      <input type="text" className="form-control" name="site_block_color" maxLength={200} placeholder defaultValue="#1877f2" required />
                      <div className="input-group-addon">
                        <i style={{ backgroundColor: 'rgb(24, 119, 242)' }} />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Post Item List Style</label>
                    <div className="row m-b-15">
                      <div className="col-sm-2">
                        <div className="col-sm-12 m-b-15">
                          <div className="iradio_square-purple checked" style={{ position: 'relative' }}>
                            <input type="radio" name="post_list_style" defaultValue="vertical" />

                          </div>
                        </div>
                        <img src="https://prernamedia.com/assets/admin/img/post_vertical.jpg" alt className="img-responsive" style={{ width: 100 }} />
                      </div>
                      <div className="col-sm-3">
                        <div className="col-sm-12 m-b-15">
                          <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="post_list_style" defaultValue="horizontal" /></div>
                        </div>
                        <img src="https://prernamedia.com/assets/admin/img/post_horizontal.jpg" alt className="img-responsive" style={{ height: 68 }} />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label">Logo</label>
                    <div style={{ marginBottom: 10 }}>
                      <img src="https://prernamedia.com/uploads/logo/logo_63f9f9b8378d1.png" alt="logo" style={{ maxWidth: 250, maxHeight: 250 }} />
                    </div>
                    <div className="display-block">
                      <a className="btn btn-success btn-sm btn-file-upload">
                        Change logo                            <input type="file" name="logo" size={40} accept=".png, .jpg, .jpeg, .gif, .svg" onchange="$('#upload-file-info1').html($(this).val().replace(/.*[\/\\]/, ''));" />
                      </a>
                      (.png, .jpg, .jpeg, .gif, .svg)
                    </div>
                    <span className="label label-info" id="upload-file-info1" />
                  </div>
                  <div className="form-group">
                    <label className="control-label">Logo Footer</label>
                    <div style={{ marginBottom: 10 }}>
                      <img src="https://prernamedia.com/uploads/logo/logo_63f9f9b8378d11.png" alt="logo" style={{ maxWidth: 250, maxHeight: 250 }} />
                    </div>
                    <div className="display-block">
                      <a className="btn btn-success btn-sm btn-file-upload">
                        Change logo                            <input type="file" name="logo_footer" size={40} accept=".png, .jpg, .jpeg, .gif, .svg" onchange="$('#upload-file-info2').html($(this).val().replace(/.*[\/\\]/, ''));" />
                      </a>
                      (.png, .jpg, .jpeg, .gif, .svg)
                    </div>
                    <span className="label label-info" id="upload-file-info2" />
                  </div>
                  <div className="form-group">
                    <label className="control-label">Logo Email</label>
                    <div style={{ marginBottom: 10 }}>
                      <img src="https://prernamedia.com/uploads/logo/logo_63f9f9b8378d12.png" alt="logo" style={{ maxWidth: 200, maxHeight: 200 }} />
                    </div>
                    <div className="display-block">
                      <a className="btn btn-success btn-sm btn-file-upload">
                        Change logo                            <input type="file" name="logo_email" size={40} accept=".png, .jpg, .jpeg" onchange="$('#upload-file-info3').html($(this).val().replace(/.*[\/\\]/, ''));" />
                      </a>
                      (.png, .jpg, .jpeg)
                    </div>
                    <span className="label label-info" id="upload-file-info3" />
                  </div>
                  <div className="form-group">
                    <label className="control-label">Favicon (16x16px)</label>
                    <div style={{ marginBottom: 10 }}>
                      <img src="https://prernamedia.com/uploads/logo/logo_63f9f9b8378d13.png" alt="favicon" style={{ maxWidth: 100, maxHeight: 100 }} />
                    </div>
                    <div className="display-block">
                      <a className="btn btn-success btn-sm btn-file-upload">
                        Change favicon                            <input type="file" name="favicon" size={40} accept=".png" onchange="$('#upload-file-info4').html($(this).val().replace(/.*[\/\\]/, ''));" />
                      </a>
                      (.png)
                    </div>
                    <span className="label label-info" id="upload-file-info4" />
                  </div>
                </div>
                <div className="box-footer">
                  <button type="submit" className="btn btn-primary pull-right">Save Changes</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
export default VisualSetting