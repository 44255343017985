
function ImagesSec() {
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="box">
            <div className="box-header with-border">
              <div className="left">
                <h3 className="box-title">Image
                  <small className="small-title">Main post image</small>
                </h3>
              </div>
            </div>{/* /.box-header */}
            <div className="box-body">
              <div className="form-group m-0">
                <div className="row">
                  <div className="col-sm-12">
                    <div id="post_select_image_container" className="post-select-image-container">
                      <a className="btn-select-image" data-toggle="modal" data-target="#file_manager_image" data-image-type="main">
                        <div className="btn-select-image-inner">
                          <i className="icon-images" />
                          <button className="btn">Select Image</button>
                        </div>
                      </a>
                    </div>
                    <input type="hidden" name="post_image_id" id="post_image_id" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label>or&nbsp;Add Image Url</label>
                  </div>
                </div>
                <div className="row m-b-15">
                  <div className="col-sm-12 m-b-5">
                    <input type="text" className="form-control" name="image_url" id="video_thumbnail_url" placeholder="Add Image Url" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label className="control-label">Image Description</label>
                    <input type="text" className="form-control" name="image_description" placeholder="Image Description" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="box">
            <div className="box-header with-border">
              <div className="left">
                <h3 className="box-title">Additional Images                <small className="small-title">More main images (slider will be active)</small>
                </h3>
              </div>
            </div>{/* /.box-header */}
            <div className="box-body">
              <div className="form-group m-0">
                <div className="row">
                  <div className="col-sm-12">
                    <a className="btn btn-sm bg-purple" data-toggle="modal" data-target="#file_manager_image" data-image-type="additional">
                      Select Image
                    </a>
                  </div>
                </div>
              </div>
              <div className="form-group m-0">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="additional-image-list">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>                          </div>
        <div className="col-sm-12">
          <div className="box">
            <div className="box-header with-border">
              <div className="left">
                <h3 className="box-title">
                  Files				<small className="small-title">Downloadable additional files (.pdf, .docx, .zip etc..)</small>
                </h3>
              </div>
            </div>{/* /.box-header */}
            <div className="box-body">
              <div className="form-group m-0">
                <div className="row">
                  <div className="col-sm-12">
                    <a className="btn btn-sm bg-purple" data-toggle="modal" data-target="#file_manager">
                      Select File					</a>
                  </div>
                  <div className="col-sm-12 post-selected-files-container">
                    <div id="post_selected_files" className="post-selected-files">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="box">
            <div className="box-header with-border">
              <div className="left">
                <h3 className="box-title">Post Owner</h3>
              </div>
            </div>{/* /.box-header */}
            <div className="box-body">
              <div className="form-group m-0">
                <label>Post Owner</label>
                <select name="user_id" className="form-select">
                  <option value="2">abaris&nbsp;(user)</option>
                  <option value="1" >prernamedia_admin&nbsp;(admin)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="box">
            <div className="box-header with-border">
              <div className="left">
                <h3 className="box-title">Category</h3>
              </div>
            </div>{/* /.box-header */}
            <div className="box-body">
              <div className="form-group">
                <label>Language</label>
                <select name="lang_id" className="form-select" >
                  <option value="1" >English</option>
                  <option value="2">Hindi</option>
                </select>
              </div>
              <div className="form-group">
                <label className="control-label">Category</label>
                <select id="categories" name="category_id" className="form-select" required>
                  <option value>Select a category</option>
                  <option value="1">सिटी</option>
                  <option value="3">प्रदेश</option>
                  <option value="4">देश</option>
                  <option value="5" >वर्ल्ड</option>
                  <option value="6">बॉयोस्कोप</option>
                  <option value="7">खेल</option>
                  <option value="8">B&amp;E</option>
                  <option value="9">ज्ञान-विज्ञान</option>
                  <option value="10">लाइफस्टाइल</option>
                  <option value="11">आस्था</option>
                  <option value="12">कैरियर</option>
                  <option value="13">हेल्थ</option>
                  <option value="14">रसोई</option>
                  <option value="15">Business</option>
                  <option value="69">VIDEO</option>
                  <option value="70">Politics</option>
                  <option value="71">Funding</option>
                </select>

              </div>
              <div className="form-group m-0">
                <label className="control-label">Subcategory</label>
                <select id="subcategories" name="subcategory_id" className="form-select">
                  <option value="0">Select a category</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="box">
            <div className="box-header with-border">
              <div className="left">
                <h3 className="box-title">Publish</h3>
              </div>
            </div>{/* /.box-header */}
            <div className="box-body">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-7 col-sm-12 col-xs-12">
                    <label className="control-label">Scheduled Post</label>
                  </div>
                  <div className="col-md-5 col-sm-12 col-xs-12 text-right">
                    <div className="icheckbox_square-purple" style={{ position: 'relative' }}>
                      <input type="checkbox" id="any" name=""></input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-12">
                    <label>Date Published</label>
                    <div className="input-group date" id="datetimepicker">
                      <input type="date" className="form-control" name="date_published" placeholder="Date Published" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button type="submit" name="publish" className="btn btn-primary pull-right" >Save Changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ImagesSec;