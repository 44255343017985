import PendingPosts from "../../../Components/postsCompMain/pendingPosts/PendingPosts"

const pendingData = {
  title: "Drafts",
  cron: "Cron Job",
  link: "http://domain.com/cron/check-scheduled-posts",
  paragraph: "If you want to automatically publish scheduled posts, you should create a Cron Job function with this URL. For more information, please read the documentation."

}
function DraftsPage() {
  return (
    <>
      <PendingPosts val={pendingData} />
    </>
  )
}
export default DraftsPage