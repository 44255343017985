import { ImImages } from "react-icons/im"
import React, { useState } from 'react';

import Editor from "../../../commonComponents/editor/Editor"
import AddPostsAccordion from "../../../commonComponents/addpostsAccordion/AddPostsAccordion";

function AddPostsInnerVideo() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section className="content">
        <div className="row">
          <div className="col-sm-12">
            {/* form start */}
            <form action="https://prernamedia.com/post_controller/add_post_post" encType="multipart/form-data" method="post" acceptCharset="utf-8">
              <input type="hidden" name="varient_csrf_token" />
              <input type="hidden" name="post_type" />
              <div className="row">
                <div className="col-sm-12 form-header">
                  <h1 className="form-title">Add Video</h1>
                  <a href="https://prernamedia.com/admin/posts?lang_id=1" className="btn btn-success btn-add-new pull-right">
                    <i className="fa fa-bars" />
                    Posts
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-post">
                    <div className="form-post-left">
                      <div className="box">
                        <div className="box-header with-border">
                          <div className="left">
                            <h3 className="box-title">Post Details</h3>
                          </div>
                        </div>
                        <div className="box-body">

                          <div className="form-group">
                            <label className="control-label">Title</label>
                            <input type="text" id="wr_input_post_title" className="form-control" name="title" placeholder="Title" required />
                          </div>
                          <div className="form-group">
                            <label className="control-label">Slug
                              <small>(If you leave it blank, it will be generated automatically.)</small>
                            </label>
                            <input type="text" className="form-control" name="title_slug" placeholder="Slug" />
                          </div>
                          <div className="form-group">
                            <label className="control-label">Summary &amp; Description (Meta Tag)</label>
                            <textarea className="form-control text-area" name="summary" placeholder="Summary & Description (Meta Tag)" />
                          </div>
                          <div className="form-group">
                            <label className="control-label">Keywords (Meta Tag)</label>
                            <input type="text" className="form-control" name="keywords" placeholder="Keywords (Meta Tag)" />
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-4 col-xs-12">
                                <label>Visibility</label>
                              </div>
                              <div className="col-md-2 col-sm-4 col-xs-12 col-option">

                                <div className="iradio_square-purple checked" style={{ position: 'relative' }}>
                                  <input type="radio" id="rb_visibility_1" name="visibility" defaultValue={1} />
                                </div>
                                <label htmlFor="rb_visibility_1" className="cursor-pointer">Show</label>
                              </div>
                              <div className="col-md-2 col-sm-4 col-xs-12 col-option">
                                <div className="iradio_square-purple" style={{ position: 'relative' }}>
                                  <input type="radio" id="rb_visibility_2" name="visibility" defaultValue={0} />
                                </div>
                                <label htmlFor="rb_visibility_2" className="cursor-pointer">Hide</label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-4 col-xs-12">
                                <label>Show Right Column</label>
                              </div>
                              <div className="col-md-2 col-sm-4 col-xs-12 col-option">
                                <div className="iradio_square-purple checked" style={{ position: 'relative' }}>
                                  <input type="radio" name="show_right_column" defaultValue={1} id="right_column_enabled" />
                                </div>
                                <label htmlFor="right_column_enabled" className="option-label">Yes</label>
                              </div>
                              <div className="col-md-2 col-sm-4 col-xs-12 col-option">
                                <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="show_right_column" defaultValue={0} id="right_column_disabled" /></div>
                                <label htmlFor="right_column_disabled" className="option-label">No</label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4 col-sm-12">
                                <label className="control-label">Add to Featured</label>
                              </div>
                              <div className="col-md-8 col-sm-12">
                                <div className="icheckbox_square-purple" style={{ position: 'relative' }}><input type="checkbox" name="is_featured" defaultValue={1} /></div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4 col-sm-12">
                                <label className="control-label">Add to Breaking</label>
                              </div>
                              <div className="col-md-8 col-sm-12">
                                <div className="icheckbox_square-purple" style={{ position: 'relative' }}><input type="checkbox" name="is_breaking" defaultValue={1} /></div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4 col-sm-12">
                                <label className="control-label">Add to Slider</label>
                              </div>
                              <div className="col-md-8 col-sm-12">
                                <div className="icheckbox_square-purple" style={{ position: 'relative' }}><input type="checkbox" name="is_slider" defaultValue={1} /></div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4 col-sm-12">
                                <label className="control-label">Add to Recommended</label>
                              </div>
                              <div className="col-md-8 col-sm-12">
                                <div className="icheckbox_square-purple" style={{ position: 'relative' }}><input type="checkbox" name="is_recommended" defaultValue={1} /></div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-4 col-sm-12">
                                <label className="control-label">Show Only to Registered Users</label>
                              </div>
                              <div className="col-md-8 col-sm-12">
                                <div className="icheckbox_square-purple" style={{ position: 'relative' }}><input type="checkbox" name="need_auth" defaultValue={1} className="square-purple" /></div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-12">
                                <label className="control-label">Tags</label>
                                <input id="tags_1" type="text" name="tags" className="form-control tags" data-tagsinput-init="true" style={{ display: 'none' }} /><div id="tags_1_tagsinput" className="tagsinput" style={{ width: 'auto', minHeight: 80, height: 80 }}><div id="tags_1_addTag"><input id="tags_1_tag" defaultValue data-default="add a tag" style={{ color: 'rgb(102, 102, 102)', width: 68 }} /></div><div className="tags_clear" /></div>
                                <small>(Type tag and hit enter)</small>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row-optional-url">
                            <div className="row">
                              <div className="col-sm-12">
                                <label className="control-label">Optional URL</label>
                                <input type="text" className="form-control" name="optional_url" placeholder="Optional URL" defaultValue />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <label className="control-label control-label-content">Content</label>
                          <div id="main_editor">
                            <Editor />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-post-right">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="nav-tabs-custom video-upload-tab">
                                <ul className="nav nav-tabs">
                                  <li className="active"><a href="#tab_1" data-toggle="tab" aria-expanded="false">Get Video from URL</a></li>
                                  <li className><a href="#tab_2" data-toggle="tab" aria-expanded="true">Upload Video</a></li>
                                </ul>
                                <div className="tab-content settings-tab-content">
                                  <div className="tab-pane active" id="tab_1">
                                    <div className="form-group">
                                      <label className="control-label">Video URL                                <small>(Youtube, Vimeo, Dailymotion, Facebook)</small>
                                      </label>
                                      <input type="text" className="form-control" name="video_url" id="video_url" placeholder="Video URL" />
                                      <a href="javascript:void(0)" className="btn btn-sm btn-info pull-right btn-get-embed" onclick="get_video_from_url();">Get Video</a>
                                    </div>
                                    <div className="form-group">
                                      <label className="control-label video-embed-lbl">Video Embed Code</label>
                                      <textarea className="form-control text-embed" name="video_embed_code" id="video_embed_code" placeholder="Video Embed Code" defaultValue={""} />
                                    </div>
                                    <iframe src id="video_embed_preview" frameBorder={0} allow="encrypted-media" allowFullScreen className="video-embed-preview" />
                                  </div>
                                  <div className="tab-pane" id="tab_2">
                                    <div className="row">
                                      <div className="col-sm-12">
                                        <a className="btn btn-sm bg-purple" data-toggle="modal" data-target="#file_manager_video">
                                          Select Video
                                        </a>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div id="post_selected_video" className="col-sm-12" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <div className="box">
                            <div className="box-header with-border">
                              <div className="left">
                                <h3 className="box-title">Video Thumbnail
                                  <small className="small-title">Image for video</small>
                                </h3>
                              </div>
                            </div>{/* /.box-header */}
                            <div className="box-body">
                              <div className="form-group m-0">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div id="post_select_image_container" className="post-select-image-container">
                                      <a className="btn-select-image" data-toggle="modal" data-target="#file_manager_image" data-image-type="main">
                                        <div className="btn-select-image-inner">
                                          <ImImages className="iconss images" />
                                          <button className="btn">Select Image</button>
                                        </div>
                                      </a>
                                    </div>
                                    <input type="hidden" name="post_image_id" id="post_image_id" />
                                  </div>
                                </div>
                                <div className="row m-b-15">
                                  <div className="col-sm-12">
                                    <label>or&nbsp;Add Image Url</label>
                                  </div>
                                  <div className="col-sm-12 m-b-5">
                                    <input type="text" className="form-control" name="image_url" id="video_thumbnail_url" placeholder="Add Image Url" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <div className="box">
                            <div className="box-header with-border">
                              <div className="left">
                                <h3 className="box-title">
                                  Files				<small className="small-title">Downloadable additional files (.pdf, .docx, .zip etc..)</small>
                                </h3>
                              </div>
                            </div>{/* /.box-header */}
                            <div className="box-body">
                              <div className="form-group m-0">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <a className="btn btn-sm bg-purple" data-toggle="modal" data-target="#file_manager">
                                      Select File					</a>
                                  </div>
                                  <div className="col-sm-12 post-selected-files-container">
                                    <div id="post_selected_files" className="post-selected-files">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="box">
                            <div className="box-header with-border">
                              <div className="left">
                                <h3 className="box-title">Category</h3>
                              </div>
                            </div>{/* /.box-header */}
                            <div className="box-body">
                              <div className="form-group">
                                <label>Language</label>
                                <select name="lang_id" className="form-select" >
                                  <option value={1}>English</option>
                                  <option value={2}>Hindi</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label className="control-label">Category</label>
                                <select id="categories" name="category_id" className="form-select" required>
                                  <option value>Select a category</option>
                                  <option value={1}>सिटी</option>
                                  <option value={3}>प्रदेश</option>
                                  <option value={4}>देश</option>
                                  <option value={5}>वर्ल्ड</option>
                                  <option value={6}>बॉयोस्कोप</option>
                                  <option value={7}>खेल</option>
                                  <option value={8}>B&amp;E</option>
                                  <option value={9}>ज्ञान-विज्ञान</option>
                                  <option value={10}>लाइफस्टाइल</option>
                                  <option value={11}>आस्था</option>
                                  <option value={12}>कैरियर</option>
                                  <option value={13}>हेल्थ</option>
                                  <option value={14}>रसोई</option>
                                  <option value={15}>Business</option>
                                  <option value={69}>VIDEO</option>
                                  <option value={70}>Politics</option>
                                  <option value={71}>Funding</option>
                                </select>
                              </div>
                              <div className="form-group m-0">
                                <label className="control-label">Subcategory</label>
                                <select id="subcategories" name="subcategory_id" className="form-select">
                                  <option value={0}>Select a category</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="box">
                            <div className="box-header with-border">
                              <div className="left">
                                <h3 className="box-title">Publish</h3>
                              </div>
                            </div>{/* /.box-header */}
                            <div className="box-body">
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-md-7 col-sm-12 col-xs-12">
                                    <label className="control-label">Scheduled Post</label>
                                  </div>
                                  <div className="col-md-5 col-sm-12 col-xs-12 text-right">
                                    <div className="icheckbox_square-purple" style={{ position: 'relative' }}><input type="checkbox" name="scheduled_post" defaultValue={1} id="cb_scheduled" /></div>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <label>Date Published</label>
                                    <div className="input-group date" id="datetimepicker">
                                      <input type="date" className="date_year" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <button type="submit" name="status" value={1} className="btn btn-primary pull-right" >Submit</button>
                                <button type="submit" name="status" value={0} className="btn btn-warning btn-draft pull-right" >Save as Draft</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>{/* form end */}
          </div>
        </div>
        <link rel="stylesheet" href="https://prernamedia.com/assets/admin/plugins/file-uploader/css/jquery.dm-uploader.min.css" />
        <link rel="stylesheet" href="https://prernamedia.com/assets/admin/plugins/file-uploader/css/styles.css" />
        <link rel="stylesheet" href="https://prernamedia.com/assets/admin/plugins/file-manager/fileicon.css" />
        {/* Modal */}
        <div id="file_manager_image" className="modal fade modal-file-manager" role="dialog">
          <div className="modal-dialog modal-lg">
            {/* Modal content*/}
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">×</button>
                <h4 className="modal-title">Images</h4>
                <div className="file-manager-search">
                  <input type="text" id="input_search_image" className="form-select" placeholder="Search" />
                </div>
              </div>
              <div className="modal-body">
                <div className="file-manager">
                  <div className="file-manager-left">
                    <div className="dm-uploader-container">
                      <div id="drag-and-drop-zone-image" className="dm-uploader text-center">
                        <p className="file-manager-file-types">
                          <span>JPG</span>
                          <span>JPEG</span>
                          <span>PNG</span>
                          <span>GIF</span>
                        </p>
                        <p className="dm-upload-icon">
                          <i className="fa fa-cloud-upload" />
                        </p>
                        <p className="dm-upload-text">Drag and drop files here or</p>
                        <p className="text-center">
                          <button className="btn btn-default btn-browse-files">Browse Files</button>
                        </p>
                        <a className="btn btn-md dm-btn-select-files">
                          <input type="file" name="file" size={40} multiple />
                        </a>
                        <ul className="dm-uploaded-files" id="files-image" />
                        <button type="button" id="btn_reset_upload_image" className="btn btn-reset-upload">Reset</button>
                      </div>
                    </div>
                  </div>
                  <div className="file-manager-right">
                    <div className="file-manager-content">
                      <div className="col-sm-12">
                        <div className="row">
                          <div id="image_file_upload_response" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <input type="hidden" id="selected_img_file_id" />
                  <input type="hidden" id="selected_img_mid_file_path" />
                  <input type="hidden" id="selected_img_default_file_path" />
                  <input type="hidden" id="selected_img_slider_file_path" />
                  <input type="hidden" id="selected_img_big_file_path" />
                </div>
              </div>
              <div className="modal-footer">
                <div className="file-manager-footer">
                  <button type="button" id="btn_img_delete" className="btn btn-danger pull-left btn-file-delete"><i className="fa fa-trash" />&nbsp;&nbsp;Delete</button>
                  <button type="button" id="btn_img_select" className="btn bg-olive btn-file-select"><i className="fa fa-check" />&nbsp;&nbsp;Select Image</button>
                  <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* File item template */}
        {/* Modal */}
        <div id="file_manager" className="modal fade modal-file-manager" role="dialog">
          <div className="modal-dialog modal-lg">
            {/* Modal content*/}
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">×</button>
                <h4 className="modal-title">Files</h4>
                <div className="file-manager-search">
                  <input type="text" id="input_search_file" className="form-select" placeholder="Search" />
                </div>
              </div>
              <div className="modal-body">
                <div className="file-manager">
                  <div className="file-manager-left">
                    <div className="dm-uploader-container">
                      <div id="drag-and-drop-zone" className="dm-uploader text-center">
                        <p className="dm-upload-icon">
                          <i className="fa fa-cloud-upload" />
                        </p>
                        <p className="dm-upload-text">Drag and drop files here or</p>
                        <p className="text-center">
                          <button className="btn btn-default btn-browse-files">Browse Files</button>
                        </p>
                        <a className="btn btn-md dm-btn-select-files">
                          <input type="file" name="file" size={40} multiple />
                        </a>
                        <ul className="dm-uploaded-files" id="files-file" />
                        <button type="button" id="btn_reset_upload" className="btn btn-reset-upload">Reset</button>
                      </div>
                    </div>
                  </div>
                  <div className="file-manager-right">
                    <div className="file-manager-content">
                      <div className="col-sm-12">
                        <div className="row">
                          <div id="file_upload_response">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input type="hidden" id="selected_file_id" />
                  <input type="hidden" id="selected_file_name" />
                  <input type="hidden" id="selected_file_path" />
                </div>
              </div>
              <div className="modal-footer">
                <div className="file-manager-footer">
                  <button type="button" id="btn_file_delete" className="btn btn-danger pull-left btn-file-delete"><i className="fa fa-trash" />&nbsp;&nbsp;Delete</button>
                  <button type="button" id="btn_file_select" className="btn bg-olive btn-file-select"><i className="fa fa-check" />&nbsp;&nbsp;Select File</button>
                  <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default AddPostsInnerVideo;