import ImagesSec from "./imagesSec/ImagesSec";
import UpdateArticle from "./updateArticle/UpdateArticle";

function PostEdit() {
  return (
    <>
      <section className="content">
        <div className="row">
          <div className="col-sm-12">
            {/* form start */}
            <form action="https://prernamedia.com/post_controller/update_post_post" encType="multipart/form-data" method="post" acceptCharset="utf-8">
              <input type="hidden" name="varient_csrf_token" defaultValue="c24e298065f3194ce0cc5bcc4d91d1e3" />
              <input type="hidden" name="post_type" defaultValue="article" />
              <div className="row">
                <div className="col-sm-12 form-header">
                  <h1 className="form-title">Update Article</h1>
                  <a href="https://prernamedia.com/admin/posts" className="btn btn-success btn-add-new pull-right">
                    <i className="fa fa-bars" />
                    Posts              </a>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-post">
                    <div className="form-post-left">
                      <UpdateArticle />
                    </div>
                    <div className="form-post-right">
                      <ImagesSec />
                    </div>
                  </div>
                </div>
              </div>
            </form>{/* form end */}
          </div>
        </div>
        <link rel="stylesheet" href="https://prernamedia.com/assets/admin/plugins/file-uploader/css/jquery.dm-uploader.min.css" />
        <link rel="stylesheet" href="https://prernamedia.com/assets/admin/plugins/file-uploader/css/styles.css" />
        <link rel="stylesheet" href="https://prernamedia.com/assets/admin/plugins/file-manager/fileicon.css" />
        {/* Modal */}
        <div id="file_manager_image" className="modal fade modal-file-manager" role="dialog">
          <div className="modal-dialog modal-lg">
            {/* Modal content*/}
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">×</button>
                <h4 className="modal-title">Images</h4>
                <div className="file-manager-search">
                  <input type="text" id="input_search_image" className="form-control" placeholder="Search" />
                </div>
              </div>
              <div className="modal-body">
                <div className="file-manager">
                  <div className="file-manager-left">
                    <div className="dm-uploader-container">
                      <div id="drag-and-drop-zone-image" className="dm-uploader text-center">
                        <p className="file-manager-file-types">
                          <span>JPG</span>
                          <span>JPEG</span>
                          <span>PNG</span>
                          <span>GIF</span>
                        </p>
                        <p className="dm-upload-icon">
                          <i className="fa fa-cloud-upload" />
                        </p>
                        <p className="dm-upload-text">Drag and drop files here or</p>
                        <p className="text-center">
                          <button className="btn btn-default btn-browse-files">Browse Files</button>
                        </p>
                        <a className="btn btn-md dm-btn-select-files">
                          <input type="file" name="file" size={40} multiple />
                        </a>
                        <ul className="dm-uploaded-files" id="files-image" />
                        <button type="button" id="btn_reset_upload_image" className="btn btn-reset-upload">Reset</button>
                      </div>
                    </div>
                  </div>
                  <div className="file-manager-right">
                    <div className="file-manager-content">
                      <div className="col-sm-12">
                        <div className="row">
                          <div id="image_file_upload_response" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <input type="hidden" id="selected_img_file_id" />
                  <input type="hidden" id="selected_img_mid_file_path" />
                  <input type="hidden" id="selected_img_default_file_path" />
                  <input type="hidden" id="selected_img_slider_file_path" />
                  <input type="hidden" id="selected_img_big_file_path" />
                </div>
              </div>
              <div className="modal-footer">
                <div className="file-manager-footer">
                  <button type="button" id="btn_img_delete" className="btn btn-danger pull-left btn-file-delete"><i className="fa fa-trash" />&nbsp;&nbsp;Delete</button>
                  <button type="button" id="btn_img_select" className="btn bg-olive btn-file-select"><i className="fa fa-check" />&nbsp;&nbsp;Select Image</button>
                  <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
export default PostEdit;