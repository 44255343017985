

import AddPostsArticle from "./addPostsArticle/AddPostsArticle"
import AddPostsGallery from "./addPostsGallery/AddPostsGallery"
import AddPostsSortedList from "./addPostsSortedList/AddPostsSortedList"
import AddPostsTriviaQuiz from "./addpostsTriviaQuiz/AddPostsTriviaQuiz"
import AddPostsPersonalityQuiz from "./addPostsPersonalityQuiz/AddPostsPersonalityQuiz"
import AddPostsVideo from "./addPostsVideo/AddPostsVideo"
import AddPostsAudio from "./addPostsAudio/AddPostsAudio"
function Addposts() {
  return (
    <>
      <section className="content">
        <div className="row">
          <div className="col-sm-12 form-header">
            <a href="https://prernamedia.com/admin/posts?lang_id=1" className="btn btn-success btn-add-new pull-right">
              <i className="fa fa-bars" />
              Posts      </a>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 form-header">
            <h1 className="form-title form-title-post-format">Choose a Post Format</h1>
          </div>
        </div>

        <div className="col-sm-12 post-format-container">
          <AddPostsArticle />
          <AddPostsGallery/>
          <AddPostsSortedList/>
          <AddPostsTriviaQuiz/>
          <AddPostsPersonalityQuiz/>
          <AddPostsVideo/>
          <AddPostsAudio/>
        </div>
      </section>
    </>
  )
}
export default Addposts