import { TfiFile } from "react-icons/tfi"
import { Link } from "react-router-dom";
function AddPostsArticle() {
  return (
    <>
      <div className="col-xs-12 col-sm-4 col-add-post">
        <Link to="/admin/add-post-article">
          <div className="item">
            <div className="item-icon">
              < TfiFile className="iconss article" />
            </div>
            <h5 className="title">Article</h5>
            <p className="desc">An article with images and embed videos</p>
          </div>
        </Link>
      </div>
    </>
  )
}
export default AddPostsArticle;