import { Outlet } from "react-router-dom";
import Aside from "../../Components/commonComponents/aside/Aside";
import Footer from "../../Components/commonComponents/footer/Footer";
import Header from "../../Components/commonComponents/header/Header";

function AdminPage() {
  
  return (
    <>
      <div className="wrapper" style={{ height: "auto", minHeight: "100%" }}>
        <Aside />
        <Header />
        <div className="content-wrapper">
          <Outlet></Outlet>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default AdminPage;
