import GalleryCategories from "../../../Components/gallery/galleryCategories/GalleryCategories"

function GalleryCategoriesPage() {
    return(
        <>
        <GalleryCategories/>
        </>
    )
}
export default GalleryCategoriesPage
