import { Link } from "react-router-dom"


function RolePermission() {
    return (
        <>
            <section className="content">
                <div className="box">
                    <div className="box-header with-border">
                        <div className="left">
                            <h3 className="box-title">Roles &amp; Permissions</h3>
                        </div>
                    </div>{/* /.box-header */}
                    <div className="box-body">
                        <div className="row">
                            {/* include message block */}
                            <div className="col-sm-12">
                                {/*print error messages*/}
                                {/*print custom error message*/}
                                {/*print custom success message*/}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped" role="grid">
                                        <thead>
                                            <tr role="row">
                                                <th width={20}>Role</th>
                                                <th>Permissions</th>
                                                <th style={{ width: 180 }}>Options</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><strong style={{ fontWeight: 600 }}>Admin</strong></td>
                                                <td style={{ height: 50 }}>
                                                    <label className="label label-default">All Permissions</label>
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong style={{ fontWeight: 600 }}>Moderator</strong></td>
                                                <td style={{ height: 50 }}>
                                                    <label className="label label-default">Control Panel</label>
                                                    <label className="label label-default">Add Post</label>
                                                    <label className="label label-default">Manage All Posts</label>
                                                    <label className="label label-default">Navigation</label>
                                                    <label className="label label-default">Pages</label>
                                                    <label className="label label-default">RSS Feeds</label>
                                                    <label className="label label-default">Categories</label>
                                                    <label className="label label-default">Widgets</label>
                                                    <label className="label label-default">Polls</label>
                                                    <label className="label label-default">Gallery</label>
                                                    <label className="label label-default">Comments, Contact Messages</label>
                                                    <label className="label label-default">Newsletter</label>
                                                    <label className="label label-default">Ad Spaces</label>
                                                    <label className="label label-default">Users</label>
                                                    <label className="label label-default">SEO Tools</label>
                                                </td>
                                                <td>
                                                    <Link to="/admin/edit-role" className="btn btn-success"><i className="fa fa-edit" />&nbsp;&nbsp;Edit</Link>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong style={{ fontWeight: 600 }}>Author</strong></td>
                                                <td style={{ height: 50 }}>
                                                    <label className="label label-default">Control Panel</label>
                                                    <label className="label label-default">Add Post</label>
                                                </td>
                                                <td>
                                                    <a href="https://prernamedia.com/admin/edit-role/3" className="btn btn-success"><i className="fa fa-edit" />&nbsp;&nbsp;Edit</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong style={{ fontWeight: 600 }}>User</strong></td>
                                                <td style={{ height: 50 }}>
                                                    <label className="label label-default">Control Panel</label>
                                                    <label className="label label-default">Add Post</label>
                                                </td>
                                                <td>
                                                    <a href="https://prernamedia.com/admin/edit-role/4" className="btn btn-success"><i className="fa fa-edit" />&nbsp;&nbsp;Edit</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>{/* /.box-body */}
                </div>
                <style dangerouslySetInnerHTML={{ __html: "\n    td label {\n        float: left;\n        margin-right: 10px;\n        margin-bottom: 10px !important;\n        padding: .3em .7em .4em !important;\n    }\n" }} />
            </section>

        </>
    )
}
export default RolePermission