import AddUsers from "../../../Components/users/addUsers/AddUsers"


function AddUsersPage() {
    return (
        <>
        <AddUsers/>
        </>
    )
}
export default AddUsersPage