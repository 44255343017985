import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Editor from "../../Components/commonComponents/editor/Editor"

function GeneralSettings() {
  return (
    <>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            {/* form start */}
            <form action="https://prernamedia.com/admin_controller/settings_post" encType="multipart/form-data" method="post" acceptCharset="utf-8">
              <input type="hidden" name="varient_csrf_token" defaultValue="0cc2888ef16566e3e312875393a0aeb2" />
              <div className="form-group">
                <label>Settings Language</label>
                <select name="lang_id" className="form-select max-400" onchange="window.location.href = 'https://prernamedia.com/admin/'+'settings?lang='+this.value;">
                  <option value={1} selected>English</option>
                  <option value={2}>Hindi</option>
                </select>
              </div>
              {/* Custom Tabs */}

              <div className="nav-tabs-custom">
                <Tabs
                  defaultActiveKey="profile"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="General Settings" title="General Settings">
                    <div className="tab-pane active" id="tab_1">
                      <div className="form-group">
                        <label className="control-label">Timezone</label>
                        <input type="text" className="form-control" name="timezone" placeholder="Timezone" defaultValue="Asia/Kolkata" />
                        <a href="http://php.net/manual/en/timezones.php" target="_blank">Timeszones</a>
                      </div>
                      <div className="form-group">
                        <label className="control-label">Application Name</label>
                        <input type="text" className="form-control" name="application_name" placeholder="Application Name" defaultValue="Prerna News India" />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Footer About Section</label>
                        <textarea className="form-control text-area" name="about_footer" placeholder="Footer About Section" style={{ minHeight: 140 }} defaultValue={"Prerna News India"} />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Post Optional URL Button Name</label>
                        <input type="text" className="form-control" name="optional_url_button_name" placeholder="Post Optional URL Button Name" defaultValue="Click Here To See More" />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Copyright</label>
                        <input type="text" className="form-control" name="copyright" placeholder="Copyright" defaultValue="Copyright © 2020 Prerna News India | All Rights Reserved. Powered By: Abaris Softech" />
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Contact Settings" title="Contact Settings">
                    <div className="form-group">
                      <label className="control-label">Address</label>
                      <input type="text" className="form-control" name="contact_address" placeholder="Address" defaultValue="B-23, 3rd Floor Sector 2, Noida, Uttar Pradesh - 201301" />
                    </div>
                    <div className="form-group">
                      <label className="control-label">Email</label>
                      <input type="text" className="form-control" name="contact_email" placeholder="Email" defaultValue="PrernaNewsIndia@gmail.com" />
                    </div>
                    <div className="form-group">
                      <label className="control-label">Phone</label>
                      <input type="text" className="form-control" name="contact_phone" placeholder="Phone" defaultValue="+91-120-4333097, +91-9953264567" />
                    </div>

                    <Editor />
                  </Tab>
                  <Tab eventKey="Social Media Settings" title="Social Media Settings">
                    <div className="tab-pane" id="tab_4">
                      <div className="form-group">
                        <label className="control-label">Facebook URL</label>
                        <input type="text" className="form-control" name="facebook_url" placeholder="Facebook URL" defaultValue="https://www.facebook.com/profile.php?id=100090786447597" />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Twitter URL</label>
                        <input type="text" className="form-control" name="twitter_url" placeholder="Twitter URL" defaultValue="https://twitter.com/PrernaNewsIndia" />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Instagram URL</label>
                        <input type="text" className="form-control" name="instagram_url" placeholder="Instagram URL" defaultValue="https://www.instagram.com/prernamedianewsindia/" />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Pinterest URL</label>
                        <input type="text" className="form-control" name="pinterest_url" placeholder="Pinterest URL" defaultValue />
                      </div>
                      <div className="form-group">
                        <label className="control-label">LinkedIn URL</label>
                        <input type="text" className="form-control" name="linkedin_url" placeholder="LinkedIn URL" defaultValue="https://www.linkedin.com/in/city-newsindia-846828203" />
                      </div>
                      <div className="form-group">
                        <label className="control-label">VK URL</label>
                        <input type="text" className="form-control" name="vk_url" placeholder="VK URL" defaultValue />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Telegram URL</label>
                        <input type="text" className="form-control" name="telegram_url" placeholder="Telegram URL" defaultValue="https://www.tumblr.com/blog/citynewsindia" />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Youtube URL</label>
                        <input type="text" className="form-control" name="youtube_url" placeholder="Youtube URL" defaultValue />
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Facebook Comments" title="Facebook Comments">
                    <div className="tab-pane" id="tab_5">
                      <div className="form-group">
                        <label className="control-label">Facebook Comments Plugin Code</label>
                        <textarea className="form-control text-area" name="facebook_comment" placeholder="Facebook Comments Plugin Code" style={{ minHeight: 140 }} defaultValue={""} />
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Cookies Warning" title="Cookies Warning">
                    <div className="tab-pane" id="tab_6">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-3 col-xs-12 col-option">
                            <label>Show Cookies Warning</label>
                          </div>
                          <div className="col-md-2 col-sm-4 col-xs-12 col-option">
                            <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="cookies_warning" defaultValue={1} id="cookies_warning_1" /></div>
                            <label htmlFor="cookies_warning_1" className="option-label">Yes</label>
                          </div>
                          <div className="col-md-2 col-sm-4 col-xs-12 col-option">
                            <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="cookies_warning" defaultValue={0} id="cookies_warning_2" /></div>
                            <label htmlFor="cookies_warning_2" className="option-label">No</label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="control-label" />
                        <Editor />
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Custom CSS Codes" title="Custom CSS Codes">
                    <div className="tab-pane" id="tab_7">
                      <div className="form-group">
                        <label className="control-label">Custom CSS Codes</label>&nbsp;<small className="small-title-inline">(These codes will be added to the header of the site.)</small>
                        <textarea className="form-control text-area" name="custom_css_codes" placeholder="Custom CSS Codes" style={{ minHeight: 200 }} defaultValue={""} />
                      </div>
                      E.g. &lt;style&gt; body {'{'}background - color: #00a65a;{'}'} &lt;/style&gt;
                    </div>
                  </Tab>
                  <Tab eventKey="Custom JavaScript Codes" title="Custom JavaScript Codes">
                    <div className="tab-pane" id="tab_8">
                      <div className="form-group">
                        <label className="control-label">Custom JavaScript Codes</label>&nbsp;<small className="small-title-inline">(These codes will be added to the footer of the site.)</small>
                        <textarea className="form-control text-area" name="custom_javascript_codes" placeholder="Custom JavaScript Codes" style={{ minHeight: 200 }} defaultValue={""} />
                      </div>
                      E.g. &lt;script&gt; alert('Hello!'); &lt;/script&gt;
                    </div>
                  </Tab>
                </Tabs>
                <div className="box-footer">
                  <button type="submit" className="btn btn-primary pull-right">Save Changes</button>
                </div>
              </div>

            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Google reCAPTCHA</h3>
              </div>
              <form action="https://prernamedia.com/admin_controller/recaptcha_settings_post" method="post" acceptCharset="utf-8">
                <input type="hidden" name="varient_csrf_token" defaultValue="0cc2888ef16566e3e312875393a0aeb2" />
                <div className="box-body">
                  {/* include message block */}
                  <div className="form-group">
                    <label className="control-label">Site Key</label>
                    <input type="text" className="form-control" name="recaptcha_site_key" placeholder="Site Key" defaultValue />
                  </div>
                  <div className="form-group">
                    <label className="control-label">Secret Key</label>
                    <input type="text" className="form-control" name="recaptcha_secret_key" placeholder="Secret Key" defaultValue />
                  </div>
                  <div className="form-group">
                    <label className="control-label">Language</label>
                    <input type="text" className="form-control" name="recaptcha_lang" placeholder="Language" defaultValue="en" />
                    <a href="https://developers.google.com/recaptcha/docs/language" target="_blank">https://developers.google.com/recaptcha/docs/language</a>
                  </div>
                  {/* /.box-body */}
                  <div className="box-footer" style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <button type="submit" className="btn btn-primary pull-right">Save Changes</button>
                  </div>
                  {/* /.box-footer */}
                  {/* form end */}
                </div></form>
              {/* /.box */}
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Maintenance Mode</h3>
              </div>
              {/* /.box-header */}
              {/* form start */}
              <form action="https://prernamedia.com/admin_controller/maintenance_mode_post" method="post" acceptCharset="utf-8">
                <input type="hidden" name="varient_csrf_token" defaultValue="0cc2888ef16566e3e312875393a0aeb2" />
                <div className="box-body">
                  {/* include message block */}
                  <div className="form-group">
                    <label className="control-label">Title</label>
                    <input type="text" className="form-control" name="maintenance_mode_title" placeholder="Title" defaultValue="Coming Soon!" />
                  </div>
                  <div className="form-group">
                    <label className="control-label">Description</label>
                    <textarea className="form-control text-area" name="maintenance_mode_description" placeholder="Description" style={{ minHeight: 100 }} defaultValue={"Our website is under construction. We'll be here soon with our new awesome site."} />
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-4 col-xs-12">
                        <label>Status</label>
                      </div>
                      <div className="col-sm-4 col-xs-12 col-option">
                        <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="maintenance_mode_status" defaultValue={1} id="maintenance_mode_status_1" /></div>
                        <label htmlFor="maintenance_mode_status_1" className="option-label">Enable</label>
                      </div>
                      <div className="col-sm-4 col-xs-12 col-option">
                        <div className="iradio_square-purple checked" style={{ position: 'relative' }}>
                          <input type="radio" name="maintenance_mode_status" defaultValue={0} id="maintenance_mode_status_2"  />
                        </div>
                        <label htmlFor="maintenance_mode_status_2" className="option-label">Disable</label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Image</label>: assets/img/maintenance_bg.jpg
                  </div>
                </div>
                {/* /.box-body */}
                <div className="box-footer">
                  <button type="submit" className="btn btn-primary pull-right">Save Changes</button>
                </div>
                {/* /.box-footer */}
                {/* /.box */}
              </form>{/* form end */}
            </div>
          </div>
        </div>
        <style dangerouslySetInnerHTML={{ __html: "\n          .tox-tinymce {\n            height: 340px !important;\n    }\n        " }} />
      </section>

    </>
  )
}
export default GeneralSettings