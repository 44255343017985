import PendingPosts from "../../../Components/postsCompMain/pendingPosts/PendingPosts"

const pendingData = {
  title: "Scheduled Posts",
}
function ScheduledPostsPage() {
  return (
    <>
      <PendingPosts val={pendingData}/>
    </>
  )
}
export default ScheduledPostsPage