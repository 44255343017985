import { useState } from "react";
import { Button, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"

function Polls() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <section className="content">
                <div className="box">
                    <div className="box-header with-border">
                        <div className="left">
                            <h3 className="box-title">Polls</h3>
                        </div>
                        <div className="right">
                            <Link to="/admin/add-poll" className="btn btn-success btn-add-new">
                                <i className="fa fa-plus" />
                                Add Poll          </Link>
                        </div>
                    </div>{/* /.box-header */}
                    <div className="box-body">
                        <div className="row">
                            {/* include message block */}
                            <div className="col-sm-12">
                                {/*print error messages*/}
                                {/*print custom error message*/}
                                {/*print custom success message*/}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    <div id="cs_datatable_lang_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer"><div className="dataTables_length" id="cs_datatable_lang_length"><label>Show <select name="cs_datatable_lang_length" aria-controls="cs_datatable_lang" className="form-control input-sm"><option value={15}>15</option><option value={30}>30</option><option value={60}>60</option><option value={100}>100</option></select></label></div><div id="table_dropdown"><label className="table-label">Language</label><select className="form-control input-sm"><option value>All</option><option value="English">English</option><option value="Hindi">Hindi</option></select></div><div id="cs_datatable_lang_filter" className="dataTables_filter"><label>Search<input type="search" className="form-control input-sm" placeholder aria-controls="cs_datatable_lang" /></label></div><table className="table table-bordered table-striped dataTable no-footer" id="cs_datatable_lang" role="grid" aria-describedby="cs_datatable_lang_info">
                                        <thead>
                                            <tr role="row"><th width={20} className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Id: activate to sort column ascending" style={{ width: 20 }}>Id</th><th className="sorting_asc" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Question: activate to sort column descending" style={{ width: '445.5px' }} aria-sort="ascending">Question</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Language: activate to sort column ascending" style={{ width: '69.8625px' }}>Language</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Vote Permission: activate to sort column ascending" style={{ width: '111.912px' }}>Vote Permission</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Status: activate to sort column ascending" style={{ width: '46.875px' }}>Status</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Date Added: activate to sort column ascending" style={{ width: '100.688px' }}>Date Added</th><th className="max-width-120 sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Options: activate to sort column ascending" style={{ width: '99.5625px' }}>Options</th></tr>
                                        </thead>
                                        <tbody>
                                            <tr role="row" className="odd">
                                                <td className>2</td>
                                                <td className="break-word sorting_1">Who is the Best Overseas Admission Consultant In India ?&nbsp;&nbsp;&nbsp;
                                                    <button className="btn btn-info btn-xs" data-toggle="modal" data-target="#pollModal2">View Results                                  </button>
                                                </td>
                                                <td>
                                                    English                              </td>
                                                <td>
                                                    All Users Can Vote                                                                  </td>
                                                <td>
                                                    <label className="label label-success">Active</label>
                                                </td>
                                                <td>2021-01-08 / 15:22</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                            
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <Link to="/admin/update-poll"><i className="fa fa-edit option-icon" />Edit</Link>
                                                            </li>
                                                            <li>
                                                                {/* <a href="javascript:void(0)" onclick="delete_item('poll_controller/delete_poll_post','2','Are you sure you want to delete this poll?');"><i className="fa fa-trash option-icon" />Delete</a> */}
                                                                <button onClick={handleShow}><i className="fa fa-trash option-icon" />Delete</button>
                                                                <Modal show={show} onHide={handleClose}>
                                                                    <Modal.Header closeButton>
                                                                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                                                                    </Modal.Header>
                                                                    <Modal.Body>Are you sure you want to delete this poll?</Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="secondary" onClick={handleClose}>
                                                                            Close
                                                                        </Button>
                                                                        <Button variant="danger" onClick={handleClose}>
                                                                            Ok
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr></tbody>
                                    </table><div className="dataTables_info" id="cs_datatable_lang_info" role="status" aria-live="polite">Showing 1 to 1 of 1 entries</div><div className="dataTables_paginate paging_simple_numbers" id="cs_datatable_lang_paginate"><ul className="pagination"><li className="paginate_button previous disabled" id="cs_datatable_lang_previous"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={0} tabIndex={0}>‹</a></li><li className="paginate_button active"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={1} tabIndex={0}>1</a></li><li className="paginate_button next disabled" id="cs_datatable_lang_next"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={2} tabIndex={0}>›</a></li></ul></div></div>
                                </div>
                            </div>
                        </div>
                    </div>{/* /.box-body */}
                </div>
                {/* Modal */}
                <div id="pollModal2" className="modal fade" role="dialog" style={{ display: 'none' }}>
                    <div className="modal-dialog">
                        {/* Modal content*/}
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">×</button>
                                <h4 className="modal-title">Who is the Best Overseas Admission Consultant In India ?</h4>
                            </div>
                            <div className="modal-body">
                                <div className="poll">
                                    <div className="result">
                                        <p className="total-vote text-center"><strong>Total Vote: 6</strong></p>
                                        <span>Admission Waala</span>
                                        <div className="progress">
                                            <span>16.7&nbsp;%</span>
                                            <div className="progress-bar progress-bar-success" role="progressbar" aria-valuenow={6} aria-valuemin={0} aria-valuemax={100} style={{ width: '16.7%' }} />
                                        </div>
                                        <span>www.admissionwaala.com</span>
                                        <div className="progress">
                                            <span>83.3&nbsp;%</span>
                                            <div className="progress-bar progress-bar-success" role="progressbar" aria-valuenow={6} aria-valuemin={0} aria-valuemax={100} style={{ width: '83.3%' }} />
                                        </div>
                                        <span>AV People Kart Pvt. Ltd.</span>
                                        <div className="progress">
                                            <span>0&nbsp;%</span>
                                            <div className="progress-bar progress-bar-0" role="progressbar" aria-valuenow={6} aria-valuemin={0} aria-valuemax={100} style={{ width: '0%' }} />
                                        </div>
                                        <span>www.avpeoplekart.com</span>
                                        <div className="progress">
                                            <span>0&nbsp;%</span>
                                            <div className="progress-bar progress-bar-0" role="progressbar" aria-valuenow={6} aria-valuemin={0} aria-valuemax={100} style={{ width: '0%' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            </div></div></div></div></section>





        </>
    )
}
export default Polls