
function UpdateAlbum() {
    return (
        <>
            <section className="content">
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <div className="box box-primary">
                            <div className="box-header with-border">
                                <h3 className="box-title">Update Album</h3>
                            </div>
                            {/* /.box-header */}
                            {/* form start */}
                            <form action="https://prernamedia.com/gallery_controller/update_gallery_album_post" method="post" acceptCharset="utf-8">
                                <input type="hidden" name="varient_csrf_token" defaultValue="3d53193b0ce930dea5ad6663457d1f8b" />
                                <input type="hidden" name="id" defaultValue={1} />
                                <div className="box-body">
                                    {/* include message block */}
                                    {/*print error messages*/}
                                    {/*print custom error message*/}
                                    {/*print custom success message*/}
                                    <div className="form-group">
                                        <label>Language</label>
                                        <select name="lang_id" className="form-control" fdprocessedid="iiuwg">
                                            <option value={1} selected>English</option>
                                            <option value={2}>Hindi</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Album Name</label>
                                        <input type="text" className="form-control" name="name" placeholder="Album Name" defaultValue="Media" maxLength={200} required fdprocessedid="ny9m2n" />
                                    </div>
                                </div>
                                {/* /.box-body */}
                                <div className="box-footer">
                                    <button type="submit" className="btn btn-primary pull-right" fdprocessedid="fqvfsg">Save Changes</button>
                                </div>
                                {/* /.box-footer */}
                            </form>{/* form end */}
                        </div>
                        {/* /.box */}
                    </div>
                </div>
            </section>

        </>
    )
}
export default UpdateAlbum