import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom"


function LanguageSettings() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <section className="content">
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <div className="box box-primary">
                            <div className="box-header with-border">
                                <h3 className="box-title">Default Language</h3>
                            </div>
                            {/* /.box-header */}
                            {/* form start */}
                            <form action="https://prernamedia.com/language_controller/set_language_post" method="post" acceptCharset="utf-8">
                                <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />
                                <div className="box-body">
                                    {/* include message block */}
                                    <div className="form-group">
                                        <label>Language</label>
                                        <select name="site_lang" className="form-control">
                                            <option value={1} selected>English</option>
                                            <option value={2}>Hindi</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Text Editor Language</label>
                                        <select name="text_editor_lang" className="form-control" required>
                                            <option value="ar">Arabic</option>
                                            <option value="hy">Armenian</option>
                                            <option value="az">Azerbaijani</option>
                                            <option value="eu">Basque</option>
                                            <option value="be">Belarusian</option>
                                            <option value="bn_BD">Bengali (Bangladesh)</option>
                                            <option value="bs">Bosnian</option>
                                            <option value="bg_BG">Bulgarian</option>
                                            <option value="ca">Catalan</option>
                                            <option value="zh_CN">Chinese (China)</option>
                                            <option value="zh_TW">Chinese (Taiwan)</option>
                                            <option value="hr">Croatian</option>
                                            <option value="cs">Czech</option>
                                            <option value="da">Danish</option>
                                            <option value="dv">Divehi</option>
                                            <option value="nl">Dutch</option>
                                            <option value="en" selected>English</option>
                                            <option value="et">Estonian</option>
                                            <option value="fo">Faroese</option>
                                            <option value="fi">Finnish</option>
                                            <option value="fr_FR">French</option>
                                            <option value="gd">Gaelic, Scottish</option>
                                            <option value="gl">Galician</option>
                                            <option value="ka_GE">Georgian</option>
                                            <option value="de">German</option>
                                            <option value="el">Greek</option>
                                            <option value="he">Hebrew</option>
                                            <option value="hi_IN">Hindi</option>
                                            <option value="hu_HU">Hungarian</option>
                                            <option value="is_IS">Icelandic</option>
                                            <option value="id">Indonesian</option>
                                            <option value="it">Italian</option>
                                            <option value="ja">Japanese</option>
                                            <option value="kab">Kabyle</option>
                                            <option value="kk">Kazakh</option>
                                            <option value="km_KH">Khmer</option>
                                            <option value="ko_KR">Korean</option>
                                            <option value="ku">Kurdish</option>
                                            <option value="lv">Latvian</option>
                                            <option value="lt">Lithuanian</option>
                                            <option value="lb">Luxembourgish</option>
                                            <option value="ml">Malayalam</option>
                                            <option value="mn">Mongolian</option>
                                            <option value="nb_NO">Norwegian Bokmål (Norway)</option>
                                            <option value="fa">Persian</option>
                                            <option value="pl">Polish</option>
                                            <option value="pt_BR">Portuguese (Brazil)</option>
                                            <option value="pt_PT">Portuguese (Portugal)</option>
                                            <option value="ro">Romanian</option>
                                            <option value="ru">Russian</option>
                                            <option value="sr">Serbian</option>
                                            <option value="si_LK">Sinhala (Sri Lanka)</option>
                                            <option value="sk">Slovak</option>
                                            <option value="sl_SI">Slovenian (Slovenia)</option>
                                            <option value="es">Spanish</option>
                                            <option value="es_MX">Spanish (Mexico)</option>
                                            <option value="sv_SE">Swedish (Sweden)</option>
                                            <option value="tg">Tajik</option>
                                            <option value="ta">Tamil</option>
                                            <option value="tt">Tatar</option>
                                            <option value="th_TH">Thai</option>
                                            <option value="tr">Turkish</option>
                                            <option value="ug">Uighur</option>
                                            <option value="uk">Ukrainian</option>
                                            <option value="vi">Vietnamese</option>
                                            <option value="cy">Welsh</option>
                                        </select>
                                    </div>
                                </div>
                                {/* /.box-body */}
                                <div className="box-footer">
                                    <button type="submit" className="btn btn-primary pull-right">Save Changes</button>
                                </div>
                                {/* /.box-footer */}
                            </form>{/* form end */}
                        </div>
                        <div className="box box-primary">
                            <div className="box-header with-border">
                                <h3 className="box-title">Add Language</h3>
                            </div>
                            {/* /.box-header */}
                            {/* form start */}
                            <form action="https://prernamedia.com/language_controller/add_language_post" method="post" acceptCharset="utf-8">
                                <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />
                                <div className="box-body">
                                    {/* include message block */}
                                    {/*print error messages*/}
                                    {/*print custom error message*/}
                                    {/*print custom success message*/}
                                    <div className="form-group">
                                        <label>Language Name</label>
                                        <input type="text" className="form-control" name="name" placeholder="Language Name" defaultValue maxLength={200} required />
                                        <small>(Ex: English)</small>
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Short Form </label>
                                        <input type="text" className="form-control" name="short_form" placeholder="Short Form" defaultValue maxLength={200} required />
                                        <small>(Ex: en)</small>
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Language Code </label>
                                        <input type="text" className="form-control" name="language_code" placeholder="Language Code" defaultValue maxLength={200} required />
                                        <small>(Ex: en-US)</small>
                                    </div>
                                    <div className="form-group">
                                        <label>Order</label>
                                        <input type="number" className="form-control" name="language_order" placeholder="Order" defaultValue={1} min={1} max={3000} required />
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-4 col-xs-12">
                                                <label>Text Direction</label>
                                            </div>
                                            <div className="col-sm-4 col-xs-12 col-option">
                                                <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" id="rb_type_1" name="text_direction" defaultValue="ltr" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                                <label htmlFor="rb_type_1" className="cursor-pointer">Left to Right</label>
                                            </div>
                                            <div className="col-sm-4 col-xs-12 col-option">
                                                <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" id="rb_type_2" name="text_direction" defaultValue="rtl" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                                <label htmlFor="rb_type_2" className="cursor-pointer">Right to Left</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-4 col-xs-12">
                                                <label>Status</label>
                                            </div>
                                            <div className="col-sm-4 col-xs-12 col-option">
                                                <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="status" defaultValue={1} id="status1" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                                <label htmlFor="status1" className="option-label">Active</label>
                                            </div>
                                            <div className="col-sm-4 col-xs-12 col-option">
                                                <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="status" defaultValue={0} id="status2" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                                <label htmlFor="status2" className="option-label">Inactive</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.box-body */}
                                <div className="box-footer">
                                    <button type="submit" className="btn btn-primary pull-right">Add Language</button>
                                </div>
                                {/* /.box-footer */}
                            </form>{/* form end */}
                        </div>
                        {/* /.box */}
                    </div>
                    <div className="col-lg-7 col-md-12">
                        <div className="box">
                            <div className="box-header with-border">
                                <div className="pull-left">
                                    <h3 className="box-title">Languages</h3>
                                </div>
                            </div>{/* /.box-header */}
                            <div className="box-body">
                                <div className="row">
                                    {/* include message block */}
                                    <div className="col-sm-12">
                                        {/*print error messages*/}
                                        {/*print custom error message*/}
                                        {/*print custom success message*/}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="table-responsive">
                                            <div id="cs_datatable_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer"><div className="row"><div className="col-sm-6"><div className="dataTables_length" id="cs_datatable_length"><label>Show <select name="cs_datatable_length" aria-controls="cs_datatable" className="form-control input-sm"><option value={15}>15</option><option value={30}>30</option><option value={60}>60</option><option value={100}>100</option></select></label></div></div><div className="col-sm-6"><div id="cs_datatable_filter" className="dataTables_filter"><label>Search<input type="search" className="form-control input-sm" placeholder aria-controls="cs_datatable" /></label></div></div></div><div className="row"><div className="col-sm-12"><table className="table table-bordered table-striped dataTable no-footer" id="cs_datatable" role="grid" aria-describedby="cs_datatable_info">
                                                <thead>
                                                    <tr role="row"><th width={20} className="sorting_desc" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-sort="descending" aria-label="Id: activate to sort column ascending" style={{ width: 20 }}>Id</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Language Name: activate to sort column ascending" style={{ width: '119.75px' }}>Language Name</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Short Form: activate to sort column ascending" style={{ width: '86.1px' }}>Short Form</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label=": activate to sort column ascending" style={{ width: '123.338px' }} /><th className="max-width-120 sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Options: activate to sort column ascending" style={{ width: '106.312px' }}>Options</th></tr>
                                                </thead>
                                                <tbody>
                                                    <tr role="row" className="odd">
                                                        <td className="sorting_1">2</td>
                                                        <td>Hindi&nbsp;
                                                            <label className="label label-success pull-right lbl-lang-status">Active</label>
                                                        </td>
                                                        <td>hn</td>
                                                        <td><Link to="/admin/update-phrases" className="btn btn-sm btn-success">
                                                            <i className="fa fa-exchange" />&nbsp;Edit Translations                                          </Link>
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" aria-expanded="false">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <Link to="/admin/update-language"><i className="fa fa-edit option-icon" />Edit</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="javascript:void(0)" onClick={handleShow}><i className="fa fa-trash option-icon" />Delete</Link>
                                                                        <Modal show={show} onHide={handleClose}>
                                                                    <Modal.Header closeButton>
                                                                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                                                                    </Modal.Header>
                                                                    <Modal.Body>Are you sure you want to delete this poll?</Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="secondary" onClick={handleClose}>
                                                                            Close
                                                                        </Button>
                                                                        <Button variant="danger" onClick={handleClose}>
                                                                            Ok
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="even">
                                                        <td className="sorting_1">1</td>
                                                        <td>English&nbsp;
                                                            <label className="label label-success pull-right lbl-lang-status">Active</label>
                                                        </td>
                                                        <td>en</td>
                                                        <td><a href="https://prernamedia.com/admin/update-phrases/1?page=1" className="btn btn-sm btn-success">
                                                            <i className="fa fa-exchange" />&nbsp;Edit Translations                                          </a>
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <a href="https://prernamedia.com/admin/update-language/1"><i className="fa fa-edit option-icon" />Edit</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('language_controller/delete_language_post','1','Are you sure you want to delete this language?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr></tbody>
                                            </table></div></div><div className="row"><div className="col-sm-5"><div className="dataTables_info" id="cs_datatable_info" role="status" aria-live="polite">Showing 1 to 2 of 2 entries</div></div><div className="col-sm-7"><div className="dataTables_paginate paging_simple_numbers" id="cs_datatable_paginate"><ul className="pagination"><li className="paginate_button previous disabled" id="cs_datatable_previous"><a href="#" aria-controls="cs_datatable" data-dt-idx={0} tabIndex={0}>‹</a></li><li className="paginate_button active"><a href="#" aria-controls="cs_datatable" data-dt-idx={1} tabIndex={0}>1</a></li><li className="paginate_button next disabled" id="cs_datatable_next"><a href="#" aria-controls="cs_datatable" data-dt-idx={2} tabIndex={0}>›</a></li></ul></div></div></div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* /.box-body */}
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default LanguageSettings