import Editor from "../../commonComponents/editor/Editor"

function SendEmailSubscriber() {
    return (
        <>
            <section className="content">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="box box-primary">
                            <div className="box-header with-border">
                                <h3 className="box-title">Send Email to Subscribers</h3>
                            </div>
                            {/* /.box-header */}
                            {/* form start */}
                            <form action="https://prernamedia.com/admin_controller/send_email_subscribers_post" method="post" acceptCharset="utf-8">
                                <input type="hidden" name="varient_csrf_token" defaultValue="d13da8141583a61e4e5871e61238aaf6" />
                                <div className="box-body">
                                    {/* include message block */}
                                    {/*print error messages*/}
                                    {/*print custom error message*/}
                                    {/*print custom success message*/}
                                    <div className="form-group">
                                        <label>Subject</label>
                                        <input type="text" name="subject" className="form-control" placeholder="Subject" required fdprocessedid="wivltt" />
                                    </div>
                                    <div className="form-group">
                                        <label>Content</label>
                                        <div className="row">
                                            <div className="col-sm-12 editor-buttons">
                                                <button type="button" className="btn btn-sm btn-success" data-toggle="modal" data-target="#file_manager_image" data-image-type="editor" fdprocessedid="hc4pwa"><i className="fa fa-image" />&nbsp;&nbsp;&nbsp;Add Image</button>
                                            </div>
                                        </div>
                                        <Editor/>
                                    </div>
                                </div>
                                {/* /.box-body */}
                                <div className="box-footer">
                                    <button type="submit" className="btn btn-primary pull-right" fdprocessedid="j9ruv">Send Email</button>
                                </div>
                                {/* /.box-footer */}
                            </form>{/* form end */}
                        </div>
                        {/* /.box */}
                    </div>
                </div>
                <link rel="stylesheet" href="https://prernamedia.com/assets/admin/plugins/file-uploader/css/jquery.dm-uploader.min.css" />
                <link rel="stylesheet" href="https://prernamedia.com/assets/admin/plugins/file-uploader/css/styles.css" />
                <link rel="stylesheet" href="https://prernamedia.com/assets/admin/plugins/file-manager/fileicon.css" />
                {/* Modal */}
                <div id="file_manager_image" className="modal fade modal-file-manager" role="dialog">
                    <div className="modal-dialog modal-lg">
                        {/* Modal content*/}
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">×</button>
                                <h4 className="modal-title">Images</h4>
                                <div className="file-manager-search">
                                    <input type="text" id="input_search_image" className="form-control" placeholder="Search" />
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="file-manager">
                                    <div className="file-manager-left">
                                        <div className="dm-uploader-container">
                                            <div id="drag-and-drop-zone-image" className="dm-uploader text-center">
                                                <p className="file-manager-file-types">
                                                    <span>JPG</span>
                                                    <span>JPEG</span>
                                                    <span>PNG</span>
                                                    <span>GIF</span>
                                                </p>
                                                <p className="dm-upload-icon">
                                                    <i className="fa fa-cloud-upload" />
                                                </p>
                                                <p className="dm-upload-text">Drag and drop files here or</p>
                                                <p className="text-center">
                                                    <button className="btn btn-default btn-browse-files">Browse Files</button>
                                                </p>
                                                <a className="btn btn-md dm-btn-select-files">
                                                    <input type="file" name="file" size={40} multiple />
                                                </a>
                                                <ul className="dm-uploaded-files" id="files-image" />
                                                <button type="button" id="btn_reset_upload_image" className="btn btn-reset-upload">Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="file-manager-right">
                                        <div className="file-manager-content">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div id="image_file_upload_response" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="hidden" id="selected_img_file_id" />
                                    <input type="hidden" id="selected_img_mid_file_path" />
                                    <input type="hidden" id="selected_img_default_file_path" />
                                    <input type="hidden" id="selected_img_slider_file_path" />
                                    <input type="hidden" id="selected_img_big_file_path" />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="file-manager-footer">
                                    <button type="button" id="btn_img_delete" className="btn btn-danger pull-left btn-file-delete"><i className="fa fa-trash" />&nbsp;&nbsp;Delete</button>
                                    <button type="button" id="btn_img_select" className="btn bg-olive btn-file-select"><i className="fa fa-check" />&nbsp;&nbsp;Select Image</button>
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* File item template */}
            </section>

        </>
    )
}
export default SendEmailSubscriber