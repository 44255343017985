

function EditRole() {
    return (
        <>
            <section className="content">
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="box box-primary">
                            <div className="box-header with-border">
                                <h3 className="box-title">Edit Role</h3>
                            </div>
                            {/* /.box-header */}
                            {/* form start */}
                            <form action="https://prernamedia.com/admin_controller/edit_role_post" method="post" acceptCharset="utf-8">
                                <input type="hidden" name="varient_csrf_token" defaultValue="5a5e4d65e454de9fc452e230536deb5b" />
                                <input type="hidden" name="id" defaultValue={2} />
                                <div className="box-body">
                                    {/* include message block */}
                                    {/*print error messages*/}
                                    {/*print custom error message*/}
                                    {/*print custom success message*/}
                                    <div className="m-b-15">
                                    </div>
                                    <div className="form-group">
                                        <label>Role Name</label>
                                        <input type="text" className="form-control" name="role_name" placeholder="Role Name" defaultValue="Moderator" maxLength={200} required fdprocessedid="d8ikbj" />
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2 col-xs-2">
                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                {/* <div className="icheckbox_square-purple  form-check" style={{ position: 'relative' }}>
                                                
                                                    <input type="radio" name="flexRadioDefault" defaultValue={1} id="flexRadioDefault1" className="form-check-input" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} />
                                                </div> */}
                                            </div>
                                            <div className="col-md-11 col-sm-10 col-xs-10">
                                                <label htmlFor="role_admin_panel" className="control-label cursor-pointer">Control Panel</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2 col-xs-2">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                {/* <div className="icheckbox_square-purple checked" style={{ position: 'relative' }}>
                                                
                                                    <input type="checkbox" name="add_post" defaultValue={1} id="role_add_post" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} />
                                                </div> */}
                                            </div>
                                            <div className="col-md-11 col-sm-10 col-xs-10">
                                                <label htmlFor="role_add_post" className="control-label cursor-pointer">Add Post</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2 col-xs-2">
                                                <input className="form-check-input" type="checkbox" defaultValue id="defaultCheck1" />

                                                    {/* <div className="icheckbox_square-purple checked" style={{ position: 'relative' }}><input type="checkbox" name="manage_all_posts" defaultValue={1} id="role_manage_all_posts" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div> */}
                                            </div>
                                            <div className="col-md-11 col-sm-10 col-xs-10">
                                                <label htmlFor="role_manage_all_posts" className="control-label cursor-pointer">Manage All Posts</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2 col-xs-2">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                {/* <div className="icheckbox_square-purple checked" style={{ position: 'relative' }}><input type="checkbox" name="navigation" defaultValue={1} id="role_navigation" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div> */}
                                            </div>
                                            <div className="col-md-11 col-sm-10 col-xs-10">
                                                <label htmlFor="role_navigation" className="control-label cursor-pointer">Navigation</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2 col-xs-2">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                {/* <div className="icheckbox_square-purple checked" style={{ position: 'relative' }}><input type="checkbox" name="pages" defaultValue={1} id="role_pages" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div> */}
                                            </div>
                                            <div className="col-md-11 col-sm-10 col-xs-10">
                                                <label htmlFor="role_pages" className="control-label cursor-pointer">Pages</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2 col-xs-2">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                {/* <div className="icheckbox_square-purple checked" style={{ position: 'relative' }}><input type="checkbox" name="rss_feeds" defaultValue={1} id="role_rss_feeds" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div> */}
                                            </div>
                                            <div className="col-md-11 col-sm-10 col-xs-10">
                                                <label htmlFor="role_rss_feeds" className="control-label cursor-pointer">RSS Feeds</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2 col-xs-2">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                {/* <div className="icheckbox_square-purple checked" style={{ position: 'relative' }}><input type="checkbox" name="categories" defaultValue={1} id="role_categories" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div> */}
                                            </div>
                                            <div className="col-md-11 col-sm-10 col-xs-10">
                                                <label htmlFor="role_categories" className="control-label cursor-pointer">Categories</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2 col-xs-2">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                {/* <div className="icheckbox_square-purple checked" style={{ position: 'relative' }}><input type="checkbox" name="widgets" defaultValue={1} id="role_widgets" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div> */}
                                            </div>
                                            <div className="col-md-11 col-sm-10 col-xs-10">
                                                <label htmlFor="role_widgets" className="control-label cursor-pointer">Widgets</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2 col-xs-2">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                {/* <div className="icheckbox_square-purple checked" style={{ position: 'relative' }}><input type="checkbox" name="polls" defaultValue={1} id="role_polls" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div> */}
                                            </div>
                                            <div className="col-md-11 col-sm-10 col-xs-10">
                                                <label htmlFor="role_polls" className="control-label cursor-pointer">Polls</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2 col-xs-2">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                {/* <div className="icheckbox_square-purple checked" style={{ position: 'relative' }}><input type="checkbox" name="gallery" defaultValue={1} id="role_gallery" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div> */}
                                            </div>
                                            <div className="col-md-11 col-sm-10 col-xs-10">
                                                <label htmlFor="role_gallery" className="control-label cursor-pointer">Gallery</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2 col-xs-2">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                {/* <div className="icheckbox_square-purple checked" style={{ position: 'relative' }}><input type="checkbox" name="comments_contact" defaultValue={1} id="role_comments_contact" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div> */}
                                            </div>
                                            <div className="col-md-11 col-sm-10 col-xs-10">
                                                <label htmlFor="role_comments_contact" className="control-label cursor-pointer">Comments, Contact Messages</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2 col-xs-2">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                {/* <div className="icheckbox_square-purple checked" style={{ position: 'relative' }}><input type="checkbox" name="newsletter" defaultValue={1} id="role_newsletter" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div> */}
                                            </div>
                                            <div className="col-md-11 col-sm-10 col-xs-10">
                                                <label htmlFor="role_newsletter" className="control-label cursor-pointer">Newsletter</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2 col-xs-2">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                {/* <div className="icheckbox_square-purple checked" style={{ position: 'relative' }}><input type="checkbox" name="ad_spaces" defaultValue={1} id="role_ad_spaces" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div> */}
                                            </div>
                                            <div className="col-md-11 col-sm-10 col-xs-10">
                                                <label htmlFor="role_ad_spaces" className="control-label cursor-pointer">Ad Spaces</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2 col-xs-2">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                {/* <div className="icheckbox_square-purple checked" style={{ position: 'relative' }}><input type="checkbox" name="users" defaultValue={1} id="role_users" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div> */}
                                            </div>
                                            <div className="col-md-11 col-sm-10 col-xs-10">
                                                <label htmlFor="role_users" className="control-label cursor-pointer">Users</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2 col-xs-2">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                {/* <div className="icheckbox_square-purple checked" style={{ position: 'relative' }}><input type="checkbox" name="seo_tools" defaultValue={1} id="role_seo_tools" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div> */}
                                            </div>
                                            <div className="col-md-11 col-sm-10 col-xs-10">
                                                <label htmlFor="role_seo_tools" className="control-label cursor-pointer">SEO Tools</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-1 col-sm-2 col-xs-2">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                {/* <div className="icheckbox_square-purple" style={{ position: 'relative' }}><input type="checkbox" name="settings" defaultValue={1} id="role_settings" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div> */}

                                            </div>
                                            <div className="col-md-11 col-sm-10 col-xs-10">
                                                <label htmlFor="role_settings" className="control-label cursor-pointer">Settings</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.box-body */}
                                <div className="box-footer">
                                    <button type="submit" className="btn btn-primary pull-right" fdprocessedid="xylqv">Save Changes </button>
                                </div>
                                {/* /.box-footer */}
                            </form>{/* form end */}
                        </div>
                        {/* /.box */}
                    </div>
                </div>
                <style dangerouslySetInnerHTML={{ __html: "\n    .form-group .col-sm-2{\n        max-width: 40px;\n        padding-right: 0 !important;\n    }\n" }} />
            </section>

        </>
    )
}
export default EditRole