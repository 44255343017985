import { useState } from "react";
import { Button, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"

function Widgets() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <section className="content">
                <div className="box">
                    <div className="box-header with-border">
                        <div className="left">
                            <h3 className="box-title">Widgets</h3>
                        </div>
                        <div className="right">
                            <Link to="/admin/add-widget" className="btn btn-success btn-add-new">
                                <i className="fa fa-plus" />
                                Add Widget          </Link>
                        </div>
                    </div>{/* /.box-header */}
                    <div className="box-body">
                        <div className="row">
                            {/* include message block */}
                            <div className="col-sm-12">
                                {/*print error messages*/}
                                {/*print custom error message*/}
                                {/*print custom success message*/}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    <div id="cs_datatable_lang_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer"><div className="dataTables_length" id="cs_datatable_lang_length"><label>Show <select name="cs_datatable_lang_length" aria-controls="cs_datatable_lang" className="form-control input-sm"><option value={15}>15</option><option value={30}>30</option><option value={60}>60</option><option value={100}>100</option></select></label></div><div id="table_dropdown"><label className="table-label">Language</label><select className="form-control input-sm"><option value>All</option><option value="English">English</option><option value="Hindi">Hindi</option></select></div><div id="cs_datatable_lang_filter" className="dataTables_filter"><label>Search<input type="search" className="form-control input-sm" placeholder aria-controls="cs_datatable_lang" /></label></div><table className="table table-bordered table-striped dataTable no-footer" id="cs_datatable_lang" role="grid" aria-describedby="cs_datatable_lang_info">
                                        <thead>
                                            <tr role="row"><th width={20} className="sorting_desc" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-sort="descending" aria-label="Id: activate to sort column ascending" style={{ width: 20 }}>Id</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Title: activate to sort column ascending" style={{ width: '178.15px' }}>Title</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Language: activate to sort column ascending" style={{ width: '110.688px' }}>Language</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Order: activate to sort column ascending" style={{ width: '74.3875px' }}>Order</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Type: activate to sort column ascending" style={{ width: '65.925px' }}>Type</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Visibility: activate to sort column ascending" style={{ width: '100.525px' }}>Visibility</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Date Added: activate to sort column ascending" style={{ width: '152.875px' }}>Date Added</th><th className="max-width-120 sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Options: activate to sort column ascending" style={{ width: '151.05px' }}>Options</th></tr>
                                        </thead>
                                        <tbody>
                                            <tr role="row" className="odd">
                                                <td className="sorting_1">12</td>
                                                <td className="break-word">Voting Poll</td>
                                                <td>
                                                    Hindi                              </td>
                                                <td>6</td>
                                                <td>
                                                    <label className="label label-default">Default</label>
                                                </td>
                                                <td>
                                                    <label className="label label-success"><i className="fa fa-eye" /></label>
                                                </td>
                                                <td>2021-05-31 / 07:55</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" aria-expanded="false">Select an option                                            
                                                        {/* <span className="caret" /> */}
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <Link to="/admin/update-widget"><i className="fa fa-edit option-icon" />Edit</Link>
                                                            </li>
                                                            <li>
                                                                <button onClick={handleShow}><i className="fa fa-trash option-icon" />Delete</button>

                                                                <Modal show={show} onHide={handleClose}>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>

                                                                        </Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>Are you sure you want to delete this category?</Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="secondary" onClick={handleClose}>
                                                                            Cancel
                                                                        </Button>
                                                                        <Button variant="danger" onClick={handleClose}>
                                                                            ok
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="even">
                                                <td className="sorting_1">11</td>
                                                <td className="break-word">Tags</td>
                                                <td>
                                                    Hindi                              </td>
                                                <td>5</td>
                                                <td>
                                                    <label className="label label-default">Default</label>
                                                </td>
                                                <td>
                                                    <label className="label label-success"><i className="fa fa-eye" /></label>
                                                </td>
                                                <td>2021-05-31 / 07:55</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                            
                                                        {/* <span className="caret" /> */}
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="https://prernamedia.com/admin/update-widget/11"><i className="fa fa-edit option-icon" />Edit</a>
                                                            </li>
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('widget_controller/delete_widget_post','11','Are you sure you want to delete this widget?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="odd">
                                                <td className="sorting_1">10</td>
                                                <td className="break-word">Random Posts</td>
                                                <td>
                                                    Hindi                              </td>
                                                <td>4</td>
                                                <td>
                                                    <label className="label label-default">Default</label>
                                                </td>
                                                <td>
                                                    <label className="label label-success"><i className="fa fa-eye" /></label>
                                                </td>
                                                <td>2021-05-31 / 07:55</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option  
                                                                                                  {/* <span className="caret" /> */}
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="https://prernamedia.com/admin/update-widget/10"><i className="fa fa-edit option-icon" />Edit</a>
                                                            </li>
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('widget_controller/delete_widget_post','10','Are you sure you want to delete this widget?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="even">
                                                <td className="sorting_1">9</td>
                                                <td className="break-word">Recommended Posts</td>
                                                <td>
                                                    Hindi                              </td>
                                                <td>3</td>
                                                <td>
                                                    <label className="label label-default">Default</label>
                                                </td>
                                                <td>
                                                    <label className="label label-success"><i className="fa fa-eye" /></label>
                                                </td>
                                                <td>2021-05-31 / 07:55</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option   
                                                                                                 {/* <span className="caret" /> */}
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="https://prernamedia.com/admin/update-widget/9"><i className="fa fa-edit option-icon" />Edit</a>
                                                            </li>
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('widget_controller/delete_widget_post','9','Are you sure you want to delete this widget?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="odd">
                                                <td className="sorting_1">8</td>
                                                <td className="break-word">Popular Posts</td>
                                                <td>
                                                    Hindi                              </td>
                                                <td>2</td>
                                                <td>
                                                    <label className="label label-default">Default</label>
                                                </td>
                                                <td>
                                                    <label className="label label-success"><i className="fa fa-eye" /></label>
                                                </td>
                                                <td>2021-05-31 / 07:55</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                    
                                                                                {/* <span className="caret" /> */}
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="https://prernamedia.com/admin/update-widget/8"><i className="fa fa-edit option-icon" />Edit</a>
                                                            </li>
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('widget_controller/delete_widget_post','8','Are you sure you want to delete this widget?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="even">
                                                <td className="sorting_1">7</td>
                                                <td className="break-word">Follow Us</td>
                                                <td>
                                                    Hindi                              </td>
                                                <td>1</td>
                                                <td>
                                                    <label className="label label-default">Default</label>
                                                </td>
                                                <td>
                                                    <label className="label label-success"><i className="fa fa-eye" /></label>
                                                </td>
                                                <td>2021-05-31 / 07:55</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option  
                                                                                                  {/* <span className="caret" /> */}
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="https://prernamedia.com/admin/update-widget/7"><i className="fa fa-edit option-icon" />Edit</a>
                                                            </li>
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('widget_controller/delete_widget_post','7','Are you sure you want to delete this widget?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="odd">
                                                <td className="sorting_1">6</td>
                                                <td className="break-word">Voting Poll</td>
                                                <td>
                                                    English                              </td>
                                                <td>6</td>
                                                <td>
                                                    <label className="label label-default">Default</label>
                                                </td>
                                                <td>
                                                    <label className="label label-success"><i className="fa fa-eye" /></label>
                                                </td>
                                                <td>2020-02-18 / 12:54</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option 
                                                                                                   {/* <span className="caret" /> */}
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="https://prernamedia.com/admin/update-widget/6"><i className="fa fa-edit option-icon" />Edit</a>
                                                            </li>
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('widget_controller/delete_widget_post','6','Are you sure you want to delete this widget?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="even">
                                                <td className="sorting_1">5</td>
                                                <td className="break-word">Tags</td>
                                                <td>
                                                    English                              </td>
                                                <td>5</td>
                                                <td>
                                                    <label className="label label-default">Default</label>
                                                </td>
                                                <td>
                                                    <label className="label label-success"><i className="fa fa-eye" /></label>
                                                </td>
                                                <td>2020-02-18 / 12:54</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option   
                                                                                                 {/* <span className="caret" /> */}
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="https://prernamedia.com/admin/update-widget/5"><i className="fa fa-edit option-icon" />Edit</a>
                                                            </li>
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('widget_controller/delete_widget_post','5','Are you sure you want to delete this widget?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="odd">
                                                <td className="sorting_1">4</td>
                                                <td className="break-word">Random Posts</td>
                                                <td>
                                                    English                              </td>
                                                <td>4</td>
                                                <td>
                                                    <label className="label label-default">Default</label>
                                                </td>
                                                <td>
                                                    <label className="label label-success"><i className="fa fa-eye" /></label>
                                                </td>
                                                <td>2020-02-18 / 12:54</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option    
                                                                                                {/* <span className="caret" /> */}
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="https://prernamedia.com/admin/update-widget/4"><i className="fa fa-edit option-icon" />Edit</a>
                                                            </li>
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('widget_controller/delete_widget_post','4','Are you sure you want to delete this widget?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="even">
                                                <td className="sorting_1">3</td>
                                                <td className="break-word">Recommended Posts</td>
                                                <td>
                                                    English                              </td>
                                                <td>3</td>
                                                <td>
                                                    <label className="label label-default">Default</label>
                                                </td>
                                                <td>
                                                    <label className="label label-success"><i className="fa fa-eye" /></label>
                                                </td>
                                                <td>2020-02-18 / 12:54</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option   
                                                                                                 {/* <span className="caret" /> */}
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="https://prernamedia.com/admin/update-widget/3"><i className="fa fa-edit option-icon" />Edit</a>
                                                            </li>
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('widget_controller/delete_widget_post','3','Are you sure you want to delete this widget?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="odd">
                                                <td className="sorting_1">2</td>
                                                <td className="break-word">Popular Posts</td>
                                                <td>
                                                    English                              </td>
                                                <td>1</td>
                                                <td>
                                                    <label className="label label-default">Default</label>
                                                </td>
                                                <td>
                                                    <label className="label label-success"><i className="fa fa-eye" /></label>
                                                </td>
                                                <td>2020-02-18 / 12:54</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option   
                                                                                                 {/* <span className="caret" /> */}
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="https://prernamedia.com/admin/update-widget/2"><i className="fa fa-edit option-icon" />Edit</a>
                                                            </li>
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('widget_controller/delete_widget_post','2','Are you sure you want to delete this widget?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="even">
                                                <td className="sorting_1">1</td>
                                                <td className="break-word">Follow Us</td>
                                                <td>
                                                    English                              </td>
                                                <td>2</td>
                                                <td>
                                                    <label className="label label-default">Default</label>
                                                </td>
                                                <td>
                                                    <label className="label label-success"><i className="fa fa-eye" /></label>
                                                </td>
                                                <td>2020-02-18 / 12:54</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option   
                                                                                                 {/* <span className="caret" /> */}
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="https://prernamedia.com/admin/update-widget/1"><i className="fa fa-edit option-icon" />Edit</a>
                                                            </li>
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('widget_controller/delete_widget_post','1','Are you sure you want to delete this widget?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr></tbody>
                                    </table><div className="dataTables_info" id="cs_datatable_lang_info" role="status" aria-live="polite">Showing 1 to 12 of 12 entries</div><div className="dataTables_paginate paging_simple_numbers" id="cs_datatable_lang_paginate"><ul className="pagination"><li className="paginate_button previous disabled" id="cs_datatable_lang_previous"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={0} tabIndex={0}>‹</a></li><li className="paginate_button active"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={1} tabIndex={0}>1</a></li><li className="paginate_button next disabled" id="cs_datatable_lang_next"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={2} tabIndex={0}>›</a></li></ul></div></div>
                                </div>
                            </div>
                        </div>
                    </div>{/* /.box-body */}
                </div>
            </section>

        </>
    )
}
export default Widgets