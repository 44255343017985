
function DashBoardPage() {
    return (
        <>
    <section className="content">
  {/* Small boxes (Stat box) */}
  <div className="row">
    <div className="col-lg-3 col-xs-6">
      {/* small box */}
      <div className="small-box admin-small-box bg-success">
        <div className="inner">
          <h3 className="increase-count">3030</h3>
          <a href="https://prernamedia.com/admin/posts">
            <p>Posts</p>
          </a>
        </div>
        <div className="icon">
          <a href="https://prernamedia.com/admin/posts">
            <i className="fa fa-file" />
          </a>
        </div>
      </div>
    </div>
    <div className="col-lg-3 col-xs-6">
      {/* small box */}
      <div className="small-box admin-small-box bg-danger">
        <div className="inner">
          <h3 className="increase-count">0</h3>
          <a href="https://prernamedia.com/admin/pending-posts">
            <p>Pending Posts</p>
          </a>
        </div>
        <div className="icon">
          <a href="https://prernamedia.com/admin/pending-posts">
            <i className="fa fa-low-vision" />
          </a>
        </div>
      </div>
    </div>
    <div className="col-lg-3 col-xs-6">
      {/* small box */}
      <div className="small-box admin-small-box bg-purple">
        <div className="inner">
          <h3 className="increase-count">0</h3>
          <a href="https://prernamedia.com/admin/drafts">
            <p>Drafts</p>
          </a>
        </div>
        <div className="icon">
          <a href="https://prernamedia.com/admin/drafts">
            <i className="fa fa-file-text-o" />
          </a>
        </div>
      </div>
    </div>
    <div className="col-lg-3 col-xs-6">
      {/* small box */}
      <div className="small-box admin-small-box bg-warning">
        <div className="inner">
          <h3 className="increase-count">0</h3>
          <a href="https://prernamedia.com/admin/scheduled-posts">
            <p>Scheduled Posts</p>
          </a>
        </div>
        <div className="icon">
          <a href="https://prernamedia.com/admin/scheduled-posts">
            <i className="fa fa-clock-o" />
          </a>
        </div>
      </div>
    </div>
  </div>
  {/* /.row */}
  <div className="row">
    <div className="col-sm-12 no-padding">
      <div className="col-lg-6 col-sm-12 col-xs-12">
        <div className="box box-primary">
          <div className="box-header with-border">
            <h3 className="box-title">Pending Comments</h3>
            <br />
            <small>Recently added unapproved comments</small>
            <div className="box-tools pull-right">
              <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus" />
              </button>
              <button type="button" className="btn btn-box-tool" data-widget="remove"><i className="fa fa-times" />
              </button>
            </div>
          </div>{/* /.box-header */}
          <div className="box-body index-table">
            <div className="table-responsive">
              <table className="table no-margin">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th style={{width: '60%'}}>Comment</th>
                    <th style={{minWidth: '13%'}}>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> 5 </td>
                    <td>
                      ABARIS RANZ                                          </td>
                    <td style={{width: '60%'}} className="break-word">
                      good to see                                          </td>
                    <td className="nowrap">2023-02-25 / 14:52</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* /.table-responsive */}
          </div>
          <div className="box-footer clearfix">
            <a href="https://prernamedia.com/admin/pending-comments" className="btn btn-sm btn-default btn-flat pull-right">View All</a>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-sm-12 col-xs-12">
        <div className="box box-primary">
          <div className="box-header with-border">
            <h3 className="box-title">Latest Contact Messages</h3>
            <br />
            <small>Recently added contact messages</small>
            <div className="box-tools pull-right">
              <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus" />
              </button>
              <button type="button" className="btn btn-box-tool" data-widget="remove"><i className="fa fa-times" />
              </button>
            </div>
          </div>{/* /.box-header */}
          <div className="box-body index-table">
            <div className="table-responsive">
              <table className="table no-margin">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th style={{width: '60%'}}>Message</th>
                    <th style={{minWidth: '13%'}}>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      1                                      </td>
                    <td>
                      RichardExcug                                      </td>
                    <td style={{width: '60%'}} className="break-word">
                      Hi, ego volo scire vestri pretium.                                      </td>
                    <td style={{minWidth: '16%'}} className="nowrap">
                      2023-03-04 / 05:53                                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* /.table-responsive */}
          </div>
          <div className="box-footer clearfix">
            <a href="https://prernamedia.com/admin/contact-messages" className="btn btn-sm btn-default btn-flat pull-right">View All</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* /.row */}
  <div className="row">
    <div className="no-padding margin-bottom-20">
      <div className="col-lg-6 col-sm-12 col-xs-12">
        {/* USERS LIST */}
        <div className="box box-primary box-sm">
          <div className="box-header with-border">
            <h3 className="box-title">Latest Users</h3>
            <br />
            <small>Recently registered users</small>
            <div className="box-tools pull-right">
              <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus" />
              </button>
              <button type="button" className="btn btn-box-tool" data-widget="remove"><i className="fa fa-times" />
              </button>
            </div>
          </div>
          {/* /.box-header */}
          <div className="box-body">
            <ul className="users-list clearfix">
              <li>
                <a href="https://prernamedia.com/profile/abaris">
                  <img src="https://prernamedia.com/assets/img/user.png" alt="user" className="img-responsive" />
                </a>
                <a href="https://prernamedia.com/profile/abaris" className="users-list-name">abaris</a>
                <span className="users-list-date">2 years ago</span>
              </li>
              <li>
                <a href="https://prernamedia.com/profile/prernamedia_admin">
                  <img src="https://prernamedia.com/assets/img/user.png" alt="user" className="img-responsive" />
                </a>
                <a href="https://prernamedia.com/profile/prernamedia_admin" className="users-list-name">prernamedia_admin</a>
                <span className="users-list-date">2 years ago</span>
              </li>
            </ul>
            {/* /.users-list */}
          </div>
          {/* /.box-body */}
          <div className="box-footer text-center">
            <a href="https://prernamedia.com/admin/users" className="btn btn-sm btn-default btn-flat pull-right">View All</a>
          </div>
          {/* /.box-footer */}
        </div>
        {/*/.box */}
      </div>
    </div>
  </div>
  {/* /.row */}
</section>

        </>
    )
}
export default DashBoardPage