
function EditFont() {
  return (
    <>
      <section className="content">
        <div className="row">
          <div className="col-lg-5 col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Update Font</h3>
              </div>

              <form action="https://prernamedia.com/admin_controller/update_font_post" method="post" acceptCharset="utf-8">
                <input type="hidden" name="varient_csrf_token" defaultValue="e3042456da8e91b1048dced2012726c4" />
                <input type="hidden" name="id" defaultValue={32} />
                <div className="box-body">
                  {/* include message block */}
                  <div className="form-group">
                    <label>Name</label>
                    <input type="text" className="form-control" name="font_name" defaultValue="Verdana" placeholder="Name" maxLength={200} required />
                    <small>(E.g: Open Sans)</small>
                  </div>
                  <input type="hidden" name="font_url" defaultValue />
                  <div className="form-group">
                    <label className="control-label">Font Family </label>
                    <input type="text" className="form-control" name="font_family" defaultValue="font-family: Verdana, Helvetica, sans-serif" placeholder="Font Family" maxLength={500} required />
                    <small>(E.g: font-family: "Open Sans", Helvetica, sans-serif)</small>
                  </div>
                </div>

                <div className="box-footer">
                  <button type="submit" className="btn btn-primary pull-right">Save Changes</button>
                </div>

              </form>
            </div>

          </div>
        </div>
      </section>

    </>
  )
}
export default EditFont