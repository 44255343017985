import AddMenuLink from "./addMenuLink/AddMenuLink";
import MenuLimit from "./MenuLimit/MenuLimit";
import NavigationMenu from "./navigationMenu/NavigationMenu";

function Navigation() {
  return (
    <>
      <section className="content">
        {/* <div className="row" style={{ marginBottom: 20 }}>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <label>Language</label>
            <select
              name="lang_id"
              className="form-control max-400"
              onchange="window.location.href = 'https://prernamedia.com/admin/'+'navigation?lang='+this.value;"
              fdprocessedid="cllj0q"
            >
              <option value={1} selected>
                English
              </option>
              <option value={2}>Hindi</option>
            </select>
          </div>
        </div> */}
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
          <NavigationMenu />
            <div className="alert alert-danger alert-large">
              <strong>Warning!</strong>&nbsp;&nbsp;You cannot drag a category
              below a page or a page below a category link!
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="row">
              <div className="col-sm-12">
              <AddMenuLink />
              </div>
              <div className="col-sm-12">
                <MenuLimit/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Navigation;
