

function EmailSetting() {
  return (
    <>
      <section className="content">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Email Settings</h3>
              </div>

              <div className="box-body">
                <div className="form-group">
                  <label className="control-label">Mail Library</label>
                  <select name="mail_library" className="form-select" onchange="window.location.href = 'https://prernamedia.com/admin/email-settings?library='+this.value;">
                    <option value="swift">Swift Mailer</option>
                    <option value="php" selected>PHP Mailer</option>
                    <option value="codeigniter">CodeIgniter Mail</option>
                  </select>
                </div>
                <input type="hidden" name="mail_protocol" defaultValue="smtp" />
                <div className="form-group">
                  <label className="control-label">Mail Title</label>
                  <input type="text" className="form-control" name="mail_title" placeholder="Mail Title" defaultValue="Citynewsindia" />
                </div>
                <div className="form-group">
                  <label className="control-label">Mail Host</label>
                  <input type="text" className="form-control" name="mail_host" placeholder="Mail Host" defaultValue="smtp.gmail.com" />
                </div>
                <div className="form-group">
                  <label className="control-label">Mail Port</label>
                  <input type="text" className="form-control" name="mail_port" placeholder="Mail Port" defaultValue={465} />
                </div>
                <div className="form-group">
                  <label className="control-label">Mail Username</label>
                  <input type="text" className="form-control" name="mail_username" placeholder="Mail Username" defaultValue="citinewsindia@gmail.com" />
                </div>
                <div className="form-group">
                  <label className="control-label">Mail Password</label>
                  <input type="password" className="form-control" name="mail_password" placeholder="Mail Password" defaultValue="Rockstar@123" />
                </div>
                <div className="form-group">
                  <label className="control-label">Email Template</label>
                  <div className="row m-b-15 m-t-15">
                    <div className="category-block-box" style={{ width: 320, height: 320, marginLeft: 15 }}>
                      <div className="col-sm-12 text-center m-b-15">
                        <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="block_type" defaultValue="block-1" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                      </div>
                      <img src="https://prernamedia.com/assets/admin/img/email-template-1.png" alt className="img-responsive" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-footer">
                <button type="submit" name="submit" value="email" className="btn btn-primary pull-right">Save Changes</button>
              </div>
            </div>
            <div className="callout" style={{ marginTop: 30, backgroundColor: '#fff', borderColor: '#00c0ef', maxWidth: 600 }}>
              <h4>Gmail SMTP</h4>
              <p>To send e-mails with Gmail server, please read Email Settings section in our documentation.</p>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Email Verification</h3>
              </div>

              <div className="box-body">
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-12 col-xs-12">
                      <label>Email Verification</label>
                    </div>
                    <div className="col-sm-4 col-xs-12 col-option">
                      <div className="iradio_square-purple" style={{ position: 'relative' }}>
                        <input type="radio" name="email_verification" defaultValue={1} id="email_verification_1" />
                      </div>
                      <label htmlFor="email_verification_1" className="option-label">Enable</label>
                    </div>

                    <div className="col-sm-4 col-xs-12 col-option">
                      <div className="iradio_square-purple checked" style={{ position: 'relative' }}>
                        <input type="radio" name="email_verification" defaultValue={0} id="email_verification_2" />
                      </div>
                      <label htmlFor="email_verification_2" className="option-label">Disable</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-footer">
                <button type="submit" name="submit" value="verification" className="btn btn-primary pull-right">Save Changes</button>
              </div>
            </div>
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Contact Messages</h3>
              </div>

              <div className="box-body">
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-12 col-xs-12">
                      <label>Send Contact Messages to Email Address</label>
                    </div>
                    <div className="col-sm-4 col-xs-12 col-option">
                      <div className="iradio_square-purple" style={{ position: 'relative' }}>
                        <input type="radio" name="mail_contact_status" defaultValue={1} id="mail_contact_status_1" />
                      </div>
                      <label htmlFor="mail_contact_status_1" className="option-label">Yes</label>
                    </div>
                    <div className="col-sm-4 col-xs-12 col-option">
                      <div className="iradio_square-purple checked" style={{ position: 'relative' }}>
                        <input type="radio" name="mail_contact_status" defaultValue={0} id="mail_contact_status_2" /></div>
                      <label htmlFor="mail_contact_status_2" className="option-label">No</label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label">Email (Contact messages will be sent to this email.)</label>
                  <input type="text" className="form-control" name="mail_contact" placeholder="Email" defaultValue />
                </div>
              </div>
              {/* /.box-body */}
              <div className="box-footer">
                <button type="submit" name="submit" value="contact" className="btn btn-primary pull-right">Save Changes</button>
              </div>
            </div>
          </div>

        </div>

      </section>

    </>
  )
}
export default EmailSetting