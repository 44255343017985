
function UpdateImage() {
    return (
        <>
            <section className="content">
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="box box-primary">
                            <div className="box-header with-border">
                                <h3 className="box-title">Update Image</h3>
                            </div>
                            {/* /.box-header */}
                            {/* form start */}
                            <form action="https://prernamedia.com/gallery_controller/update_gallery_image_post" encType="multipart/form-data" method="post" acceptCharset="utf-8">
                                <input type="hidden" name="varient_csrf_token" defaultValue="3d53193b0ce930dea5ad6663457d1f8b" />
                                <div className="box-body">
                                    {/* include message block */}
                                    {/*print error messages*/}
                                    {/*print custom error message*/}
                                    {/*print custom success message*/}
                                    <input type="hidden" name="id" defaultValue={1} />
                                    <input type="hidden" name="path_big" defaultValue />
                                    <input type="hidden" name="path_small" defaultValue />
                                    <div className="form-group">
                                        <label>Language</label>
                                        <select name="lang_id" className="form-control" onchange="get_albums_by_lang(this.value);" fdprocessedid="1j6vmn">
                                            <option value={1} selected>English</option>
                                            <option value={2}>Hindi</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Album</label>
                                        <select name="album_id" id="albums" className="form-control" required onchange="get_categories_by_albums(this.value);" fdprocessedid="9wc5ws">
                                            <option value>Select</option>
                                            <option value={1} selected>Media</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Category</label>
                                        <select id="categories" name="category_id" className="form-control" fdprocessedid="bvrjw">
                                            <option value>Select</option>
                                            <option value={1} selected>
                                                Ad_photos                              </option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Title</label>
                                        <input type="text" className="form-control" name="title" id="title" placeholder="Title" defaultValue="b1" fdprocessedid="leh8am" />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Image </label>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <img src="https://prernamedia.com/" alt className="thumbnail img-responsive" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <a className="btn btn-success btn-sm btn-file-upload">
                                                    Select Image                                <input type="file" id="Multifileupload" name="file" size={40} accept=".png, .jpg, .jpeg, .gif" style={{ cursor: 'pointer' }} />
                                                </a>
                                            </div>
                                        </div>
                                        <div id="MultidvPreview" />
                                    </div>
                                </div>
                                {/* /.box-body */}
                                <div className="box-footer">
                                    <button type="submit" className="btn btn-primary pull-right" fdprocessedid="ogsgs">Save Changes</button>
                                </div>
                                {/* /.box-footer */}
                            </form>{/* form end */}
                        </div>
                        {/* /.box */}
                    </div>
                </div>
            </section>

        </>
    )
}
export default UpdateImage