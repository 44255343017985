
function AddMenuLink() {
    return (
        <>
       <div className="box box-primary">
  <div className="box-header with-border">
    <h3 className="box-title">Add Menu Link</h3>
  </div>
  {/* /.box-header */}
  {/* form start */}
  <form action="https://prernamedia.com/admin_controller/add_menu_link_post" method="post" acceptCharset="utf-8">
    <input type="hidden" name="varient_csrf_token" defaultValue="aadfcd795a80e220ec5908f7a85daf40" />                                  
    <input type="hidden" name="lang_id" defaultValue={1} />
    <div className="box-body">
      {/* include message block */}
      {/*print error messages*/}
      {/*print custom error message*/}
      {/*print custom success message*/}
      <div className="form-group">
        <label>Title</label>
        <input type="text" className="form-control" name="title" placeholder="Title" defaultValue maxLength={200} required fdprocessedid="errqr" />
      </div>
      <div className="form-group">
        <label>Link</label>
        <input type="text" className="form-control" name="link" placeholder="Link" defaultValue fdprocessedid="w1vhvl" />
      </div>
      <div className="form-group">
        <label>Menu Order</label>
        <input type="number" className="form-control" name="page_order" placeholder="Menu Order" defaultValue={1} min={1} fdprocessedid="hdbc88" />
      </div>
      <div className="form-group">
        <label className="control-label">Parent Link</label>
        <select id="parent_links" name="parent_id" className="form-control" fdprocessedid="t4o13">
          <option value={0}>None</option>
        </select>
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col-sm-4 col-xs-12">
            <label>Show on Menu</label>
          </div>
          <div className="col-md-3 col-sm-4 col-xs-12 col-option">
            <div className="iradio_square-purple checked" style={{position: 'relative'}}><input type="radio" id="rb_show_on_menu_1" name="visibility" defaultValue={1} className="square-purple" defaultChecked style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /><ins className="iCheck-helper" style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /></div>
            <label htmlFor="rb_show_on_menu_1" className="cursor-pointer">Yes</label>
          </div>
          <div className="col-md-3 col-sm-4 col-xs-12 col-option">
            <div className="iradio_square-purple" style={{position: 'relative'}}><input type="radio" id="rb_show_on_menu_2" name="visibility" defaultValue={0} className="square-purple" style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /><ins className="iCheck-helper" style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /></div>
            <label htmlFor="rb_show_on_menu_2" className="cursor-pointer">No</label>
          </div>
        </div>
      </div>
    </div>
    {/* /.box-body */}
    <div className="box-footer">
      <button type="submit" className="btn btn-primary pull-right" fdprocessedid="8dgg5">Add Menu Link</button>
    </div>
    {/* /.box-footer */}
  </form>{/* form end */}
</div>

        </>
    )
}
export default AddMenuLink