import { useState } from "react";
import { Button, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"
import AddCategory from "./addCategories/AddCategory"

function Categories() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <section className="content">
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <AddCategory />
                        {/* /.box */}
                    </div>
                    <div className="col-lg-7 col-md-12">
                        <div className="box">
                            <div className="box-header with-border">
                                <div className="pull-left">
                                    <h3 className="box-title">Categories</h3>
                                </div>
                            </div>{/* /.box-header */}
                            <div className="box-body">
                                <div className="row">
                                    {/* include message block */}
                                    <div className="col-sm-12">
                                        {/*print error messages*/}
                                        {/*print custom error message*/}
                                        {/*print custom success message*/}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="table-responsive">
                                            <div id="cs_datatable_lang_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer"><div className="dataTables_length" id="cs_datatable_lang_length"><label>Show <select name="cs_datatable_lang_length" aria-controls="cs_datatable_lang" className="form-control input-sm" fdprocessedid="ylruw"><option value={15}>15</option><option value={30}>30</option><option value={60}>60</option><option value={100}>100</option></select></label></div><div id="table_dropdown"><label className="table-label">Language</label><select className="form-control input-sm" fdprocessedid="un3i6"><option value>All</option><option value="English">English</option><option value="Hindi">Hindi</option></select></div><div id="cs_datatable_lang_filter" className="dataTables_filter"><label>Search<input type="search" className="form-control input-sm" placeholder aria-controls="cs_datatable_lang" /></label></div><table className="table table-bordered table-striped dataTable no-footer" id="cs_datatable_lang" role="grid" aria-describedby="cs_datatable_lang_info">
                                                <thead>
                                                    <tr role="row"><th width={20} className="sorting_desc" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-sort="descending" aria-label="Id: activate to sort column ascending" style={{ width: 20 }}>Id</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Category Name: activate to sort column ascending" style={{ width: '113.838px' }}>Category Name</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Language: activate to sort column ascending" style={{ width: '74.7875px' }}>Language</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Order: activate to sort column ascending" style={{ width: '47.1px' }}>Order</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Color: activate to sort column ascending" style={{ width: '53.225px' }}>Color</th><th className="max-width-120 sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Options: activate to sort column ascending" style={{ width: '105.75px' }}>Options</th></tr>
                                                </thead>
                                                <tbody>
                                                    <tr role="row" className="odd">
                                                        <td className="sorting_1">71</td>
                                                        <td>Funding</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>18</td>
                                                        <td>
                                                            <div style={{ width: 60, height: 30, backgroundColor: '#2c50f1' }} />
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="iqwtyl">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <Link to="/admin/navigation/update-category"><i className="fa fa-edit option-icon" />Edit</Link>
                                                                    </li>
                                                                    <li>
                                                                        <button onClick={handleShow}><i className="fa fa-trash option-icon" />Delete</button>

                                                                        <Modal show={show} onHide={handleClose}>
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title>
                                                                        
                                                                                </Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>Are you sure you want to delete this category?</Modal.Body>
                                                                            <Modal.Footer>
                                                                                <Button variant="secondary" onClick={handleClose}>
                                                                                    Cancel
                                                                                </Button>
                                                                                <Button variant="danger" onClick={handleClose}>
                                                                                    ok
                                                                                </Button>
                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="even">
                                                        <td className="sorting_1">70</td>
                                                        <td>Politics</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>3</td>
                                                        <td>
                                                            <div style={{ width: 60, height: 30, backgroundColor: '#1877f2' }} />
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="03rcke">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <Link to="/admin/navigation/update-category"><i className="fa fa-edit option-icon" />Edit</Link>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','70','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="odd">
                                                        <td className="sorting_1">69</td>
                                                        <td>VIDEO</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>2</td>
                                                        <td>
                                                            <div style={{ width: 60, height: 30, backgroundColor: '#1877f2' }} />
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="9i43z8">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <Link to="/admin/navigation/update-category"><i className="fa fa-edit option-icon" />Edit</Link>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','69','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="even">
                                                        <td className="sorting_1">15</td>
                                                        <td>Business</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>17</td>
                                                        <td>
                                                            <div style={{ width: 60, height: 30, backgroundColor: '#1877f2' }} />
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="s7xu4g">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <Link to="/admin/navigation/update-category"><i className="fa fa-edit option-icon" />Edit</Link>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','15','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="odd">
                                                        <td className="sorting_1">14</td>
                                                        <td>रसोई</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>16</td>
                                                        <td>
                                                            <div style={{ width: 60, height: 30, backgroundColor: '#1877F2' }} />
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="mgk64">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <Link to="/admin/navigation/update-category"><i className="fa fa-edit option-icon" />Edit</Link>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','14','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="even">
                                                        <td className="sorting_1">13</td>
                                                        <td>हेल्थ</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>15</td>
                                                        <td>
                                                            <div style={{ width: 60, height: 30, backgroundColor: '#1877F2' }} />
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="dt6qvr">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <Link to="/admin/navigation/update-category"><i className="fa fa-edit option-icon" />Edit</Link>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','13','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="odd">
                                                        <td className="sorting_1">12</td>
                                                        <td>कैरियर</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>14</td>
                                                        <td>
                                                            <div style={{ width: 60, height: 30, backgroundColor: '#1877F2' }} />
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="7sr7iq">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <Link to="/admin/navigation/update-category"><i className="fa fa-edit option-icon" />Edit</Link>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','12','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="even">
                                                        <td className="sorting_1">11</td>
                                                        <td>आस्था</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>13</td>
                                                        <td>
                                                            <div style={{ width: 60, height: 30, backgroundColor: '#1877F2' }} />
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="y12k4">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <Link to="/admin/navigation/update-category"><i className="fa fa-edit option-icon" />Edit</Link>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','11','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="odd">
                                                        <td className="sorting_1">10</td>
                                                        <td>लाइफस्टाइल</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>12</td>
                                                        <td>
                                                            <div style={{ width: 60, height: 30, backgroundColor: '#1877F2' }} />
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="5byhj">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <Link to="/admin/navigation/update-category"><i className="fa fa-edit option-icon" />Edit</Link>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','10','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="even">
                                                        <td className="sorting_1">9</td>
                                                        <td>ज्ञान-विज्ञान</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>11</td>
                                                        <td>
                                                            <div style={{ width: 60, height: 30, backgroundColor: '#1877F2' }} />
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="yfxd3f">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <Link to="/admin/navigation/update-category"><i className="fa fa-edit option-icon" />Edit</Link>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','9','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="odd">
                                                        <td className="sorting_1">8</td>
                                                        <td>B&amp;E</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>10</td>
                                                        <td>
                                                            <div style={{ width: 60, height: 30, backgroundColor: '#1877f2' }} />
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="6lodvv">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <Link to="/admin/navigation/update-category"><i className="fa fa-edit option-icon" />Edit</Link>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','8','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="even">
                                                        <td className="sorting_1">7</td>
                                                        <td>खेल</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>9</td>
                                                        <td>
                                                            <div style={{ width: 60, height: 30, backgroundColor: '#1877F2' }} />
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="hmazqo">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <Link to="/admin/navigation/update-category"><i className="fa fa-edit option-icon" />Edit</Link>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','7','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="odd">
                                                        <td className="sorting_1">6</td>
                                                        <td>बॉयोस्कोप</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>8</td>
                                                        <td>
                                                            <div style={{ width: 60, height: 30, backgroundColor: '#1877F2' }} />
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="cwir968">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <a href="https://prernamedia.com/admin/update-category/6"><i className="fa fa-edit option-icon" />Edit</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','6','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="even">
                                                        <td className="sorting_1">5</td>
                                                        <td>वर्ल्ड</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>4</td>
                                                        <td>
                                                            <div style={{ width: 60, height: 30, backgroundColor: '#1877F2' }} />
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="eu0h7g">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <a href="https://prernamedia.com/admin/update-category/5"><i className="fa fa-edit option-icon" />Edit</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','5','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr><tr role="row" className="odd">
                                                        <td className="sorting_1">4</td>
                                                        <td>देश</td>
                                                        <td>
                                                            English                                      </td>
                                                        <td>5</td>
                                                        <td>
                                                            <div style={{ width: 60, height: 30, backgroundColor: '#1877F2' }} />
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" fdprocessedid="oanmlu">Select an option                                                    <span className="caret" />
                                                                </button>
                                                                <ul className="dropdown-menu options-dropdown">
                                                                    <li>
                                                                        <a href="https://prernamedia.com/admin/update-category/4"><i className="fa fa-edit option-icon" />Edit</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:void(0)" onclick="delete_item('category_controller/delete_category_post','4','Are you sure you want to delete this category?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr></tbody>
                                            </table><div className="dataTables_info" id="cs_datatable_lang_info" role="status" aria-live="polite">Showing 1 to 15 of 17 entries</div><div className="dataTables_paginate paging_simple_numbers" id="cs_datatable_lang_paginate"><ul className="pagination"><li className="paginate_button previous disabled" id="cs_datatable_lang_previous"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={0} tabIndex={0}>‹</a></li><li className="paginate_button active"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={1} tabIndex={0}>1</a></li><li className="paginate_button "><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={2} tabIndex={0}>2</a></li><li className="paginate_button next" id="cs_datatable_lang_next"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={3} tabIndex={0}>›</a></li></ul></div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* /.box-body */}
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default Categories