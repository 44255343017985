import { useState } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [state, setState] = useState(false)
  const togleButton = (event) => {
    // document.body.classList.add('sidebar-collapse')
    document.body.classList.add('sidebar-open')

    if (state) {
      // document.body.classList.remove('sidebar-collapse')
      document.body.classList.remove('sidebar-open')
    }

    setState(!state)
  }
  return (
    <>
      <header className="main-header">
        {/* Logo */}
        <a href="https://prernamedia.com/admin/" className="logo">
          {/* mini logo for sidebar mini 50x50 pixels */}
          <span className="logo-mini" />
          {/* logo for regular state and mobile devices */}
          <span className="logo-lg"><b>Prerna News India</b> Panel</span>
        </a>
        {/* Header Navbar: style can be found in header.less */}
        <nav className="navbar navbar-static-top" role="navigation">
          {/* Sidebar toggle button*/}
          <Link to="#" onClick={togleButton} className="sidebar-toggle" data-toggle="push-menu" role="button">
            <i className="fa fa-bars"  />
          </Link>
          <div className="navbar-custom-menu">
            <form action="https://prernamedia.com/admin_controller/control_panel_language_post" id="form_control_panel_lang" method="post" acceptCharset="utf-8">
              <input type="hidden" name="varient_csrf_token" defaultValue="9380223c80b5c51d05169904f2cd3146" />
              <ul className="nav navbar-nav">
                <li>
                  <a className="btn btn-sm btn-success pull-left btn-site-prev" target="_blank" href="https://prernamedia.com/"><i className="fa fa-eye" /> View Site</a>
                </li>
                <li className="dropdown user-menu">
                  <a className="dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="false">
                    <i className="fa fa-globe" />&nbsp;
                    English
                    {/* <span className="fa fa-caret-down" /> */}
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <button type="submit" value={1} name="lang_id" className="control-panel-lang-btn">English</button>
                    </li>
                    <li>
                      <button type="submit" value={2} name="lang_id" className="control-panel-lang-btn">Hindi</button>
                    </li>
                  </ul>
                </li>
                <li className="dropdown user user-menu">
                  <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                    <img src="https://prernamedia.com/assets/img/user.png" className="user-image" alt />
                    <span className="hidden-xs">prernamedia_admin
                      {/* <i className="fa fa-caret-down" />  */}
                    </span>
                  </a>
                  <ul className="dropdown-menu  pull-right" role="menu" aria-labelledby="user-options">
                    <li>
                      <a href="https://prernamedia.com/profile/prernamedia_admin"><i className="fa fa-user" /> Profile</a>
                    </li>
                    <li>
                      <a href="https://prernamedia.com/settings"><i className="fa fa-cog" /> Update Profile</a>
                    </li>
                    <li>
                      <a href="https://prernamedia.com/settings/change-password"><i className="fa fa-lock" /> Change Password</a>
                    </li>
                    <li className="divider" />
                    <li>
                      <a href="https://prernamedia.com/logout"><i className="fa fa-sign-out" /> Logout</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </form>          </div>
        </nav>
      </header>

    </>
  )
}
export default Header