import { Link } from "react-router-dom"
import Editor from "../../commonComponents/editor/Editor"

function AddWidgets() {
    return (
        <>
            <section className="content">
                <div className="row">
                    <div className="col-sm-12 col-xs-12">
                        <div className="box box-primary">
                            <div className="box-header with-border">
                                <div className="left">
                                    <h3 className="box-title">Add Widget</h3>
                                </div>
                                <div className="right">
                                    <Link to="/admin/widgets" className="btn btn-success btn-add-new">
                                        <i className="fa fa-bars" />
                                        Widgets                      </Link>
                                </div>
                            </div>{/* /.box-header */}
                            {/* form start */}
                            <form action="https://prernamedia.com/widget_controller/add_widget_post" encType="multipart/form-data" method="post" acceptCharset="utf-8">
                                <input type="hidden" name="varient_csrf_token" defaultValue="0453982c1ee679f7a571bf17f277d514" />
                                <input type="hidden" name="is_custom" defaultValue={1} />
                                <input type="hidden" name="type" defaultValue="custom" />
                                <div className="box-body">
                                    {/* include message block */}
                                    {/*print error messages*/}
                                    {/*print custom error message*/}
                                    {/*print custom success message*/}
                                    <div className="form-group">
                                        <label className="control-label">Title</label>
                                        <input type="text" className="form-control" name="title" placeholder="Title" defaultValue required fdprocessedid="upfuue" />
                                    </div>
                                    <div className="form-group">
                                        <label>Language</label>
                                        <select name="lang_id" className="form-control max-600" fdprocessedid="f75gcq">
                                            <option value={1} selected>English</option>
                                            <option value={2}>Hindi</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Order</label>
                                        <input type="number" className="form-control max-600" name="widget_order" min={1} max={3000} placeholder="Order" defaultValue={1} required fdprocessedid="tdpyq3" />
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-3 col-xs-12">
                                                <label>Visibility</label>
                                            </div>
                                            <div className="col-md-2 col-sm-4 col-xs-12 col-option">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                        Show
                                                    </label>
                                                </div>

                                                {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" id="rb_visibility_1" name="visibility" defaultValue={1} className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                                <label htmlFor="rb_visibility_1" className="cursor-pointer">Show</label> */}
                                            </div>
                                            <div className="col-md-2 col-sm-4 col-xs-12 col-option">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                    Hide
                                                    </label>
                                                </div>

                                                {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" id="rb_visibility_2" name="visibility" defaultValue={0} className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                                <label htmlFor="rb_visibility_2" className="cursor-pointer">Hide</label> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div id="main_editor">
                                            <label>Content</label>
                                            <div className="row">
                                                <div className="col-sm-12 editor-buttons">
                                                    <button type="button" className="btn btn-sm btn-success" data-toggle="modal" data-target="#file_manager_image" data-image-type="editor" fdprocessedid="6n46sq"><i className="fa fa-image" />&nbsp;&nbsp;&nbsp;Add Image</button>
                                                    {/* <button type="button" className="btn btn-sm bg-purple btn_tinymce_add_media" data-editor-id="main_editor" fdprocessedid="2dfne"><i className="fa fa-file" />&nbsp;&nbsp;&nbsp;Add Media</button> */}
                                                </div>
                                            </div>
                                            <Editor />
                                        </div>
                                    </div>
                                </div>
                                {/* /.box-body */}
                                <div className="box-footer">
                                    <button type="submit" className="btn btn-primary pull-right" fdprocessedid="2qumdv">Add Widget</button>
                                </div>
                                {/* /.box-footer */}
                            </form>{/* form end */}
                        </div>
                        {/* /.box */}
                    </div>
                </div>
                <link rel="stylesheet" href="https://prernamedia.com/assets/admin/plugins/file-uploader/css/jquery.dm-uploader.min.css" />
                <link rel="stylesheet" href="https://prernamedia.com/assets/admin/plugins/file-uploader/css/styles.css" />
                <link rel="stylesheet" href="https://prernamedia.com/assets/admin/plugins/file-manager/fileicon.css" />
                {/* Modal */}
                <div id="file_manager_image" className="modal fade modal-file-manager" role="dialog">
                    <div className="modal-dialog modal-lg">
                        {/* Modal content*/}
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">×</button>
                                <h4 className="modal-title">Images</h4>
                                <div className="file-manager-search">
                                    <input type="text" id="input_search_image" className="form-control" placeholder="Search" />
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="file-manager">
                                    <div className="file-manager-left">
                                        <div className="dm-uploader-container">
                                            <div id="drag-and-drop-zone-image" className="dm-uploader text-center">
                                                <p className="file-manager-file-types">
                                                    <span>JPG</span>
                                                    <span>JPEG</span>
                                                    <span>PNG</span>
                                                    <span>GIF</span>
                                                </p>
                                                <p className="dm-upload-icon">
                                                    <i className="fa fa-cloud-upload" />
                                                </p>
                                                <p className="dm-upload-text">Drag and drop files here or</p>
                                                <p className="text-center">
                                                    <button className="btn btn-default btn-browse-files">Browse Files</button>
                                                </p>
                                                <a className="btn btn-md dm-btn-select-files">
                                                    <input type="file" name="file" size={40} multiple />
                                                </a>
                                                <ul className="dm-uploaded-files" id="files-image" />
                                                <button type="button" id="btn_reset_upload_image" className="btn btn-reset-upload">Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="file-manager-right">
                                        <div className="file-manager-content">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div id="image_file_upload_response" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="hidden" id="selected_img_file_id" />
                                    <input type="hidden" id="selected_img_mid_file_path" />
                                    <input type="hidden" id="selected_img_default_file_path" />
                                    <input type="hidden" id="selected_img_slider_file_path" />
                                    <input type="hidden" id="selected_img_big_file_path" />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="file-manager-footer">
                                    <button type="button" id="btn_img_delete" className="btn btn-danger pull-left btn-file-delete"><i className="fa fa-trash" />&nbsp;&nbsp;Delete</button>
                                    <button type="button" id="btn_img_select" className="btn bg-olive btn-file-select"><i className="fa fa-check" />&nbsp;&nbsp;Select Image</button>
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* File item template */}
            </section>

        </>
    )
}
export default AddWidgets