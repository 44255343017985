
import GeneralSettings from "../../Components/GeneralSettings/GeneralSettings";

function GeneralSettingsPage(){
  return (
    <>
      <GeneralSettings/>
    </>
  )
}
export default GeneralSettingsPage;