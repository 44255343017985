// import Posts from "../../../Components/posts/Posts";

import Posts from "../../../Components/postsCompMain/posts/Posts";

const data = {
  title: "post",
}
function PostsPage() {

  return (
    <>
      <Posts data={data} />
    </>
  )
}
export default PostsPage;