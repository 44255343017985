
function AddUsers() {
    return (
        <>
        <section className="content">
  <div className="row">
    <div className="col-lg-6 col-md-12">
      <div className="box box-primary">
        <div className="box-header with-border">
          <h3 className="box-title">Add User</h3>
        </div>
        {/* /.box-header */}
        {/* form start */}
        <form action="https://prernamedia.com/admin_controller/add_user_post" encType="multipart/form-data" method="post" acceptCharset="utf-8">
          <input type="hidden" name="varient_csrf_token" defaultValue="b7cee03983b9efdb13b50854fc1438db" />                                                                                                                               
          <div className="box-body">
            {/* include message block */}
            {/*print error messages*/}
            {/*print custom error message*/}
            {/*print custom success message*/}
            <div className="form-group">
              <label>Username</label>
              <input type="text" name="username" className="form-control auth-form-input" placeholder="Username" defaultValue required fdprocessedid="tf0udp" />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input type="email" name="email" className="form-control auth-form-input" placeholder="Email" defaultValue required fdprocessedid="9ppbka" />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input type="password" name="password" className="form-control auth-form-input" placeholder="Password" defaultValue required fdprocessedid="6h5xx" />
            </div>
            <div className="form-group">
              <label>Role</label>
              <select name="role" className="form-select" fdprocessedid="co0sx">
                <option value="admin">Admin</option>
                <option value="moderator">Moderator</option>
                <option value="author">Author</option>
                <option value="user">User</option>
              </select>
            </div>
          </div>
          {/* /.box-body */}
          <div className="box-footer">
            <button type="submit" className="btn btn-primary pull-right" fdprocessedid="szn1e3">Add User</button>
          </div>
          {/* /.box-footer */}
        </form>{/* form end */}
      </div>
      {/* /.box */}
    </div>
  </div>
</section>

        </>
    )
}
export default AddUsers