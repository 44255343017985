import { Link } from "react-router-dom"

function AddPolls() {
    return (
        <>
            <section className="content">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="box box-primary">
                            <div className="box-header with-border">
                                <div className="left">
                                    <h3 className="box-title">Add Poll</h3>
                                </div>
                                <div className="right">
                                    <Link to="/admin/polls" className="btn btn-success btn-add-new">
                                        <i className="fa fa-bars" />
                                        Polls                  </Link>
                                </div>
                            </div>{/* /.box-header */}
                            {/* form start */}
                            <form action="https://prernamedia.com/poll_controller/add_poll_post" encType="multipart/form-data" method="post" acceptCharset="utf-8">
                                <input type="hidden" name="varient_csrf_token" defaultValue="0453982c1ee679f7a571bf17f277d514" />
                                <div className="box-body">
                                    {/* include message block */}
                                    {/*print error messages*/}
                                    {/*print custom error message*/}
                                    {/*print custom success message*/}
                                    <div className="form-group">
                                        <label>Language</label>
                                        <select name="lang_id" className="form-control max-600" fdprocessedid="2tv34a">
                                            <option value={1} selected>English</option>
                                            <option value={2}>Hindi</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Question</label>
                                        <textarea className="form-control text-area" name="question" placeholder="Question" required defaultValue={""} />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Option 1</label>
                                        <input type="text" className="form-control" name="option1" placeholder="Option 1" defaultValue required fdprocessedid="powwts" />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Option 2</label>
                                        <input type="text" className="form-control" name="option2" placeholder="Option 2" defaultValue required fdprocessedid="ttc81n" />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Option 3</label>
                                        <input type="text" className="form-control" name="option3" placeholder="Option 3 (Optional)" defaultValue fdprocessedid="t1m0gk" />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Option 4</label>
                                        <input type="text" className="form-control" name="option4" placeholder="Option 4 (Optional)" defaultValue fdprocessedid="co51o" />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Option 5</label>
                                        <input type="text" className="form-control" name="option5" placeholder="Option 5 (Optional)" defaultValue fdprocessedid="c0gue5" />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Option 6</label>
                                        <input type="text" className="form-control" name="option6" placeholder="Option 6 (Optional)" defaultValue fdprocessedid="zom6mj" />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Option 7</label>
                                        <input type="text" className="form-control" name="option7" placeholder="Option 7 (Optional)" defaultValue fdprocessedid="2bddfp" />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Option 8</label>
                                        <input type="text" className="form-control" name="option8" placeholder="Option 8 (Optional)" defaultValue fdprocessedid="4qkih5" />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Option 9</label>
                                        <input type="text" className="form-control" name="option9" placeholder="Option 9 (Optional)" defaultValue fdprocessedid="c146lr" />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Option 10</label>
                                        <input type="text" className="form-control" name="option10" placeholder="Option 10 (Optional)" defaultValue fdprocessedid="ftngxr" />
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-3 col-xs-12">
                                                <label>Vote Permission</label>
                                            </div>
                                            <div className="col-md-3 col-sm-4 col-xs-12 col-option">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                        All Users Can Vote
                                                    </label>
                                                </div>

                                                {/* <div className="iradio_square-purple checked" style={{position: 'relative'}}><input type="radio" name="vote_permission" defaultValue="all" id="vote_permission1" className="square-purple" defaultChecked style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /><ins className="iCheck-helper" style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /></div>
                  <label htmlFor="vote_permission1" className="option-label">All Users Can Vote</label> */}
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12 col-option">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                        Only Registered Users Can Vote
                                                    </label>
                                                </div>

                                                {/* <div className="iradio_square-purple" style={{position: 'relative'}}><input type="radio" name="vote_permission" defaultValue="registered" id="vote_permission2" className="square-purple" style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /><ins className="iCheck-helper" style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /></div>
                  <label htmlFor="vote_permission2" className="option-label">Only Registered Users Can Vote</label> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-3 col-xs-12">
                                                <label>Status</label>
                                            </div>
                                            <div className="col-md-3 col-sm-4 col-xs-12 col-option">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault1" />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                        Active
                                                    </label>
                                                </div>

                                                {/* <div className="iradio_square-purple checked" style={{position: 'relative'}}><input type="radio" name="status" defaultValue={1} id="status1" className="square-purple" defaultChecked style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /><ins className="iCheck-helper" style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /></div>
                  <label htmlFor="status1" className="option-label">Active</label> */}
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12 col-option">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault2" defaultChecked />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                        Inactive
                                                    </label>
                                                </div>

                                                {/* <div className="iradio_square-purple" style={{position: 'relative'}}><input type="radio" name="status" defaultValue={0} id="status2" className="square-purple" style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /><ins className="iCheck-helper" style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /></div>
                  <label htmlFor="status2" className="option-label">Inactive</label> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.box-body */}
                                <div className="box-footer">
                                    <button type="submit" className="btn btn-primary pull-right" fdprocessedid="zxxn9e">Add Poll</button>
                                </div>
                                {/* /.box-footer */}
                            </form>{/* form end */}
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default AddPolls