
function UpdateLanguage() {
    return (
        <>
       <section className="content">
  <div className="row">
    <div className="col-lg-5 col-md-12">
      <div className="box box-primary">
        <div className="box-header with-border">
          <h3 className="box-title">Update Language</h3>
        </div>
        {/* /.box-header */}
        {/* form start */}
        <form action="https://prernamedia.com/language_controller/update_language_post" method="post" acceptCharset="utf-8">
          <input type="hidden" name="varient_csrf_token" defaultValue="52fe97bd0f9f9b80fdae0b7d1da72071" />                                                      
          <input type="hidden" name="id" defaultValue={2} />
          <div className="box-body">
            {/* include message block */}
            {/*print error messages*/}
            {/*print custom error message*/}
            {/*print custom success message*/}
            <div className="form-group">
              <label>Language Name</label>
              <input type="text" className="form-control" name="name" placeholder="Language Name" defaultValue="Hindi" maxLength={200} required />
              <small>(Ex: English)</small>
            </div>
            <div className="form-group">
              <label className="control-label">Short Form </label>
              <input type="text" className="form-control" name="short_form" placeholder="Short Form" defaultValue="hn" maxLength={200} required />
              <small>(Ex: en)</small>
            </div>
            <div className="form-group">
              <label className="control-label">Language Code </label>
              <input type="text" className="form-control" name="language_code" placeholder="Language Code" defaultValue="hn-IND" maxLength={200} required />
              <small>(Ex: en_us)</small>
            </div>
            <div className="form-group">
              <label>Order</label>
              <input type="number" className="form-control" name="language_order" placeholder="Order" defaultValue={1} min={1} max={3000} required />
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-sm-3 col-xs-12">
                  <label>Text Direction</label>
                </div>
                <div className="col-sm-4 col-xs-12 col-option">
                  <div className="iradio_square-purple checked" style={{position: 'relative'}}><input type="radio" id="rb_type_1" name="text_direction" defaultValue="ltr" className="square-purple" defaultChecked style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /><ins className="iCheck-helper" style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /></div>
                  <label htmlFor="rb_type_1" className="cursor-pointer">Left to Right</label>
                </div>
                <div className="col-sm-4 col-xs-12 col-option">
                  <div className="iradio_square-purple" style={{position: 'relative'}}><input type="radio" id="rb_type_2" name="text_direction" defaultValue="rtl" className="square-purple" style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /><ins className="iCheck-helper" style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /></div>
                  <label htmlFor="rb_type_2" className="cursor-pointer">Right to Left</label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-sm-3 col-xs-12">
                  <label>Status</label>
                </div>
                <div className="col-sm-4 col-xs-12 col-option">
                  <div className="iradio_square-purple checked" style={{position: 'relative'}}><input type="radio" name="status" defaultValue={1} id="status1" className="square-purple" defaultChecked style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /><ins className="iCheck-helper" style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /></div>
                  <label htmlFor="status1" className="option-label">Active</label>
                </div>
                <div className="col-sm-4 col-xs-12 col-option">
                  <div className="iradio_square-purple" style={{position: 'relative'}}><input type="radio" name="status" defaultValue={0} id="status2" className="square-purple" style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /><ins className="iCheck-helper" style={{position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0}} /></div>
                  <label htmlFor="status2" className="option-label">Inactive</label>
                </div>
              </div>
            </div>
          </div>
          {/* /.box-body */}
          <div className="box-footer">
            <button type="submit" className="btn btn-primary pull-right">Save Changes</button>
          </div>
          {/* /.box-footer */}
        </form>{/* form end */}
      </div>
      {/* /.box */}
    </div>
  </div>
</section>

        </>
    )
}
export default UpdateLanguage