import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function ConatctMessage() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <section className="content">
                <div className="box">
                    <div className="box-header with-border">
                        <div className="left">
                            <h3 className="box-title">Contact Messages</h3>
                        </div>
                    </div>{/* /.box-header */}
                    {/* include message block */}
                    <div className="col-sm-12">
                        {/*print error messages*/}
                        {/*print custom error message*/}
                        {/*print custom success message*/}
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    <div id="cs_datatable_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer"><div className="row"><div className="col-sm-6"><div className="dataTables_length" id="cs_datatable_length"><label>Show <select name="cs_datatable_length" aria-controls="cs_datatable" className="form-control input-sm"><option value={15}>15</option><option value={30}>30</option><option value={60}>60</option><option value={100}>100</option></select></label></div></div><div className="col-sm-6"><div id="cs_datatable_filter" className="dataTables_filter"><label>Search<input type="search" className="form-control input-sm" placeholder aria-controls="cs_datatable" /></label></div></div></div><div className="row"><div className="col-sm-12"><table className="table table-bordered table-striped dataTable no-footer" id="cs_datatable" role="grid" aria-describedby="cs_datatable_info">
                                        <thead>
                                            <tr role="row"><th width={20} className="table-no-sort sorting_desc" style={{ textAlign: 'center !important', width: 20 }} tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-sort="descending" aria-label=": activate to sort column ascending"><input type="checkbox" className="checkbox-table" id="checkAll" /></th><th width={20} className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Id: activate to sort column ascending" style={{ width: 20 }}>Id</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{ width: '97.925px' }}>Name</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Email: activate to sort column ascending" style={{ width: '152.45px' }}>Email</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Message: activate to sort column ascending" style={{ width: '354.062px' }}>Message</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Date: activate to sort column ascending" style={{ width: '135.8px' }}>Date</th><th className="max-width-120 sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Options: activate to sort column ascending" style={{ width: '134.163px' }}>Options</th></tr>
                                        </thead>
                                        <tbody>
                                            <tr role="row" className="odd">
                                                <td style={{ textAlign: 'center !important' }} className="sorting_1"><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={2} /></td>
                                                <td>2</td>
                                                <td>JeffreyExcug</td>
                                                <td>zelatcol@gmail.com</td>
                                                <td className="break-word">Dia duit, theastaigh uaim do phraghas a fháil.</td>
                                                <td>2023-03-11 / 08:47</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown" aria-expanded="false">Select an option                                          
                                                          {/* <span className="caret" /> */}
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <Link to="#" onclick="delete_item('admin_controller/delete_contact_message_post','2','Are you sure you want to delete this message?');" onClick={handleShow}><i className="fa fa-trash option-icon" />Delete</Link>
                                                                <Modal show={show} onHide={handleClose}>
                                                                    <Modal.Header closeButton>
                                                                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                                                                    </Modal.Header>
                                                                    <Modal.Body>Are you sure you want to delete this poll?</Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="secondary" onClick={handleClose}>
                                                                            Close
                                                                        </Button>
                                                                        <Button variant="danger" onClick={handleClose}>
                                                                            Ok
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr><tr role="row" className="even">
                                                <td style={{ textAlign: 'center !important' }} className="sorting_1"><input type="checkbox" name="checkbox-table" className="checkbox-table" defaultValue={1} /></td>
                                                <td>1</td>
                                                <td>RichardExcug</td>
                                                <td>zelatcol@gmail.com</td>
                                                <td className="break-word">Hi, ego volo scire vestri pretium.</td>
                                                <td>2023-03-04 / 05:53</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn bg-purple dropdown-toggle btn-select-option" type="button" data-toggle="dropdown">Select an option                                       
                                                             {/* <span className="caret" /> */}
                                                        </button>
                                                        <ul className="dropdown-menu options-dropdown">
                                                            <li>
                                                                <a href="javascript:void(0)" onclick="delete_item('admin_controller/delete_contact_message_post','1','Are you sure you want to delete this message?');"><i className="fa fa-trash option-icon" />Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr></tbody>
                                    </table></div></div><div className="row"><div className="col-sm-5"><div className="dataTables_info" id="cs_datatable_info" role="status" aria-live="polite">Showing 1 to 2 of 2 entries</div></div><div className="col-sm-7"><div className="dataTables_paginate paging_simple_numbers" id="cs_datatable_paginate"><ul className="pagination"><li className="paginate_button previous disabled" id="cs_datatable_previous"><a href="#" aria-controls="cs_datatable" data-dt-idx={0} tabIndex={0}>‹</a></li><li className="paginate_button active"><a href="#" aria-controls="cs_datatable" data-dt-idx={1} tabIndex={0}>1</a></li><li className="paginate_button next disabled" id="cs_datatable_next"><a href="#" aria-controls="cs_datatable" data-dt-idx={2} tabIndex={0}>›</a></li></ul></div></div></div></div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="row">
                                        <div className="pull-left">
                                            <button className="btn btn-sm btn-danger btn-table-delete" onclick="delete_selected_contact_messages('Are you sure you want to delete selected messages?');"><i className="fa fa-trash option-icon" />Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/* /.box-body */}
                </div>
            </section>

        </>
    )
}
export default ConatctMessage