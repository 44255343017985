import SocialLoginFacebook from "./socialLoginFacebook/SocialLoginFacebook";
import SocialLoginGoogle from "./socialLoginGoogle/SocialLoginGoogle";
import SocialLoginVKontakte from "./socialLoginVKontakte/SocialLoginVKontakte";

function SocialLoginConfiguration() {
  return (
    <>
      <section className="content">
        <div className="row" style={{ marginBottom: 15 }}>
          <div className="col-sm-12">
            <h3 style={{ fontSize: 18, fontWeight: 600 }}>Social Login Configuration</h3>
          </div>
        </div>

        <div className="row">
          <SocialLoginFacebook />
          <SocialLoginGoogle />
          <SocialLoginVKontakte />
        </div>
      </section>
    </>
  )
}
export default SocialLoginConfiguration;