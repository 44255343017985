import Posts from "../../../Components/postsCompMain/posts/Posts";
const data = {
  title: "Featured Posts",
}
function FeaturedPosts() {

  return (
    <>
      <Posts data={data} />
    </>
  )
}
export default FeaturedPosts;