import '../../../../node_modules/react-quill/dist/quill.snow.css'
import ReactQuill from "react-quill";
import { useState } from 'react';


function Editor() {
    const [body,setBody] = useState("")
 const handleBody = (e) => {
  setBody(e)
 }

    return (
        <>
        <ReactQuill
    placeholder='write something amazing...'
    modules={Editor.modules}
    formats={Editor.formats}
    onChange={handleBody}
    value={body}
    />
        </>
    )
}
Editor.modules = {
    toolbar:[
      [{header:'1'},{header:'2'}, {header:[3,4,5,6]}, {font:[]}],
      [{size:[]}],
      ['bold','italic','underline','strike','blockquote'],
      [{list:'ordered'},{list:'bullet'}],
      ['link','image','video'],
      ['clean'],
      ['code-block']
    ]
  };
  Editor.formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'image',
    'video',
    'code-block'
  ]
export default Editor