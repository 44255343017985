
function SeoTool() {
  return (
    <>
      <section className="content">
        <div className="row">
          <form action="https://prernamedia.com/admin_controller/seo_tools_post" method="post" acceptCharset="utf-8">
            <input type="hidden" name="varient_csrf_token" defaultValue="e2bd765919d5a399e4619f6b57df12a2" />
            <div className="col-lg-6 col-md-12">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">SEO Tools</h3>
                </div>
                {/* /.box-header */}
                {/* form start */}
                <div className="box-body">
                  {/* include message block */}
                  {/*print error messages*/}
                  {/*print custom error message*/}
                  {/*print custom success message*/}
                  <div className="form-group">
                    <label>Settings Language</label>
                    <select name="lang_id" className="form-control max-400" onchange="window.location.href = 'https://prernamedia.com/admin/'+'seo-tools?lang='+this.value;">
                      <option value={1} selected>English</option>
                      <option value={2}>Hindi</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="control-label">Site Title</label>
                    <input type="text" className="form-control" name="site_title" placeholder="Site Title" defaultValue="Hindi News (हिंदी न्यूज़), Latest Live News In Hindi Today (आज के ताजा समाचार) Breaking News In Hindi (ब्रेकिंग न्यूज़) - City News INDIA" />
                  </div>
                  <div className="form-group">
                    <label className="control-label">Home Title</label>
                    <input type="text" className="form-control" name="home_title" placeholder="Home Title" defaultValue="Welcome to City News INDIA :  Latest Live News In Hindi Today (आज के ताजा समाचार) Breaking News In Hindi (ब्रेकिंग न्यूज़)" />
                  </div>
                  <div className="form-group">
                    <label className="control-label">Site Description</label>
                    <input type="text" className="form-control" name="site_description" placeholder="Site Description" defaultValue="आज की ताजा खबर पढे (Live News) हिंदी में City News INDIA पर, #1 Hindi News website - पढ़ें भारत और दुनिया की aaj ki breaking (ब्रेकिंग) khabar. AAJ Tak (आज तक ) के मुख्य समाचार जो है,  व्यापार, राजनीति, बॉलीवुड, मनोरंजन और खेल जगत से जुडे। बने रहे लाइव ब्रेकिंग न्यूज़ के लिए।" />
                  </div>
                  <div className="form-group">
                    <label className="control-label">Keywords</label>
                    <textarea className="form-control text-area" name="keywords" placeholder="Keywords" style={{ minHeight: 100 }} defaultValue={"Hindi news, news in hindi, breaking news in hindi, latest news in hindi, latest hindi news, today news in hindi, hindi news today, hindi news paper, हिंदी न्यूज़, हिन्दी समाचार, हिंदी में समाचार, City News INDIA"} />
                  </div>
                  <div className="form-group">
                    <label className="control-label">Google Analytics</label>
                    <textarea className="form-control text-area" name="google_analytics" placeholder="Google Analytics Code" style={{ minHeight: 100 }} defaultValue={""} />
                  </div>
                  {/* /.box-body */}
                  <div className="box-footer" style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <button type="submit" className="btn btn-primary pull-right">Save Changes</button>
                  </div>
                  {/* /.box-footer */}
                </div>
                {/* /.box */}
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Sitemap</h3>
                </div>
                <form action="https://prernamedia.com/sitemap_controller/generate_sitemap_post" method="post" acceptCharset="utf-8">
                  <input type="hidden" name="varient_csrf_token" defaultValue="e2bd765919d5a399e4619f6b57df12a2" />
                  <div className="box-body">
                    <div className="form-group">
                      <label className="label-sitemap">Frequency</label>
                      <small className="small-sitemap"> (This value indicates how frequently the content at a particular URL is likely to change)</small>
                      <select name="frequency" className="form-control">
                        <option value="none">None</option>
                        <option value="always">Always</option>
                        <option value="hourly" selected>Hourly</option>
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                        <option value="never">Never</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="label-sitemap">Last Modification</label>
                      <small className="small-sitemap"> (The time the URL was last modified)</small>
                      <p>
                      </p><div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="last_modification" id="last_modification_1" defaultValue="none" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                      <label htmlFor="last_modification_1" className="cursor-pointer">&nbsp;None</label>
                      <p />
                      <p>
                      </p><div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="last_modification" id="last_modification_2" defaultValue="server_response" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                      <label htmlFor="last_modification_2" className="cursor-pointer">&nbsp;Server's Response</label>
                      <p />
                    </div>
                    <div className="form-group">
                      <label className="label-sitemap">Priority</label>
                      <small className="small-sitemap"> (The priority of a particular URL relative to other pages on the same site)</small>
                      <p>
                      </p><div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="priority" id="priority_1" defaultValue="none" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                      <label htmlFor="priority_1" className="cursor-pointer">&nbsp;None</label>
                      <p />
                      <p>
                      </p><div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="priority" id="priority_2" defaultValue="automatically" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                      <label htmlFor="priority_2" className="cursor-pointer">&nbsp;Automatically Calculated Priority</label>
                      <p />
                    </div>
                  </div>
                  
                  <div className="box-footer">
                    <button type="submit" name="process" value="generate" className="btn btn-primary pull-right" style={{ marginBottom: 5 }}>Download Sitemap</button>
                    <button type="submit" name="process" value="update" className="btn btn-success pull-right m-r-10">Update Sitemap</button>
                  </div>
                  
                </form>
              </div>
              
              <div className="callout" style={{ marginTop: 30, backgroundColor: '#fff', borderColor: '#00c0ef', maxWidth: 600 }}>
                <h4>Cron Job</h4>
                <p><strong>http://domain.com/cron/update-sitemap</strong></p>
                <small>With this URL you can automatically update your sitemap.</small>
              </div>
            </div>
          </form>
          
        </div>
      </section>


    </>
  )
}
export default SeoTool