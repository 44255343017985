

import SliderPosts from "../../../Components/postsCompMain/sliderPosts/SliderPosts";
const data = {
  title: "Slider Posts",
}
function SliderPostPage() {
  return (
    <>
      <SliderPosts data={data} />
    </>
  )
}
export default SliderPostPage;