
import { HiOutlineMusicalNote } from "react-icons/hi2"
import { Link } from "react-router-dom";

function AddPostsAudio() {
  return (
    <>
      <div className="col-xs-12 col-sm-4 col-sm-offset-4 col-add-post">
        <Link to="/admin/add-post-audio">
          <div className="item">
            <div className="item-icon">
              <HiOutlineMusicalNote className="iconss music" />
            </div>
            <h5 className="title">Audio</h5>
            <p className="desc">Upload audios and create playlist</p>
          </div>
        </Link>
      </div>
    </>
  )
}
export default AddPostsAudio;