import Accordion from 'react-bootstrap/Accordion';
import { ImImages } from "react-icons/im"

import Editor from "../../commonComponents/editor/Editor"
function AddPostsAccordion() {
  return (
    <>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header className='acco_header'> #1</Accordion.Header>
          <Accordion.Body>
            <div id="collapse_640f29b723414" className="panel-collapse collapse in" style={{}}>
              <div className="panel-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="control-label">Question</label>
                      <input type="text" className="form-control input-post-list-item-title" data-title-id="list_item_title_640f29b723414" name="list_item_title[]" placeholder="Question" defaultValue />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="list-item-description d-flex" style={{}}>
                      <div className="left me-4" style={{ width: "70%" }}>
                        <label className="control-label">Image</label>
                        <div id="post_list_item_image_container_640f29b723414" className="m-b-5">
                          <div className="list-item-image-container">
                            <input type="hidden" name="list_item_image[]" defaultValue />
                            <input type="hidden" name="list_item_image_large[]" defaultValue />
                            <a className="btn-select-image" data-toggle="modal" data-target="#file_manager_image" data-image-type="list_item" data-list-item-id="640f29b723414" data-is-update={0}>
                              <div className="btn-select-image-inner block-img">
                                <ImImages className="iconss images" />
                                <button className="btn">Select Image</button>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="form-group">
                          <input type="text" className="form-control" name="list_item_image_description[]" placeholder="Image Description" maxLength={300} />
                        </div>
                      </div>
                      <div className="right">
                        <label className="control-label">Description</label>
                        <div className="row">
                          <div className="col-sm-12 editor-buttons">
                            <button type="button" className="btn btn-sm btn-success" data-toggle="modal" data-target="#file_manager_image" data-image-type="list_item_editor" data-editor-id="640f29b723414"><i className="fa fa-image" />&nbsp;&nbsp;&nbsp;Add Image</button>
                          </div>
                        </div>
                        <Editor />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  )
}
export default AddPostsAccordion;