
import { VscPlayCircle } from "react-icons/vsc"
import { Link } from "react-router-dom";
function AddPostsVideo() {
  return (
    <>
      <div className="col-xs-12 col-sm-4 col-add-post">
        <Link to="/admin/add-post-video">
          <div className="item">
            <div className="item-icon">
              <VscPlayCircle className="iconss video" />
            </div>
            <h5 className="title">Video</h5>
            <p className="desc">Upload or embed videos</p>
          </div>
        </Link>
      </div>
    </>
  )
}
export default AddPostsVideo;