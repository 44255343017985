
function PostFormats() {
    return (
        <>
            <div className="box box-primary">
                <div className="box-header with-border">
                    <h3 className="box-title">Post Formats</h3>
                </div>
                {/* /.box-header */}
                {/* form start */}
                <form action="https://prernamedia.com/admin_controller/preferences_post" method="post" acceptCharset="utf-8">
                    <input type="hidden" name="varient_csrf_token" defaultValue="b4ab68091002ec2207d835146854e587" />
                    <div className="box-body">
                        {/* include message block */}
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Article</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Enable
                                        </label>
                                    </div>

                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="post_format_article" defaultValue={1} id="post_format_article_1" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="post_format_article_1" className="option-label">Enable</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Disable
                                        </label>
                                    </div>

                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="post_format_article" defaultValue={0} id="post_format_article_2" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="post_format_article_2" className="option-label">Disable</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Gallery</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Enable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="post_format_gallery" defaultValue={1} id="post_format_gallery_1" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="post_format_gallery_1" className="option-label">Enable</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Disable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="post_format_gallery" defaultValue={0} id="post_format_gallery_2" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="post_format_gallery_2" className="option-label">Disable</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Sorted List</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Enable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="post_format_sorted_list" defaultValue={1} id="post_format_sorted_list_1" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="post_format_sorted_list_1" className="option-label">Enable</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Disable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="post_format_sorted_list" defaultValue={0} id="post_format_sorted_list_2" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="post_format_sorted_list_2" className="option-label">Disable</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Video</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault4" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Enable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="post_format_video" defaultValue={1} id="post_format_video_1" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="post_format_video_1" className="option-label">Enable</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault4" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Disable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="post_format_video" defaultValue={0} id="post_format_video_2" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="post_format_video_2" className="option-label">Disable</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Audio</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault5" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Enable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="post_format_audio" defaultValue={1} id="post_format_audio_1" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="post_format_audio_1" className="option-label">Enable</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault5" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Disable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="post_format_audio" defaultValue={0} id="post_format_audio_2" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="post_format_audio_2" className="option-label">Disable</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Trivia Quiz</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault6" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Enable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="post_format_trivia_quiz" defaultValue={1} id="post_format_trivia_quiz_1" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="post_format_trivia_quiz_1" className="option-label">Enable</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault6" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Disable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="post_format_trivia_quiz" defaultValue={0} id="post_format_trivia_quiz_2" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="post_format_trivia_quiz_2" className="option-label">Disable</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Personality Quiz</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault7" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Enable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="post_format_personality_quiz" defaultValue={1} id="post_format_personality_quiz_1" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="post_format_personality_quiz_1" className="option-label">Enable</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault7" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Disable
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="post_format_personality_quiz" defaultValue={0} id="post_format_personality_quiz_2" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="post_format_personality_quiz_2" className="option-label">Disable</label> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /.box-body */}
                    <div className="box-footer">
                        <button type="submit" name="submit" value="post_formats" className="btn btn-primary pull-right" fdprocessedid="tzl64p">Save Changes</button>
                    </div>
                    {/* /.box-footer */}
                    {/* /.box */}
                </form>{/* form end */}
            </div>
        </>
    )
}
export default PostFormats