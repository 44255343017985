

function UserInner() {
    return (
        <>
            <section className="content">
                <div className="box">
                    <div className="box-header with-border">
                        <div className="left">
                            <h3 className="box-title">Users</h3>
                        </div>
                    </div>{/* /.box-header */}
                    <div className="box-body">
                        <div className="row">
                            {/* include message block */}
                            <div className="col-sm-12">
                                {/*print error messages*/}
                                {/*print custom error message*/}
                                {/*print custom success message*/}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    <div id="cs_datatable_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer"><div className="row"><div className="col-sm-6"><div className="dataTables_length" id="cs_datatable_length"><label>Show <select name="cs_datatable_length" aria-controls="cs_datatable" className="form-control input-sm" fdprocessedid="zuea28"><option value={15}>15</option><option value={30}>30</option><option value={60}>60</option><option value={100}>100</option></select></label></div></div><div className="col-sm-6"><div id="cs_datatable_filter" className="dataTables_filter"><label>Search<input type="search" className="form-control input-sm" placeholder aria-controls="cs_datatable" /></label></div></div></div><div className="row"><div className="col-sm-12"><table className="table table-bordered table-striped dataTable no-footer" id="cs_datatable" role="grid" aria-describedby="cs_datatable_info">
                                        <thead>
                                            <tr role="row"><th width={20} className="sorting_desc" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-sort="descending" aria-label="Id: activate to sort column ascending" style={{ width: 20 }}>Id</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Avatar: activate to sort column ascending" style={{ width: '118.025px' }}>Avatar</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Username: activate to sort column ascending" style={{ width: '163.925px' }}>Username</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Email: activate to sort column ascending" style={{ width: '109.062px' }}>Email</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Role: activate to sort column ascending" style={{ width: '94.4625px' }}>Role</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Status: activate to sort column ascending" style={{ width: '116.825px' }}>Status</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Date: activate to sort column ascending" style={{ width: '96.375px' }}>Date</th><th className="max-width-120 sorting" tabIndex={0} aria-controls="cs_datatable" rowSpan={1} colSpan={1} aria-label="Options: activate to sort column ascending" style={{ width: '134.925px' }}>Options</th></tr>
                                        </thead>
                                        <tbody>
                                            <tr className="odd"><td valign="top" colSpan={8} className="dataTables_empty">No data available in table</td></tr></tbody>
                                    </table></div></div><div className="row"><div className="col-sm-5"><div className="dataTables_info" id="cs_datatable_info" role="status" aria-live="polite">Showing 0 to 0 of 0 entries</div></div><div className="col-sm-7"><div className="dataTables_paginate paging_simple_numbers" id="cs_datatable_paginate"><ul className="pagination"><li className="paginate_button previous disabled" id="cs_datatable_previous"><a href="#" aria-controls="cs_datatable" data-dt-idx={0} tabIndex={0}>‹</a></li><li className="paginate_button next disabled" id="cs_datatable_next"><a href="#" aria-controls="cs_datatable" data-dt-idx={1} tabIndex={0}>›</a></li></ul></div></div></div></div>
                                </div>
                            </div>
                        </div>
                    </div>{/* /.box-body */}
                </div>
                {/* Modal */}
                <div id="myModal" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        {/* Modal content*/}
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">×</button>
                                <h4 className="modal-title">Change User Role</h4>
                            </div>
                            <form action="https://prernamedia.com/admin_controller/change_user_role_post" method="post" acceptCharset="utf-8">
                                <input type="hidden" name="varient_csrf_token" defaultValue="88c8a1da67092ac6a195caf1b3c12b18" />
                                <div className="modal-body">
                                    <div className="form-group">
                                        <div className="row">
                                            <input type="hidden" name="user_id" id="modal_user_id" defaultValue />
                                            <div className="col-sm-3">
                                                <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="role" defaultValue="admin" id="role_admin" className="square-purple" required style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>&nbsp;&nbsp;
                                                <label htmlFor="role_admin" className="option-label cursor-pointer">Admin</label>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="role" defaultValue="moderator" id="role_moderator" className="square-purple" required style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>&nbsp;&nbsp;
                                                <label htmlFor="role_moderator" className="option-label cursor-pointer">Moderator</label>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="role" defaultValue="author" id="role_editor" className="square-purple" required style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>&nbsp;&nbsp;
                                                <label htmlFor="role_editor" className="option-label cursor-pointer">Author</label>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="role" defaultValue="user" id="role_user" className="square-purple" required style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>&nbsp;&nbsp;
                                                <label htmlFor="role_user" className="option-label cursor-pointer">User</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-success">Save</button>
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                </div>
                            </form>{/* form end */}
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default UserInner