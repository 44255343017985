
function AdSpaces() {
    return (
        <>
        <section className="content">
  <div className="row">
    <div className="col-sm-12">
      <div className="box box-primary">
        <div className="box-header with-border">
          <h3 className="box-title">Ad Spaces</h3>
        </div>
        {/* /.box-header */}
        <div className="box-body">
          {/* include message block */}
          {/*print error messages*/}
          {/*print custom error message*/}
          {/*print custom success message*/}
          <div className="form-group">
            <label>Select Ad Space</label>
            <select className="form-select custom-select" name="parent_id" onchange="window.location.href = 'https://prernamedia.com/admin/'+'ad-spaces?ad_space='+this.value;" fdprocessedid="71pi39">
              <option value="header" selected>Header</option>
              <option value="index_top">Index (Top)</option>
              <option value="index_bottom">Index (Bottom)</option>
              <option value="post_top">Post Details (Top)</option>
              <option value="post_bottom">Post Details (Bottom)</option>
              <option value="posts_top">Posts (Top)</option>
              <option value="posts_bottom">Posts (Bottom)</option>
              <option value="category_top">Category (Top)</option>
              <option value="category_bottom">Category (Bottom)</option>
              <option value="tag_top">Tag (Top)</option>
              <option value="tag_bottom">Tag (Bottom)</option>
              <option value="search_top">Search (Top)</option>
              <option value="search_bottom">Search (Bottom)</option>
              <option value="profile_top">Profile (Top)</option>
              <option value="profile_bottom">Profile (Bottom)</option>
              <option value="reading_list_top">Reading List (Top)</option>
              <option value="reading_list_bottom">Reading List (Bottom)</option>
              <option value="sidebar_top">Sidebar (Top)</option>
              <option value="sidebar_bottom">Sidebar (Bottom)</option>
            </select>
          </div>
          <form action="https://prernamedia.com/admin_controller/ad_spaces_post" encType="multipart/form-data" method="post" acceptCharset="utf-8">
            <input type="hidden" name="varient_csrf_token" defaultValue="99f4f5e34599f64c40fee306b213d79b" />                                        
            <input type="hidden" name="ad_space" defaultValue="header" />
            <div className="form-group">
              <h4 />
              <p><strong>728x90 Banner</strong></p>
              <div className="row row-ad-space">
                <div className="col-sm-6">
                  <label className="control-label">Ad Code</label>
                  <textarea className="form-control text-area-adspace" name="ad_code_728" placeholder="Ad Code" defaultValue={""} />
                </div>
                <div className="col-sm-6">
                  <label className="control-label">Create Ad Code</label>
                  <input type="text" className="form-control" name="url_ad_code_728" placeholder="Ad URL" fdprocessedid="u8wgz4" />
                  <div className="row m-t-15">
                    <div className="col-sm-12">
                      <a className="btn bg-olive btn-sm btn-file-upload">
                        Select Image                                            <input type="file" name="file_ad_code_728" size={40} accept=".png, .jpg, .jpeg, .gif" onchange="$('#upload-file-info1').html($(this).val().replace(/.*[\/\\]/, ''));" />
                      </a>
                    </div>
                  </div>
                  <span className="label label-info" id="upload-file-info1" />
                </div>
              </div>
              <p className="m-t-15"><strong>468x60 Banner</strong></p>
              <div className="row row-ad-space">
                <div className="col-sm-6">
                  <label className="control-label">Ad Code</label>
                  <textarea className="form-control text-area-adspace" name="ad_code_468" placeholder="Ad Code" defaultValue={""} />
                </div>
                <div className="col-sm-6">
                  <label className="control-label">Create Ad Code</label>
                  <input type="text" className="form-control" name="url_ad_code_468" placeholder="Ad URL" fdprocessedid="q5y8x9" />
                  <div className="row m-t-15">
                    <div className="col-sm-12">
                      <a className="btn bg-olive btn-sm btn-file-upload">
                        Select Image                                            <input type="file" name="file_ad_code_468" size={40} accept=".png, .jpg, .jpeg, .gif" onchange="$('#upload-file-info2').html($(this).val().replace(/.*[\/\\]/, ''));" />
                      </a>
                    </div>
                  </div>
                  <span className="label label-info" id="upload-file-info2" />
                </div>
              </div>
              <p className="m-t-15"><strong>234x60 Banner</strong></p>
              <div className="row row-ad-space">
                <div className="col-sm-6">
                  <label className="control-label">Ad Code</label>
                  <textarea className="form-control text-area-adspace" name="ad_code_234" placeholder="Ad Code" defaultValue={""} />
                </div>
                <div className="col-sm-6">
                  <label className="control-label">Create Ad Code</label>
                  <input type="text" className="form-control" name="url_ad_code_234" placeholder="Ad URL" fdprocessedid="f6i0oc" />
                  <div className="row m-t-15">
                    <div className="col-sm-12">
                      <a className="btn bg-olive btn-sm btn-file-upload">
                        Select Image                                            <input type="file" name="file_ad_code_234" size={40} accept=".png, .jpg, .jpeg, .gif" onchange="$('#upload-file-info3').html($(this).val().replace(/.*[\/\\]/, ''));" />
                      </a>
                    </div>
                  </div>
                  <span className="label label-info" id="upload-file-info3" />
                </div>
              </div>
              <div className="row row-ad-space row-button">
                <div className="col-sm-12">
                  <button type="submit" className="btn btn-primary pull-right" fdprocessedid="x9h2u">Save Changes</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* /.box */}
    </div>
  </div>
  <div className="row">
    <div className="col-lg-6 col-md-12">
      <div className="box box-primary">
        <div className="box-header with-border">
          <h3 className="box-title">AdSense Activation Code</h3><small className="small-title">These codes will be added to the footer of the site.</small>
        </div>
        {/* /.box-header */}
        {/* form start */}
        <form action="https://prernamedia.com/admin_controller/google_adsense_code_post" method="post" acceptCharset="utf-8">
          <input type="hidden" name="varient_csrf_token" defaultValue="99f4f5e34599f64c40fee306b213d79b" />
          <div className="box-body">
            {/* include message block */}
            <div className="form-group">
              <textarea name="adsense_activation_code" className="form-control" placeholder="AdSense Activation Code" style={{minHeight: 140}} defaultValue={""} />
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary pull-right" fdprocessedid="0xzaa">Save Changes</button>
            </div>
          </div>
          {/* /.box-footer */}
          {/* /.box */}
        </form>{/* form end */}
      </div>
    </div>
  </div>
  <style dangerouslySetInnerHTML={{__html: "\n    textarea {\n        height: 100px !important;\n    }\n\n    h4 {\n        color: #0d6aad;\n        text-align: left;\n        font-weight: 600;\n        margin-bottom: 15px;\n        margin-top: 30px;\n    }\n\n    .row-ad-space {\n        padding: 15px 0;\n        background-color: #f7f7f7;\n    }\n\n    .row-button {\n        background-color: transparent !important;\n        min-height: 60px;\n    }\n" }} />
</section>

        </>
    )
}
export default AdSpaces