
function Homepage() {
    return (
        <>
            <div className="box box-primary">
                <div className="box-header with-border">
                    <h3 className="box-title">Homepage</h3>
                </div>
                {/* /.box-header */}
                {/* form start */}
                <form action="https://prernamedia.com/admin_controller/preferences_post" method="post" acceptCharset="utf-8">
                    <input type="hidden" name="varient_csrf_token" defaultValue="b4ab68091002ec2207d835146854e587" />
                    <div className="box-body">
                        {/* include message block */}
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Show Featured Section</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault">
                                            Yes
                                        </label>
                                    </div>

                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="show_featured_section" defaultValue={1} id="show_featured_section_1" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="show_featured_section_1" className="option-label">Yes</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault">
                                        No
                                        </label>
                                    </div>

                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="show_featured_section" defaultValue={0} id="show_featured_section_2" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="show_featured_section_2" className="option-label">No</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Show Latest Posts on Homepage</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                            Yes
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" name="show_latest_posts" defaultValue={1} id="show_latest_posts_1" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="show_latest_posts_1" className="option-label">Yes</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        No
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" name="show_latest_posts" defaultValue={0} id="show_latest_posts_2" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="show_latest_posts_2" className="option-label">No</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Show News Ticker</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault3">
                                            Yes
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" id="show_newsticker_1" name="show_newsticker" defaultValue={1} className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="show_newsticker_1" className="cursor-pointer">Yes</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault3">
                                        No
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" id="show_newsticker_2" name="show_newsticker" defaultValue={0} className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="show_newsticker_2" className="cursor-pointer">No</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Sort Slider Posts</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault3">
                                        by Slider Order
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" id="sort_slider_posts_1" name="sort_slider_posts" defaultValue="by_slider_order" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="sort_slider_posts_1" className="cursor-pointer">by Slider Order</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault3">
                                        by Date
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" id="sort_slider_posts_2" name="sort_slider_posts" defaultValue="by_date" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="sort_slider_posts_2" className="cursor-pointer">by Date</label> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <label>Sort Featured Posts</label>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault4" id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault4">
                                        by Featured Order
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple" style={{ position: 'relative' }}><input type="radio" id="sort_featured_posts_1" name="sort_featured_posts" defaultValue="by_featured_order" className="square-purple" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="sort_featured_posts_1" className="cursor-pointer">by Featured Order</label> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-option">
                                <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault4" id="flexRadioDefault2" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault4">
                                        by Date
                                        </label>
                                    </div>
                                    {/* <div className="iradio_square-purple checked" style={{ position: 'relative' }}><input type="radio" id="sort_featured_posts_2" name="sort_featured_posts" defaultValue="by_date" className="square-purple" defaultChecked style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /><ins className="iCheck-helper" style={{ position: 'absolute', top: '-20%', left: '-20%', display: 'block', width: '140%', height: '140%', margin: 0, padding: 0, background: 'rgb(255, 255, 255)', border: 0, opacity: 0 }} /></div>
                                    <label htmlFor="sort_featured_posts_2" className="cursor-pointer">by Date</label> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /.box-body */}
                    <div className="box-footer">
                        <button type="submit" name="submit" value="homepage" className="btn btn-primary pull-right" fdprocessedid="u4h4nd">Save Changes</button>
                    </div>
                    {/* /.box-footer */}
                    {/* /.box */}
                </form>{/* form end */}
            </div>
        </>
    )
}
export default Homepage