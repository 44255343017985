import { Link } from "react-router-dom"

function Feed() {
    return (
        <>
            <section className="content">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="box">
                            <div className="box-header with-border">
                                <div className="left">
                                    <h3 className="box-title">RSS Feeds</h3>
                                </div>
                                <div className="right">
                                    <Link to="/admin/import-feed" className="btn btn-success btn-add-new">
                                        <i className="fa fa-plus" />
                                        Import RSS Feed                  </Link>
                                </div>
                            </div>{/* /.box-header */}
                            <div className="box-body">
                                <div className="row">
                                    {/* include message block */}
                                    <div className="col-sm-12">
                                        {/*print error messages*/}
                                        {/*print custom error message*/}
                                        {/*print custom success message*/}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="table-responsive">
                                            <div id="cs_datatable_lang_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer"><div className="dataTables_length" id="cs_datatable_lang_length"><label>Show <select name="cs_datatable_lang_length" aria-controls="cs_datatable_lang" className="form-control input-sm"><option value={15}>15</option><option value={30}>30</option><option value={60}>60</option><option value={100}>100</option></select></label></div><div id="table_dropdown"><label className="table-label">Language</label><select className="form-control input-sm"><option value>All</option><option value="English">English</option><option value="Hindi">Hindi</option></select></div><div id="cs_datatable_lang_filter" className="dataTables_filter"><label>Search<input type="search" className="form-control input-sm" placeholder aria-controls="cs_datatable_lang" /></label></div><table className="table table-bordered table-striped dataTable no-footer" id="cs_datatable_lang" role="grid" aria-describedby="cs_datatable_lang_info">
                                                <thead>
                                                    <tr role="row"><th width={20} className="sorting_desc" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-sort="descending" aria-label="Id: activate to sort column ascending" style={{ width: 20 }}>Id</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Feed Name: activate to sort column ascending" style={{ width: '127.825px' }}>Feed Name</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Feed URL: activate to sort column ascending" style={{ width: '110.912px' }}>Feed URL</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Language: activate to sort column ascending" style={{ width: '114.412px' }}>Language</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Category: activate to sort column ascending" style={{ width: '107.275px' }}>Category</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Posts: activate to sort column ascending" style={{ width: '73.2125px' }}>Posts</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Auto Update: activate to sort column ascending" style={{ width: '139.738px' }}>Auto Update</th><th className="sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label=": activate to sort column ascending" style={{ width: '22.95px' }} /><th className="max-width-120 sorting" tabIndex={0} aria-controls="cs_datatable_lang" rowSpan={1} colSpan={1} aria-label="Options: activate to sort column ascending" style={{ width: '96.475px' }}>Options</th></tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="odd"><td valign="top" colSpan={9} className="dataTables_empty">No data available in table</td></tr></tbody>
                                            </table><div className="dataTables_info" id="cs_datatable_lang_info" role="status" aria-live="polite">Showing 0 to 0 of 0 entries</div><div className="dataTables_paginate paging_simple_numbers" id="cs_datatable_lang_paginate"><ul className="pagination"><li className="paginate_button previous disabled" id="cs_datatable_lang_previous"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={0} tabIndex={0}>‹</a></li><li className="paginate_button next disabled" id="cs_datatable_lang_next"><a href="#" aria-controls="cs_datatable_lang" data-dt-idx={1} tabIndex={0}>›</a></li></ul></div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* /.box-body */}
                        </div>
                        <div className="alert alert-danger alert-large" style={{ maxWidth: 1000 }}>
                            <strong>Warning!</strong>&nbsp;&nbsp;If you chose to download the images to your server, adding posts will take more time and will use more resources. If you see any problems, increase 'max_execution_time' and 'memory_limit' values from your server settings.      </div>
                        <div className="callout" style={{ marginTop: 30, backgroundColor: '#fff', borderColor: '#00c0ef', maxWidth: 600 }}>
                            <h4>Cron Job</h4>
                            <p><strong>http://domain.com/cron/update-feeds</strong></p>
                            <small>With this URL you can automatically update your feeds.</small>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default Feed