function Footer() {
  return (
    <>
      <footer id="footer" className="main-footer">
        <div className="pull-right hidden-xs">
          <strong style={{ fontWeight: 600 }}>
            Copyright © 2020 Prerna News India | All Rights Reserved. Powered
            By: Abaris Softech&nbsp;
          </strong>
        </div>
        <b>Version</b> 1.7
      </footer>
    </>
  );
}
export default Footer;
