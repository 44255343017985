

import EmailSetting from "../../Components/emailSettings/EmailSettings"

function EmailSettingsPage() {
  return (
    <>
      <EmailSetting/>
    </>
  )
}
export default EmailSettingsPage