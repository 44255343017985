import Administrators from "../../../Components/users/administrators/Administrators"


function AdministratorsPage() {
    return (
        <>
        <Administrators/>
        </>
    )
}
export default AdministratorsPage