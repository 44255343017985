import PendingPosts from "../../../Components/postsCompMain/pendingPosts/PendingPosts";

const pendingData = {
  title:"Pending Posts",
  cron:"Cron Job",
  link:"http://domain.com/cron/check-scheduled-posts",
  paragraph:"If you want to automatically publish scheduled posts, you should create a Cron Job function with this URL. For more information, please read the documentation."

}
function PendingPostsPage() {
  return (
    <>
      <PendingPosts val={pendingData}/>
    </>
  )
}
export default PendingPostsPage;