
import { BsListCheck } from "react-icons/bs"
import { Link } from "react-router-dom";
function AddPostsTriviaQuiz() {
  return (
    <>
      <div className="col-xs-12 col-sm-4 col-add-post">
        <Link to="/admin/addPost-trivia-quiz">
          <div className="item">
            <div className="item-icon">
              <BsListCheck className="iconss trivia-quiz" />
            </div>
            <h5 className="title">Trivia Quiz</h5>
            <p className="desc">Quizzes with right and wrong answers</p>
          </div>
        </Link>
      </div>
    </>
  )
}
export default AddPostsTriviaQuiz;