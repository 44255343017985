import { TfiGallery } from "react-icons/tfi"
import { Link } from "react-router-dom";
function AddPostsGallery() {
  return (
    <>
      <div className="col-xs-12 col-sm-4 col-add-post">
        <Link to="/admin/add-post-gallery">
          <div className="item">
            <div className="item-icon">
              <TfiGallery className="iconss gallery" />
            </div>
            <h5 className="title">Gallery</h5>
            <p className="desc">A collection of images</p>
          </div>
        </Link>
      </div>
    </>
  )
}
export default AddPostsGallery;