import { CiCircleQuestion } from "react-icons/ci"
import { Link } from "react-router-dom"

function AddPostsPersonalityQuiz(){
  return (
    <>
      <div className="col-xs-12 col-sm-4 col-add-post">
        <Link to="/admin/add-post-personality_quiz">
          <div className="item">
            <div className="item-icon">
              <CiCircleQuestion className="iconss personality-quiz" />
            </div>
            <h5 className="title">Personality Quiz</h5>
            <p className="desc">Quizzes with custom results</p>
          </div>
        </Link>
      </div>
    </>
  )
}
export default AddPostsPersonalityQuiz